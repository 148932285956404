import {
  postalCodeRegex,
  telephoneRegex,
  companyNumRegex,
  emailRegex,
  payerIDRegex,
  shipToIDRegex,
} from '../../../../utils/Utils';

export function validateFields(values, payerSap, copyClick) {
  let errors = {};
  const mainPageForm =
    sessionStorage.getItem('createCustomerMainPage') === null
      ? {}
      : JSON.parse(sessionStorage.getItem('createCustomerMainPage'));

  const country = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')).country;

  if (copyClick) {
    if (values.existingShipToID == '') {
      errors.existingShipToID = 'ShipTo ID is required';
    } else if (values.existingShipToID != '' && !shipToIDRegex.test(values.existingPayerID)) {
      errors.existingShipToID = 'ShipTo ID is incorrect';
    }
  } else {
    if (values.name != '' && !copyClick) {
      if (values.postalCode === '') {
        errors.postalCode = 'Postal Code is required';
      } else if (values.postalCode !== '' && !postalCodeRegex[country].test(values.postalCode)) {
        errors.postalCode = 'Postal code is incorrect';
      } else if (values.street === '') {
        errors.street = 'Street is required';
      } else if (values.city === '') {
        errors.city = 'City is required';
      } else if (values.region == '' && !['Luxembourg'].includes(country)) {
        errors.region = 'Region is required';
      } else if (values.houseNumber === '') {
        errors.houseNumber = 'House Number is required';
      } else if (['Netherlands', 'Germany'].includes(country) && values.poBox == '') {
        errors.poBox = 'PO Box is required';
      } else if (['Netherlands', 'Germany'].includes(country) && values.poBoxPostalCode == '') {
        errors.poBoxPostalCode = 'PO Box Postal Code is required';
      }
    }
  }

  return errors;
}
