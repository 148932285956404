import {
  ibanRegex,
  postalCodeRegex,
  telephoneRegex,
  emailRegex,
  payerIDRegex,
  vatRegex,
  companyNumRegex,
} from '../../../utils/Utils';

export function validateFields(values) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country;

  const customerReqType = JSON.parse(sessionStorage.getItem('createCustomerMainPage'))
    .customerReqType;

  const shipToType = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).shipToType;

  if (values.existingPayerID === '') {
    errors.existingPayerID = 'Please provide correct Vendor ID';
  } else if (values.name == '') {
    errors.existingPayerID = 'Click Copy';
  }
  return errors;
}
