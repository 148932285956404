import React from 'react';

import { Container, Row, Col, Button } from 'react-bootstrap';

const NoInternet = (props) => {
  return (
    <Container className="center-page">
      <Row>
        <Col xs={12}>
          <h3>{`Sorry, the CMD Portal isn't loading right now.`}</h3>
        </Col>
        <Col xs={12}>{`Please check your network connection and try again.`}</Col>
        <Col style={{ marginTop: '2rem' }}>
          <Button
            variant="outline-secondary"
            onClick={() => {
              window.location.reload();
            }}
          >
            Try Again
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default NoInternet;
