import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Button, ButtonGroup, Row, Col, Container, Modal, Spinner } from 'react-bootstrap';
import MandatoryText from '../../../components/MandatoryText';

import { validateFields } from './utils/FormValidation';
import { FetchShipToBapi, FetchPartnerTypes, FetchEDTypes } from '../utils/FlowCallAPI';
import { useFormFields } from './utils/HooksLib';
import { formTemplate } from '../Data';
import { postalCodeLength } from '../../../utils/Utils';

const UpdateTruckTrailerUpdate = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  const [translations, setTranslations] = useState(props.translations);

  const [inputs, setInputs] = useFormFields(props.formTemplate);

  useEffect(() => {
    // if (!isAuthenticated) navigate('/');
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    console.log(inputs);
    if (
      props.draftTicketNum[0] !== 'M' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected'
    ) {
      setIsDisable(true);
    }

    if (inputs.name != '') setIsDisableFields(false);
  }, []);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkForward, setLinkForward] = useState('');
  const [isCopying, setIsCopying] = useState(false);
  const [copyClick, setCopyClick] = useState(false);

  const [isDisableFields, setIsDisableFields] = useState(true);

  const mainPageForm = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')) || {};

  const shipToDataRes = FetchShipToBapi(
    inputs.existingShipToID,
    mainPageForm.companyCode,
    mainPageForm.salesOrg,
    isCopying,
  );

  // const partnerTypesRes = FetchPartnerTypes(mainPageForm.country);

  // const EDTypesRes = FetchEDTypes(mainPageForm.country);

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(validateFields(inputs));
      setIsSubmitting(true);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      props.setActivePage(linkForward);
    }
  }, [errors]);

  function getLang(id) {
    // console.log(props.translations);
    let obj = translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    setTranslations(props.translations);
  }, [props.translations]);

  function copyShipToData(e) {
    setCopyClick(true);
    setErrors(validateFields(inputs, true));
    console.log(`I am true ${isCopying}`);
  }

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && copyClick) {
      console.log(inputs.name, isDisableFields, copyClick);
      setCopyClick(false);
      setIsCopying(true);
      Object.entries(formTemplate.truckTrailerUpdate).forEach(([key, value]) => {
        if (!['existingShipToID'].includes(key)) inputs[key] = value;
      });
    }
  }, [errors]);

  useEffect(() => {
    if (Object.keys(shipToDataRes.shipToData).length !== 0) {
      Object.entries(shipToDataRes.shipToData).forEach(([key, value]) => {
        if (key in inputs) {
          // if (key === 'telephone' && value !== '') inputs.telephoneOption = 'General/Accounting';

          inputs[key] = value;
        }
      });
      sessionStorage.setItem('updateTruckTrailerUpdate', JSON.stringify(inputs));
      setIsDisableFields(false);
    }
    setIsCopying(false);
  }, [shipToDataRes.shipToData]);

  return (
    <form className="card" onSubmit={handleSubmit}>
      <Modal show={isCopying} centered>
        <Modal.Body>
          <Row>
            <div className="col-auto">
              <Spinner animation="border" variant="warning" />
            </div>
            <div className="text-left">
              <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
      <div className="card-header">
        <div className="col-12">
          <h3 className="card-title text-left">
            <strong>Customer Data {getLang('Update')}</strong>
          </h3>
        </div>
      </div>
      <Row className="sidebar-row">
        <div className="col-12">
          <div className="card-body">
            <Row>
              <Col sm={12} lg="auto">
                <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                  {getLang('Truck / Trailer Type Update')}
                </h2>
              </Col>
            </Row>
            <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

            <div className="row">
              <div className="col-lg-12">
                <Row>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">
                        {getLang('Existing Ship-to ID')} *
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control${errors.existingShipToID ? ' is-invalid' : ''}`}
                          name="existingShipToID"
                          maxLength="8"
                          placeholder={`Enter ${getLang('Existing Ship-to ID')}`}
                          value={inputs.existingShipToID}
                          onChange={setInputs}
                          disabled={isDisable}
                        />
                        <span className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="button"
                            style={{
                              borderTopRightRadius: '3px',
                              borderBottomRightRadius: '3px',
                            }}
                            onClick={copyShipToData}
                            disabled={isDisable || inputs.existingShipToID == ''}
                          >
                            {getLang('Copy')}
                          </button>
                        </span>
                        {errors.existingShipToID && (
                          <div className="invalid-feedback text-left">
                            {errors.existingShipToID}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Row>
                <hr />
                <Row>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Name')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                        name="name"
                        placeholder={`${getLang('Name')}`}
                        value={inputs.name}
                        onChange={setInputs}
                        disabled
                      />
                      {errors.name && (
                        <div className="invalid-feedback text-left">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Country')}</label>
                      <input
                        type="text"
                        className="form-control"
                        name="country"
                        value={mainPageForm.country}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Sales Org')}</label>
                      <input
                        type="text"
                        className="form-control"
                        value={mainPageForm.salesOrg}
                        readOnly
                      />
                    </div>
                  </div> */}
                  {/* <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Street')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.street ? 'is-invalid' : ''}`}
                        name="street"
                        value={inputs.street}
                        onChange={setInputs}
                        maxLength="35"
                        disabled
                      />
                      {errors.street && (
                        <div className="invalid-feedback text-left">{errors.street}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Postal Code')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.postalCode ? 'is-invalid' : ''}`}
                        name="postalCode"
                        maxLength={postalCodeLength(mainPageForm?.country)}
                        value={inputs.postalCode}
                        onChange={setInputs}
                        disabled
                      />
                      {errors.postalCode && (
                        <div className="invalid-feedback text-left">{errors.postalCode}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('City')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                        name="city"
                        value={inputs.city}
                        maxLength="35"
                        onChange={setInputs}
                        // disabled={inputs.name == '' || isDisable}
                        disabled
                      />
                      {errors.city && (
                        <div className="invalid-feedback text-left">{errors.city}</div>
                      )}
                    </div>
                  </div> */}
                  {/* </Row>
                <Row> */}
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Truck Type')} *</label>
                      <select
                        className={`form-control ${errors.truckType ? 'is-invalid' : ''}`}
                        name="truckType"
                        value={inputs.truckType}
                        onChange={setInputs}
                        disabled={isDisableFields || isDisable}
                        required
                      >
                        <option value="">Select</option>
                        <option value="ZITTR3 - TRACTOR 3 AXELS">{`ZITTR3 - TRACTOR 3 AXELS`}</option>
                      </select>
                      {errors.truckType && (
                        <div className="invalid-feedback text-left">{errors.truckType}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Trailer Type')} *</label>
                      <select
                        className={`form-control ${errors.trailerType ? 'is-invalid' : ''}`}
                        name="trailerType"
                        value={inputs.trailerType}
                        onChange={setInputs}
                        disabled={isDisableFields || isDisable}
                        required
                      >
                        <option value="">Select</option>
                        <option value="ZITMOTRI - MOTRICE (16 PLT)">{`ZITMOTRI - MOTRICE (16 PLT)`}</option>
                        <option value="ZITAN3 - TRAILER 3 AXELS">{`ZITAN3 - TRAILER 3 AXELS`}</option>
                      </select>
                      {errors.trailerType && (
                        <div className="invalid-feedback text-left">{errors.trailerType}</div>
                      )}
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <br />

      <div className="card-footer">
        <div className="row">
          {!isDisable && (
            <div className="col-auto col-md-auto text-left">
              <Button variant="outline-warning" onClick={props.saveDraft}>
                Save Draft
              </Button>
            </div>
          )}
          <div className="col col-md text-left">
            <span className="align-middle">{props.draftMsg}</span>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="mainPage"
              data-back="true"
              className="btn btn-warning btn-block"
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Back
            </Link>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="truckTrailerUpdateReview"
              className={`btn btn-danger btn-block${
                inputs.existingShipToID == '' ||
                inputs.name == '' ||
                inputs.truckType == '' ||
                inputs.trailerType == ''
                  ? ' disabled'
                  : ''
              }`}
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Review
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateTruckTrailerUpdate;
