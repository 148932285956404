import React from 'react';
const CountrySelect = ({
  country,
  errors,
  getLang,
  inputs,
  setInputs,
  isDisable,
  countriesRes,
  value,
  props,
  status,
}) => {
  console.log('country select ', countriesRes);
  return (
    <div className="form-group">
      <label className="form-label text-left">{getLang('Country')} *</label>
      <select
        id="select-country"
        className={`form-control ${errors.country ? 'is-invalid-select' : ''}`}
        name="country"
        onChange={setInputs}
        value={value}
        required
        disabled={isDisable || status === 'Rejected' || status === 'Duplicate'}
      >
        <option value="">{getLang('Select')}</option>

        {countriesRes?.countries.map((val) => (
          <option
            key={val.countryCode}
            value={val.country}
            data-companycode={val.companyCode}
            data-salesorg={val.salesOrg}
          >
            {val.country}
          </option>
        ))}
      </select>
      {errors.country && (
        <div className="invalid-feedback text-left">{getLang(errors.country)}</div>
      )}
    </div>
  );
};

export default CountrySelect;
