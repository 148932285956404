import { useState, useEffect } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);
  const [fileB64Str, setFileB64Str] = useState('');
  const [fileName, setFileName] = useState('');

  const encodeFileB64 = (file, link) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var b64 = reader.result;
        console.log(`B64 = ${b64}`);
        setFileB64Str(b64);
        setFileName(file.name);
      };
      reader.onerror = (err) => {
        console.log(`Error: ${err}`);
      };
    }
  };

  useEffect(() => {
    sessionStorage.setItem('updatePaymentTermChange', JSON.stringify(fields));
  }, [fields]);
  console.log('fields', fields);

  useEffect(() => {
    console.log('lalal');
    if (fileB64Str !== '' && fileName !== '') {
      setValues({
        ...fields,
        ['supportDocsBank']: fileB64Str,
        ['supportDocsBankName']: fileName,
      });
    }
  }, [fileB64Str, fileName]);

  return [
    fields,
    function (event, action) {
      if (action?.name == 'paymentTerms') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            paymentTerms: '',
            paymentTermsDesc: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            paymentTerms: event.termsOfPayment,
            paymentTermsDesc: event.paymentVal,
          });
        }
      } else if (action?.name == 'risk') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            risk: '',
            riskDesc: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            risk: event.riskCode,
            riskDesc: event.riskDesc,
          });
        }
      } else if (event.target.name == 'existingPayerID') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          name: '',
          // name2: '',
          // name3: '',
          street: '',
          houseNumber: '',
          postalCode: '',
          city: '',
          // region: '',
          // regionDesc: '',
          // poBox: '',
          // poBoxPostalCode: '',
          // telephone: '',
          // mobile: '',
          // email: '',
        });
      } else if (event.target.name === 'region') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          regionDesc: event.target.selectedOptions[0].getAttribute('data-region'),
        });
      } else if (event.target.name === 'supportDocsBank') {
        encodeFileB64(event.target.files[0], event.target.value);
        // console.log(`Res: ${fileB64Str}`);
      } else if (event.target.id === 'clearFile') {
        console.log('sup');
        setValues({
          ...fields,
          ['supportDocsBank']: '',
          ['supportDocsBankName']: '',
        });
      } else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
    },
  ];
}
