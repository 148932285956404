import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import ErrorIcon from '../../../assets/images/error.png';

import { Button, ButtonGroup, Row, Col } from 'react-bootstrap';

const UpdateCustSaveError = (props) => {
  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    props.setActivePage(e.target.getAttribute('data-link'));
  };

  function getLang(id) {
    let obj = props.translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  return (
    <form className="card">
      <div className="card-header">
        <div className="col-12 col-lg-12">
          <h3 className="card-title text-left">
            <strong>Customer Data {getLang('Update')}</strong> - {getLang('Error')}
          </h3>
        </div>
      </div>
      <Row className="sidebar-row">
        <div className="col-12 col-lg-12">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-lg-auto">
                <img src={ErrorIcon} height="50px" />
              </div>
              <div className="col-auto justify-content-center align-self-center">
                <span>{getLang('Request was unsuccessfull, please resubmit')}</span>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <br />

      <div className="card-footer">
        <div className="row">
          <div className="col col-md text-left justify-content"></div>
          {/* <div className="col-auto col-md-auto text-right">
            <Link
              data-link="review"
              data-back="true"
              className="btn btn-warning btn-block"
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Back
            </Link>
          </div> */}
          <div className="col-auto col-md-auto text-right">
            <Link
              to="/"
              className="btn btn-danger btn-block"
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              View Requests
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateCustSaveError;
