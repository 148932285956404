import { useState, useEffect } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  useEffect(() => {
    sessionStorage.setItem('updateRouteToMarketUpdate', JSON.stringify(fields));
  }, [fields]);

  return [
    fields,
    function (event) {
      if (event.target.name == 'existingShipToID') {
       
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          name: '',
          street: '',
          postalCode:'',
          city: '',
          // attributeType5: '',
          // deliveringPlant: '',
          // shippingCondition: ''

        });
      } else if (['attributeType5'].includes(event.target.name)) {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          
        });
    } else if (event.target.name === 'deliveringPlant' && event.target.value !== '') {
      setValues({
        ...fields,
        [event.target.name]: event.target.value,
        deliveringPlantDesc: event.target.selectedOptions[0].getAttribute('data-plant'),
      });
    } else if (event.target.name === 'shippingCondition' && event.target.value !== '') {
      setValues({
        ...fields,
        [event.target.name]: event.target.value,
        shippingConditionDesc: event.target.selectedOptions[0].getAttribute('data-shipcondn'),
      });
      } else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
    },
  ];
}
