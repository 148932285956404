import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Button, ButtonGroup, Row, Col } from 'react-bootstrap';
import MandatoryText from '../../../components/MandatoryText';

import { validateFields } from './utils/FormValidation';
import { FetchCountries, FetchCustomerReqTypes, FetchShipToTypes } from './utils/FlowCallAPI';
import { fetchUserRoleAccessCountry } from '../../createcustomer/mainpage/FlowCallAPI';
import { useFormFields } from './utils/HooksLib';

const UpdateCustMainPage = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  const [translations, setTranslations] = useState(props.translations);

  useEffect(() => {
    // if (!isAuthenticated) navigate('/');
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    console.log(inputs);
    console.log('LALLAAL', props.draftTicketNum);
    if (props.draftTicketNum[0] !== 'M') {
      setIsDisable(true);
    }
    // if (!props.newRequest) {
    //   setIsDisable(true);
    // }
  }, []);

  const countriesRes = fetchUserRoleAccessCountry(
    accounts[0].username != '' ? accounts[0].username : '',
  );

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkForward, setLinkForward] = useState('');

  const [isSave, setIsSave] = useState(false);
  const [draftMsg, setDraftMsg] = useState('');

  const saveDraft = (e) => {
    // console.log(props.draftTicketNum);
    if (inputs.country) {
      setIsSave(true);
    }
  };
  const [inputs, setInputs] = useFormFields(props.formTemplate);

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    setErrors(validateFields(inputs));
    setIsSubmitting(true);
    // setLinkForward(e.target.getAttribute('data-link'));
  };
  console.log(inputs.reqPage);
  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      // navigate(linkForward);
      props.setActivePage(inputs.reqPage);
    }
  }, [errors]);

  // const fetchCustReqTypesRes = FetchCustomerReqTypes(inputs.country);
  const fetchShipToTypesRes = FetchShipToTypes(inputs.country);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  function getLang(id) {
    // console.log(props.translations);
    let obj = translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  function setReqInput(e) {
    setLinkForward(e.target.selectedOptions[0].getAttribute('data-page'));
    setInputs(e);
  }

  useEffect(() => {
    setTranslations(props.translations);
  }, [props.translations]);

  return (
    <form className="card" onSubmit={handleSubmit}>
      <div className="card-header">
        <div className="col-11 col-lg-12">
          <h3 className="card-title text-left">
            <strong>Customer Data {getLang('Update')}</strong>
          </h3>
        </div>
        <a
          className={`sidebar-toggler col-auto ${isSidebarOpen ? '' : 'collapsed'}`}
          onClick={togglingSidebar}
        >
          <span className="sidebar-toggler-icon"></span>
        </a>
      </div>
      <Row className="sidebar-row">
        <div className="col-12 col-lg-12">
          <div className="card-body">
            <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

            <div className="row">
              <div className="col-12 col-lg-3">
                <div className="form-group">
                  <label className="form-label text-left">{getLang('Country')} *</label>
                  <select
                    id="select-country"
                    className={`form-control ${errors.country ? 'is-invalid-select' : ''}`}
                    name="country"
                    onChange={setInputs}
                    value={inputs.country}
                    disabled={
                      isDisable || props.status === 'Rejected' || props.status === 'Duplicate'
                    }
                  >
                    <option value="">Select</option>
                    {/* {process.env.REACT_APP_ENV === 'prod' && countriesRes.countries
                      .filter((e) => !['France'].includes(e.country))
                      .map((val) => (
                        <option key={val.countryCode} value={val.country} data-companycode={val.companyCode} data-salesorg={val.salesOrg}>
                          {val.country}
                        </option>
                      ))} */}
                    {/* {process.env.REACT_APP_ENV == 'prod' &&
                      countriesRes.countries
                        .filter((e) =>
                          [
                            'Belgium',
                            'France',
                            'Germany',
                            'Netherlands',
                            'United Kingdom',
                          ].includes(e.country),
                        )
                        .map((val) => (
                          <option
                            key={val.countryCode}
                            value={val.country}
                            data-companycode={val.companyCode}
                            data-salesorg={val.salesOrg}
                          >
                            {val.country}
                          </option>
                        ))} */}
                    {/* {process.env.REACT_APP_ENV != 'prod' && */}
                    {countriesRes.countries.map((val) => (
                      <option
                        key={val.countryCode}
                        value={val.country}
                        data-companycode={val.companyCode}
                        data-salesorg={val.salesOrg}
                      >
                        {val.country}
                      </option>
                    ))}
                  </select>
                  {errors.country && (
                    <div className="invalid-feedback text-left">{errors.country}</div>
                  )}
                </div>
              </div>
              {inputs.country == 'Belgium' && (
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label className="form-label text-left">{getLang('Ship-to Type')} *</label>
                    <select
                      className={`form-control ${errors.shipToType ? 'is-invalid-select' : ''}`}
                      name="shipToType"
                      onChange={setInputs}
                      value={inputs.shipToType}
                      disabled={isDisable}
                    >
                      <option value="">Select</option>
                      <option value="Wholesaler">WholseSaler</option>
                      {/* <option value="">No</option> */}
                      {fetchShipToTypesRes.shipToTypes
                        .filter(
                          (e) =>
                            ![
                              'Wholesaler - Main Agent',
                              'Wholesaler - Sub Agent',
                              'Miscellaneous',
                              'Wholesaler - On Trade',
                            ].includes(e.shipToType),
                        )
                        .map((shipType) =>
                          shipType.shipToType.includes('Direct') ? (
                            <option
                              key={shipType.shipToType}
                              value={shipType.shipToType}
                              style={{ fontWeight: 'bold' }}
                            >
                              {shipType.shipToType}
                            </option>
                          ) : (
                            <option key={shipType.shipToType} value={shipType.shipToType}>
                              {shipType.shipToType}
                            </option>
                          ),
                        )}
                    </select>
                    {errors.shipToType && (
                      <div className="invalid-feedback text-left">{errors.shipToType}</div>
                    )}
                  </div>
                </div>
              )}
              <div className="col-12 col-lg-auto">
                <div className="form-group">
                  <label className="form-label text-left">{getLang('Request Type')} *</label>
                  <select
                    id="select-req"
                    name="reqType"
                    className={`form-control ${errors.reqType ? 'is-invalid-select' : ''}`}
                    onChange={setReqInput}
                    value={inputs.reqType}
                    disabled={
                      isDisable ||
                      inputs.country == '' ||
                      props.status === 'Rejected' ||
                      props.status === 'Duplicate'
                    }
                  >
                    <option value="">Select</option>
                    <option value="Block/Unblock" data-page="blockUnblock">
                      Block/Unblock
                    </option>
                    <option value="Address Modification" data-page="payerUpdate">
                      Payer Modification
                    </option>
                    {(inputs?.shipToType == 'Wholesaler' ||
                      !['Belgium'].includes(inputs?.country)) && (
                      <option
                        value="Ship-to & Sold To Modification"
                        data-page="nameDelivPlantUpdate"
                      >
                        {'Ship-to & Sold To Modification'}
                      </option>
                    )}
                    {!['Belgium', 'France'].includes(inputs.country) && (
                      <option value="VAT & Tax Number update" data-page="vatTaxUpdate">
                        {'VAT & Tax Number update'}
                      </option>
                    )}
                    <option value="IBAN change/update" data-page="ibanUpdate">
                      IBAN change/update
                    </option>
                    <option value="Payer E-Invoicing update" data-page="eInvPayerUpdate">
                      Payer E-Invoicing update
                    </option>
                    <option value="Bill-To E-Invoicing update" data-page="eInvBillUpdate">
                      Bill-To E-Invoicing update
                    </option>
                    {!['Germany', 'Italy', 'Netherlands', 'Belgium', 'France'].includes(
                      inputs.country,
                    ) && (
                      <option value="Payment Term Update" data-page="paymentTermupdate">
                        Payment Term Update
                      </option>
                    )}
                    {!['France', 'United Kingdom'].includes(inputs.country) && (
                      <option value="Others - Warehouse Number" data-page="othersWarehouseUpdate">
                        Others - Warehouse Number
                      </option>
                    )}
                    {inputs.country != 'Belgium' && (
                      <option value="Others - Profit Center Update" data-page="othersProfitUpdate">
                        Others - Profit Center Update
                      </option>
                    )}
                    <option value="Others - GLN/EDI Update" data-page="othersGlnUpdate">
                      Others - GLN/EDI Update
                    </option>
                    {inputs.country != 'Netherlands' && (
                      <option value="Others - Text Update Sold To" data-page="othersTextUpdate">
                        Others - Text Update Sold To
                      </option>
                    )}
                    {inputs.country == 'Germany' && (
                      <option value="Others - LDE Update Sold To" data-page="ldeUpdate">
                        Others - LDE Update Sold To
                      </option>
                    )}
                    {/* <option value="M1 mapping" data-page="M1mappingUpdate">
                      M1 mapping
                    </option> */}

                    {![
                      'Germany',
                      'France',
                      'United Kingdom',
                      'Italy',
                      'Netherlands',
                      'Belgium',
                      'Austria',
                      'Switzerland',
                      'Denmark',
                      'Finland',
                      'Luxembourg',
                      'Norway',
                      'Poland',
                      'Sweden',
                    ].includes(inputs.country) && (
                      <option value="Route To Market" data-page="routeToMarket">
                        Route To Market
                      </option>
                    )}

                    {!['Germany', 'France', 'Netherlands', 'Belgium'].includes(inputs.country) && (
                      <option value="Credit Limit" data-page="creditLimit">
                        Credit and Risk Category
                      </option>
                    )}
                    {!['Germany', 'France', 'Netherlands', 'Belgium'].includes(inputs.country) && (
                      <option value="Accounting Clerk" data-page="acctgClerk">
                        Accounting Clerk Update
                      </option>
                    )}

                    {!['Germany', 'France', 'United Kingdom', 'Netherlands', 'Belgium'].includes(
                      inputs.country,
                    ) && (
                      <option value="Price List" data-page="priceList">
                        Price List Update
                      </option>
                    )}

                    {(inputs.country == 'Germany' || inputs.country == 'Austria') && (
                      <option value="Regulator Update" data-page="regulatorUpdate">
                        Regulator Update
                      </option>
                    )}

                    {![
                      'Germany',
                      'France',
                      'United Kingdom',
                      'Italy',
                      'Netherlands',
                      'Belgium',
                    ].includes(inputs.country) && (
                      <option value="Truck Trailer Update" data-page="truckTrailerUpdate">
                        Truck / Trailer Type Update
                      </option>
                    )}

                    {![
                      'Germany',
                      'France',
                      'United Kingdom',
                      'Netherlands',
                      'Belgium',
                      'Austria',
                      'Switzerland',
                      'Denmark',
                      'Finland',
                      'Luxembourg',
                      'Norway',
                      'Poland',
                      'Sweden',
                    ].includes(inputs.country) && (
                      <option value="Payment Methods Update" data-page="paymentMethodsUpdate">
                        Payment Method Update
                      </option>
                    )}

                    {![
                      'Germany',
                      'France',
                      'Italy',
                      'United Kingdom',
                      'Netherlands',
                      'Belgium',
                      'Austria',
                      'Switzerland',
                      'Denmark',
                      'Finland',
                      'Luxembourg',
                      'Norway',
                      'Poland',
                      'Sweden',
                    ].includes(inputs.country) && (
                      <option value="Segment Update" data-page="segmentUpdate">
                        Segment/Sub-Segment Update
                      </option>
                    )}
                    {(inputs.country == 'United Kingdom' || inputs.country == 'Italy') && (
                      <option value="Hierarchy Map Update" data-page="hierarchyMapUpdate">
                        Hierarchy Mapping Update
                      </option>
                    )}

                    {![
                      'Germany',
                      'France',
                      'United Kingdom',
                      'Italy',
                      'Netherlands',
                      'Belgium',
                      'Austria',
                      'Switzerland',
                      'Denmark',
                      'Finland',
                      'Luxembourg',
                      'Norway',
                      'Poland',
                      'Sweden',
                    ].includes(inputs.country) && (
                      <option value="Address Update" data-page="addressUpdate">
                        Address Update
                      </option>
                    )}

                    {!['Germany', 'France', 'United Kingdom', 'Netherlands', 'Belgium'].includes(
                      inputs.country,
                    ) && (
                      <option value="Delivery Plant Update" data-page="deliveryPlanUpdate">
                        Delivery Plant Update
                      </option>
                    )}

                    {![
                      'Germany',
                      'France',
                      'Italy',
                      'United Kingdom',
                      'Netherlands',
                      'Belgium',
                      'Austria',
                      'Switzerland',
                      'Denmark',
                      'Finland',
                      'Luxembourg',
                      'Norway',
                      'Poland',
                      'Sweden',
                    ].includes(inputs.country) && (
                      <option value="Excise Duty Update" data-page="exciseDutyUpdate">
                        Excise Duty Update
                      </option>
                    )}

                    {![
                      'Germany',
                      'France',
                      'United Kingdom',
                      'Italy',
                      'Netherlands',
                      'Belgium',
                      'Austria',
                      'Switzerland',
                      'Denmark',
                      'Finland',
                      'Luxembourg',
                      'Norway',
                      'Poland',
                      'Sweden',
                    ].includes(inputs.country) && (
                      <option value="Closed Block/Unblock Update" data-page="closedBlockUnbUpdate">
                        Closed Block/Unblock Update
                      </option>
                    )}
                  </select>
                  {errors.reqType && (
                    <div className="invalid-feedback text-left">{errors.reqType}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <br />

      <div className="card-footer">
        <div className="row">
          {/* {!isDisable && (
            <div className="col-auto col-md-auto text-left">
              <Button variant="outline-warning" onClick={props.saveDraft}>
                Save Draft
              </Button>
            </div>
          )} */}
          <div className="col col-md text-left">
            <span className="align-middle">{props.draftMsg}</span>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              className={`btn btn-danger btn-block ${
                inputs.country == '' || inputs.reqType == '' ? ' disabled' : ''
              }`}
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Next
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateCustMainPage;
