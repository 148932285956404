import { useState, useEffect } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  useEffect(() => {
    sessionStorage.setItem('createCustomerShipTo', JSON.stringify(fields));
  }, [fields]);

  return [
    fields,
    function (event, action) {
      if (action?.name == 'pocSupplierShipTo') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            pocSupplierShipTo: '',
            pocSupplierDesc: '',
            pocPayer: '',
            pocPriceList: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            pocSupplierShipTo: event.pocNumber,
            pocSupplierDesc: event.pocDesc,
            pocPayer: event.pocPayer,
            pocPriceList: event.pocPriceListERP,
          });
        }
      } else if (action?.name == 'region') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            region: '',
            regionDesc: '',
            erpTranspZone: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            region: event.regionCode,
            regionDesc: event.regionDesc,
            erpTranspZone: event.erpTranspZone,
          });
        }
      } else if (action?.name == 'profitCenter') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            profitCenter: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            profitCenter: event.profitCenter,
          });
        }
      } else if (event.target.name === 'segment' && event.target.value !== '') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          segmentDesc: event.target.selectedOptions[0].getAttribute('data-segment'),
        });
      } else if (event.target.name === 'subSegment' && event.target.value !== '') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          subSegmentDesc: event.target.selectedOptions[0].getAttribute('data-subsegment'),
        });
      } else if (event.target.name === 'deliveringPlant' && event.target.value !== '') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          deliveringPlantDesc: event.target.selectedOptions[0].getAttribute('data-plant'),
        });
      } else if (event.target.name === 'shippingCondition' && event.target.value !== '') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          shippingConditionDesc: event.target.selectedOptions[0].getAttribute('data-shipcondn'),
        });
      } else if (event.target.name === 'exciseDutyType' && event.target.value !== '') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          exciseDutyTypeDesc: event.target.selectedOptions[0].getAttribute('data-excise'),
        });
      } else if (['ediOption', 'ediType', 'telephoneOption', 'language', 'subChannel', 'hierarchyTypeDNode', 'tier', 'customerClassification'].includes(event.target.name)) {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
        });
      } else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
    },
  ];
}

// else if (event.target.name === 'pocSupplierShipTo' && event.target.value !== '' && event.target.type !== 'text') {
//     console.log(event.target)
//     setValues({
//         ...fields,
//         [event.target.name]: event.target.value,
//         pocPayer: event.target.selectedOptions[0].getAttribute('data-payer'),
//         pocPriceList: event.target.selectedOptions[0].getAttribute('data-pricelist')
//     })
// }

// else if (event.target.name === 'region' && event.target.value !== '') {
//   setValues({
//     ...fields,
//     [event.target.name]: event.target.value,
//     regionDesc: event.target.selectedOptions[0].getAttribute('data-region'),
//     transportationZone: event.target.selectedOptions[0].getAttribute('data-transpzone'),
//   });
// }