import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Button, ButtonGroup, Row, Col, Container, Modal, Spinner } from 'react-bootstrap';
import MandatoryText from '../../../components/MandatoryText';

import { validateFields } from './utils/FormValidation';
import { FetchSoldToBapi } from '../utils/FlowCallAPI';
import { useFormFields } from './utils/HooksLib';
import { formTemplate } from '../Data';

const ldeUpdate = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  const [translations, setTranslations] = useState(props.translations);

  const [inputs, setInputs] = useFormFields(props.formTemplate);

  useEffect(() => {
    // if (!isAuthenticated) navigate('/');
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    console.log(inputs);
    if (
      props.draftTicketNum[0] !== 'M' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected'
    ) {
      setIsDisable(true);
    }

    if (inputs.name != '') setIsDisableFields(false);
  }, []);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkForward, setLinkForward] = useState('');
  const [isCopying, setIsCopying] = useState(false);
  const [copyClick, setCopyClick] = useState(false);

  const [isDisableFields, setIsDisableFields] = useState(true);

  const mainPageForm = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')) || {};

  const soldToDataRes = FetchSoldToBapi(
    inputs.existingSoldToID,
    mainPageForm.companyCode,
    mainPageForm.salesOrg,
    isCopying,
  );

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(validateFields(inputs));
      setIsSubmitting(true);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      props.setActivePage(linkForward);
    }
  }, [errors]);

  function getLang(id) {
    // console.log(props.translations);
    let obj = translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    setTranslations(props.translations);
  }, [props.translations]);

  function copySoldToData(e) {
    setCopyClick(true);
    setErrors(validateFields(inputs, true));
    console.log(`I am true ${isCopying}`);
  }

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && copyClick) {
      console.log(inputs.name, isDisableFields, copyClick);
      setCopyClick(false);
      setIsCopying(true);
      Object.entries(formTemplate.ldeUpdate).forEach(([key, value]) => {
        if (key !== 'existingSoldToID') inputs[key] = value;
      });
    }
  }, [errors]);

  useEffect(() => {
    if (Object.keys(soldToDataRes.soldToData).length !== 0) {
      // if (inputs.existingSoldToID != '') setSoldToSap(inputs.existingSoldToID);
      Object.entries(soldToDataRes.soldToData).forEach(([key, value]) => {
        if (key in inputs) {
          // if (key === 'telephone' && value !== '') inputs.telephoneOption = 'General/Accounting';

          inputs[key] = value;
        }
      });
      sessionStorage.setItem('updateCustomerldeUpdate', JSON.stringify(inputs));
      setIsDisableFields(false);
    }
    setIsCopying(false);
  }, [soldToDataRes.soldToData]);

  return (
    <form className="card" onSubmit={handleSubmit}>
      <Modal show={isCopying} centered>
        <Modal.Body>
          <Row>
            <div className="col-auto">
              <Spinner animation="border" variant="warning" />
            </div>
            <div className="text-left">
              <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
      <div className="card-header">
        <div className="col-12">
          <h3 className="card-title text-left">
            <strong>Customer Data {getLang('Update')}</strong> -{' '}
            {getLang('Others - LDE Update Sold To')}
          </h3>
        </div>
      </div>
      <Row className="sidebar-row">
        <div className="col-12">
          <div className="card-body">
            <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

            <div className="row">
              <div className="col-lg-12">
                <Row>
                  <Container>
                    <Row>
                      <div className="col-12 col-lg-3">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Existing Sold To ID')} *
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className={`form-control${
                                errors.existingSoldToID ? ' is-invalid' : ''
                              }`}
                              name="existingSoldToID"
                              placeholder={`Enter ${getLang('Existing Sold To ID')}`}
                              value={inputs.existingSoldToID}
                              onChange={setInputs}
                              disabled={isDisable}
                            />
                            <span className="input-group-append">
                              <button
                                className="btn btn-primary"
                                type="button"
                                style={{
                                  borderTopRightRadius: '3px',
                                  borderBottomRightRadius: '3px',
                                }}
                                onClick={copySoldToData}
                                disabled={isDisable || inputs.existingSoldToID == ''}
                              >
                                {getLang('Copy')}
                              </button>
                            </span>
                            {errors.existingSoldToID && (
                              <div className="invalid-feedback text-left">
                                {errors.existingSoldToID}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Row>
                    <hr />
                    <Row>
                      <div className="col-12 col-lg-3">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Name')}</label>
                          <input
                            type="text"
                            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                            name="name"
                            placeholder={`${getLang('Name')}`}
                            value={inputs.name}
                            onChange={setInputs}
                            disabled
                          />
                          {errors.name && (
                            <div className="invalid-feedback text-left">{errors.name}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-3">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('LDE Type')}</label>
                          <select
                            className="form-control"
                            name="ldeNumber"
                            onChange={setInputs}
                            value={inputs.ldeNumber}
                            disabled={inputs.name == '' || isDisable}
                          >
                            <option value="">Select</option>
                            <option value="16">16</option>
                            <option value="24">24</option>
                            <option value="30">30</option>
                          </select>
                        </div>
                      </div>
                    </Row>
                  </Container>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <br />

      <div className="card-footer">
        <div className="row">
          {!isDisable && (
            <div className="col-auto col-md-auto text-left">
              <Button variant="outline-warning" onClick={props.saveDraft}>
                Save Draft
              </Button>
            </div>
          )}
          <div className="col col-md text-left">
            <span className="align-middle">{props.draftMsg}</span>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="mainPage"
              data-back="true"
              className="btn btn-warning btn-block"
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Back
            </Link>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="ldeUpdateReview"
              className={`btn btn-danger btn-block${
                inputs.existingSoldToID == '' || inputs.name == '' ? ' disabled' : ''
              }`}
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Review
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ldeUpdate;
