import { shipToIDRegex } from '../../../../utils/Utils';

export function validateFields(values, copyClick) {
  let errors = {};

  if (copyClick) {
    if (values.existingShipToID == '') {
      errors.existingShipToID = 'Ship To ID is required';
    } else if (values.existingShipToID != '' && !shipToIDRegex.test(values.existingShipToID)) {
      errors.existingShipToID = 'Ship To ID is incorrect';
    }
  } else {
    // console.log(soldToSap);
    if (values.name != '') {
      if(values.orderBlock == ''){
        errors.orderBlock = 'Order Block is required'
      }else if(values.deliveryBlock == ''){
        errors.deliveryBlock = 'Delivery Block is required'
      } else if(values.billingBlock == ''){
        errors.billingBlock = 'Billing Block is required'
      }
    }
  }

  return errors;
}
