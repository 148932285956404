import { shipToIDRegex, payerIDRegex, billToIDRegex, soldToIDRegex } from '../../../../utils/Utils';

export function validateFields(
  values,
  copyShipClick,
  copySoldClick,
  copyPayerClick,
  copyBillClick,
) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')).country;

  if (
    copyShipClick &&
    values.existingShipToID != '' &&
    !shipToIDRegex.test(values.existingShipToID)
  ) {
    errors.existingShipToID = 'Ship To ID is incorrect';
  } else if (
    copySoldClick &&
    values.existingSoldToID != '' &&
    !soldToIDRegex.test(values.existingSoldToID)
  ) {
    errors.existingSoldToID = 'Sold To ID is incorrect';
  } else if (
    copyPayerClick &&
    values.existingPayerID != '' &&
    !payerIDRegex.test(values.existingPayerID)
  ) {
    errors.existingPayerID = 'Payer ID is incorrect';
  } else if (
    copyBillClick &&
    values.existingBillToID != '' &&
    !billToIDRegex.test(values.existingBillToID)
  ) {
    errors.existingBillToID = 'Bill To ID is incorrect';
  } else if (!copyShipClick && values?.checkShipTo == 'Y' && values?.nameShipTo == '') {
    errors.existingShipToID = 'Ship To ID is required';
  } else if (!copyPayerClick && values?.checkPayer == 'Y' && values?.namePayer == '') {
    errors.existingPayerID = 'Payer ID is required';
  } else if (!copySoldClick && values?.checkSoldTo == 'Y' && values?.nameSoldTo == '') {
    errors.existingSoldToID = 'Sold To ID is required';
  } else if (!copyBillClick && values?.checkBillTo == 'Y' && values?.nameBillTo == '') {
    errors.existingBillToID = 'Bill To ID is required';
  } else if (
    values.blockUnblockOpt == 'Block' &&
    values.nameShipTo != '' &&
    values.orderBlockShipTo == ' _Not Required' &&
    values.deliveryBlockShipTo == ' _Not Required' &&
    values.billingBlockShipTo == ' _Not Required'
  ) {
    // console.log((!copyShipClick && !copySoldClick && !copyPayerClick && !copyBillClick))
    errors.existingShipToID = 'Atleast one block is required';
  } else if (
    values.blockUnblockOpt == 'Block' &&
    values.namePayer != '' &&
    values.orderBlockPayer == ' _Not Required' &&
    values.deliveryBlockPayer == ' _Not Required' &&
    values.billingBlockPayer == ' _Not Required'
  ) {
    errors.existingPayerID = 'Atleast one block is required';
  } else if (
    values.blockUnblockOpt == 'Block' &&
    values.nameSoldTo != '' &&
    values.orderBlockSoldTo == ' _Not Required' &&
    values.deliveryBlockSoldTo == ' _Not Required' &&
    values.billingBlockSoldTo == ' _Not Required'
  ) {
    errors.existingSoldToID = 'Atleast one block is required';
  } else if (
    values.blockUnblockOpt == 'Block' &&
    values.nameBillTo != '' &&
    values.orderBlockBillTo == ' _Not Required' &&
    values.deliveryBlockBillTo == ' _Not Required' &&
    values.billingBlockBillTo == ' _Not Required'
  ) {
    errors.existingBillToID = 'Atleast one block is required';
  } else if (
    country == 'Netherlands' &&
    values.blockUnblockOpt == 'Unblock' &&
    values.supportDocsBank == ''
  ) {
    errors.supportDocsBank = 'Support document is required';
  }

  //   if (values.blockUnblockOpt == '') {
  //     errors.country = 'Type is required';
  //   } else if (values.reqType === '') {
  //     errors.reqType = 'Request Type is required';
  //   }

  return errors;
}
