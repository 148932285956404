import {
  postalCodeRegex,
  telephoneRegex,
  companyNumRegex,
  emailRegex,
  payerIDRegex,
} from '../../../../utils/Utils';

export function validateFields(values, payerSap, copyClick) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')).country;

  if (copyClick) {
    if (values.existingPayerID == '') {
      errors.existingPayerID = 'Payer ID is required';
    } else if (values.existingPayerID != '' && !payerIDRegex.test(values.existingPayerID)) {
      errors.existingPayerID = 'Payer ID is incorrect';
    }
  } else {
    if (values.name != '' && !copyClick) {
      if (
        ['United Kingdom', 'Netherlands'].includes(country) &&
        values.name3 != '' &&
        !companyNumRegex.test(values.name3)
      ) {
        errors.name3 =
          country == 'United Kingdom' ? 'Company Number is incorrect' : 'KVK Number is incorrect';
      } else if (values.postalCode === '') {
        errors.postalCode = 'Postal Code is required';
      } else if (values.postalCode !== '' && !postalCodeRegex[country].test(values.postalCode)) {
        errors.postalCode = 'Postal code is incorrect';
      } else if (values.street === '') {
        errors.street = 'Street is required';
      } else if (values.city === '') {
        errors.city = 'City is required';
      } else if (values.region == '' && !['Luxembourg'].includes(country)) {
        errors.region = 'Region is required';
      } else if (values.mobile !== '' && !telephoneRegex.test(values.mobile)) {
        errors.mobile = 'Mobile number is incorrect';
      } else if (values.telephone === '' && ['Netherlands', 'Belgium'].includes(country)) {
        errors.telephone = 'Telephone is required';
      } else if (values.telephone !== '' && !telephoneRegex.test(values.telephone)) {
        errors.telephone = 'Telephone number is incorrect';
      } else if (values.email !== '' && !emailRegex.test(values.email)) {
        errors.email = 'Email is incorrect';
      } else if (['France', 'Netherlands'].includes(country) && values.supportDocsBank == '') {
        errors.supportDocsBank = 'Support document is required';
      }
    }
  }

  return errors;
}

// else if (values.mobile === '' && ['Netherlands', 'United Kingdom', 'France'].includes(country)) {
//   errors.mobile = 'Mobile is required';
// else if (values.email === '') {
//   errors.email = 'Email is required';
// }
