const prod = { url: {
  fetchRegionsURL: 'https://mavenapis.ab-inbev.com/api/data/regions',
  fetchPartnerTypesURL: 'https://mavenapis.ab-inbev.com/api/data/partnertypes',
  fetchEDTypesURL: 'https://mavenapis.ab-inbev.com/api/data/edtypes',
  fetchPaymentTermsURL: 'https://mavenapis.ab-inbev.com/api/data/paymentterms',
  fetchPriceListURL: 'https://mavenapis.ab-inbev.com/api/data/pricelist',
},
};
const test = { url: {
  fetchRegionsURL: 'https://mavenapistest.ab-inbev.com/api/data/regions',
  fetchPartnerTypesURL: 'https://mavenapistest.ab-inbev.com/api/data/partnertypes',
  fetchEDTypesURL: 'https://mavenapistest.ab-inbev.com/api/data/edtypes',
  fetchPaymentTermsURL: 'https://mavenapistest.ab-inbev.com/api/data/paymentterms',
  fetchPriceListURL: 'https://mavenapistest.ab-inbev.com/api/data/pricelist',
},
};
const local = { url: {
  fetchRegionsURL: 'http://localhost:1234/api/data/regions',
  fetchPartnerTypesURL: 'http://localhost:1234/api/data/partnertypes',
  fetchEDTypesURL: 'http://localhost:1234/api/data/edtypes',
  fetchPaymentTermsURL: 'http://localhost:1234/api/data/paymentterms',
  fetchPriceListURL: 'http://localhost:1234/api/data/pricelist',
},
};
const configData  = process.env.REACT_APP_ENV === 'prod' ? prod.url : (process.env.REACT_APP_ENV === 'test' ? test.url : local.url);
export default configData;
