import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
// import { withRouter } from 'react-router';
import SuccessIcon from '../../../assets/images/success.png';

import { Button, ButtonGroup, Row, Col } from 'react-bootstrap';

const CreateCustSaveSuccess = (props) => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    props.setActivePage(e.target.getAttribute('data-link'));
  };

  return (
    <form className="card">
      <div className="card-header">
        <div className="col-12 col-lg-12">
          <h3 className="card-title text-left">
            <strong>Vendor Customer Creation</strong> - Success
          </h3>
        </div>
      </div>
      <Row className="sidebar-row">
        <div className="col-12 col-lg-12">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-lg-auto">
                <img src={SuccessIcon} height="50px" />
              </div>
              <div className="col-auto justify-content-center align-self-center">
                <span>
                  Your request <strong>{`${props.draftTicketNum.slice(1)}`}</strong> has been
                  submitted successfully, you will receive the information shortly in your
                  registered email.
                </span>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <br />

      <div className="card-footer">
        <div className="row">
          <div className="col col-md text-left justify-content"></div>
          <div className="col-auto col-md-auto text-right">
            <Link
              to="#"
              className="btn btn-danger btn-block"
              style={{ textDecoration: 'none' }}
              onClick={() => navigate('/')}
            >
              View Requests
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateCustSaveSuccess;
