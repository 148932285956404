import { useState } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  return [
    fields,
    function (event, action) {
      if (action?.name == 'region') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            region: '',
            regionDesc: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            region: event.regionCode,
            regionDesc: event.regionDesc,
          });
        }
      } else if (
        ['showBillTo', 'title', 'language', 'eInvOption', 'billCountry'].includes(event.target.name)
      ) {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
        });
      } else if (['emailAddress'].includes(event.target.name)) {
        setValues({
          ...fields,
          eInvEmail: event.target.value.toUpperCase(),
          [event.target.name]: event.target.value.toUpperCase(),
        });
      } else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
    },
  ];
}

// else if (event.target.name === 'region') {
//     setValues({
//         ...fields,
//         [event.target.name]: event.target.value,
//         regionDesc: event.target.selectedOptions[0].getAttribute('data-region')
//     })
// }
