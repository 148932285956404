import { useState, useEffect } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  const [fileB64Str, setFileB64Str] = useState('');

  const [fileName, setFileName] = useState('');

  const encodeFileB64 = (file, link) => {
    var reader = new FileReader();
    if (file) {
      if (file.size < 1000000) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var b64 = reader.result;
          // console.log(`B64 = ${b64}`);
          setFileB64Str(b64);
          setFileName(file.name);
        };
        reader.onerror = (err) => {
          console.log(`Error: ${err}`);
        };
      }
    }
  };

  useEffect(() => {
    sessionStorage.setItem('updateCustomerIbanUpdate', JSON.stringify(fields));
  }, [fields]);

  useEffect(() => {
    console.log('lalal');
    if (fileB64Str !== '' && fileName !== '') {
      setValues({
        ...fields,
        ['supportDocsBank']: fileB64Str,
        ['supportDocsBankName']: fileName,
      });
    }
  }, [fileB64Str, fileName]);

  return [
    fields,
    function (event) {
      if (['ibanOpt'].includes(event.target.name)) {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
        });
      } else if (event.target.name == 'existingPayerID') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          name: '',
          iban: '',
          bkType: '',
          accHolderName: '',
          supportDocsBank: '',
          supportDocsBankName: '',
          bankTypes: '',
        });
      } else if (event.target.name === 'supportDocsBank') {
        encodeFileB64(event.target.files[0], event.target.value);
        // console.log(`Res: ${fileB64Str}`);
      } else if (event.target.id === 'clearFile') {
        console.log('sup');
        setValues({
          ...fields,
          ['supportDocsBank']: '',
          ['supportDocsBankName']: '',
        });
      } else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
    },
  ];
}
