import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import { Row, ButtonGroup, Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import MandatoryText from '../../../components/MandatoryText';

import { FetchCountries } from '../mainpage/FlowCallAPI';
import { FetchRegions, FetchShippingConditions, FetchPOCSuppliers } from '../shipto/FlowCallAPI';
import { languages, languageMap, LDEs, LdeMap } from '../payer/Data';
import { useFormFields } from './HooksLib';
import { validateFields } from './FormValidation';
import { FetchPricingHierarchies } from './FlowCallAPI';
import { soldToNamesWhs } from './Config';
import Sidebar from '../../../components/layout/Sidebar/Sidebar';

import {
  mobileLength,
  mobilePlaceholder,
  telephoneLength,
  telephonePlaceholder,
  postalCodeLength,
  postalCodePlaceholder,
} from '../../../utils/Utils';
import {
  FetchBuyingGroups,
  FetchMergers,
  FetchPriceLists,
  FetchSecondSoldToPayers,
} from '../../FlowCallAPI';

const CreateCustSoldTo = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) navigate('/');
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  const [errors, setErrors] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [linkForward, setLinkForward] = useState('');

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  const [isDisabled, setIsDisabled] = useState(true);

  // const soldToForm = sessionStorage.getItem('createCustomerSoldTo') === null ? soldToFormTemplate : JSON.parse(sessionStorage.getItem('createCustomerSoldTo'));

  // const [inputs, setInputs] = useFormFields(soldToForm);

  const [inputs, setInputs] = useFormFields(props.formTemplate);

  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country || '';

  const shipToType = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).shipToType || '';

  const payerID = JSON.parse(sessionStorage.getItem('createCustomerPayer')).existingPayerID || '';

  const customerReqType =
    JSON.parse(sessionStorage.getItem('createCustomerMainPage')).customerReqType || '';

  const aNodeVal = JSON.parse(sessionStorage.getItem('createCustomerShipTo')).aNode || '';

  const soldToPayer = JSON.parse(sessionStorage.getItem('createCustomerShipTo')).pocPayer || '';

  const pricingHierarchiesRes = FetchPricingHierarchies(country, shipToType);

  const [dataLoaded, setDataLoaded] = useState(false);

  const countriesRes = FetchCountries();

  const regionsRes = FetchRegions(inputs.soldCountry, false);

  const mergersRes = FetchMergers(country, shipToType);

  const buyingGroupsRes = FetchBuyingGroups(country, shipToType);

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      if (!isDisable) {
        setLinkForward(e.target.getAttribute('data-link'));
        setErrors(validateFields(inputs, country, shipToType));
        setIsSubmitting(true);
      } else {
        props.setActivePage(e.target.getAttribute('data-link'));
      }
    }
  };

  function getLang(id) {
    let obj = props.translations.find((o) => o.textID === id);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  const regionOptions = regionsRes.regions.map((val) => {
    return {
      ...val,
      regionCode: val.regionCode,
      regionText: val.regionText,
      erpTranspZone: val.erpTranspZone,
      regionDesc: `${val.regionCode} - ${val.regionText}`,
    };
  });

  const name1Length = () => {
    if (inputs.name == 'K&PD_ALL_POCM (Name Merger)') {
      return Number(34 - 16 - inputs.merger.length);
    } else if (inputs.name == 'K&PD_ALL_POCM_3PP (Name Merger)') {
      return Number(34 - 20 - inputs.merger.length);
    } else if (inputs.name == 'BC_ALL (Name Buying Group)') {
      return Number(34 - 9 - inputs.buyingGroup.length);
    } else return Number(34 - inputs.name.length);
  };

  useEffect(() => {
    if (
      props.draftTicketNum[0] !== 'D' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected' &&
      props.status !== 'Duplicate'
    ) {
      //if ((props.draftTicketNum[0] === 'D')) {
      setIsDisable(true);
    }

    if (inputs.hierarchyTypeANode == 'New') inputs.aNode = 'New';

    if (inputs.soldCountry == '') inputs.soldCountry = country;

    if (country === 'France') {
      inputs.incoTerms1 = shipToType.startsWith('Off-Trade') ? 'FCA' : 'EXW';
      inputs.ediOption = 'No';
      inputs.incoTerms2 = 'HOUPLINES';
    } else if (country === 'Netherlands') {
      if (inputs.hierarchyTypeANode == '') inputs.hierarchyTypeANode = 'Existing';

      if (inputs.att7 == '') inputs.att7 = 'VERKEERDE FUSTEN';

      if (inputs.soldToPayer2 == '') inputs.soldToPayer2 = soldToPayer;

      if (shipToType.startsWith('On-Trade')) {
        inputs.aNode = '102101';
        if (inputs.priceList == '') inputs.priceList = 'N6';
      } else if (shipToType.startsWith('National')) {
        if (inputs.priceList == '') inputs.priceList = 'N6';
      } else if (shipToType.startsWith('Off-Trade')) {
        if (inputs.priceList == '') inputs.priceList = 'N5';
      } else {
        if (inputs.priceList == '')
          inputs.priceList =
            JSON.parse(sessionStorage.getItem('createCustomerShipTo')).pocPriceList || '';
      }

      // if (shipToType.endsWith('Indirect') || shipToType.endsWith('Direct')) {
      //   if (inputs.incoTerms1 == '') inputs.incoTerms1 = 'EXW';
      // }

      if (shipToType.endsWith('Indirect')) {
        if (inputs.promoNews == '') inputs.promoNews = 'No';
      }

      if (shipToType.endsWith('Direct') || shipToType == 'National On-Trade Indirect') {
        if (inputs.verwerkingstoeslag == '') inputs.verwerkingstoeslag = 'Yes';
      }
    } else if (country == 'United Kingdom') {
      if (shipToType.startsWith('On-Trade')) {
        if (inputs.priceList == '') inputs.priceList = 'G2';
      } else if (shipToType.startsWith('Off-Trade')) {
        if (inputs.priceList == '') inputs.priceList = 'G3';
      }
    } else if (country == 'Belgium') {
      inputs.incoTerms1 = 'FCA';
      if (inputs.att7 == '') inputs.att7 = 'No';
      if (inputs.pricingHierarchyOpt == '') inputs.pricingHierarchyOpt = 'No';
      if (inputs.pricingHierarchy != '') inputs.pricingHierarchyOpt = 'Yes';
      if (
        inputs.shippingCondition == '' &&
        shipToType == 'Off-Trade – Direct' &&
        inputs.shippingCondition == ''
      )
        inputs.shippingCondition = 'T1';
      if (shipToType == 'On-Trade – Direct') {
        if (inputs.promoNews == '') inputs.promoNews = 'Yes';
        if (shipToType.startsWith('Wholesaler')) {
          inputs.priceList = 'B5';
          if (inputs.language == '') inputs.language = 'Dutch';
        }
      }
    } else if (country == 'Germany') {
      inputs.ediOption = 'No';
      if (inputs.incoTerms1 == '') inputs.incoTerms1 = 'EXW';
      if (inputs.incoTerms2 == '') inputs.incoTerms2 = 'Brewary';
    } else if (['Denmark', 'Finland', 'Norway'].includes(country)) {
      if (inputs.shippingCondition == '') inputs.shippingCondition = 'X1';
    } else if (['Austria', 'Poland', 'Sweden', 'Switzerland'].includes(country)) {
      if (inputs.shippingCondition == '') inputs.shippingCondition = 'T1';
    } else if (country == 'Italy') {
      inputs.incoTerms1 = 'EXW';
      inputs.incoTerms2 = 'FRANCO';
      if (inputs.pricingHierarchyOpt == '') inputs.pricingHierarchyOpt = 'Yes';
      if (payerID?.length == 8) {
        inputs.pricingHierarchy == '';
      }
    }

    if (aNodeVal != '') inputs.aNode = aNodeVal;

    setDataLoaded(true);
  }, []);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    }
  }, [errors]);

  useEffect(() => {
    sessionStorage.setItem('createCustomerSoldTo', JSON.stringify(inputs));
    if (inputs.hierarchyTypeANode === 'Existing') setIsDisabled(false);
    else setIsDisabled(true);
  }, [inputs]);

  const pricingHierarchyOptions = pricingHierarchiesRes.pricingHierarchies.map((val) => {
    return {
      ...val,
      pricingHierarchy: val.pricingHierarchy,
      hierarchyDesc: val.hierarchyDesc,
      pricingHierarchyDesc: `${val.pricingHierarchy} - ${val.hierarchyDesc}`,
    };
  });

  const shippingConditionsRes = FetchShippingConditions(country, isDisable);

  const priceListsRes = FetchPriceLists(country, isDisable);

  const pocSuppliersRes = FetchPOCSuppliers(country, shipToType, isDisable);

  const secondSoldToPayersRes = FetchSecondSoldToPayers(country, isDisable);

  const secondSoldToPayerOptions = secondSoldToPayersRes.result
    // .filter((e) => e.pocPayer != '')
    .map((val) => {
      return {
        ...val,
        payer: val.payer,
        payerName: val.payerName,
        payerDesc: `${val.payer} - ${val.payerName}`,
      };
    });

  const pocOptions = pocSuppliersRes.pocSuppliers
    .filter((e) => e.pocPayer != '')
    .map((val) => {
      return {
        ...val,
        pocNumber: val.pocNumber,
        pocName: val.pocName,
        pocPayer: val.pocPayer,
        pocPriceListERP: val.pocPriceListERP,
        pocPayerName: val.pocPayerName,
        pocDesc: `${val.pocPayer} - ${val.pocPayerName}`,
      };
    });

  const sidebarItems = [
    {
      title: getLang('Main Page'),
      onClick: handleSubmit,
      ['data-link']: 'mainpage',
      ['data-back']: 'true',
      active: false,
    },
    {
      title: getLang('Vendor'),
      onClick: handleSubmit,
      ['data-link']: 'vendor',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Ship-To'),
      onClick: handleSubmit,
      ['data-link']: 'shipto',
      ['data-back']: true,
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Payer'),
      onClick: handleSubmit,
      ['data-link']: 'payer',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Sold-To'),
      onClick: handleSubmit,
      ['data-link']: 'soldto',
      ['data-back']: 'true',
      // type: 'submit',
      active: true,
    },
    {
      title: getLang('Bill-To'),
      onClick: handleSubmit,
      ['data-link']: 'billto',
      // ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
  ];

  return (
    isAuthenticated &&
    dataLoaded && (
      <form className="card" onSubmit={handleSubmit}>
        <div className="card-header">
          <div className="col-11 col-lg-12">
            <h3 className="card-title text-left">
              <strong style={{ color: 'rgb(177, 31, 36)' }}>
                Vendor Customer {getLang('Creation')}
              </strong>{' '}
              - {getLang('Sold To')}
            </h3>
          </div>
          <a
            className={`sidebar-toggler col-auto ${isSidebarOpen ? '' : 'collapsed'}`}
            // onClick={togglingSidebar}
          >
            <Sidebar sidebarItems={sidebarItems} />
            {/* <span className="sidebar-toggler-icon"></span> */}
          </a>
        </div>
        <Row className="sidebar-row">
          <div
            className={`col-12 col-lg-2 text-left sidebar ${
              isMobile ? (isSidebarOpen ? '' : 'collapse') : ''
            }`}
          >
            <Row>
              <ButtonGroup vertical className="btn-block">
                <Link
                  data-link="mainpage"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {getLang('Main Page')}
                </Link>
                <Link to="" data-link="vendor" className="btn btn-warning" onClick={handleSubmit}>
                  {getLang('Vendor')}
                </Link>
                <Link
                  data-link="shipto"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {getLang('Ship To')}
                </Link>
                <Link
                  data-link="payer"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {getLang('Payer')}
                </Link>
                <Button className="btn-danger">{getLang('Sold To')}</Button>
                <Link
                  data-link="billto"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {getLang('Bill To')}
                </Link>
              </ButtonGroup>
            </Row>
          </div>
          <div className="col-12 col-lg-10">
            <div className="card-body">
              <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12 col-lg-auto text-left">
                      <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('General Data')}</h2>
                    </div>
                  </div>
                  <div className="row">
                    <>
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Name')} *</label>
                          <select
                            name="name"
                            className={`form-control${errors.name ? ' is-invalid' : ''}`}
                            onChange={setInputs}
                            value={inputs.name}
                            disabled={isDisable}
                          >
                            <option value="">Select</option>
                            {soldToNamesWhs.map((name) => (
                              <option key={name} value={name}>
                                {name}
                              </option>
                            ))}
                          </select>
                          {errors.name && (
                            <div className="invalid-feedback text-left">{errors.name}</div>
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Postal Code')} *</label>
                          <input
                            type="text"
                            className={`form-control ${errors.postalCode ? 'is-invalid' : ''}`}
                            name="postalCode"
                            maxLength={postalCodeLength(country)}
                            placeholder={postalCodePlaceholder(country)}
                            value={inputs.postalCode}
                            onChange={setInputs}
                            disabled={isDisable}
                          />
                          {errors.postalCode && (
                            <div className="invalid-feedback text-left">{errors.postalCode}</div>
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('House Number')} {country !== 'United Kingdom' ? ' *' : ''}
                          </label>
                          <input
                            type="text"
                            className={`form-control ${errors.houseNumber ? 'is-invalid' : ''}`}
                            name="houseNumber"
                            value={inputs.houseNumber}
                            onChange={setInputs}
                            maxLength="10"
                            disabled={isDisable}
                          />
                          {errors.houseNumber && (
                            <div className="invalid-feedback text-left">{errors.houseNumber}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Street')} *</label>
                          <input
                            type="text"
                            className={`form-control ${errors.street ? 'is-invalid' : ''}`}
                            name="street"
                            value={inputs.street}
                            onChange={setInputs}
                            maxLength="35"
                            disabled={isDisable}
                          />
                          {errors.street && (
                            <div className="invalid-feedback text-left">{errors.street}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('City')} *</label>
                          <input
                            type="text"
                            className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                            name="city"
                            value={inputs.city}
                            onChange={setInputs}
                            maxLength="35"
                            disabled={isDisable}
                          />
                          {errors.city && (
                            <div className="invalid-feedback text-left">{errors.city}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Country')} *</label>
                          <select
                            className={`form-control ${errors.soldCountry ? 'is-invalid' : ''}`}
                            name="soldCountry"
                            onChange={setInputs}
                            value={inputs.soldCountry}
                            disabled={isDisable}
                          >
                            <option value="">Select</option>
                            {countriesRes.countries
                              .filter((e) =>
                                [
                                  'France',
                                  'Belgium',
                                  'Netherlands',
                                  'United Kingdom',
                                  'Germany',
                                ].includes(e.country),
                              )
                              .map((val) => (
                                <option key={val.countryCode} value={val.country}>
                                  {val.country}
                                </option>
                              ))}
                          </select>
                          {errors.soldCountry && (
                            <div className="invalid-feedback text-left">{errors.soldCountry}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Region')} *</label>
                          <Select
                            className="text-left"
                            name="region"
                            value={regionOptions.filter((opt) => opt.regionCode == inputs.region)}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#cfcfcf',
                                primary50: '#cfcfcf',
                                primary: 'orange',
                              },
                            })}
                            isClearable
                            placeholder="Select"
                            isDisabled={isDisable}
                            getOptionLabel={({ regionDesc }) => regionDesc}
                            getOptionValue={({ regionCode }) => regionCode}
                            options={regionOptions}
                            onChange={(e, action) => {
                              setInputs(e, action);
                            }}
                          />
                          {errors.region && (
                            <div className="invalid-feedback-file text-left">{errors.region}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Language')}</label>
                          <select
                            id="select-lang"
                            className="form-control"
                            name="language"
                            value={inputs.language}
                            onChange={setInputs}
                            disabled={isDisable}
                          >
                            <option value="">Select</option>
                            {languages.map((lang) => (
                              <option key={lang} value={lang}>
                                {lang}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Telephone/Fax numbers')}
                          </label>
                          <input
                            type="tel"
                            className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}
                            name="telephone"
                            placeholder={telephonePlaceholder(country)}
                            maxLength={telephoneLength(country)}
                            value={inputs.telephone}
                            onChange={setInputs}
                            disabled={isDisable}
                          />
                          {errors.telephone && (
                            <div className="invalid-feedback text-left">{errors.telephone}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Mobile')}
                            {['Netherlands', 'United Kingdom', 'France'].includes(country)
                              ? ' *'
                              : ''}
                          </label>
                          <input
                            type="tel"
                            className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                            placeholder={mobilePlaceholder(country)}
                            maxLength={mobileLength(country)}
                            name="mobile"
                            value={inputs.mobile}
                            onChange={setInputs}
                            disabled={isDisable}
                          />
                          {errors.mobile && (
                            <div className="invalid-feedback text-left">{errors.mobile}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('E-mail Address')} *
                          </label>
                          <input
                            type="email"
                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            name="email"
                            placeholder="Eg.: abc@company.com"
                            value={inputs.email}
                            onChange={setInputs}
                            maxLength="240"
                            disabled={isDisable}
                          />
                          {errors.email && (
                            <div className="invalid-feedback text-left">{errors.email}</div>
                          )}
                        </div>
                      </div>
                    </>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </Row>

        <div className="card-footer">
          <div className="row">
            <div className="col-auto col-md-auto text-left">
              {!isDisable && (
                <Button variant="outline-warning" onClick={props.saveDraft}>
                  Save Draft
                </Button>
              )}
            </div>
            <div className="col col-md text-left justify-content">{props.draftMsg}</div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link="payer"
                data-back="true"
                className="btn btn-warning btn-block"
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Back
              </Link>
            </div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link="billto"
                className={`btn btn-${
                  Object.keys(validateFields(inputs)).length == 0 || isDisable
                    ? 'danger'
                    : 'secondary'
                } btn-block`}
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Next
              </Link>
            </div>
          </div>
        </div>
      </form>
    )
  );
};

export default CreateCustSoldTo;
