const prod = {
  url: {
    fetchTitlesURL: 'https://mavenapis.ab-inbev.com/api/data/titles',
    fetchPaymentTermsURL: 'https://mavenapis.ab-inbev.com/api/data/paymentterms',
    fetchPaymentMethodURL: 'https://mavenapis.ab-inbev.com/api/data/paymentmethod',
    fetchReasonDirectDebit: 'https://mavenapis.ab-inbev.com/api/data/reasondirectdebit',
  },
};

const test = {
  url: {
    fetchTitlesURL: 'https://mavenapistest.ab-inbev.com/api/data/titles',
    fetchPaymentTermsURL: 'https://mavenapistest.ab-inbev.com/api/data/paymentterms',
    fetchPaymentMethodURL: 'https://mavenapistest.ab-inbev.com/api/data/paymentmethod',
    fetchReasonDirectDebit: 'https://mavenapistest.ab-inbev.com/api/data/reasondirectdebit',
  },
};

const local = {
  url: {
    fetchTitlesURL: 'http://localhost:1234/api/data/titles',
    fetchPaymentTermsURL: 'http://localhost:1234/api/data/paymentterms',
    fetchPaymentMethodURL: 'http://localhost:1234/api/data/paymentmethod',
    fetchReasonDirectDebit: 'http://localhost:1234/api/data/reasondirectdebit',
  },
};

const configData =
  process.env.REACT_APP_ENV === 'prod'
    ? prod.url
    : process.env.REACT_APP_ENV === 'test'
    ? test.url
    : local.url;

export default configData;
