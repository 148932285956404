import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Button, ButtonGroup, Row, Col, Container, Modal, Spinner } from 'react-bootstrap';
import MandatoryText from '../../../components/MandatoryText';

import { validateFields } from './utils/FormValidation';
import { FetchShipToBapi, FetchPartnerTypes, FetchEDTypes } from '../utils/FlowCallAPI';
import { useFormFields } from './utils/HooksLib';
import { formTemplate } from '../Data';
import { postalCodeLength } from '../../../utils/Utils';

const UpdateExciseDutyUpdate = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  const [translations, setTranslations] = useState(props.translations);

  const [inputs, setInputs] = useFormFields(props.formTemplate);

  useEffect(() => {
    // if (!isAuthenticated) navigate('/');
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    console.log(inputs);
    if (
      props.draftTicketNum[0] !== 'M' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected'
    ) {
      setIsDisable(true);
    }

    if (inputs.name != '') setIsDisableFields(false);

    if (inputs.edValidFrom == '') {
      var today = new Date();
      var todayDisp = `${today.getFullYear()}-${
        today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1
      }-${today.getDate() < 10 ? '0' + today.getDate() : today.getDate()}`;
      // var todayDisp = `${today.getDate() < 10 ? '0' + today.getDate() : today.getDate()}.${today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1}.${today.getFullYear()}`;
      inputs.edValidFrom = todayDisp; // set date
      // inputs.warehouseValidFrom = '2021-03-11'
    } else console.log(inputs.edValidFrom);

    if (inputs.edValidTo == '') {
      inputs.edValidTo = '9999-12-31';
    }
  }, []);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkForward, setLinkForward] = useState('');
  const [isCopying, setIsCopying] = useState(false);
  const [copyClick, setCopyClick] = useState(false);

  const [isDisableFields, setIsDisableFields] = useState(true);

  const mainPageForm = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')) || {};

  const shipToDataRes = FetchShipToBapi(
    inputs.existingShipToID,
    mainPageForm.companyCode,
    mainPageForm.salesOrg,
    isCopying,
  );

  const partnerTypesRes = FetchPartnerTypes(mainPageForm.country);
  console.log(partnerTypesRes);

  const EDTypesRes = FetchEDTypes(mainPageForm.country);

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(validateFields(inputs));
      setIsSubmitting(true);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      props.setActivePage(linkForward);
    }
  }, [errors]);

  function getLang(id) {
    // console.log(props.translations);
    let obj = translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    setTranslations(props.translations);
  }, [props.translations]);

  function copyShipToData(e) {
    setCopyClick(true);
    setErrors(validateFields(inputs, true));
    console.log(`I am true ${isCopying}`);
  }

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && copyClick) {
      console.log(inputs.name, isDisableFields, copyClick);
      setCopyClick(false);
      setIsCopying(true);
      Object.entries(formTemplate.exciseDutyUpdate).forEach(([key, value]) => {
        if (!['existingShipToID', 'edValidFrom', 'edValidTo'].includes(key)) inputs[key] = value;
      });
    }
  }, [errors]);

  useEffect(() => {
    if (Object.keys(shipToDataRes.shipToData).length !== 0) {
      // if (inputs.existingSoldToID != '') {
      //   setSoldToSap(inputs.existingSoldToID);
      //   console.log(`Setting sold to num: ${soldToSap}`);
      // }

      inputs.edCustomerNumber = inputs.existingShipToID;

      Object.entries(shipToDataRes.shipToData).forEach(([key, value]) => {
        if (key in inputs) {
          // if (key === 'telephone' && value !== '') inputs.telephoneOption = 'General/Accounting';

          inputs[key] = value;
        }
      });
      sessionStorage.setItem('updateExciseDutyUpdate', JSON.stringify(inputs));
      setIsDisableFields(false);
    }
    setIsCopying(false);
  }, [shipToDataRes.shipToData]);

  return (
    <form className="card" onSubmit={handleSubmit}>
      <Modal show={isCopying} centered>
        <Modal.Body>
          <Row>
            <div className="col-auto">
              <Spinner animation="border" variant="warning" />
            </div>
            <div className="text-left">
              <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
      <div className="card-header">
        <div className="col-12">
          <h3 className="card-title text-left">
            <strong>Customer Data {getLang('Update')}</strong>
          </h3>
        </div>
      </div>
      <Row className="sidebar-row">
        <div className="col-12">
          <div className="card-body">
            <Row>
              <Col sm={12} lg="auto">
                <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Excise Duty Update')}</h2>
              </Col>
            </Row>
            <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

            <div className="row">
              <div className="col-lg-12">
                <Row>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">
                        {getLang('Existing Ship-to ID')} *
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control${errors.existingShipToID ? ' is-invalid' : ''}`}
                          name="existingShipToID"
                          maxLength="8"
                          placeholder={`Enter ${getLang('Existing Ship-to ID')}`}
                          value={inputs.existingShipToID}
                          onChange={setInputs}
                          disabled={isDisable}
                        />
                        <span className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="button"
                            style={{
                              borderTopRightRadius: '3px',
                              borderBottomRightRadius: '3px',
                            }}
                            onClick={copyShipToData}
                            disabled={isDisable || inputs.existingShipToID == ''}
                          >
                            {getLang('Copy')}
                          </button>
                        </span>
                        {errors.existingShipToID && (
                          <div className="invalid-feedback text-left">
                            {errors.existingShipToID}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Row>
                <hr />
                <Row>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Name')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                        name="name"
                        placeholder={`${getLang('Name')}`}
                        value={inputs.name}
                        onChange={setInputs}
                        disabled
                      />
                      {errors.name && (
                        <div className="invalid-feedback text-left">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Country')}</label>
                      <input
                        type="text"
                        className="form-control"
                        name="country"
                        value={mainPageForm.country}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Customer Number')}</label>
                      <input
                        type="text"
                        className="form-control"
                        name="edCustomerNumber"
                        value={inputs.edCustomerNumber}
                        maxLength="35"
                        onChange={setInputs}
                        disabled
                      />
                    </div>
                  </div>
                  {/* <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('ED Type')}</label>
                      <select
                        className="form-control"
                        name="warehouseEdType"
                        onChange={setInputs}
                        value={inputs.warehouseEdType}
                        disabled={inputs.name == '' || isDisable}
                      >
                        <option value="">Select</option>
                        {EDTypesRes.EDTypes.map((val) => (
                          <option
                            key={val.code}
                            value={val.code}
                            data-ed={`${val.code} - ${val.edType}`}
                          >{`${val.code} - ${val.edType}`}</option>
                        ))}
                      </select>
                    </div>
                  </div> */}
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Street')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.street ? 'is-invalid' : ''}`}
                        name="street"
                        value={inputs.street}
                        onChange={setInputs}
                        maxLength="35"
                        disabled
                      />
                      {errors.street && (
                        <div className="invalid-feedback text-left">{errors.street}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Postal Code')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.postalCode ? 'is-invalid' : ''}`}
                        name="postalCode"
                        maxLength={postalCodeLength(mainPageForm?.country)}
                        value={inputs.postalCode}
                        onChange={setInputs}
                        disabled
                      />
                      {errors.postalCode && (
                        <div className="invalid-feedback text-left">{errors.postalCode}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('City')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                        name="city"
                        value={inputs.city}
                        maxLength="35"
                        onChange={setInputs}
                        disabled
                      />
                      {errors.city && (
                        <div className="invalid-feedback text-left">{errors.city}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">
                        {getLang('Excise Duty Type')} *
                      </label>
                      <select
                        className="form-control"
                        name="warehouseEdType"
                        onChange={setInputs}
                        value={inputs.warehouseEdType}
                        disabled={inputs.name == '' || isDisable}
                      >
                        <option value="">Select</option>
                        {EDTypesRes.EDTypes.map((val) => (
                          <option
                            key={val.code}
                            value={val.code}
                            data-ed={`${val.code} - ${val.edType}`}
                          >{`${val.code} - ${val.edType}`}</option>
                        ))}
                      </select>
                      {/* {errors.edType && (
                        <div className="invalid-feedback text-left">{errors.edType}</div>
                      )} */}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Valid From')} *</label>
                      <input
                        type="date"
                        className="form-control"
                        name="edValidFrom"
                        value={inputs.edValidFrom}
                        onChange={setInputs}
                        disabled={inputs.name == '' || isDisable}
                      />
                      {errors.edValidFrom && (
                        <div className="invalid-feedback text-left">{errors.edValidFrom}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Valid To')} *</label>
                      <input
                        type="date"
                        className="form-control"
                        name="edValidTo"
                        value={inputs.edValidTo}
                        onChange={setInputs}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">
                        {getLang('Tax Warehouse Number')} *
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="taxWarehouseNumber"
                        value={inputs.taxWarehouseNumber}
                        maxLength="15"
                        onChange={setInputs}
                        disabled={inputs.name == '' || isDisable}
                      />
                      {errors.taxWarehouseNumber && (
                        <div className="invalid-feedback text-left">
                          {errors.taxWarehouseNumber}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">
                        {getLang('External ED Number')}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="externalEdNumber"
                        value={inputs.EdNumber}
                        onChange={setInputs}
                        maxLength="35"
                        disabled={inputs.name == '' || isDisable}
                      />
                      {errors.externalEdNumber && (
                        <div className="invalid-feedback text-left">{errors.externalEdNumber}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Partner Type')}</label>
                      <select
                        className="form-control"
                        name="partnerType"
                        onChange={setInputs}
                        value={inputs.partnerType}
                        disabled={inputs.name == '' || isDisable}
                      >
                        <option value="">Select</option>
                        {partnerTypesRes.partnerTypes.map((val) => (
                          <option key={val.code} value={val.code}>
                            {val.code}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Partner Type')}</label>
                      <select
                        className="form-control"
                        name="warehousePartnerType"
                        onChange={setInputs}
                        value={inputs.warehousePartnerType}
                        disabled={inputs.name == '' || isDisable}
                      >
                        <option value="">Select</option>
                        {partnerTypesRes.partnerTypes.map((val) => (
                          <option key={val.code} value={val.code}>
                            {val.code}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div> */}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <br />

      <div className="card-footer">
        <div className="row">
          {!isDisable && (
            <div className="col-auto col-md-auto text-left">
              <Button variant="outline-warning" onClick={props.saveDraft}>
                Save Draft
              </Button>
            </div>
          )}
          <div className="col col-md text-left">
            <span className="align-middle">{props.draftMsg}</span>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="mainPage"
              data-back="true"
              className="btn btn-warning btn-block"
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Back
            </Link>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="exciseDutyUpdateReview"
              className={`btn btn-danger btn-block${
                inputs.existingShipToID == '' || inputs.name == '' || inputs.partnerType == ''
                  ? ' disabled'
                  : ''
              }`}
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Review
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateExciseDutyUpdate;
