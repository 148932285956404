import { useState, useEffect } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  const country = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')).country;

  useEffect(() => {
    sessionStorage.setItem('updateCustomerVatTaxUpdate', JSON.stringify(fields));
  }, [fields]);

  return [
    fields,
    function (event) {
      if (event.target.name == 'existingSoldToID') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          existingPayerID: '',
          name: '',
          vatRegNumber: '',
          taxNumber: '',
        });
      } else if (event.target.name == 'vatRegNumber' && country != 'Netherlands') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
          taxNumber: event.target.value.slice(2).toUpperCase(),
        });
      } else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
    },
  ];
}
