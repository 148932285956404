import { shipToIDRegex } from '../../../../utils/Utils';

export function validateFields(values, copyClick) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')).country;

  if (copyClick) {
    if (values.existingShipToID == '') {
      errors.existingShipToID = 'Ship To ID is required';
    } else if (values.existingShipToID != '' && !shipToIDRegex.test(values.existingShipToID)) {
      errors.existingShipToID = 'Ship To ID is incorrect';
    }
  } else {
    // console.log(soldToSap);
    if (values.name != '') {
      if (values.pocsupplier === '' && country == 'Netherlands') {
        errors.pocsupplier = 'POC Supplier is required';
      } else if (values.deliveringPlant == '') {
        errors.deliveringPlant = 'Delivering Plant is required';
      }
    }
  }

  return errors;
}
