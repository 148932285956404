import { useState, useEffect } from 'react';
import configData from './Config';
import { urls } from '../../../utils/Utils';
// import axios from 'axios';
import axios from '../../../utils/axios';

export function FetchRegions(country, isDisableFields) {
  const url = configData.fetchRegionsURL;
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setRegions(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && country !== '') getData();
    else if (country == '') setRegions([]);
  }, [country]);

  return { loading, regions, getData };
}

export function FetchSegments(country, shipToType, isDisableFields) {
  const url = configData.fetchSegmentsURL;
  const [loading, setLoading] = useState(false);
  const [segments, setSegments] = useState([]);
  var shipToKeyword;

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country, shipToType: shipToKeyword });
    setSegments(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && country !== '' && shipToType !== '') {
      if (shipToType.startsWith('National') || shipToType == 'Miscellaneous') {
        shipToKeyword = 'On-Trade ';
      } else {
        shipToKeyword = shipToType.substring(0, 9);
      }
      // let splitShipToType = shipToType.split(' ');
      // shipToKeyword = splitShipToType[0].trim();
      // console.log(`Keyword: ${shipToKeyword}`);
      getData();
    }
  }, [country, shipToType]);

  return { loading, segments, getData };
}

export function FetchCountries() {
  const [url, setUrl] = useState(configData.fetchCountriesURL);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url);
    setCountries(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [url]);

  return { loading, countries, getData };
}

export function FetchSubSegments(country, shipToType, segment, isDisableFields, fetchSubSegments) {
  const url = configData.fetchSubSegmentsURL;
  const [loading, setLoading] = useState(false);
  const [subSegments, setSubSegments] = useState([]);
  var shipToKeyword;

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, {
      country: country,
      shipToType: shipToKeyword,
      segment: segment,
    });
    setSubSegments(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if ((country !== '' && shipToType !== '' && segment !== '') || fetchSubSegments) {
      console.log('Segment changed - call sub segments');
      if (shipToType.startsWith('National') || shipToType == 'Miscellaneous') {
        shipToKeyword = 'On-Trade ';
      } else {
        shipToKeyword = shipToType.substring(0, 9);
      }
      // console.log(`Keyword: ${shipToKeyword}`);
      getData();
    }
  }, [country, shipToType, segment, fetchSubSegments]);

  return { loading, subSegments, getData };
}

export function FetchDeliveringPlants(country, isDisableFields) {
  const url = configData.fetchDeliveryPlantsURL;
  const [loading, setLoading] = useState(false);
  const [deliveringPlants, setDeliveringPlants] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setDeliveringPlants(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && country !== '') {
      getData();
    }
  }, [country]);

  return { loading, deliveringPlants, getData };
}

export function FetchShippingConditions(country, isDisableFields) {
  const url = configData.fetchShippingConditionsURL;
  const [loading, setLoading] = useState(false);
  const [shippingConditions, setShippingConditions] = useState([]);
  var countryNew;

  const getData = async () => {
    setLoading(true);
    console.log(`Country`, countryNew);
    const res = await axios.post(url, { country: countryNew });
    setShippingConditions(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && country !== '') {
      countryNew = country === 'United Kingdom' ? country : 'Others';
      console.log(countryNew);
      getData();
    }
  }, [country]);

  return { loading, shippingConditions, getData };
}

export function FetchPOCSuppliers(country, shipToType, isDisableFields) {
  const url = configData.fetchPOCSuppliersURL;
  const [loading, setLoading] = useState(false);
  const [pocSuppliers, setPocSuppliers] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setPocSuppliers(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (
      !isDisableFields &&
      !(country in ['', 'United Kingdom']) &&
      shipToType.includes('Indirect')
    ) {
      getData();
    }
  }, [country]);

  return { loading, pocSuppliers, getData };
}

export function FetchShipToBapi(shipToID, companyCode, salesOrg, isCopying) {
  const url = urls.fetchShipToBapiURL;
  const [loading, setLoading] = useState(false);
  const [shipToData, setShipToData] = useState({});

  const getData = async () => {
    setLoading(true);
    const res = await axios.get(url, {
      params: { shipToID: shipToID, companyCode: companyCode, salesOrg: salesOrg },
    });
    setShipToData(res.data ?? {});
    setLoading(false);
  };

  useEffect(() => {
    console.log(isCopying, shipToID, companyCode, salesOrg);
    if (isCopying && shipToID !== '' && companyCode !== '' && salesOrg !== '') {
      console.log('hello');
      getData();
    }
  }, [shipToID, companyCode, salesOrg, isCopying]);

  return { loading, shipToData, getData };
}

export function FetchProfitCenters(country, isDisableFields) {
  const url = configData.fetchProfitCentersURL;
  const [loading, setLoading] = useState(false);
  const [profitCenters, setProfitCenters] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, {
      country: country,
    });
    setProfitCenters(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && country === 'France') {
      getData();
    }
  }, [country, isDisableFields]);

  return { loading, profitCenters, getData };
}

export function FetchTransportationZones(country, isDisableFields) {
  const url = configData.fetchTransportationZonesURL;
  const [loading, setLoading] = useState(false);
  const [transportationZones, setTransportationZones] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setTransportationZones(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && country !== '') {
      getData();
    }
  }, [country]);

  return { loading, transportationZones, getData };
}
