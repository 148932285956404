import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Button, ButtonGroup, Row, Col, Container, Modal, Spinner } from 'react-bootstrap';
import Select, { components } from 'react-select';
import MandatoryText from '../../../components/MandatoryText';

import { validateFields } from './utils/FormValidation';
import { FetchShipToBapi, FetchSoldToBapi, FetchPayerBapi } from '../utils/FlowCallAPI';
import { FetchM1M2, FetchShipToTypes } from './utils/FlowCallAPI';
import { useFormFields } from './utils/HooksLib';
import { formTemplate } from '../Data';
import { postalCodeLength } from '../../../utils/Utils';
//import Select from 'react-select';

const UpdateM1mappingUpdate = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  const [translations, setTranslations] = useState(props.translations);

  const [inputs, setInputs] = useFormFields(props.formTemplate);

  useEffect(() => {
    // if (!isAuthenticated) navigate('/');
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    console.log(inputs);
    if (
      props.draftTicketNum[0] !== 'M' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected'
    ) {
      setIsDisable(true);
    }

    if (inputs.name != '') setIsDisableShipToFields(false);
    console.log('Hey therer', inputs.shipToType);

    // if (inputs.payerName != '') setIsDisablePayerFields(false);

    // if (inputs.soldToName != '') setIsDisableSoldToFields(false);
  }, []);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkForward, setLinkForward] = useState('');

  const [isShipCopying, setIsShipCopying] = useState(false);
  const [copyShipClick, setCopyShipClick] = useState(false);
  // const [shipSap, setShipSap ] = useState(inputs.existingShipToID);

  // const [isSoldCopying, setIsSoldCopying] = useState(false);
  // const [copySoldClick, setCopySoldClick] = useState(false);

  // const [isPayerCopying, setIsPayerCopying] = useState(false);
  // const [copyPayerClick, setCopyPayerClick] = useState(false);

  // const [isDisableFields, setIsDisableFields] = useState(true);
  const [isDisableShipToFields, setIsDisableShipToFields] = useState(true);
  // const [isDisableSoldToFields, setIsDisableSoldToFields] = useState(true);
  // const [isDisablePayerFields, setIsDisablePayerFields] = useState(true);

  const mainPageForm = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')) || {};
  // const country = JSON.parse(sessionStorage.getItem('UpdateCustomerMainPage')).country || '';

  const shipToDataRes = FetchShipToBapi(
    inputs.existingShipToID,
    mainPageForm.companyCode,
    mainPageForm.salesOrg,
    isShipCopying,
  );
  // console.log(mainPageForm.salesOrg);

  // const soldToDataRes = FetchSoldToBapi(
  //   // inputs.existingSoldToID,
  //   mainPageForm.companyCode,
  //   mainPageForm.salesOrg,
  //   isSoldCopying,
  // );

  // const payerDataRes = FetchPayerBapi(
  //   // inputs.existingPayerID,
  //   mainPageForm.companyCode,
  //   mainPageForm.salesOrg,
  //   isPayerCopying,
  // );

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(validateFields(inputs, false, false, false));
      setIsSubmitting(true);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      props.setActivePage(linkForward);
    }
  }, [errors]);

  function getLang(id) {
    // console.log(props.translations);
    let obj = translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    setTranslations(props.translations);
  }, [props.translations]);

  function copyShipToData(e) {
    setCopyShipClick(true);
    setErrors(validateFields(inputs, true, false, false));
    console.log(`I am true ${isShipCopying}`);
  }

  // function copySoldToData(e) {
  //   setCopySoldClick(true);
  //   setErrors(validateFields(inputs, false, true, false));
  //   console.log(`I am true ${isSoldCopying}`);
  // }

  // function copyPayerData(e) {
  //   setCopyPayerClick(true);
  //   setErrors(validateFields(inputs, false, false, true));
  //   console.log(`I am true ${isPayerCopying}`);
  // }

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && copyShipClick) {
      console.log(inputs.name, isDisableShipToFields, copyShipClick);
      setCopyShipClick(false);
      setIsShipCopying(true);
      Object.entries(formTemplate.M1mapping).forEach(([key, value]) => {
        if (['name'].includes(key)) inputs[key] = value;
      });
    }
  }, [errors]);

  const fetchM1M2Res = FetchM1M2(mainPageForm.country);
  const fetchShipToTypesRes = FetchShipToTypes(mainPageForm.country);
  const [isDisableFields, setIsDisableFields] = useState(false);

  // useEffect(() => {
  //   if (Object.keys(shipToDataRes.shipToData).length !== 0) {
  //     if(inputs.existingShipToID != '') setShipSap(inputs.existingShipToID);
  //     Object.entries(shipToDataRes.shipToData).forEach(([key, value]) => {
  //       if (key == 'name') inputs.shipToName = value;
  //       // else if (key == 'glnIlnCode') inputs.glnShipTo = value;
  //       else if (key in inputs) {
  //         inputs[key] = value;
  //       }
  //     });
  //     sessionStorage.setItem('UpdateM1mappingUpdate', JSON.stringify(inputs));
  //     setIsDisableShipToFields(false);
  //   }
  //   setIsShipCopying(false);
  // }, [shipToDataRes.shipToData]);
  useEffect(() => {
    if (Object.keys(shipToDataRes.shipToData).length !== 0) {
      Object.entries(shipToDataRes.shipToData).forEach(([key, value]) => {
        if (key == 'name') inputs.name = value;
        // else if (key == 'glnIlnCode') inputs.glnShipTo = value;
        else if (key in inputs) {
          inputs[key] = value;
        }
      });
      sessionStorage.setItem('updateM1mappingUpdate', JSON.stringify(inputs));
      setIsDisableShipToFields(false);
    }
    setIsShipCopying(false);
  }, [shipToDataRes.shipToData]);

  // useEffect(() => {
  //   if (Object.keys(soldToDataRes.soldToData).length !== 0) {
  //     Object.entries(soldToDataRes.soldToData).forEach(([key, value]) => {
  //       if (key == 'name') inputs.soldToName = value;
  //       // else if (key == 'glnIlnCode') inputs.glnSoldTo = value;
  //       else if (key in inputs) {
  //         if (!['existingShipToID', 'existingBillToID', 'existingPayerID'].includes(key))
  //           inputs[key] = value;
  //       }
  //     });
  //     sessionStorage.setItem('UpdateM1mappingUpdate', JSON.stringify(inputs));
  //     setIsDisableSoldToFields(false);
  //   }
  //   setIsSoldCopying(false);
  // }, [soldToDataRes.soldToData]);

  // useEffect(() => {
  //   if (Object.keys(payerDataRes.payerData).length !== 0) {
  //     Object.entries(payerDataRes.payerData).forEach(([key, value]) => {
  //       if (key == 'name') inputs.payerName = value;
  //       else if (key == 'glnIlnCode') inputs.glnPayer = value;
  //       else if (key in inputs) {
  //         inputs[key] = value;
  //       }
  //     });
  //     sessionStorage.setItem('UpdateM1mappingUpdate', JSON.stringify(inputs));
  //     setIsDisablePayerFields(false);
  //   }
  //   setIsPayerCopying(false);
  // }, [payerDataRes.payerData]);

  const m1Options = fetchM1M2Res.m1Vals.map((val) => {
    return {
      ...val,
      m1: val.m1,
      m2: val.m2,
    };
  });
  console.log(m1Options);
  return (
    <form className="card" onSubmit={handleSubmit}>
      <Modal show={isShipCopying} centered>
        {/* add isPayerCopying , isSoldCopying */}
        <Modal.Body>
          <Row>
            <div className="col-auto">
              <Spinner animation="border" variant="warning" />
            </div>
            <div className="text-left">
              <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
      <div className="card-header">
        <div className="col-12">
          <h3 className="card-title text-left">
            <strong>Customer Data {getLang('Update')}</strong>
          </h3>
        </div>
      </div>
      <Row className="sidebar-row">
        <div className="col-12">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                <Row>
                  <Col sm={12} lg="auto">
                    <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('M1 Mapping Update')}</h2>
                  </Col>
                </Row>
                <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

                <Row>
                  <Container>
                    <Row>
                      <div className="col-12 col-lg-auto">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Country')}</label>
                          <input
                            type="text"
                            className="form-control"
                            name="country"
                            value={mainPageForm.country}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-3">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Ship To Number')}
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className={`form-control${
                                errors.existingShipToID ? ' is-invalid' : ''
                              }`}
                              name="existingShipToID"
                              maxLength="8"
                              placeholder={`Enter ${getLang('Existing Ship-to ID')}`}
                              value={inputs.existingShipToID}
                              onChange={setInputs}
                              disabled={isDisable}
                            />
                            <span className="input-group-append">
                              <button
                                className="btn btn-primary"
                                type="button"
                                style={{
                                  borderTopRightRadius: '3px',
                                  borderBottomRightRadius: '3px',
                                }}
                                onClick={copyShipToData}
                                disabled={isDisable || inputs.existingShipToID == ''}
                              >
                                {getLang('Copy')}
                              </button>
                            </span>
                            {errors.existingShipToID && (
                              <div className="invalid-feedback text-left">
                                {errors.existingShipToID}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Row>
                    <hr />
                    <Row>
                      <div className="col-12 col-lg-3">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Ship-to Type')} *
                          </label>
                          <select
                            id="select-ship-to-type"
                            name="shipToType"
                            className={`form-control ${
                              errors.shipToType ? 'is-invalid-select' : ''
                            }`}
                            onChange={setInputs}
                            value={inputs.shipToType}
                            required
                            disabled={isDisable}
                          >
                            <option value="">Select</option>

                            {/* {inputs.customerReqType == '2nd Sold To' && ( */}
                            {/* <option key="On-Trade – Indirect" value="On-Trade – Indirect">
                                  {`On-Trade – Indirect`}
                                </option> */}

                            {fetchShipToTypesRes.shipToTypes.map((shipType) => (
                              <option key={shipType.shipToType} value={shipType.shipToType}>
                                {shipType.shipToType}
                              </option>
                            ))}
                          </select>
                          {errors.shipToType && (
                            <div className="invalid-feedback text-left">{errors.shipToType}</div>
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-lg-3">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Name')}</label>
                          <input
                            type="text"
                            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                            name="name"
                            placeholder={`${getLang('Name')}`}
                            value={inputs.name}
                            onChange={setInputs}
                            disabled
                          />
                          {errors.name && (
                            <div className="invalid-feedback text-left">{errors.name}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-3">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Street')}</label>
                          <input
                            type="text"
                            className={`form-control ${errors.street ? 'is-invalid' : ''}`}
                            name="street"
                            value={inputs.street}
                            onChange={setInputs}
                            maxLength="35"
                            disabled
                          />
                          {errors.street && (
                            <div className="invalid-feedback text-left">{errors.street}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-3">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('House Number')} *
                          </label>
                          <input
                            type="text"
                            className={`form-control ${errors.houseNumber ? 'is-invalid' : ''}`}
                            name="houseNumber"
                            value={inputs.houseNumber}
                            onChange={setInputs}
                            maxLength="35"
                            disabled={inputs.name == '' || isDisable}
                            required
                          />
                          {errors.houseNumber && (
                            <div className="invalid-feedback text-left">{errors.houseNumber}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-3">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Postal Code')}</label>
                          <input
                            type="text"
                            className={`form-control ${errors.postalCode ? 'is-invalid' : ''}`}
                            name="postalCode"
                            maxLength={postalCodeLength(mainPageForm?.country)}
                            value={inputs.postalCode}
                            onChange={setInputs}
                            disabled
                          />
                          {errors.postalCode && (
                            <div className="invalid-feedback text-left">{errors.postalCode}</div>
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-lg-3">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {/* {inputs.shipToType.includes('Wholesaler') ? getLang('W1') : getLang('M1')} * */}
                            {getLang('M1')} *
                          </label>
                          <Select
                            className="text-left"
                            name="m1"
                            value={m1Options.filter((opt) => opt.m1 == inputs.m1)}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#cfcfcf',
                                primary50: '#cfcfcf',
                                primary: 'orange',
                              },
                            })}
                            isClearable
                            placeholder="Select"
                            isDisabled={isDisable}
                            getOptionLabel={({ m1 }) => m1}
                            getOptionValue={({ m1 }) => m1}
                            options={m1Options}
                            onChange={(e, action) => {
                              setInputs(e, action);
                            }}
                          />
                          {/* <option value="">Select</option> */}
                          {/* </select> */}
                          {errors.m1 && (
                            <div className="invalid-feedback-file text-left">{errors.m1}</div>
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-lg-3">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {/* {inputs.shipToType.includes('Wholesaler') ? getLang('W2') : getLang('M2')} * */}
                            {getLang('M2')} *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="m2"
                            placeholder="Select an M1"
                            value={inputs.m2}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-12 col-lg-3">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Use of Tankbeer')} *
                          </label>
                          <select
                            id="select-att7"
                            name="att7"
                            className={`form-control${errors.att7 ? ' is-invalid' : ''}`}
                            onChange={setInputs}
                            value={inputs.att7}
                            disabled={isDisable}
                            // placeholder="Select"
                          >
                            <option value="">Select</option>
                            <option value="No">No</option>
                            <option value="Cellar Beer">Yes</option>
                          </select>
                          {errors.att7 && (
                            <div className="invalid-feedback text-left">{errors.att7}</div>
                          )}
                        </div>
                      </div>

                      {mainPageForm.country == 'Italy' && (
                        <div className="col-12 col-lg-3">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Truck Type')} *
                            </label>
                            <select
                              className={`form-control ${errors.truckType ? 'is-invalid' : ''}`}
                              name="truckType"
                              value={inputs.truckType}
                              onChange={setInputs}
                              disabled={isDisableFields || isDisable}
                            >
                              <option value="">Select</option>
                              <option value="ZITTR3 - TRACTOR 3 AXELS">{`ZITTR3 - TRACTOR 3 AXELS`}</option>
                            </select>
                            {errors.truckType && (
                              <div className="invalid-feedback text-left">{errors.truckType}</div>
                            )}
                          </div>
                        </div>
                      )}
                      {mainPageForm.country == 'Italy' && (
                        <div className="col-12 col-lg-3">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Trailer Type')} *
                            </label>
                            <select
                              className={`form-control ${errors.trailerType ? 'is-invalid' : ''}`}
                              name="trailerType"
                              value={inputs.trailerType}
                              onChange={setInputs}
                              disabled={isDisableFields || isDisable}
                            >
                              <option value="">Select</option>
                              <option value="ZITMOTRI - MOTRICE (16 PLT)">{`ZITMOTRI - MOTRICE (16 PLT)`}</option>
                              <option value="ZITAN3 - TRAILER 3 AXELS">{`ZITAN3 - TRAILER 3 AXELS`}</option>
                            </select>
                            {errors.trailerType && (
                              <div className="invalid-feedback text-left">{errors.trailerType}</div>
                            )}
                          </div>
                        </div>
                      )}
                    </Row>
                  </Container>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <br />

      <div className="card-footer">
        <div className="row">
          {!isDisable && (
            <div className="col-auto col-md-auto text-left">
              <Button variant="outline-warning" onClick={props.saveDraft}>
                Save Draft
              </Button>
            </div>
          )}
          <div className="col col-md text-left">
            <span className="align-middle">{props.draftMsg}</span>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="mainPage"
              data-back="true"
              className="btn btn-warning btn-block"
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Back
            </Link>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="M1mappingUpdateReview"
              className={`btn btn-danger btn-block${
                inputs.existingShipToID != '' && inputs.name != '' ? '' : ' disabled'
              }`}
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Review
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateM1mappingUpdate;
