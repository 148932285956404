import React, { useState, useEffect } from 'react';
import CreateCustMainPage from './mainpage/MainPage';
import CreateCustProfileData from './profileData/ProfileData';
import CreateCustReview from './review/Review';
import CreateCustSaveSuccess from './success/Success';
import CreateCustSaveError from './error/Error';

import { formTemplate } from './Data';
import { SaveDraft } from './FlowCallAPI';

const CreateHierarchy = (props) => {
  const [newTicket, setNewTicket] = useState(props.createNew);

  const [activePage, setActivePage] = useState('mainpage');

  const [draftTicketNum, setDraftTicketNum] = useState('');

  const [ticketStatus, setTicketStatus] = useState('');

  const [isSave, setIsSave] = useState(false);

  const [draftMsg, setDraftMsg] = useState('');

  const [done, setDone] = useState(false);
  console.log('PROPS DATA', props.data, Object.keys(props.data).length);
  const mainPageForm = props.data.mainpage
    ? JSON.parse(sessionStorage.getItem('createHierarchyMainPage')) || props.data.mainpage
    : JSON.parse(sessionStorage.getItem('createHierarchyMainPage')) || formTemplate.mainpage;

  const profileDataForm = props.data.profileData
    ? JSON.parse(sessionStorage.getItem('createCustomerProfileData')) || props.data.profileData
    : JSON.parse(sessionStorage.getItem('createCustomerProfileData')) || formTemplate.profileData;

  const saveDraft = (e) => {
    //console.log('Clicked Save Draft', mainPageForm.country);
    setIsSave(true);
    console.log(draftTicketNum);
    //console.log(mainPageForm);
  };

  useEffect(() => {
    console.log(`Active: ${activePage}`);
    console.log(props);
    setDraftMsg('');
  }, [activePage]);

  useEffect(() => {
    console.log(`Clearing1232131241294921481284: `, newTicket);
    setDone(false);
    if (Object.keys(props.data).length !== 0) {
      console.log('Doing');
      sessionStorage.setItem('createHierarchyMainPage', JSON.stringify(props.data.mainpage));
      sessionStorage.setItem('createCustomerProfileData', JSON.stringify(props.data.profileData));
      setDraftTicketNum(props.data.title);
      setTicketStatus(props.data.status);
    } else if (newTicket) {
      console.log('CreateNew called');
      var today = new Date();
      setDraftTicketNum(
        `DH${today.getDate()}${
          today.getMonth() + 1
        }${today.getFullYear()}${today.getHours()}${today.getMinutes()}${today.getSeconds()}`,
      );
      console.log(`Draft: ${draftTicketNum}`);

      sessionStorage.setItem('createHierarchyMainPage', JSON.stringify(formTemplate.mainpage));
      sessionStorage.setItem('createCustomerProfileData', JSON.stringify(formTemplate.profileData));

      setNewTicket(false);
    } else {
      console.log('this');
    }

    setDone(true);
  }, []);

  const saveDraftRes = SaveDraft(
    props.accounts.length > 0 ? props.accounts[0].username : '',
    draftTicketNum,
    isSave,
    'Hierarchy',
  );

  useEffect(() => {
    setIsSave(false);
    if ('result' in saveDraftRes.result) setDraftMsg(saveDraftRes.result.result);
    else if ('error' in saveDraftRes.result) setDraftMsg(saveDraftRes.result.error);
  }, [saveDraftRes.result]);

  console.log(activePage);
  return (
    draftTicketNum != '' && (
      <>
        {activePage === 'mainpage' && (draftTicketNum !== '' || props.data) && done && (
          <CreateCustMainPage
            setActivePage={setActivePage}
            formTemplate={mainPageForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            accounts={props.accounts}
            draftMsg={draftMsg}
            data={props.data}
            translations={props.translations}
          />
        )}
        {/* {activePage === 'profileData' && done && (
          <CreateCustProfileData setActivePage={setActivePage} draftTicketNum={draftTicketNum} formTemplate={profileDataForm} saveDraft={saveDraft} draftMsg={draftMsg} translations={props.translations} />
        )} */}
        {activePage === 'review' && done && (
          <CreateCustReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
          />
        )}
        {activePage === 'success' && done && (
          <CreateCustSaveSuccess
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            accounts={props.accounts}
            translations={props.translations}
          />
        )}
        {activePage === 'error' && done && (
          <CreateCustSaveError
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            accounts={props.accounts}
            translations={props.translations}
          />
        )}
      </>
    )
  );
};

export default CreateHierarchy;
