import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Row, Col, ButtonGroup, Button, Modal, Spinner } from 'react-bootstrap';
import Select, { components } from 'react-select';
import FeatherIcon from 'feather-icons-react';
import MandatoryText from '../../../components/MandatoryText';

import { FetchRegions } from '../shipto/FlowCallAPI';
import { FetchTitles, FetchPaymentTerms, FetchPayerBapi } from './FlowCallAPI';
import { FetchCountries } from '../mainpage/FlowCallAPI';
import { languages, einvoicingOptions, languageMap, vatPlaceholder } from '../../../utils/Utils';
import { formTemplate } from '../Data';
import { useFormFields } from './HooksLib';
import { validateFields } from './FormValidation';
import Sidebar from '../../../components/layout/Sidebar/Sidebar';

import {
  mobileLength,
  mobilePlaceholder,
  telephoneLength,
  telephonePlaceholder,
  ibanLength,
  ibanPlaceholder,
  vatLength,
  postalCodeLength,
  payerIDRegex,
} from '../../../utils/Utils';

const DispVendCust = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) navigate('/');
  }, [isAuthenticated]);

  const { instance, accounts } = useMsal();

  const [isDisable, setIsDisable] = useState(false);

  const fileRegex = /\.[^.\\/:*?"<>|\r\n]+$/;

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [copyClick, setCopyClick] = useState(false);
  const [isDisableFields, setIsDisableFields] = useState(false);
  const [linkForward, setLinkForward] = useState('');
  // const [payerForm, setPayerForm] = useState(sessionStorage.getItem('createCustomerPayer') === null ? payerFormTemplate : JSON.parse(sessionStorage.getItem('createCustomerPayer')));
  // const [inputs, setInputs] = useFormFields(payerForm);
  const [inputs, setInputs] = useFormFields(props.formTemplate);
  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country || '';
  const companyCode =
    JSON.parse(sessionStorage.getItem('createCustomerMainPage')).companyCode || '';
  const salesOrg = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).salesOrg || '';
  const shipToType = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).shipToType || '';
  const customerReqType =
    JSON.parse(sessionStorage.getItem('createCustomerMainPage')).customerReqType || '';
  const mainPageData = JSON.parse(sessionStorage.getItem('createCustomerMainPage'));
  const shipToData = JSON.parse(sessionStorage.getItem('createCustomerShipTo'));
  const titlesRes = FetchTitles(country, isDisableFields);
  const regionsRes = FetchRegions(inputs.payerCountry, isDisableFields);
  const paymentTermsRes = FetchPaymentTerms(country, isDisableFields, shipToType);
  const payerDataRes = FetchPayerBapi(inputs.existingPayerID, companyCode, salesOrg, isCopying);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleClose1 = () => setShowModal1(false);
  const handleClose2 = () => setShowModal2(false);
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState('');
  const countriesRes = FetchCountries();

  function xyz(e) {
    e.preventDefault();
  }

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    if (
      props.draftTicketNum[0] !== 'D' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected' &&
      props.status !== 'Duplicate'
    ) {
      //if ((props.draftTicketNum[0] === 'D')) {
      setIsDisable(true);
    }

    if (inputs.isVendor == '') inputs.isVendor = 'No';
    if (inputs.telephone == '') inputs.telephone = shipToData.generalTelephone;
    if (inputs.mobile == '') inputs.mobile = shipToData.mobile;
    if (inputs.email == '') inputs.email = shipToData.email;
    if (inputs.payerCountry == '') inputs.payerCountry = country;

    switch (country) {
      case 'Belgium':
        if (inputs.vatRegNumber == '0111111111') inputs.vatExist = 'No';
        else if (inputs.vatRegNumber == '0999999999') inputs.vatExist = 'In Progress';
        else inputs.vatExist = 'Yes';

        if (shipToType.endsWith('Direct') && inputs.paymentTerms == '')
          inputs.paymentTerms = '014I';
        else if (shipToType.endsWith('Indirect') && inputs.paymentTerms == '')
          inputs.paymentTerms = '014I';
        else if (!shipToType.startsWith('Wholesaler')) inputs.paymentTerms = '014I';

        if (inputs.language == '') inputs.language = 'English';

        if (shipToType == 'Off-Trade - Indirect' && inputs.eInvOption == '')
          inputs.eInvOption = 'No';

        // if (shipToType.includes('Wholesaler') && inputs.onInvoicingDiscount == '')
        //   inputs.onInvoicingDiscount = 'Yes';

        if (shipToType.startsWith('On-Trade') && inputs.rent == '') inputs.rent = 'No';
        break;
      case 'Netherlands':
        if (inputs.language == '') inputs.language = 'Dutch';
        if (shipToType.endsWith('Indirect') && inputs.paymentTerms == '')
          inputs.paymentTerms = '000A';

        if (shipToType.startsWith('On-Trade') && inputs.rent == '') inputs.rent = 'No';
        break;

      case 'United Kingdom':
        if (inputs.language == '') inputs.language = 'English';
        if (shipToType.endsWith('Indirect') && inputs.paymentTerms == '')
          inputs.paymentTerms = '0000';
        break;

      case 'France':
        if (inputs.language == '') inputs.language = 'French';

        if (inputs.paymentTerms == '') {
          if (shipToType == 'Wholesale– Direct') inputs.paymentTerms = '030M';
          else if (shipToType == 'Wholesale– Indirect') inputs.paymentTerms = '030I';
          else if (shipToType.startsWith('On-Trade')) inputs.paymentTerms = '030M';
          else inputs.paymentTerms = '045M';
        }

        // inputs.paymentTerms = shipToType.startsWith('On-Trade') ? '030M' : '045M';
        break;

      case 'Germany':
        if (inputs.language == '') inputs.language = 'German';
        if (inputs.paymentTerms == '') inputs.paymentTerms = '014I';
        break;

      case 'Finland':
        if (inputs.language == '') inputs.language = 'Finnish';
        if (inputs.paymentTerms == '') inputs.paymentTerms = '014I';
        break;

      case 'Austria':
        if (inputs.language == '') inputs.language = 'German';
        break;

      case 'Denmark':
        if (inputs.language == '') inputs.language = 'Danish';
        break;

      case 'Norway':
        if (inputs.language == '') inputs.language = 'Norwegian';
        break;

      case 'Poland':
        if (inputs.language == '') inputs.language = 'Polish';
        break;

      case 'Sweden':
        if (inputs.language == '') inputs.language = 'Swedish';
        break;

      case 'Switzerland':
        if (inputs.language == '') inputs.language = 'French';
        break;

      case 'Italy':
        if (inputs.language == '') inputs.language = 'Italian';
        inputs.eInvOption = 'Yes';
        inputs.eInvEmailType = 'Invoicing';
        break;

      default:
        break;
    }
  }, []);

  useEffect(() => {
    setFileName(inputs.supportDocsBankName);
    setFile(inputs.supportDocsBank);
  }, [inputs.supportDocsBank, inputs.supportDocsBankName]);

  function getLang(id) {
    // console.log(props.translations);
    let obj = props.translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      // if (!isDisableFields) {
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(
        validateFields(
          inputs,
          country,
          mainPageData.shipToType,
          mainPageData.customerReqType,
          inputs.title,
        ),
      );
      // }
      setIsSubmitting(true);
      // if (isDisableFields || isDisable) props.setActivePage(e.target.getAttribute('data-link'));
      if (isDisable) props.setActivePage(e.target.getAttribute('data-link'));
    }
  };

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && copyClick) {
      setCopyClick(false);
      setIsCopying(true);
      Object.entries(formTemplate.payer).forEach(([key, value]) => {
        if (
          !['existingPayerID', 'language', 'paymentTerms', 'isVendor', 'payerCountry'].includes(key)
        )
          inputs[key] = value;
      });
    } else {
      setIsSubmitting(false);
      setCopyClick(false);
      setIsCopying(false);
    }
  }, [errors]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  const [showEInvSection, setShowEInvSection] = useState(false);

  function eInvOptionChange(e) {
    if (e.target.value === 'Yes') setShowEInvSection(true);
    else setShowEInvSection(false);
    setInputs(e);
  }

  function copyPayerData(e) {
    setCopyClick(true);
    setIsCopying(true);

    // setIsCopying(true);
    // setCopyClick(true);
    // setErrors(validateFields(inputs, country, mainPageData.shipToType, mainPageData.customerReqType, inputs.title));
    // console.log(`I am true ${isCopying}`);
    // console.log(isCopying, inputs.existingPayerID, companyCode, salesOrg);
  }

  useEffect(() => {
    console.log('error outputed', payerDataRes.payerData);
    if (Object.keys(payerDataRes.payerData).length !== 0 && isCopying) {
      Object.entries(payerDataRes.payerData).forEach(([key, value]) => {
        if (key == 'contactName') inputs.contactPerson = value;
        else if (key in inputs) {
          if (key === 'telephone' && value !== '') {
            inputs.telephoneOption = 'General/Accounting';
            inputs[key] = value;
          } else if (key == 'vatRegNumber' && value != '') {
            inputs.vatExist = 'Yes';
            inputs[key] = value;
          } else if (key == 'language') inputs.language = languageMap[value];
          else inputs[key] = value;
        }
      });
      sessionStorage.setItem('createCustomerVendor', JSON.stringify(inputs));
    }

    if (payerDataRes.payerData['IsValid'] === 'Invalid') {
      setErrors({ existingPayerID: 'Invalid Vendor ID' });
    }
    setIsCopying(false);
  }, [payerDataRes.payerData]);

  useEffect(() => {
    if (inputs.existingPayerID !== '') {
      setErrors({});
      setIsDisableFields(true);
    } else setIsDisableFields(false);
  }, [inputs.existingPayerID]);

  function clearFile() {
    inputs.supportDocsBank = '';
    inputs.supportDocsBankName = '';
    setFile('');
    setFileName('');
    sessionStorage.setItem('createCustomerVendor', JSON.stringify(inputs));
  }

  const Input = (props) => <components.Input {...props} maxLength={8} />;

  const paymentTermsOptions = paymentTermsRes.paymentTerms.map((val) => {
    if (val.termsOfPayment == '0000')
      return {
        ...val,
        termsOfPayment: val.termsOfPayment,
        paymentDesc: val.paymentDesc,
        paymentVal: val.termsOfPayment,
      };
    else
      return {
        ...val,
        termsOfPayment: val.termsOfPayment,
        paymentDesc: val.paymentDesc,
        paymentVal: `${val.termsOfPayment} - ${val.paymentDesc}`,
      };
  });

  const regionOptions = regionsRes.regions.map((val) => {
    return {
      ...val,
      regionCode: val.regionCode,
      regionText: val.regionText,
      erpTranspZone: val.erpTranspZone,
      regionDesc: `${val.regionCode} - ${val.regionText}`,
    };
  });

  const sidebarItems = [
    {
      title: getLang('Main Page'),
      onClick: handleSubmit,
      ['data-link']: 'mainpage',
      ['data-back']: 'true',
      active: false,
    },
    {
      title: getLang('Vendor'),
      onClick: handleSubmit,
      ['data-link']: 'vendor',
      ['data-back']: 'true',
      // type: 'submit',
      active: true,
    },
    {
      title: getLang('Ship-To'),
      onClick: handleSubmit,
      ['data-link']: 'shipto',
      // ['data-back']: true,
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Payer'),
      onClick: handleSubmit,
      ['data-link']: 'payer',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Sold-To'),
      onClick: handleSubmit,
      ['data-link']: 'soldto',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Bill-To'),
      onClick: handleSubmit,
      ['data-link']: 'billto',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
  ];

  return (
    isAuthenticated && (
      <form className="card" onSubmit={handleSubmit}>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={inputs.supportDocsBank}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModal1} onHide={handleClose1}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={inputs.supportDocsBank1}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModal2} onHide={handleClose2}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={inputs.supportDocsBank2}></img>
          </Modal.Body>
        </Modal>

        <Modal show={isCopying} centered>
          <Modal.Body>
            <Row>
              <div className="col-auto">
                <Spinner animation="border" variant="warning" />
              </div>
              <div className="text-left">
                <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
        <div className="card-header">
          <div className="col-11 col-lg-12">
            <h3 className="card-title text-left">
              <strong style={{ color: 'rgb(177, 31, 36)' }}>
                Vendor Customer {getLang('Creation')}
              </strong>{' '}
              - {getLang('Vendor')}
            </h3>
          </div>
          <a
            className={`sidebar-toggler col-auto ${isSidebarOpen ? '' : 'collapsed'}`}
            // onClick={togglingSidebar}
          >
            <Sidebar sidebarItems={sidebarItems} />
            {/* <span className="sidebar-toggler-icon"></span> */}
          </a>
        </div>
        <Row className="sidebar-row">
          <div
            className={`col-12 col-lg-2 text-left sidebar ${
              isMobile ? (isSidebarOpen ? '' : 'collapse') : ''
            }`}
          >
            <Row>
              <ButtonGroup vertical className="btn-block">
                <Link
                  data-link="mainpage"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {getLang('Main Page')}
                </Link>
                <Button className="btn-danger">{getLang('Vendor')}</Button>
                <Link
                  data-link="shipto"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {getLang('Ship To')}
                </Link>
                <Link
                  data-link="payer"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {getLang('Payer')}
                </Link>
                <Link data-link="soldto" className="btn btn-warning" onClick={handleSubmit}>
                  {getLang('Sold To')}
                </Link>
                <Link data-link="billto" className="btn btn-warning" onClick={handleSubmit}>
                  {getLang('Bill To')}
                </Link>
              </ButtonGroup>
            </Row>
          </div>
          <div className="col-12 col-lg-10">
            <div className="card-body">
              <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Existing Vendor ID *')}
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className={`form-control${errors.existingPayerID ? ' is-invalid' : ''}`}
                            name="existingPayerID"
                            maxLength="8"
                            placeholder={`Enter ${getLang('Existing Vendor ID')}`}
                            value={inputs.existingPayerID}
                            onChange={setInputs}
                            disabled={isDisable}
                          />
                          <span className="input-group-append">
                            <button
                              className="btn btn-primary"
                              type="button"
                              style={{
                                borderTopRightRadius: '3px',
                                borderBottomRightRadius: '3px',
                              }}
                              onClick={copyPayerData}
                              disabled={isDisable || inputs.existingPayerID === ''}
                            >
                              {getLang('Copy')}
                            </button>
                          </span>
                          {errors.existingPayerID && (
                            <div className="invalid-feedback text-left">
                              {errors.existingPayerID}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div className="row">
                    <div className="col-12 col-lg-auto text-left">
                      <h2
                        style={{
                          color: 'rgb(177, 31, 36)',
                        }}
                      >
                        {getLang('General Data')}
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    {/* {country == 'Belgium' && !shipToType.includes('Wholesaler') && ( */}

                    {/* )} */}

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {['United Kingdom', 'Netherlands'].includes(country)
                            ? getLang('Legal Entity / Name')
                            : country == 'France'
                            ? getLang('NamePayer')
                            : getLang('Name')}{' '}
                          *
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                          name="name"
                          value={inputs.name}
                          placeholder={getLang('Name')}
                          onChange={setInputs}
                          maxLength="35"
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.name && (
                          <div className="invalid-feedback text-left">{errors.name}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Row>
        <div className="card-footer">
          <div className="row">
            <div className="col-auto col-md-auto text-left">
              {!isDisable && (
                <Button variant="outline-warning" onClick={props.saveDraft}>
                  Save Draft
                </Button>
              )}
            </div>
            <div className="col col-md text-left justify-content">{props.draftMsg}</div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link="shipto"
                data-back="true"
                className="btn btn-warning btn-block"
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Back
              </Link>
            </div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link="shipto"
                className={`btn btn-${
                  Object.keys(validateFields(inputs)).length == 0 || isDisable
                    ? 'danger'
                    : 'secondary'
                } btn-block`}
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Next
              </Link>
            </div>
          </div>
        </div>
      </form>
    )
  );
};

export default DispVendCust;
