import { useState, useEffect } from 'react';
import configData from './Config.js';
import { urls } from '../../../utils/Utils';
//import axios from 'axios';
import axios from './../../../utils/axios';

export function PostToProfileDB(requestor, title, isSave) {
  const url = urls.postToSharepointCreationURL;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  const [isError, setIsError] = useState(false);

  const postData = async () => {
    setLoading(true);
    const mainpage = JSON.parse(sessionStorage.getItem('createCustomerMainPage'));
    var profilepage = JSON.parse(sessionStorage.getItem('createCustomerProfileData'));
    console.log('PLANT PAGE', mainpage);
    if (Object.keys(profilepage).length != 0) {
      Object.entries(profilepage).forEach(([key, value]) => {
        profilepage[key] = value.toString().trim();
      });
    }

    try {
      const res = await axios.post(url, {
        requestor: requestor,
        requestType: 'Creation',
        title: title.slice(1),
        mainpage: mainpage,
        profilepage: profilepage,
      });
      setResult(res.data ?? {});
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log('POST DATA REQUEST', isSave, title);
    if (isSave && title !== '') {
      postData();
    }
  }, [isSave]);

  return { loading, isError, result, postData };
}

// replace(/\s+/g,' ')
