import { useState, useEffect } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  useEffect(() => {
    sessionStorage.setItem('updateExciseDutyUpdate', JSON.stringify(fields));
  }, [fields]);

  return [
    fields,
    function (event) {
      if (event.target.name == 'existingShipToID') {
        var today = new Date();
        var todayDisp = `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1}-${today.getDate() < 10 ? '0' + today.getDate() : today.getDate()}`;
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          edCustomerNumber: '',
          name:'',
          street: '',
          postalCode: '',
          city: '',
          edType: '',
          edValidFrom: todayDisp,
          edValidTo: '9999-12-31',
          taxWarehouseNumber: '',
          externalEdNumber: '',
          partnerType: ''
        });
      } else if (event.target.name == 'warehouseEdType') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          warehouseEdTypeDesc: event.target.selectedOptions[0].getAttribute('data-ed'),
        });
      } else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
    },
  ];
}
