const prod = {
    url: {
        createIncident: 'https://dscoeinternalbrandingbackend-prod.azurewebsites.net/api/createIncident/',
        getSysID: 'https://dscoeinternalbrandingbackend-prod.azurewebsites.net/api/mySysID/',
        getIncidentSysID: 'https://dscoeinternalbrandingbackend-prod.azurewebsites.net/api/incidentSysId/',
        uploadFile: "https://dscoeinternalbrandingbackend-prod.azurewebsites.net/api/uploadFile/",
        pushSQL: "https://dscoeinternalbrandingbackend-prod.azurewebsites.net/api/pushIncident",
        getIncidents: "https://dscoeinternalbrandingbackend-prod.azurewebsites.net/api/myIncidents/",
        fetchTranslationsURL: 'https://mavenapis.ab-inbev.com/api/data/translations',
        fetchRequestID: 'https://mavenapis.ab-inbev.com/api/allRequestsContactUs',
    },
  };
  
  const test = {
    url: {
        createIncident: 'https://dscoeinternalbrandingbackend-dev.azurewebsites.net/api/createIncident/',
        getSysID: 'https://dscoeinternalbrandingbackend-dev.azurewebsites.net/api/mySysID/',
        getIncidentSysID: 'https://dscoeinternalbrandingbackend-dev.azurewebsites.net/api/incidentSysId/',
        uploadFile: "https://dscoeinternalbrandingbackend-dev.azurewebsites.net/api/uploadFile/",
        pushSQL: "https://dscoeinternalbrandingbackend-dev.azurewebsites.net/api/pushIncident",
        getIncidents: "https://dscoeinternalbrandingbackend-dev.azurewebsites.net/api/myIncidents/",
        fetchTranslationsURL: 'https://mavenapistest.ab-inbev.com/api/data/translations',
        fetchRequestID: 'https://mavenapistest.ab-inbev.com/api/allRequestsContactUs',
    },
  };
  
  const local = {
    url: {
        createIncident: 'https://dscoeinternalbrandingbackend-dev.azurewebsites.net/api/createIncident/',
        getSysID: 'https://dscoeinternalbrandingbackend-dev.azurewebsites.net/api/mySysID/',
        getIncidentSysID: 'https://dscoeinternalbrandingbackend-dev.azurewebsites.net/api/incidentSysId/',
        uploadFile: "https://dscoeinternalbrandingbackend-dev.azurewebsites.net/api/uploadFile/",
        pushSQL: "https://dscoeinternalbrandingbackend-dev.azurewebsites.net/api/pushIncident",
        getIncidents: "https://dscoeinternalbrandingbackend-dev.azurewebsites.net/api/myIncidents/",
        fetchTranslationsURL: 'http://localhost:1234/api/data/translations',
        fetchRequestID: 'http://localhost:1234/api/allRequestsContactUs',
    },
  };
  
  const configData =
    process.env.REACT_APP_ENV === 'prod'
      ? prod.url
      : process.env.REACT_APP_ENV === 'test'
      ? test.url
      : local.url;
  
  export default configData;
  