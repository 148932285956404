import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import { Button, ButtonGroup, Row, Modal, Spinner } from 'react-bootstrap';
import Select, { components } from 'react-select';
import MandatoryText from '../../../components/MandatoryText';

import { formTemplate } from '../Data';
import { useFormFields } from './HooksLib';
import { FetchCountries } from '../mainpage/FlowCallAPI';
import { FetchRegions } from '../shipto/FlowCallAPI';
import { FetchTitles } from '../payer/FlowCallAPI';
import { FetchBillToBapi } from './FlowCallAPI';
import { languages, languageMap } from '../payer/Data';
import { validateFields } from './FormValidation';
import Sidebar from '../../../components/layout/Sidebar/Sidebar';
import CustomTextInput from '../../../components/core/InputFields/CustomTextInput/CustomTextInput';
import CustomTelInput from '../../../components/core/InputFields/CustomTelInput/CustomTelInput';
import {
  mobileLength,
  mobilePlaceholder,
  telephoneLength,
  telephonePlaceholder,
  postalCodeLength,
  postalCodeRegex,
  vatLength,
  vatPlaceholder,
} from '../../../utils/Utils';

const CreateCustBillTo = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) navigate('/');
  }, [isAuthenticated]);

  // const billToForm = sessionStorage.getItem('createCustomerBillTo') === null ? billToFormTemplate : JSON.parse(sessionStorage.getItem('createCustomerBillTo'));

  // const [inputs, setInputs] = useFormFields(billToForm);

  const [isDisable, setIsDisable] = useState(false);

  const [inputs, setInputs] = useFormFields(props.formTemplate);

  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country || '';

  const companyCode =
    JSON.parse(sessionStorage.getItem('createCustomerMainPage')).companyCode || '';

  const salesOrg = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).salesOrg || '';

  const customerReqType =
    JSON.parse(sessionStorage.getItem('createCustomerMainPage')).customerReqType || '';

  const shipToType = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).shipToType || '';

  const [errors, setErrors] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isDisableFields, setIsDisableFields] = useState(false);

  const [isCopying, setIsCopying] = useState(false);

  const [linkForward, setLinkForward] = useState('');

  const titlesRes = FetchTitles(country, isDisableFields);

  const countriesRes = FetchCountries();

  const billToDataRes = FetchBillToBapi(inputs.existingBillToID, companyCode, salesOrg, isCopying);

  const mainPageData = JSON.parse(sessionStorage.getItem('createCustomerMainPage'));

  useEffect(() => {
    if (
      props.draftTicketNum[0] !== 'D' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected' &&
      props.status !== 'Duplicate'
    ) {
      //if ((props.draftTicketNum[0] === 'D')) {
      setIsDisable(true);
    }

    if (inputs.showBillTo == '') inputs.showBillTo = 'No-As per payer';

    // if (inputs.showBillTo == 'Yes-Fill details') {
    //   if (inputs.billCountry == '') inputs.billCountry = country;
    // }

    if (inputs.billCountry == '') inputs.billCountry = country;

    switch (country) {
      case 'Netherlands':
        if (inputs.language == '') inputs.language = 'Dutch';
        break;

      case 'United Kingdom':
        if (inputs.language == '') inputs.language = 'English';
        break;

      case 'France':
        if (inputs.language == '') inputs.language = 'French';
        break;

      case 'Belgium':
        if (inputs.language == '') inputs.language = 'Dutch';
        break;

      case 'Germany':
        if (inputs.language == '') inputs.language = 'German';
        break;

      case 'Austria':
        if (inputs.language == '') inputs.language = 'German';
        break;

      case 'Denmark':
        if (inputs.language == '') inputs.language = 'Danish';
        break;

      case 'Finland':
        if (inputs.language == '') inputs.language = 'Finnish';
        break;

      case 'Norway':
        if (inputs.language == '') inputs.language = 'Norwegian';
        break;

      case 'Poland':
        if (inputs.language == '') inputs.language = 'Polish';
        break;

      case 'Sweden':
        if (inputs.language == '') inputs.language = 'Swedish';
        break;

      case 'Switzerland':
        if (inputs.language == '') inputs.language = 'French';
        break;

      case 'Italy':
        if (inputs.language == '') inputs.language = 'Italian';
        break;
      case 'Luxembourg':
        if (inputs.language == '') inputs.language = 'French';
        break;

      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (inputs.showBillTo == 'Yes-Fill details') {
      if (inputs.billCountry == '') inputs.billCountry = country;
    }
  }, [inputs.showBillTo]);

  function getLang(id) {
    // console.log(props.translations);
    let obj = props.translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    // props.setActivePage(e.target.getAttribute('data-link'));
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      if (!isDisableFields) setErrors(validateFields(inputs, country, customerReqType));
      setIsSubmitting(true);
      setLinkForward(e.target.getAttribute('data-link'));
      if (isDisableFields || isDisable) props.setActivePage(e.target.getAttribute('data-link'));
    }
  };

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      console.log(linkForward);
      // navigate(linkForward);
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && isDisableFields) {
      setIsCopying(true);
      Object.entries(formTemplate.billto).forEach(([key, value]) => {
        if (!['existingBillToID', 'showBillTo', 'language', 'billCountry'].includes(key))
          inputs[key] = value;
      });
    }
  }, [errors]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  const [showSection, setShowSection] = useState(false);

  const regionsRes = FetchRegions(inputs.billCountry);

  useEffect(() => {
    sessionStorage.setItem('createCustomerBillTo', JSON.stringify(inputs));
  }, [inputs]);

  useEffect(() => {
    console.log('LALAL');
    if (inputs.existingBillToID !== '') setIsDisableFields(true);
    else setIsDisableFields(false);
  }, [inputs.existingBillToID]);

  function copyBillToData(e) {
    setErrors(validateFields(inputs, country, customerReqType));
    console.log(`I am true ${isCopying}`);
    console.log(isCopying, inputs.existingBillToID, companyCode, salesOrg);
  }

  useEffect(() => {
    if (Object.keys(billToDataRes.billToData).length !== 0) {
      Object.entries(billToDataRes.billToData).forEach(([key, value]) => {
        if (key in inputs) {
          if (key == 'language') inputs.language = languageMap[value];
          else inputs[key] = value;
        }
      });
    }
    setIsCopying(false);
  }, [billToDataRes.billToData]);

  const regionOptions = regionsRes.regions.map((val) => {
    return {
      ...val,
      regionCode: val.regionCode,
      regionText: val.regionText,
      regionDesc: `${val.regionCode} - ${val.regionText}`,
    };
  });

  const sidebarItems = [
    {
      title: getLang('Main Page'),
      onClick: handleSubmit,
      ['data-link']: 'mainpage',
      ['data-back']: 'true',
      active: false,
    },
    {
      title: getLang('Ship-To'),
      onClick: handleSubmit,
      ['data-link']: 'shipto',
      ['data-back']: true,
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Payer'),
      onClick: handleSubmit,
      ['data-link']: 'payer',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Sold-To'),
      onClick: handleSubmit,
      ['data-link']: 'soldto',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Bill-To'),
      onClick: handleSubmit,
      ['data-link']: 'billto',
      ['data-back']: 'true',
      // type: 'submit',
      active: true,
    },
  ];

  return (
    isAuthenticated && (
      <form className="card" onSubmit={handleSubmit}>
        <Modal show={isCopying} centered>
          <Modal.Body>
            <Row>
              <div className="col-auto">
                <Spinner animation="border" variant="warning" />
              </div>
              <div className="text-left">
                <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
        <div className="card-header">
          <div className="col-11 col-lg-12">
            <h3 className="card-title text-left">
              {/* <strong>Customer Data {getLang('Creation')}</strong> - {getLang('Bill To')} */}
              <strong style={{ color: 'rgb(177, 31, 36)' }}>
                {getLang('General Customer Creation')}
              </strong>{' '}
              - {getLang('Bill To')}
            </h3>
          </div>
          <a
            className={`sidebar-toggler col-auto ${isSidebarOpen ? '' : 'collapsed'}`}
            // onClick={togglingSidebar}
          >
            <Sidebar sidebarItems={sidebarItems} />
            {/* <span className="sidebar-toggler-icon"></span> */}
          </a>
        </div>
        <Row className="sidebar-row">
          <div
            className={`col-12 col-lg-2 text-left sidebar ${
              isMobile ? (isSidebarOpen ? '' : 'collapse') : ''
            }`}
          >
            <Row>
              <ButtonGroup vertical className="btn-block">
                <Link
                  data-link="mainpage"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {getLang('Main Page')}
                </Link>
                <Link
                  data-link="shipto"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {'Ship To'}
                </Link>
                <Link
                  data-link="payer"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {getLang('Payer')}
                </Link>
                <Link
                  data-link="soldto"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {getLang('Sold To')}
                </Link>
                <Button className="btn-danger">{getLang('Bill To')}</Button>
              </ButtonGroup>
            </Row>
          </div>
          <div className="col-12 col-lg-10">
            <div className="card-body">
              <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Select option for Bill to')} *
                        </label>
                        <select
                          id="select-bill-to"
                          className={`form-control ${errors.showBillTo ? 'is-invalid-select' : ''}`}
                          name="showBillTo"
                          value={inputs.showBillTo}
                          onChange={setInputs}
                          disabled={isDisable || customerReqType == '2nd Sold To'}
                        >
                          <option value="">Select</option>
                          <option value="Yes-Fill details">Yes-Fill details</option>
                          <option value="No-As per payer">No-As per payer</option>
                        </select>
                        {errors.showBillTo && (
                          <div className="invalid-feedback text-left">{errors.showBillTo}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  {inputs.showBillTo === 'Yes-Fill details' && (
                    <div id="fill-details-show">
                      {(!(
                        shipToType.startsWith('Wholesaler') && customerReqType == 'Full Creation'
                      ) ||
                        (shipToType.startsWith('Wholesaler') &&
                          customerReqType == 'New Owner')) && (
                        <>
                          <hr />

                          <div className="row">
                            <div className="col-12 col-lg-4">
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Existing Bill To ID')}
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className={`form-control${
                                      errors.existingBillToID ? ' is-invalid' : ''
                                    }`}
                                    name="existingBillToID"
                                    maxLength="8"
                                    placeholder={`Enter ${getLang('Existing Bill To ID')}`}
                                    value={inputs.existingBillToID}
                                    onChange={setInputs}
                                    disabled={isDisable}
                                  />
                                  <span className="input-group-append">
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                      style={{
                                        borderTopRightRadius: '3px',
                                        borderBottomRightRadius: '3px',
                                      }}
                                      onClick={copyBillToData}
                                      disabled={isDisable || inputs.existingBillToID === ''}
                                    >
                                      {getLang('Copy')}
                                    </button>
                                  </span>
                                  {errors.existingBillToID && (
                                    <div className="invalid-feedback text-left">
                                      {errors.existingBillToID}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <hr />

                      <div className="row">
                        <div className="col-12 col-lg-4 text-left">
                          <h2
                            style={{
                              color: 'rgb(177, 31, 36)',
                            }}
                          >
                            {getLang('Bill To')}
                          </h2>
                        </div>
                      </div>

                      <div className="row">
                        {!['Norway', 'Switzerland', 'United Kingdom'].includes(country) && (
                          <div className="col-12 col-lg-4">
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Title')}</label>
                              {/* <input type="text" className="form-control" placeholder="Prefix" name="title" value={inputs.title} onChange={setInputs} disabled={isDisableFields || isDisable} /> */}
                              <select
                                id="select-title"
                                className="form-control"
                                name="title"
                                value={inputs.title}
                                onChange={setInputs}
                                disabled={isDisableFields || isDisable}
                              >
                                <option value="">Select</option>
                                {titlesRes.titles.map((val) => (
                                  <option key={val.title} value={val.title} data-title={val.title}>
                                    {val.title}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}
                        {['Belgium', 'Netherlands', 'France', 'Luxembourg', 'Germany'].includes(
                          country,
                        ) && (
                          <CustomTextInput
                            title="VAT Registration No"
                            name="vatRegNumber"
                            mandatory={country == 'France' ? true : false}
                            inputs={inputs}
                            errors={errors}
                            onChange={setInputs}
                            setInputs={setInputs}
                            maxLength={vatLength(country)}
                            placeholder={vatPlaceholder(country)}
                            disabled={isDisableFields || isDisable}
                            getLang={getLang}
                          />
                        )}
                        {['France'].includes(country) && (
                          <CustomTextInput
                            title={'Tax Number 1 (SIREN)'}
                            name="taxNumber"
                            mandatory={country == 'France' ? true : false}
                            inputs={inputs}
                            errors={errors}
                            onChange={setInputs}
                            setInputs={setInputs}
                            maxLength={9}
                            placeholder="Tax Number"
                            disabled={isDisable}
                            getLang={getLang}
                          />
                        )}
                        {['France'].includes(country) && (
                          <CustomTextInput
                            title={'Tax Number 2 (SIRET)'}
                            name="taxNumber2"
                            mandatory={country == 'France' ? true : false}
                            inputs={inputs}
                            errors={errors}
                            onChange={setInputs}
                            setInputs={setInputs}
                            maxLength={14}
                            placeholder="Tax Number 2"
                            disabled={isDisable}
                            getLang={getLang}
                          />
                        )}

                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')} *</label>
                            <input
                              type="text"
                              className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                              placeholder={`Legal ${getLang('Name')}`}
                              name="name"
                              value={inputs.name}
                              onChange={setInputs}
                              maxLength="35"
                              disabled={isDisableFields || isDisable}
                            />
                            {errors.name && (
                              <div className="invalid-feedback text-left">{errors.name}</div>
                            )}
                          </div>
                        </div>
                        {['France'].includes(country) && (
                          <CustomTextInput
                            title={country == 'France' ? 'Tax Number 1 (SIRET)' : 'Tax Number'}
                            name="taxNumber"
                            mandatory={country == 'France' ? true : false}
                            inputs={inputs}
                            errors={errors}
                            onChange={setInputs}
                            setInputs={setInputs}
                            maxLength={
                              country === 'Netherlands'
                                ? 14
                                : country === 'United Kingdom'
                                ? 10
                                : 13
                            }
                            placeholder="Tax Number"
                            disabled={isDisable}
                            getLang={getLang}
                          />
                        )}
                        {['France'].includes(country) && (
                          <CustomTextInput
                            title={'Tax Number 2 (SIREN)'}
                            name="taxNumber2"
                            mandatory={country == 'France' ? true : false}
                            inputs={inputs}
                            errors={errors}
                            onChange={setInputs}
                            setInputs={setInputs}
                            maxLength={14}
                            placeholder="Tax Number 2"
                            disabled={isDisable}
                            getLang={getLang}
                          />
                        )}
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Postal Code')} *
                            </label>
                            <input
                              type="text"
                              className={`form-control ${errors.postalCode ? 'is-invalid' : ''}`}
                              name="postalCode"
                              value={inputs.postalCode}
                              onChange={setInputs}
                              maxLength={postalCodeLength(inputs.billCountry)}
                              disabled={isDisableFields || isDisable}
                            />
                            {errors.postalCode && (
                              <div className="invalid-feedback text-left">{errors.postalCode}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('House Number')} {country !== 'United Kingdom' ? ' *' : ''}
                            </label>
                            <input
                              type="text"
                              className={`form-control ${errors.houseNumber ? 'is-invalid' : ''}`}
                              name="houseNumber"
                              value={inputs.houseNumber}
                              onChange={setInputs}
                              maxLength="35"
                              disabled={isDisableFields || isDisable}
                            />
                            {errors.houseNumber && (
                              <div className="invalid-feedback text-left">{errors.houseNumber}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')} *</label>
                            <input
                              type="text"
                              className={`form-control ${errors.street ? 'is-invalid' : ''}`}
                              name="street"
                              value={inputs.street}
                              onChange={setInputs}
                              maxLength="35"
                              disabled={isDisableFields || isDisable}
                            />
                            {errors.street && (
                              <div className="invalid-feedback text-left">{errors.street}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')} *</label>
                            <input
                              type="text"
                              className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                              name="city"
                              value={inputs.city}
                              onChange={setInputs}
                              maxLength="35"
                              disabled={isDisableFields || isDisable}
                            />
                            {errors.city && (
                              <div className="invalid-feedback text-left">{errors.city}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Country')}</label>
                            {/* <input type="text" className="form-control" name="country" value={country} readOnly /> */}
                            <select
                              className={`form-control ${errors.billCountry ? 'is-invalid' : ''}`}
                              name="billCountry"
                              onChange={setInputs}
                              value={country == 'Belgium' ? inputs.billCountry : country}
                              maxLength="35"
                              disabled={isDisableFields || isDisable || country != 'Belgium'}
                            >
                              <option value="">Select</option>
                              {countriesRes.countries
                                // .filter((e) => ['France', 'Finland', 'Belgium', 'Netherlands', 'United Kingdom', 'Germany'].includes(e.country))
                                .map((val) => (
                                  <option key={val.countryCode} value={val.country}>
                                    {val.country}
                                  </option>
                                ))}
                            </select>
                            {errors.billCountry && (
                              <div className="invalid-feedback text-left">{errors.billCountry}</div>
                            )}
                          </div>
                        </div>
                        {!['Luxembourg'].includes(inputs.billCountry) && (
                          <div className="col-12 col-lg-4">
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Region')} *</label>
                              <Select
                                className="text-left"
                                name="region"
                                value={regionOptions.filter(
                                  (opt) => opt.regionCode == inputs.region,
                                )}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#cfcfcf',
                                    primary50: '#cfcfcf',
                                    primary: 'orange',
                                  },
                                })}
                                isClearable
                                placeholder="Select"
                                isDisabled={isDisableFields || isDisable}
                                getOptionLabel={({ regionDesc }) => regionDesc}
                                getOptionValue={({ regionCode }) => regionCode}
                                options={regionOptions}
                                onChange={(e, action) => {
                                  setInputs(e, action);
                                }}
                              />
                              {errors.region && (
                                <div className="invalid-feedback-file text-left">
                                  {errors.region}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Language')}</label>
                            <select
                              id="select-lang"
                              className="form-control"
                              name="language"
                              value={inputs.language}
                              onChange={setInputs}
                              disabled={isDisableFields || isDisable}
                            >
                              <option value="">Select</option>
                              {languages.map((lang) => (
                                <option key={lang} value={lang}>
                                  {lang}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Telephone')}</label>
                            <input
                              type="tel"
                              className="form-control"
                              name="telephone"
                              placeholder={telephonePlaceholder(country)}
                              maxLength={telephoneLength(country)}
                              value={inputs.telephone}
                              onChange={setInputs}
                              disabled={isDisableFields || isDisable}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Mobile')} {['United Kingdom'].includes(country) ? ' *' : ''}
                            </label>
                            <input
                              type="tel"
                              className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                              name="mobile"
                              placeholder={mobilePlaceholder(country)}
                              maxLength={mobileLength(country)}
                              value={inputs.mobile}
                              onChange={setInputs}
                              disabled={isDisableFields || isDisable}
                            />
                            {errors.mobile && (
                              <div className="invalid-feedback text-left">{errors.mobile}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('E-mail Address')} *
                            </label>
                            <input
                              type="email"
                              className={`form-control ${errors.emailAddress ? 'is-invalid' : ''}`}
                              name="emailAddress"
                              value={inputs.emailAddress}
                              onChange={setInputs}
                              maxLength="240"
                              disabled={isDisableFields || isDisable}
                            />
                            {errors.emailAddress && (
                              <div className="invalid-feedback text-left">
                                {errors.emailAddress}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('E-Invoicing')}</label>
                            <select
                              id="select-einvoicing"
                              className="form-control"
                              name="eInvOption"
                              value={inputs.eInvOption}
                              onChange={setInputs}
                              disabled={isDisableFields || isDisable}
                            >
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>
                        {inputs.eInvOption == 'Yes' && (
                          <div className="col-12 col-lg-4">
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('E-mail address (E-invoicing)')}
                              </label>
                              <input
                                type="email"
                                className={`form-control ${errors.eInvEmail ? 'is-invalid' : ''}`}
                                name="eInvEmail"
                                value={inputs.eInvEmail}
                                onChange={setInputs}
                                maxLength="240"
                                disabled={isDisableFields || isDisable}
                              />
                              {errors.eInvEmail && (
                                <div className="invalid-feedback text-left">{errors.eInvEmail}</div>
                              )}
                            </div>
                          </div>
                        )}
                        {['France', 'Netherlands', 'Belgium', 'Luxembourg'].includes(country) && (
                          <div className="col-12 col-lg-4">
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('EDI')}
                                {['France'].includes(country) && shipToType.includes('Off-Trade')
                                  ? ' *'
                                  : ''}
                              </label>
                              <select
                                id="select-edi"
                                className={`form-control ${errors.ediOption ? 'is-invalid' : ''}`}
                                name="ediOption"
                                value={inputs.ediOption}
                                onChange={setInputs}
                                disabled={isDisableFields || isDisable}
                              >
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                              {errors.ediOption && (
                                <div className="invalid-feedback text-left">
                                  {getLang(errors.ediOption)}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {inputs.ediOption === 'Yes' && (
                          <>
                            <div className="col-12 col-lg-4">
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('GLN/ILN CODE')} *
                                </label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.glnIlnCode ? 'is-invalid' : ''
                                  }`}
                                  name="glnIlnCode"
                                  placeholder="Eg: 1234567890123"
                                  maxLength="13"
                                  value={inputs.glnIlnCode}
                                  onChange={setInputs}
                                  disabled={isDisableFields || isDisable}
                                />
                                {errors.glnIlnCode && (
                                  <div className="invalid-feedback text-left">
                                    {errors.glnIlnCode}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-lg-4">
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('EDI Email Address')} *
                                </label>
                                <input
                                  type="email"
                                  className={`form-control ${errors.ediEmail ? 'is-invalid' : ''}`}
                                  name="ediEmail"
                                  placeholder="Eg.: abc@company.com"
                                  value={inputs.ediEmail}
                                  onChange={setInputs}
                                  maxLength="240"
                                  disabled={isDisableFields || isDisable}
                                />
                                {errors.ediEmail && (
                                  <div className="invalid-feedback text-left">
                                    {errors.ediEmail}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Row>

        <div className="card-footer">
          <div className="row">
            <div className="col-auto col-md-auto text-left">
              {!isDisable && (
                <Button variant="outline-warning" onClick={props.saveDraft}>
                  Save Draft
                </Button>
              )}
            </div>
            <div className="col col-md text-left justify-content">{props.draftMsg}</div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link="soldto"
                data-back="true"
                className="btn btn-warning btn-block"
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Back
              </Link>
            </div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link="review"
                className={`btn btn-${
                  Object.keys(validateFields(inputs)).length == 0 || isDisable
                    ? 'danger'
                    : 'secondary'
                } btn-block`}
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Review
              </Link>
            </div>
          </div>
        </div>
      </form>
    )
  );
};

export default CreateCustBillTo;
