import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Button, ButtonGroup, Row, Col } from 'react-bootstrap';
import Select, { components } from 'react-select';
import MandatoryText from '../../../components/MandatoryText';
import Sidebar from '../../../components/layout/Sidebar/Sidebar';
import CountrySelect from '../../../components/core/InputFields/CountrySelect/CountrySelect';

import { validateFields } from './FormValidation';
import {
  FetchCountries,
  FetchM1M2,
  FetchCustomerReqTypes,
  FetchShipToTypes,
  SaveDraft,
  FetchReviewTicketData,
  FetchUserAccess,
  fetchUserRoleAccessCountry,
} from './FlowCallAPI';
// import fetchUserRoleAccessCountry from '../../FlowCallAPI';
import { useFormFields } from './HooksLib';

const CreateCustMainPage = (props) => {
  const navigate = useNavigate();
  //console.log("Props",props);

  const isAuthenticated = useIsAuthenticated();

  const { instance, accounts } = useMsal();

  const [translations, setTranslations] = useState(props.translations);

  useEffect(() => {
    // if (!isAuthenticated) navigate('/');
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    console.log(inputs);
    console.log('line51', props.draftTicketNum);
    if (
      props.draftTicketNum[0] !== 'D' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected'
    ) {
      setIsDisable(true);
    }
  }, []);

  const countriesRes = fetchUserRoleAccessCountry(
    accounts[0].username != '' ? accounts[0].username : '',
  );
  console.log(countriesRes, 'countries form RBA', countriesRes.countries);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkForward, setLinkForward] = useState('');

  const [isSave, setIsSave] = useState(false);
  const [draftMsg, setDraftMsg] = useState('');

  const saveDraft = (e) => {
    console.log(props.draftTicketNum);
    if (inputs.country) {
      setIsSave(true);
    }
  };

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    setErrors(validateFields(inputs));
    setIsSubmitting(true);
    setLinkForward(e.target.getAttribute('data-link'));
  };

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      // navigate(linkForward);
      props.setActivePage(linkForward);
    }
  }, [errors]);

  const [inputs, setInputs] = useFormFields(props.formTemplate);

  const fetchM1M2Res = FetchM1M2(inputs.country, inputs.shipToType);

  const fetchCustReqTypesRes = FetchCustomerReqTypes(inputs.country);

  const fetchShipToTypesRes = FetchShipToTypes(inputs.country);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  useEffect(() => {
    if (inputs.country == 'France' && inputs.shipToType == '')
      inputs.shipToType = 'On-Trade – Indirect';
  }, [inputs]);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  function countryChange(event) {
    // Call this function onChange of country, then setInput(e) here.
    inputs.companyCode = event.target.selectedOptions[0].getAttribute('data-companycode');
    inputs.salesOrg = event.target.selectedOptions[0].getAttribute('data-salesorg');
    setInputs(event);
  }

  function getLang(id) {
    // console.log(props.translations);
    let obj = translations.find((o) => o.textID == id);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    setTranslations(props.translations);
  }, [props.translations]);

  const m1Options = fetchM1M2Res.m1Vals.map((val) => {
    return {
      ...val,
      m1: val.m1,
      m2: val.m2,
    };
  });
  // console.log(m1Options);

  const sidebarItems = [
    {
      title: getLang('Main Page'),
      active: true,
    },
    {
      title: getLang('Ship-To'),
      onClick: handleSubmit,
      ['data-link']: 'shipto',
      // ['data-back']: true,
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Payer'),
      onClick: handleSubmit,
      ['data-link']: 'payer',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Sold-To'),
      onClick: handleSubmit,
      ['data-link']: 'soldto',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Bill-To'),
      onClick: handleSubmit,
      ['data-link']: 'billto',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
  ];

  // ${Object.keys(validateFields(inputs)).length != 0 ? 'disabled' : '' }

  return (
    <form className="card" onSubmit={handleSubmit}>
      <div className="card-header">
        <div className="col-11 col-lg-12">
          <h3 className="card-title text-left">
            {/* <strong>Customer Data {getLang('Creation')}</strong> */}
            <strong style={{ color: 'rgb(227, 175, 50)' }}>
              {getLang('General Customer Creation')}
            </strong>
          </h3>
        </div>
        <a
          className={`sidebar-toggler col-auto ${isSidebarOpen ? '' : 'collapsed'}`}
          // onClick={togglingSidebar}
        >
          <Sidebar sidebarItems={sidebarItems} />
          {/* <span className="sidebar-toggler-icon"></span> */}
        </a>
      </div>
      <Row className="sidebar-row">
        <div
          className={`col-12 col-lg-2 text-left sidebar ${
            isMobile ? (isSidebarOpen ? '' : 'collapse') : ''
          }`}
        >
          <Row>
            <ButtonGroup vertical className="btn-block">
              <Button className="btn-danger">{getLang('Main Page')}</Button>
              <Link to="" data-link="shipto" className="btn btn-warning" onClick={handleSubmit}>
                {'Ship To'}
              </Link>
              <Link to="" data-link="payer" className="btn btn-warning" onClick={handleSubmit}>
                {getLang('Payer')}
              </Link>
              <Link to="" data-link="soldto" className="btn btn-warning" onClick={handleSubmit}>
                {getLang('Sold To')}
              </Link>
              <Link to="" data-link="billto" className="btn btn-warning" onClick={handleSubmit}>
                {getLang('Bill To')}
              </Link>
            </ButtonGroup>
          </Row>
        </div>
        <div className="col-12 col-lg-10">
          <div className="card-body">
            <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

            <div className="row">
              <div className="col-12 col-lg-4">
                <CountrySelect
                  errors={errors}
                  inputs={inputs}
                  setInputs={setInputs}
                  isDisable={isDisable}
                  countriesRes={countriesRes}
                  getLang={getLang}
                  value={inputs.country}
                  status={props.status}
                />
              </div>
              <div className="col-12 col-lg-4">
                <div className="form-group">
                  <label className="form-label text-left">{getLang('Customer Request')} *</label>
                  <select
                    id="select-cust-req"
                    name="customerReqType"
                    className={`form-control ${errors.customerReqType ? 'is-invalid-select' : ''}`}
                    onChange={setInputs}
                    value={inputs.customerReqType}
                    required
                    disabled={isDisable || inputs.country == ''}
                  >
                    <option value="">{getLang('Select')}</option>
                    {inputs.country == 'Belgium' && inputs.shipToType.startsWith('Wholesaler') && (
                      <>
                        <option value={`Full Creation`}>{getLang(`Full Creation`)}</option>
                        <option value={`New Owner`}>{getLang(`New Owner`)}</option>
                        <option value={`Ship To`}>{getLang(`Only Ship To`)}</option>
                      </>
                    )}
                    {/* {(inputs.country == 'Belgium' || inputs.country == 'Netherlands') && (
                      <>
                        <option value={`Full Creation`}>{getLang(`Full Creation`)}</option>
                        <option value={`New Owner`}>{getLang(`New Owner`)}</option>
                        <option value={`Ship To`}>{getLang(`Only Ship To`)}</option>
                      </>
                    )} */}
                    {!inputs.shipToType.startsWith('Wholesaler') &&
                      fetchCustReqTypesRes.reqTypes.map((requestType) => (
                        <option
                          key={requestType.customerReqType}
                          value={requestType.customerReqType}
                        >
                          {getLang(requestType.customerReqType)}
                        </option>
                      ))}
                  </select>
                  {errors.customerReqType && (
                    <div className="invalid-feedback text-left">
                      {getLang(errors.customerReqType)}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="form-group">
                  <label className="form-label text-left">{getLang('Ship-to Type')} *</label>
                  <select
                    id="select-ship-to-type"
                    name="shipToType"
                    className={`form-control ${errors.shipToType ? 'is-invalid-select' : ''}`}
                    onChange={setInputs}
                    value={inputs.shipToType}
                    required
                    disabled={isDisable || inputs.country == ''}
                  >
                    <option value="">{getLang('Select')}</option>

                    {inputs.customerReqType == '2nd Sold To' && (
                      <option key="On-Trade – Indirect" value="On-Trade – Indirect">
                        {getLang(`On-Trade – Indirect`)}
                      </option>
                    )}

                    {inputs.customerReqType != '2nd Sold To' &&
                      fetchShipToTypesRes.shipToTypes.map((shipType) =>
                        shipType.shipToType.includes('Direct') ? (
                          <option
                            key={shipType.shipToType}
                            value={shipType.shipToType}
                            style={{ fontWeight: 'bold' }}
                          >
                            {getLang(shipType.shipToType)}
                          </option>
                        ) : (
                          <option key={shipType.shipToType} value={shipType.shipToType}>
                            {getLang(shipType.shipToType)}
                          </option>
                        ),
                      )}
                  </select>
                  {errors.shipToType && (
                    <div className="invalid-feedback text-left">{getLang(errors.shipToType)}</div>
                  )}
                </div>
              </div>
              {!inputs.shipToType.includes('Wholesaler') && (
                <>
                  <div className="col-12 col-lg-4">
                    <div className="form-group">
                      <label className="form-label text-left">
                        {inputs.shipToType.includes('Wholesaler') ? getLang('W1') : getLang('M1')} *
                      </label>
                      <Select
                        className="text-left"
                        name="m1"
                        value={m1Options.filter((opt) => opt.m1 == inputs.m1)}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: '#cfcfcf',
                            primary50: '#cfcfcf',
                            primary: 'orange',
                          },
                        })}
                        isClearable
                        placeholder={getLang('Select')}
                        isDisabled={isDisable || inputs.country == ''}
                        getOptionLabel={({ m1 }) => m1}
                        getOptionValue={({ m1 }) => m1}
                        options={m1Options}
                        onChange={(e, action) => {
                          setInputs(e, action);
                        }}
                      />
                      {errors.m1 && (
                        <div className="invalid-feedback-file text-left">{getLang(errors.m1)}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="form-group">
                      <label className="form-label text-left">
                        {inputs.shipToType.includes('Wholesaler') ? getLang('W2') : getLang('M2')} *
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="m2"
                        placeholder={getLang('Select an M1')}
                        value={inputs.m2}
                        disabled
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Row>

      <div className="card-footer">
        <div className="row">
          {!isDisable && inputs.customerReqType != '' && (
            <div className="col-auto col-md-auto text-left">
              <Button
                variant="outline-warning"
                onClick={props.saveDraft}
                // disabled={process.env.REACT_APP == 'prod'}
              >
                Save Draft
              </Button>
            </div>
          )}
          <div className="col col-md text-left">
            <span className="align-middle">{props.draftMsg}</span>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link={'shipto'}
              className={`btn btn-${
                Object.keys(validateFields(inputs)).length == 0 || isDisable
                  ? 'danger'
                  : 'secondary'
              } btn-block`}
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Next
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateCustMainPage;
