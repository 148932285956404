import React, { useState, useEffect } from 'react';
import CreateCustMainPage from './mainpage/MainPage';
import CreateProfileData from './profile/profileData';
import CreateCustReview from './review/Review';
import CreateCustSaveSuccess from './success/Success';
import CreateCustSaveError from './error/Error';

import { formTemplate } from './Data';
import { SaveDraft } from './FlowCallAPI';

const CreateInternalCustomer = (props) => {
  const [newTicket, setNewTicket] = useState(props.createNew);

  const [activePage, setActivePage] = useState('mainpage');

  const [draftTicketNum, setDraftTicketNum] = useState('');

  const [ticketStatus, setTicketStatus] = useState('');

  const [isSave, setIsSave] = useState(false);

  const [draftMsg, setDraftMsg] = useState('');

  const [done, setDone] = useState(false);

  // const [mainPageForm, setMainPageForm] = useState(Object.keys(props.data).length !== 0 ? props.data.mainpage : JSON.parse(sessionStorage.getItem('createCustomerMainPage')));
  // const [shipToForm, setProfileForm] = useState(Object.keys(props.data).length !== 0 ? props.data.profile : formTemplate.profile);
  // const [payerForm, setPayerForm] = useState(Object.keys(props.data).length !== 0 ? props.data.payer : formTemplate.payer);
  // const [soldToForm, setSoldToForm] = useState(Object.keys(props.data).length !== 0 ? props.data.soldto : formTemplate.soldto);
  // const [billToForm, setBillToForm] = useState(Object.keys(props.data).length !== 0 ? props.data.billto : formTemplate.billto);

  // const [mainPageForm, setMainPageForm] = useState(formTemplate.mainpage);
  // const [shipToForm, setProfileForm] = useState(formTemplate.profile);
  // const [payerForm, setPayerForm] = useState(formTemplate.payer);
  // const [soldToForm, setSoldToForm] = useState(formTemplate.soldto);
  // const [billToForm, setBillToForm] = useState(formTemplate.billto);

  const mainPageForm = props.data.mainpage
    ? JSON.parse(sessionStorage.getItem('createCustomerMainPage')) || props.data.mainpage
    : JSON.parse(sessionStorage.getItem('createCustomerMainPage')) || formTemplate.mainpage;

  const profileDataForm = props.data.profileData
    ? JSON.parse(sessionStorage.getItem('createCustomerProfileData')) || props.data.profileData
    : JSON.parse(sessionStorage.getItem('createCustomerProfile')) || formTemplate.profileData;

  const saveDraft = (e) => {
    console.log('Clicked Save Draft', mainPageForm.country);
    // if (mainPageForm.country) {
    setIsSave(true);
    console.log(draftTicketNum);
    console.log(mainPageForm);
    // }
  };

  useEffect(() => {
    console.log(`Active: ${activePage}`);
    console.log(props);
    setDraftMsg('');
  }, [activePage]);

  useEffect(() => {
    console.log(`Clearing1232131241294921481284: `, newTicket);
    // console.log(`This is the request Type ${props.data.ticketReqType}`);
    // console.log(props.translations);
    setDone(false);

    // sessionStorage.setItem('createCustomerMainPage', JSON.stringify(formTemplate.mainpage));
    // sessionStorage.setItem('createCustomerProfile', JSON.stringify(formTemplate.profile));
    // sessionStorage.setItem('createCustomerPayer', JSON.stringify(formTemplate.payer));
    // sessionStorage.setItem('createCustomerSoldTo', JSON.stringify(formTemplate.soldto));
    // sessionStorage.setItem('createCustomerBillTo', JSON.stringify(formTemplate.billto));

    if (Object.keys(props.data).length !== 0) {
      console.log('Doing');
      sessionStorage.setItem('createCustomerMainPage', JSON.stringify(props.data.mainpage));
      sessionStorage.setItem('createCustomerProfileData', JSON.stringify(props.data.profileData));

      setDraftTicketNum(props.data.title);
      setTicketStatus(props.data.status);

      //console.log("STATUS",props.data.status);
      // setMainPageForm(JSON.parse(sessionStorage.getItem('createCustomerMainPage')));
      // setProfileForm(JSON.parse(sessionStorage.getItem('createCustomerProfile')));
      // setPayerForm(JSON.parse(sessionStorage.getItem('createCustomerPayer')));
      // setSoldToForm(JSON.parse(sessionStorage.getItem('createCustomerSoldTo')));
      // setBillToForm(JSON.parse(sessionStorage.getItem('createCustomerBillTo')));
    } else if (newTicket) {
      console.log('CreateNew called');
      var today = new Date();
      setDraftTicketNum(
        `D${today.getDate()}${
          today.getMonth() + 1
        }${today.getFullYear()}${today.getHours()}${today.getMinutes()}${today.getSeconds()}`,
      );
      console.log(`Draft: ${draftTicketNum}`);

      sessionStorage.setItem('createCustomerMainPage', JSON.stringify(formTemplate.mainpage));
      sessionStorage.setItem('createCustomerProfile', JSON.stringify(formTemplate.profileData));
      // sessionStorage.removeItem('createCustomerMainPage');
      // sessionStorage.removeItem('createCustomerProfile');
      // sessionStorage.removeItem('createCustomerPayer');
      // sessionStorage.removeItem('createCustomerSoldTo');
      // sessionStorage.removeItem('createCustomerBillTo');

      setNewTicket(false);
    } else {
      console.log('this');
      // setMainPageForm(JSON.parse(sessionStorage.getItem('createCustomerMainPage')));
      // setProfileForm(JSON.parse(sessionStorage.getItem('createCustomerProfile')));
      // setPayerForm(JSON.parse(sessionStorage.getItem('createCustomerPayer')));
      // setSoldToForm(JSON.parse(sessionStorage.getItem('createCustomerSoldTo')));
      // setBillToForm(JSON.parse(sessionStorage.getItem('createCustomerBillTo')));
    }

    setDone(true);
  }, []);

  // const mainPageForm = newTicket || false ? mainPageFormTemplate : JSON.parse(sessionStorage.getItem('createCustomerMainPage')) || mainPageFormTemplate;
  // const mainPageForm = props.data.mainpage
  //   ? JSON.parse(sessionStorage.getItem('createCustomerMainPage')) || props.data.mainpage
  //   : JSON.parse(sessionStorage.getItem('createCustomerMainPage')) || formTemplate.mainpage;

  // const shipToForm = props.data.profile
  //   ? JSON.parse(sessionStorage.getItem('createCustomerProfile')) || props.data.profile
  //   : JSON.parse(sessionStorage.getItem('createCustomerProfile')) || formTemplate.profile;

  // const payerForm = props.data.payer ? JSON.parse(sessionStorage.getItem('createCustomerPayer')) || props.data.payer : JSON.parse(sessionStorage.getItem('createCustomerPayer')) || formTemplate.payer;

  // const soldToForm = props.data.soldto
  //   ? JSON.parse(sessionStorage.getItem('createCustomerSoldTo')) || props.data.soldto
  //   : JSON.parse(sessionStorage.getItem('createCustomerSoldTo')) || formTemplate.soldto;

  // const billToForm = props.data.billto
  //   ? JSON.parse(sessionStorage.getItem('createCustomerBillTo')) || props.data.billto
  //   : JSON.parse(sessionStorage.getItem('createCustomerBillTo')) || formTemplate.billto;

  const saveDraftRes = SaveDraft(
    props.accounts.length > 0 ? props.accounts[0].username : '',
    draftTicketNum,
    isSave,
    'Customer',
  );

  useEffect(() => {
    // if (saveDraftRes.result !== '') {
    //   setIsSave(false);
    //   let timeSave = new Date();

    //   if (saveDraftRes.result.result === 'Created') {
    //     setDraftMsg(`Draft created at ${timeSave.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`);
    //   } else if (saveDraftRes.result.result === 'Updated') setDraftMsg(`Draft updated at ${timeSave.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`);
    // }
    setIsSave(false);
    console.log(`Save draft result : ${saveDraftRes.result.result}`);
    if ('result' in saveDraftRes.result) setDraftMsg(saveDraftRes.result.result);
    else if ('error' in saveDraftRes.result) setDraftMsg(saveDraftRes.result.error);
  }, [saveDraftRes.result]);

  return (
    draftTicketNum != '' && (
      <>
        {activePage === 'mainpage' && (draftTicketNum !== '' || props.data) && done && (
          <CreateCustMainPage
            setActivePage={setActivePage}
            formTemplate={mainPageForm}
            draftTicketNum={draftTicketNum}
            status={ticketStatus}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            data={props.data}
            accounts={props.accounts}
            translations={props.translations}
          />
        )}
        {/* {activePage === 'profile' && done && (
          <CreateProfileData
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            formTemplate={profileDataForm}
            status={ticketStatus}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
          />
        )} */}
        {activePage === 'review' && done && (
          <CreateCustReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            status={ticketStatus}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
          />
        )}
        {activePage === 'success' && done && (
          <CreateCustSaveSuccess
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            accounts={props.accounts}
            translations={props.translations}
          />
        )}
        {activePage === 'error' && done && (
          <CreateCustSaveError
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            accounts={props.accounts}
            translations={props.translations}
          />
        )}
      </>
    )
  );
};

export default CreateInternalCustomer;
