import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import { Button, ButtonGroup, Row, Col, Modal, Spinner } from 'react-bootstrap';

import { fileRegex } from '../../../../utils/Utils';
import { PostToSharepoint } from '../../utils/FlowCallAPI';

const UpdateAcctgClerkReview = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) navigate('/');
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  const [isSave, setIsSave] = useState(false);

  const [isSaveRequest, setIsSaveRequest] = useState(false);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  const mainPageForm =
    sessionStorage.getItem('updateCustomerMainPage') === null
      ? {}
      : JSON.parse(sessionStorage.getItem('updateCustomerMainPage'));
  const acctgClerkForm =
    sessionStorage.getItem('updateAcctgClerkUpdate') === null
      ? {}
      : JSON.parse(sessionStorage.getItem('updateAcctgClerkUpdate'));
  // const shipToForm = sessionStorage.getItem('createCustomerShipTo') === null ? {} : JSON.parse(sessionStorage.getItem('createCustomerShipTo'));
  // const payerForm = sessionStorage.getItem('createCustomerPayer') === null ? {} : JSON.parse(sessionStorage.getItem('createCustomerPayer'));
  // const soldToForm = sessionStorage.getItem('createCustomerSoldTo') === null ? {} : JSON.parse(sessionStorage.getItem('createCustomerSoldTo'));
  // const billToForm = sessionStorage.getItem('createCustomerBillTo') === null ? {} : JSON.parse(sessionStorage.getItem('createCustomerBillTo'));

  // const postToSharepointRes = PostToSharepoint(mainPageForm, shipToForm, payerForm, soldToForm, billToForm);

  const postToSharepointRes = PostToSharepoint(
    props.accounts.length > 0 ? props.accounts[0].username : '',
    props.draftTicketNum,
    isSaveRequest,
  );

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    props.setActivePage(e.target.getAttribute('data-link'));
  };

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [isError, setIsError] = useState('');

  const [mainPageErrors, setMainPageErrors] = useState({});
  const [shipToErrors, setShipToErrors] = useState({});
  const [payerErrors, setPayerErrors] = useState({});
  const [soldToErrors, setSoldToErrors] = useState({});
  const [billToErrors, setBillToErrors] = useState({});

  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleSave = () => {
    setShowSubmitModal(true);
    setIsSave(true);
    setIsSaveRequest(true);
    setShipToErrors(false);
    // props.setActivePage(isError);
    // setMainPageErrors(mainPageValidate(mainPageForm));
    // setShipToErrors(shipToValidate(shipToForm));
    // setPayerErrors(payerValidate(payerForm));
    // setSoldToErrors(soldToValidate(soldToForm));
    // setBillToErrors(billToValidate(billToForm));
    // props.saveDraft();
  };

  useEffect(() => {
    console.log(
      `${JSON.stringify(mainPageErrors)} ${JSON.stringify(shipToErrors)} ${JSON.stringify(
        payerErrors,
      )} ${JSON.stringify(soldToErrors)} ${JSON.stringify(billToErrors)} ${isSave}`,
    );
    if (
      Object.keys(mainPageErrors).length == 0 &&
      Object.keys(shipToErrors).length == 0 &&
      Object.keys(payerErrors).length == 0 &&
      Object.keys(soldToErrors).length == 0 &&
      Object.keys(billToErrors).length == 0 &&
      isSave
    ) {
      console.log('Can save');
      props.saveDraft();
    } else if (
      Object.keys(mainPageErrors).length != 0 ||
      Object.keys(shipToErrors).length != 0 ||
      Object.keys(payerErrors).length != 0 ||
      Object.keys(soldToErrors).length != 0 ||
      Object.keys(billToErrors).length != 0
    ) {
      console.log('Found errors');
      setShowErrorModal(true);
      setIsSave(false);
    } else setIsSave(false);
  }, [billToErrors]);

  function getLang(id) {
    // console.log(props.translations);
    let obj = props.translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    if (props.draftMsg !== '') {
      console.log(props.draftMsg);
      setIsSaveRequest(true);
    }
  }, [props.draftMsg]);

  useEffect(() => {
    setIsSaveRequest(false);
    setIsSave(false);
    if (Object.keys(postToSharepointRes.result).length !== 0) {
      console.log('Successfully saved.');
      props.setActivePage('success');
    } else if (postToSharepointRes.isError) {
      props.setActivePage('error');
    }
  }, [postToSharepointRes.result, postToSharepointRes.isError]);

  useEffect(() => {
    if (
      props.draftTicketNum[0] !== 'M' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected'
    ) {
      setIsDisable(true);
    }
  }, []);

  return (
    isAuthenticated && (
      <form className="card">
        <Modal show={isSave} centered>
          <Modal.Body>
            <Row>
              <div className="col-auto">
                <Spinner animation="border" variant="warning" />
              </div>
              <div className="text-left">
                <span className="align-middle">
                  &nbsp;Please wait while your request is submitted
                </span>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered>
          <Modal.Header>
            <Modal.Title>Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                Errors found in:
                <ul>
                  {Object.keys(mainPageErrors).length != 0 && <li>Main Page</li>}
                  {Object.keys(shipToErrors).length != 0 && <li>Ship to</li>}
                  {Object.keys(payerErrors).length != 0 && <li>Payer</li>}
                  {Object.keys(soldToErrors).length != 0 && <li>Sold To</li>}
                  {Object.keys(billToErrors).length != 0 && <li>Bill To</li>}
                </ul>
                Please correct them and try again.
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <div className="col col-md"></div>
              <div className="col-auto col-md-auto text-right">
                <Button
                  className="btn-danger btn-block"
                  style={{ textDecoration: 'none' }}
                  onClick={() => {
                    setShowErrorModal(false);
                  }}
                >
                  OK
                </Button>
              </div>
            </Row>
          </Modal.Footer>
        </Modal>
        <div className="card-header">
          <div className="col-11 col-lg-12">
            <h3 className="card-title text-left">
              <strong>Customer Data {getLang('Update')}</strong> - {getLang('Review')}
            </h3>
          </div>
          <a
            className={`sidebar-toggler col-auto ${isSidebarOpen ? '' : 'collapsed'}`}
            onClick={togglingSidebar}
          >
            <span className="sidebar-toggler-icon"></span>
          </a>
        </div>
        <Row className="sidebar-row">
          <div className="col-12">
            <div className="card-body text-left">
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col sm={12} lg="auto">
                      <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                        {getLang('Accounting Clerk Modification')}
                      </h2>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Existing Payer ID')}
                        </label>
                        <div className="form-control-textfield">
                          {acctgClerkForm.existingPayerID}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Name')}</label>
                        <div className="form-control-textfield">{acctgClerkForm.name}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Street')}</label>
                        <div className="form-control-textfield">{acctgClerkForm.street}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('House Number')}</label>
                        <div className="form-control-textfield">{acctgClerkForm.houseNumber}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Postal Code')}</label>
                        <div className="form-control-textfield">{acctgClerkForm.postalCode}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('City')}</label>
                        <div className="form-control-textfield">{acctgClerkForm.city}</div>
                      </div>
                    </Col>

                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Accounting Clerk')}{' '}
                        </label>
                        <div className="form-control-textfield">{acctgClerkForm.acctgClerks}</div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Row>

        <br />

        <div className="card-footer">
          <div className="row">
            <div className="col col-md"></div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link="acctgClerk"
                className="btn btn-warning btn-block"
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Back
              </Link>
            </div>
            {!isDisable && (
              //   <div className="col-auto col-md-auto text-right">
              //     <Link
              //       data-link="success"
              //       className="btn btn-danger btn-block"
              //       style={{ textDecoration: 'none' }}
              //       onClick={handleSave}
              //     >
              //       {getLang('Submit')}
              //     </Link>
              //   </div>
              // ) :
              <div className="col-auto col-md-auto text-right">
                <Link
                  data-link="success"
                  className="btn btn-danger btn-block"
                  style={{ textDecoration: 'none' }}
                  onClick={handleSave}
                >
                  {getLang('Submit')}
                </Link>
              </div>
            )}
          </div>
        </div>
      </form>
    )
  );
};

export default UpdateAcctgClerkReview;
