import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import { Button, ButtonGroup, Row, Col, Modal, Spinner } from 'react-bootstrap';

import { fileRegex } from '../../../../utils/Utils';
import { PostToSharepoint } from '../../utils/FlowCallAPI';

const UpdateCustBlockUnblockReview = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) navigate('/');
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  const [isSave, setIsSave] = useState(false);

  const [isSaveRequest, setIsSaveRequest] = useState(false);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  const mainPageForm =
    sessionStorage.getItem('updateCustomerMainPage') === null
      ? {}
      : JSON.parse(sessionStorage.getItem('updateCustomerMainPage'));
  const blockUnblockForm =
    sessionStorage.getItem('updateCustomerBlockUnblock') === null
      ? {}
      : JSON.parse(sessionStorage.getItem('updateCustomerBlockUnblock'));
  // const shipToForm = sessionStorage.getItem('createCustomerShipTo') === null ? {} : JSON.parse(sessionStorage.getItem('createCustomerShipTo'));
  // const payerForm = sessionStorage.getItem('createCustomerPayer') === null ? {} : JSON.parse(sessionStorage.getItem('createCustomerPayer'));
  // const soldToForm = sessionStorage.getItem('createCustomerSoldTo') === null ? {} : JSON.parse(sessionStorage.getItem('createCustomerSoldTo'));
  // const billToForm = sessionStorage.getItem('createCustomerBillTo') === null ? {} : JSON.parse(sessionStorage.getItem('createCustomerBillTo'));

  // const postToSharepointRes = PostToSharepoint(mainPageForm, shipToForm, payerForm, soldToForm, billToForm);

  const postToSharepointRes = PostToSharepoint(
    props.accounts.length > 0 ? props.accounts[0].username : '',
    props.draftTicketNum,
    isSaveRequest,
  );

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    props.setActivePage(e.target.getAttribute('data-link'));
  };

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const [showSubmitModal, setShowSubmitModal] = useState(false);

  const [mainPageErrors, setMainPageErrors] = useState({});
  const [shipToErrors, setShipToErrors] = useState({});
  const [payerErrors, setPayerErrors] = useState({});
  const [soldToErrors, setSoldToErrors] = useState({});
  const [billToErrors, setBillToErrors] = useState({});

  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleSave = () => {
    setShowSubmitModal(true);
    setIsSave(true);
    setIsSaveRequest(true);
    // setMainPageErrors(mainPageValidate(mainPageForm));
    // setShipToErrors(shipToValidate(shipToForm));
    // setPayerErrors(payerValidate(payerForm));
    // setSoldToErrors(soldToValidate(soldToForm));
    // setBillToErrors(billToValidate(billToForm));
    // props.saveDraft();
  };

  useEffect(() => {
    console.log(
      `${JSON.stringify(mainPageErrors)} ${JSON.stringify(shipToErrors)} ${JSON.stringify(
        payerErrors,
      )} ${JSON.stringify(soldToErrors)} ${JSON.stringify(billToErrors)} ${isSave}`,
    );
    if (
      Object.keys(mainPageErrors).length == 0 &&
      Object.keys(shipToErrors).length == 0 &&
      Object.keys(payerErrors).length == 0 &&
      Object.keys(soldToErrors).length == 0 &&
      Object.keys(billToErrors).length == 0 &&
      isSave
    ) {
      console.log('Can save');
      props.saveDraft();
    } else if (
      Object.keys(mainPageErrors).length != 0 ||
      Object.keys(shipToErrors).length != 0 ||
      Object.keys(payerErrors).length != 0 ||
      Object.keys(soldToErrors).length != 0 ||
      Object.keys(billToErrors).length != 0
    ) {
      console.log('Found errors');
      setShowErrorModal(true);
      setIsSave(false);
    } else setIsSave(false);
  }, [billToErrors]);

  function getLang(id) {
    // console.log(props.translations);
    let obj = props.translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    if (props.draftMsg !== '') {
      console.log(props.draftMsg);
      setIsSaveRequest(true);
    }
  }, [props.draftMsg]);

  useEffect(() => {
    setIsSaveRequest(false);
    setIsSave(false);
    if (Object.keys(postToSharepointRes.result).length !== 0) {
      console.log('Successfully saved.');
      props.setActivePage('success');
    } else if (postToSharepointRes.isError) {
      props.setActivePage('error');
    }
  }, [postToSharepointRes.result, postToSharepointRes.isError]);

  useEffect(() => {
    if (
      props.draftTicketNum[0] !== 'M' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected'
    ) {
      setIsDisable(true);
    }
  }, []);

  return (
    isAuthenticated && (
      <form className="card">
        <Modal show={isSave} centered>
          <Modal.Body>
            <Row>
              <div className="col-auto">
                <Spinner animation="border" variant="warning" />
              </div>
              <div className="text-left">
                <span className="align-middle">
                  &nbsp;Please wait while your request is submitted
                </span>
              </div>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={blockUnblockForm.supportDocsBank}></img>
          </Modal.Body>
        </Modal>

        <div className="card-header">
          <div className="col-11 col-lg-12">
            <h3 className="card-title text-left">
              <strong>Customer Data {getLang('Update')}</strong> - {getLang('Review')}
            </h3>
          </div>
          <a
            className={`sidebar-toggler col-auto ${isSidebarOpen ? '' : 'collapsed'}`}
            onClick={togglingSidebar}
          >
            <span className="sidebar-toggler-icon"></span>
          </a>
        </div>
        <Row className="sidebar-row">
          <div className="col-12">
            <div className="card-body text-left">
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col sm={12} lg={4}>
                      <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Block/Unblock')}</h2>
                    </Col>
                  </Row>

                  {blockUnblockForm.checkShipTo == 'Y' && (
                    <>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang(blockUnblockForm.blockUnblockOpt)} {getLang('Ship-to')}
                            </label>
                            <div className="form-control-textfield">
                              {blockUnblockForm.checkShipTo}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Ship-to ID')}
                            </label>
                            <div className="form-control-textfield">
                              {blockUnblockForm.existingShipToID}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {blockUnblockForm.nameShipTo}
                            </div>
                          </div>
                        </Col>
                        {blockUnblockForm.blockUnblockOpt == 'Block' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Order Block Type')}
                                </label>
                                <div className="form-control-textfield">
                                  {blockUnblockForm.orderBlockShipTo}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Delivery Block Type')}
                                </label>
                                <div className="form-control-textfield">
                                  {blockUnblockForm.deliveryBlockShipTo}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Billing Block Type')}
                                </label>
                                <div className="form-control-textfield">
                                  {blockUnblockForm.billingBlockShipTo}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                    </>
                  )}

                  {blockUnblockForm.checkPayer == 'Y' && (
                    <>
                      <hr />
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang(blockUnblockForm.blockUnblockOpt)} {getLang('Payer')}
                            </label>
                            <div className="form-control-textfield">
                              {blockUnblockForm.checkPayer}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Payer ID')}
                            </label>
                            <div className="form-control-textfield">
                              {blockUnblockForm.existingPayerID}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {blockUnblockForm.namePayer}
                            </div>
                          </div>
                        </Col>
                        {blockUnblockForm.blockUnblockOpt == 'Block' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Order Block Type')}
                                </label>
                                <div className="form-control-textfield">
                                  {blockUnblockForm.orderBlockPayer}
                                </div>
                              </div>
                            </Col>
                            {!['France', 'Belgium'].includes(mainPageForm.country) && (
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Delivery Block Type')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {blockUnblockForm.deliveryBlockPayer}
                                  </div>
                                </div>
                              </Col>
                            )}
                            {!['France', 'Belgium'].includes(mainPageForm.country) && (
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Billing Block Type')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {blockUnblockForm.billingBlockPayer}
                                  </div>
                                </div>
                              </Col>
                            )}
                          </>
                        )}
                      </Row>
                    </>
                  )}

                  {blockUnblockForm.checkSoldTo == 'Y' && (
                    <>
                      <hr />
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang(blockUnblockForm.blockUnblockOpt)} {getLang('Sold To')}
                            </label>
                            <div className="form-control-textfield">
                              {blockUnblockForm.checkSoldTo}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Sold To ID')}
                            </label>
                            <div className="form-control-textfield">
                              {blockUnblockForm.existingSoldToID}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {blockUnblockForm.nameSoldTo}
                            </div>
                          </div>
                        </Col>
                        {blockUnblockForm.blockUnblockOpt == 'Block' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Order Block Type')}
                                </label>
                                <div className="form-control-textfield">
                                  {blockUnblockForm.orderBlockSoldTo}
                                </div>
                              </div>
                            </Col>
                            {mainPageForm.country != 'Netherlands' && (
                              <>
                                {!['France', 'Belgium'].includes(mainPageForm.country) && (
                                  <Col xs={6} lg={3}>
                                    <div className="form-group">
                                      <label className="form-label text-left">
                                        {getLang('Delivery Block Type')}
                                      </label>
                                      <div className="form-control-textfield">
                                        {blockUnblockForm.deliveryBlockSoldTo}
                                      </div>
                                    </div>
                                  </Col>
                                )}
                                {!['France', 'Belgium'].includes(mainPageForm.country) && (
                                  <Col xs={6} lg={3}>
                                    <div className="form-group">
                                      <label className="form-label text-left">
                                        {getLang('Billing Block Type')}
                                      </label>
                                      <div className="form-control-textfield">
                                        {blockUnblockForm.billingBlockSoldTo}
                                      </div>
                                    </div>
                                  </Col>
                                )}
                              </>
                            )}
                          </>
                        )}
                        {mainPageForm.country == 'Netherlands' &&
                          blockUnblockForm.blockUnblockOpt == 'Unblock' && (
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Please Attach The Supportings For KVK')}
                                </label>
                                <div className="form-control-textfield">
                                  <a
                                    href=""
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (
                                        ['.jpeg', '.png', '.jpg'].includes(
                                          fileRegex.exec(blockUnblockForm.supportDocsBankName)[0],
                                        )
                                      ) {
                                        setShowModal(true);
                                      }
                                    }}
                                  >
                                    {blockUnblockForm.supportDocsBankName}
                                  </a>
                                </div>
                              </div>
                            </Col>
                          )}
                      </Row>
                    </>
                  )}

                  {blockUnblockForm.checkBillTo == 'Y' && (
                    <>
                      <hr />
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang(blockUnblockForm.blockUnblockOpt)} {getLang('Bill To')}
                            </label>
                            <div className="form-control-textfield">
                              {blockUnblockForm.checkBillTo}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Bill To ID')}
                            </label>
                            <div className="form-control-textfield">
                              {blockUnblockForm.existingBillToID}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {blockUnblockForm.nameBillTo}
                            </div>
                          </div>
                        </Col>
                        {blockUnblockForm.blockUnblockOpt == 'Block' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Order Block Type')}
                                </label>
                                <div className="form-control-textfield">
                                  {blockUnblockForm.orderBlockBillTo}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Delivery Block Type')}
                                </label>
                                <div className="form-control-textfield">
                                  {blockUnblockForm.deliveryBlockBillTo}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Billing Block Type')}
                                </label>
                                <div className="form-control-textfield">
                                  {blockUnblockForm.billingBlockBillTo}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Row>

        <br />

        <div className="card-footer">
          <div className="row">
            <div className="col col-md"></div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link="blockUnblock"
                className="btn btn-warning btn-block"
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Back
              </Link>
            </div>
            {!isDisable && (
              <div className="col-auto col-md-auto text-right">
                <Link
                  data-link="success"
                  className="btn btn-danger btn-block"
                  style={{ textDecoration: 'none' }}
                  onClick={handleSave}
                >
                  {getLang('Submit')}
                </Link>
              </div>
            )}
          </div>
        </div>
      </form>
    )
  );
};

export default UpdateCustBlockUnblockReview;
