import React from 'react';
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import cs from 'classnames';
import './MUITableStyle.scss';

const Table = ({
  tableData,
  tableColumns,
  className,
  autoHeight = true,
  components = {},
  componentsProps = {},
  classes,
  setEditTableIndex,
  loading,
  ...props
}) => {
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  }
  return (
    <div>
      <DataGrid
        sx={{ width: '100%' }}
        rows={tableData}
        className={cs({ [className]: className })}
        componentsProps={{
          toolbar: { showQuickFilter: true },
          ...componentsProps,
        }}
        components={{
          Toolbar: CustomToolbar,
          ...components,
        }}
        columns={tableColumns}
        autoHeight={autoHeight}
        classes={{
          root: 'main-table-wrapper',
          footerContainer: 'main-table-wrapper-footer',
          columnHeaderTitleContainer: 'justify-content-center',
          iconSeparator: 'opacity-0',
          toolbarContainer: 'table-toolbar-wrapper',
          panel: 'table-panel-wrapper',
          ...classes,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSize={5}
        pageSizeOptions={[5, 10, 25]}
        pagination
        loading={loading}
        // checkboxSelection
        onSelectionModelChange={(selected) => setEditTableIndex(selected)}
        disableSelectionOnClick
        hideFooterSelectedRowCount
        columnVisibilityModel={props.columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => props.onColumnVisibilityModelChange(newModel)}
      />
    </div>
  );
};

export default Table;
