import React from 'react';

import { Badge } from 'react-bootstrap';

const StatusPill = (props) => {
  function statusVariant(status) {
    // console.log(status)

    // if (status == 'Awaiting Approval') return 'warning';
    // else if (status == 'Approved') return 'success';
    // else if (status.startsWith('Transaction Completed')) return 'success';
    // else if (status.startsWith('Awaiting Cr Manager Approval')) return 'warning';
    // else if (status.startsWith('Approved by GCC CMD')) return 'secondary';
    // else if (status.startsWith('Salesforce Ticket Created')) return 'primary';
    // else if (status.toLowerCase().includes('error')) return 'danger';
    // else if (status.includes('Selfi Created')) return 'primary';
    // else if (status.includes('Rejected')) return 'danger';
    // else return 'warning';

    if (status == 'Awaiting Approval') return 'secondary';
    else if (status == 'In Progress') return 'warning';
    else if (status == 'Rejected') return 'danger';
    else if (status == 'Completed') return 'success';
    else if (status == 'Duplicate') return 'danger';
    else return 'warning';
    // switch (status) {
    //   case 'Awaiting Approval':
    //     return 'success';
    //   case 'Approved':
    //     return 'success';
    // }
  }

  return (
    <Badge pill bg={statusVariant(props.status)}>
      {props.status.startsWith('Transaction Completed') ? 'Transaction Completed' : props.status}
    </Badge>
  );
};

export default StatusPill;
