import React, { useState } from 'react';
import { graphConfig } from '../authConfig';

// const [imageURL, setImageURL] = useState('');

export async function callMsGraphPhoto(accessToken) {
    var imageURL;

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);

    const options = {
        method: 'GET',
        headers: headers,
    };

    return fetch(graphConfig.graphProfilePhotoEndpoint, options)
        .then((response) => response.blob())
        .catch((error) => console.log(error));
}

export const ProfilePhoto = (props) => {
    console.log(props.graphData.profilePhoto);
    return <img className="avatar" src={props.graphData.profilePhoto} alt="" />;
};
