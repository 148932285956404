const prod = {
  url: {
    fetchRegionsURL: 'https://mavenapis.ab-inbev.com/api/data/regions',
    fetchSegmentsURL: 'https://mavenapis.ab-inbev.com/api/data/segments',
    fetchSubSegmentsURL: 'https://mavenapis.ab-inbev.com/api/data/subsegments',
    fetchDeliveryPlantsURL: 'https://mavenapis.ab-inbev.com/api/data/deliveryplants',
    fetchShippingConditionsURL: 'https://mavenapis.ab-inbev.com/api/data/shippingconditions',
    fetchPOCSuppliersURL: 'https://mavenapis.ab-inbev.com/api/data/pocsuppliers',
    fetchProfitCentersURL: 'https://mavenapis.ab-inbev.com/api/data/profitcenters',
    fetchTransportationZonesURL: 'https://mavenapis.ab-inbev.com/api/data/transportationzones',
    fetchLanguagesURL: 'https://mavenapis.ab-inbev.com/api/data/language',
    fetchTruckTypesURL: 'https://mavenapis.ab-inbev.com/api/data/trucktype',
    fetchTrailerTypesURL: 'https://mavenapis.ab-inbev.com/api/data/trailertype',
  },
};

const test = {
  url: {
    fetchRegionsURL: 'https://mavenapistest.ab-inbev.com/api/data/regions',
    fetchSegmentsURL: 'https://mavenapistest.ab-inbev.com/api/data/segments',
    fetchSubSegmentsURL: 'https://mavenapistest.ab-inbev.com/api/data/subsegments',
    fetchDeliveryPlantsURL: 'https://mavenapistest.ab-inbev.com/api/data/deliveryplants',
    fetchShippingConditionsURL: 'https://mavenapistest.ab-inbev.com/api/data/shippingconditions',
    fetchPOCSuppliersURL: 'https://mavenapistest.ab-inbev.com/api/data/pocsuppliers',
    fetchProfitCentersURL: 'https://mavenapistest.ab-inbev.com/api/data/profitcenters',
    fetchTransportationZonesURL: 'https://mavenapistest.ab-inbev.com/api/data/transportationzones',
    fetchLanguagesURL: 'https://mavenapistest.ab-inbev.com/api/data/language',
    fetchTruckTypesURL: 'https://mavenapistest.ab-inbev.com/api/data/trucktype',
    fetchTrailerTypesURL: 'https://mavenapistest.ab-inbev.com/api/data/trailertype',
  },
};

const local = {
  url: {
    fetchRegionsURL: 'http://localhost:1234/api/data/regions',
    fetchSegmentsURL: 'http://localhost:1234/api/data/segments',
    fetchSubSegmentsURL: 'http://localhost:1234/api/data/subsegments',
    fetchDeliveryPlantsURL: 'http://localhost:1234/api/data/deliveryplants',
    fetchShippingConditionsURL: 'http://localhost:1234/api/data/shippingconditions',
    fetchPOCSuppliersURL: 'http://localhost:1234/api/data/pocsuppliers',
    fetchProfitCentersURL: 'http://localhost:1234/api/data/profitcenters',
    fetchTransportationZonesURL: 'http://localhost:1234/api/data/transportationzones',
    fetchLanguagesURL: 'http://localhost:1234/api/data/language',
    fetchTruckTypesURL: 'http://localhost:1234/api/data/trucktype',
    fetchTrailerTypesURL: 'http://localhost:1234/api/data/trailertype',
  },
};
const configData =
  process.env.REACT_APP_ENV === 'prod'
    ? prod.url
    : process.env.REACT_APP_ENV === 'test'
    ? test.url
    : local.url;

export default configData;
