import {
  postalCodeRegex,
  telephoneRegex,
  companyNumRegex,
  emailRegex,
  payerIDRegex,
} from '../../../../utils/Utils';

export function validateFields(values, payerSap, copyClick) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')).country;

  if (copyClick) {
    if (values.existingPayerID == '') {
      errors.existingPayerID = 'Payer ID is required';
    } else if (values.existingPayerID != '' && !payerIDRegex.test(values.existingPayerID)) {
      errors.existingPayerID = 'Payer ID is incorrect';
    }
  } else {
    if (values.name != '' && !copyClick) {
      if(values.acctgClerks == ''){
        errors.acctgClerks = 'Accounting Clerk is required'
      }
    }
  }

  return errors;
}



// else if (values.mobile === '' && ['Netherlands', 'United Kingdom', 'France'].includes(country)) {
//   errors.mobile = 'Mobile is required';
// else if (values.email === '') {
//   errors.email = 'Email is required';
// }
