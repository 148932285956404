export function validateFields(values, copyClick) {
  let errors = {};

  const billToIDRegex = /^(3)\d{7}$/;
  const country = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')).country || '';

  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (copyClick) {
    if (values.existingBillToID == '') {
      errors.existingBillToID = 'Bill To ID is required';
    } else if (values.existingBillToID != '' && !billToIDRegex.test(values.existingBillToID)) {
      errors.existingBillToID = 'Bill To ID is incorrect';
    }
  } else {
    if (values.name != '') {
      if (values.contactEmail != '' && !emailRegex.test(values.contactEmail)) {
        errors.contactEmail = 'Contact email is incorrect';
      } else if (values.eInvBillOpt == '') {
        errors.eInvBillOpt = 'Option is required';
      } else if (values.function == '' && country != 'Netherlands') {
        errors.function = 'Invoicing Function is required';
      } else if (values.firstName == '' && values.lastName == '') {
        errors.firstName = 'Contact Name is required';
      } else if (values.contactEmail == '') {
        errors.contactEmail = 'Email is required';
      }
    }
  }

  return errors;
}
