import React, { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
import { act } from 'react-dom/test-utils';
function UpdateIbanDataModal({
  open,
  columns,
  onClose,
  onSubmit,
  getLang,
  inputs,
  setInputs,
  errors,
  setErrors,
  isDisable,
  handleSubmit,
  FileSize,
  rowData,
  activeRow,
  setShowModal,
  showModal,
}) {
  const fileRegex = /\.[^.\\/:*?"<>|\r\n]+$/;
  const ibanForm = JSON.parse(sessionStorage.getItem('updateCustomerIbanUpdate')) || {};
  const mainPageForm = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')) || {};
  const [bkTypeForBankType, setBkTypeForBankType] = useState();

  useEffect(() => {
    if (inputs['bkType'] !== '') {
      setBkTypeForBankType(inputs['bkType']);
    }
  }, [inputs['bkType']]);
  console.log('bkTypeForBankType: ', bkTypeForBankType);

  // useEffect(() => {
  //   if (rowData[activeRow] !== undefined) {
  //     setInputs({ target: { name: 'firstName', value: rowData[activeRow].Z_FIRSTNAME } });
  //     setInputs({ target: { name: 'lastName', value: rowData[activeRow].Z_NAME1 } });
  //   }
  // }, [rowData, activeRow]);
  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Update Bank Details</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            width: '100%',
            minWidth: { xs: '300px', sm: '360px', md: '400px' },
            gap: '1.5rem',
          }}
        >
          {columns.map((column) => (
            <div
              className="form-group"
              key={column.accessorKey}
              hidden={column.id == 'bkType' && mainPageForm.country == 'Belgium'}
            >
              <label
                className="form-label text-left"
                hidden={column.id == 'bkType' && mainPageForm.country == 'Belgium'}
              >
                {getLang(column.header)}
              </label>
              <input
                type="text"
                name={column.id}
                className={`form-control ${errors[column.id] ? 'is-invalid' : ''}`}
                onChange={setInputs}
                value={inputs[column.id]}
                maxLength="35"
                disabled={
                  inputs.name == '' ||
                  isDisable ||
                  (column.id === 'iban' && inputs.ibanOpt === 'Update Existing')
                }
                hidden={column.id == 'bkType' && mainPageForm.country == 'Belgium'}
              />
              {errors[column.id] && (
                <div className="invalid-feedback text-left">{errors[column.id]}</div>
              )}
            </div>
          ))}

          {['Belgium'].includes(mainPageForm.country) && (
            <div className="form-group">
              <div className="form-group">
                <label className="form-label text-left">{getLang('Bank Type')} *</label>
                <label className="form-label text-left" hidden={inputs.ibanOpt == 'Add New'}>
                  (Bank type old value: {bkTypeForBankType} )
                </label>
                <select
                  id="select-bankTypes"
                  name="bankTypes"
                  className={`form-control ${errors.bankTypes ? 'is-invalid-select' : ''}`}
                  onChange={setInputs}
                  value={inputs.values}
                  required
                >
                  <option value="">Select</option>
                  <option value="BM">BM</option>
                  <option value="Domiciliation Beer">Domiciliation Beer</option>
                  <option value="Domiciliation Ren">Domiciliation Rent</option>
                </select>
                {errors.bankTypes && (
                  <div className="invalid-feedback text-left">{getLang(errors.bankTypes)}</div>
                )}
              </div>
            </div>
          )}

          <div className="form-group">
            <label className="form-label text-left">
              {getLang('Please Attach The Supportings For IBAN & Bank Details')}
            </label>
            {!inputs.supportDocsBank && (
              <input
                type="file"
                id="upload-support-docs"
                accept="image/png, image/jpeg, .doc, .docx, application/msword, .msg, .pdf, .zip"
                name="supportDocsBank"
                style={{ color: 'transparent' }}
                onChange={(e, action) => {
                  FileSize(e, action);
                  setInputs(e);
                }}
                disabled={inputs.name == '' || isDisable}
              />
            )}
            {inputs.supportDocsBank && (
              <div className="form-control-textfield text-left">
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      ['.jpeg', '.jpg', '.png'].includes(
                        fileRegex.exec(inputs.supportDocsBankName)[0],
                      )
                    ) {
                      setShowModal(true);
                    }
                  }}
                >
                  {inputs.supportDocsBankName}
                </a>
                &nbsp;
                <FeatherIcon
                  id="clearFile"
                  icon="x"
                  size={14}
                  name="clearFile"
                  style={{ cursor: 'pointer' }}
                  onClick={setInputs}
                  className="fe-icon"
                />
              </div>
            )}
            {errors.supportDocsBank && (
              <div className="invalid-feedback-file text-left">{errors.supportDocsBank}</div>
            )}
          </div>
          <div className="row">
            <div className="col-12 text-left color-text">
              <p>
                <strong>{`Attachment limit is 1 MB`}</strong>
              </p>
            </div>
          </div>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          data-link="ibanUpdateReview"
          color="primary"
          onClick={handleSubmit}
          variant="contained"
        >
          Review
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateIbanDataModal;
