import { useState, useEffect } from 'react';
import { formTemplate } from '../../Data';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  useEffect(() => {
    sessionStorage.setItem('updateCustomerMainPage', JSON.stringify(fields));
  }, [fields]);

  return [
    fields,
    function (event) {
      if (
        (event.target.name == 'country' || event.target.name == 'reqType') &&
        event.target.value != ''
      ) {
        sessionStorage.setItem(
          'updateCustomerBlockUnblock',
          JSON.stringify(formTemplate.blockUnblock),
        );
        sessionStorage.setItem(
          'updateCustomerPayerUpdate',
          JSON.stringify(formTemplate.payerUpdate),
        );
        sessionStorage.setItem(
          'updateCustomerNameDelivPlantUpdate',
          JSON.stringify(formTemplate.nameDelivPlantUpdate),
        );
        sessionStorage.setItem(
          'updateCustomerVatTaxUpdate',
          JSON.stringify(formTemplate.vatTaxUpdate),
        );
        sessionStorage.setItem('updateCustomerIbanUpdate', JSON.stringify(formTemplate.ibanUpdate));
        sessionStorage.setItem(
          'updateCustomerEInvPayerUpdate',
          JSON.stringify(formTemplate.eInvPayerUpdate),
        );
        sessionStorage.setItem(
          'updateCustomerEInvBillUpdate',
          JSON.stringify(formTemplate.eInvBillUpdate),
        );
        sessionStorage.setItem(
          'updateCustomerOthersWarehouseUpdate',
          JSON.stringify(formTemplate.othersWarehouseUpdate),
        );
        sessionStorage.setItem(
          'updateCustomerOthersProfitUpdate',
          JSON.stringify(formTemplate.othersProfitUpdate),
        );
        sessionStorage.setItem(
          'updateCustomerOthersGlnUpdate',
          JSON.stringify(formTemplate.othersGlnUpdate),
        );
        sessionStorage.setItem(
          'updateCustomerOthersTextUpdate',
          JSON.stringify(formTemplate.othersTextUpdate),
        );
        sessionStorage.setItem('updateCustomerldeUpdate', JSON.stringify(formTemplate.ldeUpdate));
      }
      if (event.target.name == 'country' && event.target.value != '') {
        setValues({
          ...fields,
          country: event.target.value,
          salesOrg: event.target.selectedOptions[0].getAttribute('data-salesorg'),
          companyCode: event.target.selectedOptions[0].getAttribute('data-companycode'),
        });
      } else if (event.target.name == 'reqType' && event.target.value != '') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          reqPage: event.target.selectedOptions[0].getAttribute('data-page'),
        });
      } else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
        });
      }
    },
  ];
}
