import {
  postalCodeRegex,
  telephoneRegex,
  companyNumRegex,
  emailRegex,
  payerIDRegex,
  shipToIDRegex
} from '../../../../utils/Utils';

export function validateFields(values, payerSap, copyClick) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')).country;

  
  if (copyClick) {
    if (values.existingShipToID == '') {
      errors.existingShipToID = 'Ship To ID is required';
    } else if (values.existingShipToID != '' && !shipToIDRegex.test(values.existingShipToID)) {
      errors.existingShipToID = 'Ship To ID is incorrect';
    }
  } else {

    if(values.name != ''){
      if(values.attributeType5 == ''){
        errors.attributeType5 = 'Attribute 5  is required';
      }
      if(values.deliveringPlant == ''){
        errors.deliveringPlant = 'Delivering Plant is required'
      }
      else if(values.pocsupplier == ''){
        errors.pocsupplier = 'POC Supplier is required';
      }
      else if(country == 'Netherlands' && values.searchTerm2 == ''){
        errors.searchTerm2 = 'Search Term 2 is required';
      } else if(values.shippingCondition == ''){
        errors.shippingCondition = 'Shipping Condition is required';
      }
    }
  }
    
  

  return errors;
}


