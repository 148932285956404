import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Card, CardDeck, Col, Container, Row, Spinner, Modal, FormControl } from 'react-bootstrap';
import TopBar from '../../components/layout/TopBar';
import StatusPill from '../../components/StatusPill';
import FeatherIcon from 'feather-icons-react';

import {
  FetchDraftRequests,
  FetchSubmittedRequests,
  FetchRejectedRequests,
  FetchDuplicateRequests,
  FetchCompletedRequests,
  FetchFilteredData,
  FetchListingFeesPendingRequests,
} from './FlowCallAPI';
import ticketsService from '../../utils/ticketsService';

import moment from 'moment';

const Home = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    if (!isAuthenticated) navigate('/');
  }, [isAuthenticated]);

  function getLang(id) {
    // console.log(props.translations);
    let obj = props.translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }
  const [SubmittedRequestType, setSubmittedRequestType] = useState('Customer');

  const [DraftRequestType, setDraftRequestType] = useState('Customer');

  const [RejectedRequestType, setRejectedRequestType] = useState('Customer');

  const [CompletedRequestType, setCompletedRequestType] = useState('Customer');

  const [DuplicateRequestType, setDuplicateRequestType] = useState('Customer');

  const [ListingFeePendingRequestType, setListingFeePendingRequestType] = useState('Customer');

  const [ListingFeeCompletedRequestType, setListingFeeCompletedRequestType] = useState('Customer');

  const [SubmittedFilteredRequestType, setSubmittedFilteredRequestType] = useState('Customer');

  const [DraftFilteredRequestType, setDraftFilteredRequestType] = useState('Customer');

  const [RejectedFilteredRequestType, setRejectedFilteredRequestType] = useState('Customer');

  const [CompletedFilteredRequestType, setCompletedFilteredRequestType] = useState('Customer');

  const [DuplicateFilteredRequestType, setDuplicateFilteredRequestType] = useState('Customer');

  const draftRequestsRes = FetchDraftRequests(
    props.accounts.length > 0 ? props.accounts[0].username : '',
  );
  const completedRequestsRes = FetchCompletedRequests(
    props.accounts.length > 0 ? props.accounts[0].username : '',
  );
  const duplicateRequestsRes = FetchDuplicateRequests(
    props.accounts.length > 0 ? props.accounts[0].username : '',
  );

  const submittedRequestsRes = FetchSubmittedRequests(
    props.accounts.length > 0 ? props.accounts[0].username : '',
  );

  // const draftList = draftRequestsRes.results.map((item) => {
  //   console.log('Shipto name from draft', item.ShipTo_Name);
  // });

  const [draftRequestsLoading, setDraftRequestsLoading] = useState(false);
  const [draftRequests, setDraftRequests] = useState({ results: [] });

  const [submittedRequestsLoading, setSubmittedRequestsLoading] = useState(false);
  const [submittedRequests, setSubmittedRequests] = useState({ results: [] });

  const [rejectedRequestsLoading, setRejectedRequestsLoading] = useState(false);
  const [rejectedRequests, setRejectedRequests] = useState({ results: [] });

  const [duplicateRequestsLoading, setDuplicateRequestsLoading] = useState(false);
  const [duplicateRequests, setDuplicateRequests] = useState({ results: [] });

  const [completedRequestsLoading, setCompletedRequestsLoading] = useState(false);
  const [completedRequests, setCompletedRequests] = useState({ results: [] });

  const [listingFeePendingRequestsLoading, setListingFeePendingRequestsLoading] = useState(false);
  const [listingFeePendingRequests, setListingFeePendingRequests] = useState({ results: [] });

  const [completedListingFeeRequestsLoading, setCompletedListingFeeRequestsLoading] =
    useState(false);
  const [completedListingFeeRequests, setCompletedListingFeeRequests] = useState({ results: [] });

  const [pendingListingFeeRequestsLoading, setPendingListingFeeRequestsLoading] = useState(false);
  const [pendingListingFeeRequests, setPendingListingFeeRequests] = useState({ results: [] });

  const [submittedFilteredRequestsLoading, setSubmittedFilteredRequestsLoading] = useState(false);
  const [submittedFilteredRequests, setSubmittedFilteredRequests] = useState({ results: [] });

  const [draftFilteredRequestsLoading, setDraftFilteredRequestsLoading] = useState(false);
  const [draftFilteredRequests, setDraftFilteredRequests] = useState({ results: [] });

  const [rejectedFilteredRequestsLoading, setRejectedFilteredRequestsLoading] = useState(false);
  const [rejectedFilteredRequests, setRejectedFilteredRequests] = useState({ results: [] });

  const [duplicateFilteredRequestsLoading, setDuplicateFilteredRequestsLoading] = useState(false);
  const [duplicateFilteredRequests, setDuplicateFilteredRequests] = useState({ results: [] });

  const [completedFilteredRequestsLoading, setCompletedFilteredRequestsLoading] = useState(false);
  const [completedFilteredRequests, setCompletedFilteredRequests] = useState({ results: [] });

  const [listingFeeFilteredRequestsLoading, setListingFeeFilteredRequestsLoading] = useState(false);
  const [listingFeeFilteredRequests, setlistingFeeFilteredRequests] = useState({ results: [] });

  const [
    listingFeeCompletedFilteredRequestsLoading,
    setListingFeeCompletedFilteredRequestsLoading,
  ] = useState(false);
  const [listingFeeCompletedFilteredRequests, setListingFeeCompletedFilteredRequests] = useState({
    results: [],
  });

  // const [completedListingFeeFilter, setCompletedListingFeeFilteredRequests] = useState(false);

  const [draftSearchTerm, setDraftsearchTerm] = useState('');
  const [submittedSearchTerm, setSubmittedsearchTerm] = useState('');
  const [rejectedSearchTerm, setRejectedsearchTerm] = useState('');
  const [duplicateSearchTerm, setDuplicatesearchTerm] = useState('');
  const [completedSearchTerm, setCompletedsearchTerm] = useState('');
  const [listingFeePendingSearchTerm, setListingFeePendingSearchTerm] = useState('');
  const [listingFeeCompletedSearchTerm, setListingFeeCompletedSearchTerm] = useState('');

  // console.log(draftRequests);

  function handleDraftTicketClick(ticketNum, reqType, creationType, customerRequest) {
    // console.log('HANDLE DRAFT CLICK', ticketNum, reqType, creationType, customerRequest);
    // props.openRequest(ticketNum, reqType, creationType, customerRequest);
    navigate('/create/general');

    // props.RequestCreationType(ticketNum, creationType, customerRequest, reqType);
    // console.log(e.target);
    // if (e.target.getAttribute('data-ticket')) props.openRequest(e.target.getAttribute('data-ticket'), e.target.getAttribute('data-reqtype'));
  }

  function handleTicketClick(ticketNum, reqType, creationType, customerRequest) {
    console.log('TICKET CLICK', ticketNum, reqType, creationType, customerRequest);
    console.log(`request/${ticketNum}`);
    navigate({ pathname: `/request/${ticketNum}` });
    props.RequestCreationType(ticketNum, creationType, customerRequest, reqType);
    //props.openRequest(reqType,creationType);
    console.log('CUSTOMER DATA CLICK', ticketNum, reqType, creationType, customerRequest);
    // props.openRequest(ticketNum, reqType,creationType);
    // // props.openRequest(ticketNum, reqType);
    // console.log(e.target);
    // if (e.target.getAttribute('data-ticket')) props.openRequest(e.target.getAttribute('data-ticket'), e.target.getAttribute('data-reqtype'));
  }

  function formatDate(dateStr) {
    if (dateStr != '') {
      let x = new Date(dateStr);
      let res = `${x.getDate()}/${x.getMonth() + 1}/${x.getFullYear()}`;

      return res;
    }

    return dateStr;
  }

  function getDraftRequests(pageNum = 1) {
    if (accounts.length > 0) {
      setDraftRequests({ results: [] });
      setDraftRequestsLoading(true);
      ticketsService
        .getDraftRequestsPaginated(
          accounts.length > 0 ? accounts[0].username : '',
          pageNum,
          // DraftRequestType,
        )
        .then((res) => {
          setDraftRequests(res);
          setDraftRequestsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setDraftRequestsLoading(false);
        });
    }
  }

  function getSubmittedRequests(pageNum = 1) {
    if (accounts.length > 0) {
      setSubmittedRequests({ results: [] });
      setSubmittedRequestsLoading(true);
      ticketsService
        .getSubmittedRequestsPaginated(
          accounts.length > 0 ? accounts[0].username : '',
          pageNum,

          // SubmittedRequestType,
        )
        .then((res) => {
          setSubmittedRequests(res);
          setSubmittedRequestsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setSubmittedRequestsLoading(false);
        });
    }
  }

  function getRejectedRequests(pageNum = 1) {
    if (accounts.length > 0) {
      setRejectedRequests({ results: [] });
      setRejectedRequestsLoading(true);
      ticketsService
        .getRejectedRequestsPaginated(
          accounts.length > 0 ? accounts[0].username : '',
          pageNum,
          // RejectedRequestType,
        )
        .then((res) => {
          setRejectedRequests(res);
          setRejectedRequestsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setRejectedRequestsLoading(false);
        });
    }
  }

  function getDuplicateRequests(pageNum = 1) {
    if (accounts.length > 0) {
      setDuplicateRequests({ results: [] });
      setDuplicateRequestsLoading(true);
      ticketsService
        .getDuplicateRequestsPaginated(
          accounts.length > 0 ? accounts[0].username : '',
          pageNum,
          // DuplicateRequestType,
        )
        .then((res) => {
          setDuplicateRequests(res);
          setDuplicateRequestsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setDuplicateRequestsLoading(false);
        });
    }
  }

  function getCompletedRequests(pageNum = 1) {
    if (accounts.length > 0) {
      setCompletedRequests({ results: [] });
      setCompletedRequestsLoading(true);
      ticketsService
        .getCompletedRequestsPaginated(
          accounts.length > 0 ? accounts[0].username : '',
          pageNum,
          // CompletedRequestType,
        )
        .then((res) => {
          setCompletedRequests(res);
          setCompletedRequestsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setCompletedRequestsLoading(false);
        });
    }
  }

  function getListingFeesPendingRequests(pageNum = 1) {
    if (accounts.length > 0) {
      setListingFeePendingRequests({ results: [] });
      setListingFeePendingRequestsLoading(true);
      ticketsService
        .getListingFeesPendingPaginated(
          accounts.length > 0 ? accounts[0].username : '',
          pageNum,

          // SubmittedRequestType,
        )
        .then((res) => {
          setListingFeePendingRequests(res);
          setListingFeePendingRequestsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setListingFeePendingRequestsLoading(false);
        });
    }
  }

  function getListingFeesCompletedRequests(pageNum) {
    pageNum = pageNum ? pageNum : 1;
    if (accounts.length > 0) {
      setCompletedListingFeeRequests({ results: [] });
      setCompletedListingFeeRequestsLoading(true);
      ticketsService
        .getListingFeesCompletedPaginated(
          accounts.length > 0 ? accounts[0].username : '',
          pageNum,
          SubmittedRequestType,
        )
        .then((res) => {
          setCompletedListingFeeRequests(res);
          setCompletedListingFeeRequestsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setCompletedListingFeeRequestsLoading(false);
        });
    }
  }

  function getSubmittedFilteredRequests(pageNum = 1) {
    if (accounts.length > 0) {
      setSubmittedFilteredRequests({ results: [] });
      setSubmittedFilteredRequestsLoading(true);
      ticketsService
        .getSubmittedFilteredRequestsPaginated(
          accounts.length > 0 ? accounts[0].username : '',
          pageNum,
          submittedSearchTerm,
          // CompletedRequestType,
        )
        .then((res) => {
          setSubmittedFilteredRequests(res);
          setSubmittedFilteredRequestsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setSubmittedFilteredRequestsLoading(false);
        });
    }
  }

  function getDraftFilteredRequests(pageNum = 1) {
    if (accounts.length > 0) {
      setDraftFilteredRequests({ results: [] });
      setDraftFilteredRequestsLoading(true);
      ticketsService
        .getDraftFilteredRequestsPaginated(
          accounts.length > 0 ? accounts[0].username : '',
          pageNum,
          draftSearchTerm,
          // CompletedRequestType,
        )
        .then((res) => {
          setDraftFilteredRequests(res);
          setDraftFilteredRequestsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setDraftFilteredRequestsLoading(false);
        });
    }
  }

  function getRejectedFilteredRequests(pageNum = 1) {
    if (accounts.length > 0) {
      setRejectedFilteredRequests({ results: [] });
      setRejectedFilteredRequestsLoading(true);
      ticketsService
        .getRejectedFilteredRequestsPaginated(
          accounts.length > 0 ? accounts[0].username : '',
          pageNum,
          rejectedSearchTerm,
          // CompletedRequestType,
        )
        .then((res) => {
          setRejectedFilteredRequests(res);
          setRejectedFilteredRequestsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setRejectedFilteredRequestsLoading(false);
        });
    }
  }

  function getDuplicateFilteredRequests(pageNum = 1) {
    if (accounts.length > 0) {
      setDuplicateFilteredRequests({ results: [] });
      setDuplicateFilteredRequestsLoading(true);
      ticketsService
        .getDuplicateFilteredRequestsPaginated(
          accounts.length > 0 ? accounts[0].username : '',
          pageNum,
          duplicateSearchTerm,
          // CompletedRequestType,
        )
        .then((res) => {
          setDuplicateFilteredRequests(res);
          setDuplicateFilteredRequestsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setDuplicateFilteredRequestsLoading(false);
        });
    }
  }

  function getCompletedFilteredRequests(pageNum = 1) {
    if (accounts.length > 0) {
      setCompletedFilteredRequests({ results: [] });
      setCompletedFilteredRequestsLoading(true);
      ticketsService
        .getCompletedFilteredRequestsPaginated(
          accounts.length > 0 ? accounts[0].username : '',
          pageNum,
          completedSearchTerm,
          // CompletedRequestType,
        )
        .then((res) => {
          setCompletedFilteredRequests(res);
          setCompletedFilteredRequestsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setCompletedFilteredRequestsLoading(false);
        });
    }
  }

  function getListingFeesPendingFilteredRequests(pageNum = 1) {
    if (accounts.length > 0) {
      setPendingListingFeeRequests({ results: [] });
      setPendingListingFeeRequestsLoading(true);
      ticketsService
        .getPendingListingFeesFilteredRequestsPaginated(
          accounts.length > 0 ? accounts[0].username : '',
          pageNum,
          listingFeePendingSearchTerm,
          // CompletedRequestType,
        )
        .then((res) => {
          setlistingFeeFilteredRequests(res);
          setListingFeeFilteredRequestsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setListingFeeFilteredRequestsLoading(false);
        });
    }
  }

  function getListingFeesCompletedFilteredRequests(pageNum = 1) {
    if (accounts.length > 0) {
      // setCompletedListingFeeRequests({ results: [] });
      setListingFeeCompletedFilteredRequestsLoading(true);
      ticketsService
        .getCompletedListingFeesFilteredRequestsPaginated(
          accounts.length > 0 ? accounts[0].username : '',
          pageNum,
          listingFeeCompletedSearchTerm,
          // CompletedRequestType,
        )
        .then((res) => {
          setListingFeeCompletedFilteredRequests(res);
          setListingFeeCompletedFilteredRequestsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setListingFeeCompletedFilteredRequestsLoading(false);
        });
    }
  }

  useEffect(() => {
    getDraftRequests();
    getSubmittedRequests();
    getRejectedRequests();
    getDuplicateRequests();
    getCompletedRequests();
    getDuplicateRequests();
    getListingFeesPendingRequests();
    getListingFeesCompletedRequests();
    // getSubmittedFilteredRequests();
    // getDraftFilteredRequests();
    // getRejectedFilteredRequests();
    // getDuplicateFilteredRequests();
    // getCompletedFilteredRequests();
    // pipline check
  }, [
    accounts,
    SubmittedRequestType,
    DraftRequestType,
    RejectedRequestType,
    CompletedRequestType,
    DuplicateRequestType,
    ListingFeePendingRequestType,
    ListingFeeCompletedRequestType,
    // SubmittedFilteredRequestType,
    // DraftFilteredRequestType,
    // RejectedFilteredRequestType,
    // DuplicateFilteredRequestType,
    // CompletedFilteredRequestType,
  ]);

  useEffect(() => {
    if (submittedSearchTerm) getSubmittedFilteredRequests();
  }, [submittedSearchTerm]);

  useEffect(() => {
    if (draftSearchTerm) getDraftFilteredRequests();
  }, [draftSearchTerm]);

  useEffect(() => {
    if (rejectedSearchTerm) getRejectedFilteredRequests();
  }, [rejectedSearchTerm]);

  useEffect(() => {
    if (duplicateSearchTerm) getDuplicateFilteredRequests();
  }, [duplicateSearchTerm]);

  useEffect(() => {
    if (completedSearchTerm) getCompletedFilteredRequests();
  }, [completedSearchTerm]);

  useEffect(() => {
    if (listingFeePendingSearchTerm) getListingFeesPendingFilteredRequests();
  }, [listingFeePendingSearchTerm]);

  useEffect(() => {
    if (listingFeeCompletedSearchTerm) getListingFeesCompletedFilteredRequests();
  }, [listingFeeCompletedSearchTerm]);

  const [draftToDelete, setDraftToDelete] = useState('');
  const [showDeleteDraftModal, setShowDeleteDraftModal] = useState(false);

  function deleteDraft(ticketNum) {
    console.log(draftToDelete);
    if (accounts.length > 0) {
      ticketsService
        .deleteDraftRequest(ticketNum, accounts.length > 0 ? accounts[0].username : '')
        .then((res) => {
          setShowDeleteDraftModal(false);
          setDraftToDelete(null);
          getDraftRequests(draftRequests.page);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  // const style = {
  //   'border-radius': '48px',
  //   ':focus': {
  //     outline: 'none',
  //   },
  //   ':active': {
  //     outline: 'none',
  //   },
  // };
  const [hasFocus, setonFocus] = useState(false);

  return (
    <>
      {isAuthenticated &&
        (localStorage.Role == 'VMD' ? (
          <>
            <Row>
              <Col xs={12}>
                <Card style={{ borderRadius: '18px' }}>
                  <Card.Header
                    style={{
                      background: '#f4e00f',
                      borderTopRightRadius: '18px',
                      borderTopLeftRadius: '18px',
                    }}
                  >
                    <Container>
                      <Row>
                        <Col xs={6} md={7} className="d-flex">
                          <h3
                            className="card-title text-left"
                            style={{
                              marginTop: 'auto',
                              marginBottom: 'auto',
                            }}
                          >
                            <strong>{getLang(`Vendor Creation Requests`)}</strong>
                          </h3>
                        </Col>
                        <Col xs={6} md={5}>
                          <div
                            className="wrapper mt-2"
                            style={{
                              background: 'white',
                              borderRadius: '48px',
                              position: 'relative',
                              float: 'right',
                            }}
                          >
                            <div className="input-group">
                              <input
                                className="form-control py-2 border-0"
                                type="search"
                                placeholder="Ticket No./ Name"
                                style={{ borderRadius: '48px' }}
                                onChange={(event) =>
                                  setListingFeePendingSearchTerm(event.target.value)
                                }
                              />
                              <span className="input-group-append">
                                <button
                                  className="btn btn-outline-none border-0 bg-white shadow-none"
                                  type="button"
                                  style={{ borderRadius: '48px' }}
                                  onClick={() => setlistingFeeFilteredRequests}
                                >
                                  <FeatherIcon icon="search" size={22} stroke-width={3} />
                                </button>
                              </span>
                            </div>
                          </div>
                        </Col>
                        {/* <Col lg={3} xs={6}>
                      <FormControl
                        as="select"
                        defaultValue={CompletedRequestType}
                        onChange={(e) => setCompletedRequestType(e.target.value)}
                      >
                        <option value="Customer">Customer</option>
                        <option value="Hierarchy">Hierarchy</option>
                        <option value="Plant">Plant</option>
                      </FormControl>
                    </Col> */}
                      </Row>
                    </Container>
                  </Card.Header>
                  {listingFeePendingRequestsLoading &&
                    listingFeePendingRequests.results.length == 0 && (
                      <Card.Body>
                        <Row>
                          <Col xs={12} className="text-center">
                            <Spinner animation="grow" variant="warning" />
                          </Col>
                        </Row>
                      </Card.Body>
                    )}
                  {!listingFeePendingRequestsLoading &&
                    listingFeePendingRequests.results.length == 0 && (
                      <Card.Body>
                        <Row>
                          <Col xs={12}>
                            <p>Requests that are processed will show here.</p>
                          </Col>
                        </Row>
                      </Card.Body>
                    )}
                  {/* {!listingFeePendingRequestsLoading &&
                    listingFeePendingRequests.results.length == 0 &&
                    listingFeeFilteredRequests.results.length == 0 && (
                      <Card.Body>
                        <Row>
                          <Col xs={12}>
                            <p>Requests that are processed will show here.</p>
                          </Col>
                        </Row>
                      </Card.Body>
                    )} */}
                  {!listingFeePendingRequestsLoading &&
                    listingFeePendingRequests.results.length > 0 && (
                      <>
                        <div className="table-responsive">
                          <table className="table card-table table-hover table-outline text-nowrap table-vcenter card-table">
                            <thead>
                              <tr className="text-center">
                                <th>Request ID</th>
                                <th>Vendor Type</th>
                                <th>Vendor Number</th>
                                <th>{getLang('Created')}</th>
                                <th>Salesforce Ticket</th>
                                <th colSpan="1">Request Type</th>
                                <th colSpan="1">Customer Request Type</th>
                                <th>{getLang('Country')}</th>
                                <th> {getLang('Name')} </th>
                                <th>{getLang('M1')}</th>
                                <th>{getLang('M2')}</th>
                                <th>{getLang('Ship to')} ID</th>
                                <th>{getLang('Payer')} ID</th>
                                <th>{getLang('Sold to')} ID</th>
                                <th>{getLang('Bill to')} ID</th>
                                <th>{getLang('A or D Node')}</th>
                                <th>{getLang('Status')}</th>
                              </tr>
                            </thead>
                            <tbody className="text-center">
                              {listingFeePendingSearchTerm.length == 0 &&
                                listingFeePendingRequests.results.map((ticket) => (
                                  <tr key={ticket.Title}>
                                    <td>
                                      <Link
                                        to={{ pathname: `/review/${ticket.Title}` }}
                                        onClick={() =>
                                          handleTicketClick(
                                            ticket.Title,
                                            ticket.Request_Type,
                                            CompletedRequestType,
                                            ticket.CustomerRequest,
                                          )
                                        }
                                      >
                                        {ticket.Title}
                                      </Link>
                                    </td>
                                    <td>{ticket?.vendorType}</td>
                                    <td>{ticket?.vendorNum}</td>
                                    <td>{ticket.Created}</td>
                                    <td>{ticket.SFTicketNo}</td>
                                    <td>{ticket.Request_Type}</td>
                                    <td>{ticket.CustomerRequest}</td>
                                    <td>{ticket.MainCountry}</td>
                                    <td>{ticket.Name}</td>
                                    <td>{ticket.MainRequest1}</td>
                                    <td>{ticket.MainRequest2}</td>
                                    <td>{ticket.Bot_ShipToID}</td>
                                    <td>{ticket.Bot_PayerID}</td>
                                    <td>{ticket.Bot_SoldToID}</td>
                                    <td>
                                      {ticket.Bot_BillToID == null
                                        ? ticket.Bot_PayerID
                                        : ticket.Bot_BillToID}
                                    </td>
                                    <td>{ticket.BotADNode}</td>
                                    <td>
                                      <StatusPill status={'Pending Vendor Creation'} />
                                    </td>
                                  </tr>
                                ))}
                              {listingFeePendingSearchTerm.length > 0 &&
                                listingFeeFilteredRequests.results.map((ticket) => (
                                  <tr key={ticket.Title}>
                                    <td>
                                      <Link
                                        to={{ pathname: `/request/${ticket.Title}` }}
                                        onClick={() =>
                                          handleTicketClick(
                                            ticket.Title,
                                            ticket.Request_Type,
                                            CompletedRequestType,
                                            ticket.CustomerRequest,
                                          )
                                        }
                                      >
                                        {ticket.Title}
                                      </Link>
                                    </td>
                                    <td>{ticket.vendorType}</td>
                                    <td>{ticket.vendorNum}</td>
                                    <td>{ticket.Created}</td>
                                    <td>{ticket.SFTicketNo}</td>
                                    <td>{ticket.Request_Type}</td>
                                    <td>{ticket.CustomerRequest}</td>
                                    <td>{ticket.MainCountry}</td>
                                    <td>{ticket.Name}</td>
                                    <td>{ticket.MainRequest1}</td>
                                    <td>{ticket.MainRequest2}</td>
                                    <td>{ticket.Bot_ShipToID}</td>
                                    <td>{ticket.Bot_PayerID}</td>
                                    <td>{ticket.Bot_SoldToID}</td>
                                    <td>
                                      {ticket.Bot_BillToID == null
                                        ? ticket.Bot_PayerID
                                        : ticket.Bot_BillToID}
                                    </td>
                                    <td>{ticket.BotADNode}</td>
                                    <td>
                                      <StatusPill status={'Pending Vendor Creation'} />
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        <Card.Footer>
                          <Container>
                            <Row>
                              {listingFeePendingSearchTerm.length == 0 && (
                                <>
                                  <div className="col col-md text-left">
                                    {listingFeePendingRequests.page_list ? (
                                      <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                        Page {listingFeePendingRequests.page} of{' '}
                                        {
                                          listingFeePendingRequests.page_list[
                                            listingFeePendingRequests.page_list.length - 1
                                          ]
                                        }
                                      </p>
                                    ) : null}
                                  </div>
                                  <div className="col-auto col-md-auto text-right">
                                    <div className="btn-group" role="group">
                                      <button
                                        type="button"
                                        className="btn btn-warning"
                                        style={{ color: 'black' }}
                                        disabled={listingFeePendingRequests.has_prev ? false : true}
                                        onClick={() =>
                                          getListingFeesPendingRequests(
                                            listingFeePendingRequests.prev_num,
                                          )
                                        }
                                      >
                                        Previous
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-warning"
                                        style={{ color: 'black' }}
                                        disabled={listingFeePendingRequests.has_next ? false : true}
                                        onClick={() =>
                                          getListingFeesPendingRequests(
                                            listingFeePendingRequests.next_num,
                                          )
                                        }
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>
                                </>
                              )}

                              {listingFeePendingSearchTerm.length > 0 && (
                                <>
                                  <div className="col col-md text-left">
                                    {listingFeePendingSearchTerm.page_list ? (
                                      <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                        Page {listingFeePendingSearchTerm.page} of{' '}
                                        {
                                          listingFeePendingSearchTerm.page_list[
                                            listingFeePendingSearchTerm.page_list.length - 1
                                          ]
                                        }
                                      </p>
                                    ) : null}
                                  </div>
                                  <div className="col-auto col-md-auto text-right">
                                    <div className="btn-group" role="group">
                                      <button
                                        type="button"
                                        className="btn btn-warning"
                                        style={{ color: 'black' }}
                                        disabled={
                                          listingFeeFilteredRequests.has_prev ? false : true
                                        }
                                        onClick={() =>
                                          getCompletedFilteredRequests(
                                            listingFeeFilteredRequests.prev_num,
                                          )
                                        }
                                      >
                                        Previous
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-warning"
                                        style={{ color: 'black' }}
                                        disabled={
                                          listingFeeFilteredRequests.has_next ? false : true
                                        }
                                        onClick={() =>
                                          getCompletedFilteredRequests(
                                            listingFeeFilteredRequests.next_num,
                                          )
                                        }
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>
                                </>
                              )}
                            </Row>
                          </Container>
                        </Card.Footer>
                      </>
                    )}
                </Card>
              </Col>
              {/* {!completedRequestsRes.loading && completedRequestsRes.completedRequests.length === 0 && <Col className="col-12">You do not have any completed requests.</Col>} */}
            </Row>
            <Row>
              <Col xs={12}>
                <Card style={{ borderRadius: '18px' }}>
                  <Card.Header
                    style={{
                      background: '#f4e00f',
                      borderTopRightRadius: '18px',
                      borderTopLeftRadius: '18px',
                    }}
                  >
                    <Container>
                      <Row>
                        <Col xs={6} md={7} className="d-flex">
                          <h3
                            className="card-title text-left"
                            style={{
                              marginTop: 'auto',
                              marginBottom: 'auto',
                            }}
                          >
                            <strong>{getLang(`Completed Requests`)}</strong>
                          </h3>
                        </Col>
                        <Col xs={6} md={5}>
                          <div
                            className="wrapper mt-2"
                            style={{
                              background: 'white',
                              borderRadius: '48px',
                              position: 'relative',
                              float: 'right',
                            }}
                          >
                            <div className="input-group">
                              <input
                                className="form-control py-2 border-0"
                                type="search"
                                placeholder="Ticket No./ Name"
                                style={{ borderRadius: '48px' }}
                                onChange={(event) =>
                                  setListingFeeCompletedSearchTerm(event.target.value)
                                }
                              />
                              <span className="input-group-append">
                                <button
                                  className="btn btn-outline-none border-0 bg-white shadow-none"
                                  type="button"
                                  style={{ borderRadius: '48px' }}
                                  onClick={() => setListingFeeCompletedFilteredRequests}
                                >
                                  <FeatherIcon icon="search" size={22} stroke-width={3} />
                                </button>
                              </span>
                            </div>
                          </div>
                        </Col>
                        {/* <Col lg={3} xs={6}>
                      <FormControl
                        as="select"
                        defaultValue={CompletedRequestType}
                        onChange={(e) => setCompletedRequestType(e.target.value)}
                      >
                        <option value="Customer">Customer</option>
                        <option value="Hierarchy">Hierarchy</option>
                        <option value="Plant">Plant</option>
                      </FormControl>
                    </Col> */}
                      </Row>
                    </Container>
                  </Card.Header>
                  {completedListingFeeRequestsLoading &&
                    completedListingFeeRequests.results.length == 0 && (
                      <Card.Body>
                        <Row>
                          <Col xs={12} className="text-center">
                            <Spinner animation="grow" variant="warning" />
                          </Col>
                        </Row>
                      </Card.Body>
                    )}
                  {!completedListingFeeRequestsLoading &&
                    completedListingFeeRequests.results.length == 0 && (
                      <Card.Body>
                        <Row>
                          <Col xs={12}>
                            <p>Requests that are processed will show here.</p>
                          </Col>
                        </Row>
                      </Card.Body>
                    )}
                  {!completedListingFeeRequestsLoading &&
                    completedListingFeeRequests.results.length > 0 && (
                      <>
                        <div className="table-responsive">
                          <table className="table card-table table-hover table-outline text-nowrap table-vcenter card-table">
                            <thead>
                              <tr className="text-center">
                                <th>Request ID</th>
                                <th>Vendor Type</th>
                                <th>Vendor Number</th>
                                <th>Vendor Created</th>
                                <th>{getLang('Created')}</th>
                                <th>Salesforce Ticket</th>
                                <th colSpan="1">Request Type</th>
                                <th colSpan="1">Customer Request Type</th>
                                <th>{getLang('Country')}</th>
                                <th> {getLang('Name')} </th>
                                <th>{getLang('M1')}</th>
                                <th>{getLang('M2')}</th>
                                <th>{getLang('Ship to')} ID</th>
                                <th>{getLang('Payer')} ID</th>
                                <th>{getLang('Sold to')} ID</th>
                                <th>{getLang('Bill to')} ID</th>
                                <th>{getLang('A or D Node')}</th>
                                <th>{getLang('Status')}</th>
                              </tr>
                            </thead>
                            <tbody className="text-center">
                              {listingFeeCompletedSearchTerm.length == 0 &&
                                completedListingFeeRequests.results.map((ticket) => (
                                  <tr key={ticket.Title}>
                                    <td>
                                      <Link
                                        to={{ pathname: `/request/${ticket.Title}` }}
                                        onClick={() =>
                                          handleTicketClick(
                                            ticket.Title,
                                            ticket.Request_Type,
                                            CompletedRequestType,
                                            ticket.CustomerRequest,
                                          )
                                        }
                                      >
                                        {ticket.Title}
                                      </Link>
                                    </td>
                                    <td>{ticket?.vendorType}</td>
                                    <td>{ticket?.vendorNum}</td>
                                    <td>{ticket?.vendorDate}</td>
                                    <td>{ticket.Created}</td>
                                    <td>{ticket.SFTicketNo}</td>
                                    <td>{ticket.Request_Type}</td>
                                    <td>{ticket.CustomerRequest}</td>
                                    <td>{ticket.MainCountry}</td>
                                    <td>{ticket.Name}</td>
                                    <td>{ticket.MainRequest1}</td>
                                    <td>{ticket.MainRequest2}</td>
                                    <td>{ticket.Bot_ShipToID}</td>
                                    <td>{ticket.Bot_PayerID}</td>
                                    <td>{ticket.Bot_SoldToID}</td>
                                    <td>
                                      {ticket.Bot_BillToID == null
                                        ? ticket.Bot_PayerID
                                        : ticket.Bot_BillToID}
                                    </td>
                                    <td>{ticket.BotADNode}</td>
                                    <td>
                                      <StatusPill status={ticket.Status} />
                                    </td>
                                  </tr>
                                ))}
                              {listingFeeCompletedSearchTerm.length > 0 &&
                                listingFeeCompletedFilteredRequests.results.map((ticket) => (
                                  <tr key={ticket.Title}>
                                    <td>
                                      <Link
                                        to={{ pathname: `/request/${ticket.Title}` }}
                                        onClick={() =>
                                          handleTicketClick(
                                            ticket.Title,
                                            ticket.Request_Type,
                                            CompletedRequestType,
                                            ticket.CustomerRequest,
                                          )
                                        }
                                      >
                                        {ticket.Title}
                                      </Link>
                                    </td>
                                    <td>{ticket.vendorType}</td>
                                    <td>{ticket.vendorNum}</td>
                                    <td>{ticket.Created}</td>
                                    <td>{ticket.SFTicketNo}</td>
                                    <td>{ticket.Request_Type}</td>
                                    <td>{ticket.CustomerRequest}</td>
                                    <td>{ticket.MainCountry}</td>
                                    <td>{ticket.Name}</td>
                                    <td>{ticket.MainRequest1}</td>
                                    <td>{ticket.MainRequest2}</td>
                                    <td>{ticket.Bot_ShipToID}</td>
                                    <td>{ticket.Bot_PayerID}</td>
                                    <td>{ticket.Bot_SoldToID}</td>
                                    <td>
                                      {ticket.Bot_BillToID == null
                                        ? ticket.Bot_PayerID
                                        : ticket.Bot_BillToID}
                                    </td>
                                    <td>{ticket.BotADNode}</td>
                                    <td>
                                      <StatusPill status={ticket.Status} />
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        <Card.Footer>
                          <Container>
                            <Row>
                              {listingFeeCompletedSearchTerm.length == 0 && (
                                <>
                                  <div className="col col-md text-left">
                                    {completedListingFeeRequests.page_list ? (
                                      <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                        Page {completedListingFeeRequests.page} of{' '}
                                        {
                                          completedListingFeeRequests.page_list[
                                            completedListingFeeRequests.page_list.length - 1
                                          ]
                                        }
                                      </p>
                                    ) : null}
                                  </div>
                                  <div className="col-auto col-md-auto text-right">
                                    <div className="btn-group" role="group">
                                      <button
                                        type="button"
                                        className="btn btn-warning"
                                        style={{ color: 'black' }}
                                        disabled={
                                          completedListingFeeRequests.has_prev ? false : true
                                        }
                                        onClick={() =>
                                          getListingFeesCompletedRequests(
                                            completedListingFeeRequests.prev_num,
                                          )
                                        }
                                      >
                                        Previous
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-warning"
                                        style={{ color: 'black' }}
                                        disabled={
                                          completedListingFeeRequests.has_next ? false : true
                                        }
                                        onClick={() =>
                                          getListingFeesCompletedRequests(
                                            completedListingFeeRequests.next_num,
                                          )
                                        }
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>
                                </>
                              )}

                              {listingFeeCompletedSearchTerm.length > 0 && (
                                <>
                                  <div className="col col-md text-left">
                                    {listingFeeCompletedFilteredRequests.page_list ? (
                                      <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                        Page {listingFeeCompletedFilteredRequests.page} of{' '}
                                        {
                                          listingFeeCompletedFilteredRequests.page_list[
                                            listingFeeCompletedFilteredRequests.page_list.length - 1
                                          ]
                                        }
                                      </p>
                                    ) : null}
                                  </div>
                                  <div className="col-auto col-md-auto text-right">
                                    <div className="btn-group" role="group">
                                      <button
                                        type="button"
                                        className="btn btn-warning"
                                        style={{ color: 'black' }}
                                        disabled={
                                          listingFeeCompletedFilteredRequests.has_prev
                                            ? false
                                            : true
                                        }
                                        onClick={() =>
                                          getCompletedFilteredRequests(
                                            listingFeeCompletedFilteredRequests.prev_num,
                                          )
                                        }
                                      >
                                        Previous
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-warning"
                                        style={{ color: 'black' }}
                                        disabled={
                                          listingFeeFilteredRequests.has_next ? false : true
                                        }
                                        onClick={() =>
                                          getCompletedFilteredRequests(
                                            listingFeeCompletedFilteredRequests.next_num,
                                          )
                                        }
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>
                                </>
                              )}
                            </Row>
                          </Container>
                        </Card.Footer>
                      </>
                    )}
                </Card>
              </Col>
              {/* {!completedRequestsRes.loading && completedRequestsRes.completedRequests.length === 0 && <Col className="col-12">You do not have any completed requests.</Col>} */}
            </Row>
          </>
        ) : (
          <>
            {/* <Container className="text-left"> */}
            <Modal show={showDeleteDraftModal} centered>
              <Modal.Header>
                <Modal.Title>Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>{`Are you sure you want to delete the draft ${draftToDelete}?`}</p>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={() => setShowDeleteDraftModal(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => deleteDraft(draftToDelete)}
                >
                  Delete
                </button>
              </Modal.Footer>
            </Modal>
            <Row>
              {/* {draftRequestsRes.loading && draftRequestsRes.draftRequests.length === 0 && (
            <Col className="col-12">
              <Spinner animation="grow" variant="warning" />
            </Col>
          )} */}

              <Col xs={12}>
                <Card style={{ borderRadius: '18px' }}>
                  <Card.Header
                    style={{
                      background: '#f4e00f',
                      borderTopRightRadius: '18px',
                      borderTopLeftRadius: '18px',
                    }}
                  >
                    <Container>
                      <Row>
                        <Col xs={6} md={7} className="d-flex">
                          <h3
                            // className="card-title text-left text-warning"
                            className="card-title text-left text-dark"
                            style={{
                              marginTop: 'auto',
                              marginBottom: 'auto',
                            }}
                          >
                            <strong>{getLang(`Drafts`)}</strong>
                          </h3>
                        </Col>
                        {/* <Col lg={3} xs={6}>
                      <FormControl
                        as="select"
                        defaultValue={DraftRequestType}
                        onChange={(e) => setDraftRequestType(e.target.value)}
                      >
                        <option value="Customer">Customer</option>
                        <option value="Hierarchy">Hierarchy</option>
                        <option value="Plant">Plant</option>
                      </FormControl>
                    </Col> */}
                        <Col xs={6} md={5}>
                          <div
                            className="wrapper mt-2 "
                            style={{
                              background: 'white',
                              borderRadius: '48px',
                              position: 'relative',
                              float: 'right',
                            }}
                          >
                            <div className="input-group">
                              <input
                                className="form-control py-2 border-0"
                                type="search"
                                placeholder="Ticket No./ Name"
                                // style={style}
                                style={{ borderRadius: '48px' }}
                                onChange={(event) => setDraftsearchTerm(event.target.value)}
                              />
                              <span className="input-group-append">
                                <button
                                  className="btn border-0 border bg-white"
                                  type="button"
                                  style={{ borderRadius: '48px' }}
                                  // style={style}
                                  onClick={() => setDraftFilteredRequests}
                                >
                                  <FeatherIcon icon="search" size={22} stroke-width={3} />
                                </button>
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Header>
                  {draftRequestsLoading && draftRequests.results?.length == 0 && (
                    <Card.Body>
                      <Row>
                        <Col xs={12} className="text-center">
                          <Spinner animation="grow" variant="warning" />
                        </Col>
                      </Row>
                    </Card.Body>
                  )}
                  {!draftRequestsLoading && draftRequests.results?.length == 0 && (
                    <Card.Body>
                      <Row>
                        <Col xs={12}>
                          <p>
                            You do not have any saved drafts. Head to the{' '}
                            <strong>Create section</strong> to begin creating a request.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  )}
                  {!draftRequestsLoading && draftRequests.results?.length > 0 && (
                    <>
                      <div className="table-responsive">
                        <table className="table card-table table-hover table-outline text-nowrap table-vcenter card-table">
                          <thead>
                            <tr className="text-center">
                              <th>Request ID</th>
                              <th>{getLang('Created')}</th>
                              <th>{getLang('Request Type')}</th>
                              <th>{getLang('Customer Request Type')}</th>
                              <th>{getLang('Country')}</th>
                              <th>{getLang('Name')}</th>
                              {/* <th>Date Modified</th> */}
                              <th></th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {draftSearchTerm.length == 0 &&
                              draftRequests.results.map((ticket) => (
                                <tr key={ticket.Title}>
                                  <td>
                                    <Link
                                      to={{ pathname: `/request/${ticket.Title}` }}
                                      onClick={() => {
                                        handleTicketClick(
                                          ticket.Title,
                                          ticket.Request_Type,
                                          DraftRequestType,
                                          ticket.CustomerRequest,
                                        );
                                      }}
                                    >
                                      {ticket.Title}
                                    </Link>
                                  </td>
                                  <td>{ticket.Created}</td>
                                  <td>{ticket.Request_Type} </td>
                                  <td>{ticket.CustomerRequest} </td>

                                  <td>{ticket.MainCountry}</td>
                                  <td>{ticket.Name}</td>

                                  {/* <td>{ticket.ShipTo_Name}</td> */}

                                  {/* <td className="text-nowrap">{ticket?.Created}</td> */}
                                  <td>
                                    {/* onClick={() => { alert(`Deleting: ${ticket.Title}`) }} */}
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        setDraftToDelete(ticket.Title);
                                        setShowDeleteDraftModal(true);
                                      }}
                                    >
                                      <FeatherIcon icon="trash-2" size={14} />
                                    </Link>
                                  </td>
                                </tr>
                              ))}

                            {draftSearchTerm.length > 0 &&
                              draftFilteredRequests.results.map((ticket) => (
                                <tr key={ticket.Title}>
                                  <td>
                                    <Link
                                      to={{ pathname: `/request/${ticket.Title}` }}
                                      onClick={() => {
                                        handleTicketClick(
                                          ticket.Title,
                                          ticket.Request_Type,
                                          DraftRequestType,
                                          ticket.CustomerRequest,
                                        );
                                      }}
                                    >
                                      {ticket.Title}
                                    </Link>
                                  </td>
                                  <td>{ticket.Created}</td>
                                  <td>{ticket.Request_Type} </td>
                                  <td>{ticket.CustomerRequest} </td>

                                  <td>{ticket.MainCountry}</td>
                                  <td>{ticket.Name}</td>

                                  {/* <td>{ticket.ShipTo_Name}</td> */}

                                  {/* <td className="text-nowrap">{ticket?.Created}</td> */}
                                  <td>
                                    {/* onClick={() => { alert(`Deleting: ${ticket.Title}`) }} */}
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        setDraftToDelete(ticket.Title);
                                        setShowDeleteDraftModal(true);
                                      }}
                                    >
                                      <FeatherIcon icon="trash-2" size={14} />
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      <Card.Footer>
                        <Container>
                          <Row>
                            {draftSearchTerm.length == 0 && (
                              <>
                                <div className="col col-md text-left">
                                  {draftRequests.page_list ? (
                                    <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                      Page {draftRequests.page} of{' '}
                                      {draftRequests.page_list[draftRequests.page_list.length - 1]}
                                    </p>
                                  ) : null}
                                </div>

                                <div className="col-auto col-md-auto text-right">
                                  <div className="btn-group" role="group">
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={draftRequests.has_prev ? false : true}
                                      onClick={() => getDraftRequests(draftRequests.prev_num)}
                                    >
                                      Previous
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={draftRequests.has_next ? false : true}
                                      onClick={() => getDraftRequests(draftRequests.next_num)}
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                            {draftSearchTerm.length > 0 && (
                              <>
                                <div className="col col-md text-left">
                                  {draftFilteredRequests.page_list ? (
                                    <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                      Page {draftFilteredRequests.page} of{' '}
                                      {
                                        draftFilteredRequests.page_list[
                                          draftFilteredRequests.page_list.length - 1
                                        ]
                                      }
                                    </p>
                                  ) : null}
                                </div>

                                <div className="col-auto col-md-auto text-right">
                                  <div className="btn-group" role="group">
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={draftFilteredRequests.has_prev ? false : true}
                                      onClick={() =>
                                        getDraftFilteredRequests(draftFilteredRequests.prev_num)
                                      }
                                    >
                                      Previous
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={draftFilteredRequests.has_next ? false : true}
                                      onClick={() =>
                                        getDraftFilteredRequests(draftFilteredRequests.next_num)
                                      }
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                          </Row>
                        </Container>
                      </Card.Footer>
                    </>
                  )}
                </Card>
              </Col>

              {/* {!draftRequestsRes.loading && draftRequestsRes.draftRequests.length === 0 && (
            <Col className="col-12">
              You do not have any request drafts. Head to the <strong>Create section</strong> to begin creating a request.
            </Col>
          )} */}
            </Row>

            {/* <hr /> */}

            <Row>
              <Col xs={12}>
                <Card style={{ borderRadius: '18px' }}>
                  <Card.Header
                    style={{
                      background: '#f4e00f',
                      borderTopRightRadius: '18px',
                      borderTopLeftRadius: '18px',
                    }}
                  >
                    {/* //style={{background: 'linear-gradient(90deg,#787874 50%,#242322 100%)'}}> */}
                    <Container>
                      <Row>
                        <Col xs={6} md={7} className="d-flex">
                          <h3
                            className="card-title text-left"
                            style={{
                              marginTop: 'auto',
                              marginBottom: 'auto',
                              // text-shadow:
                            }}
                          >
                            <strong>{getLang(`Submitted Requests`)}</strong>
                          </h3>
                        </Col>
                        <Col xs={6} md={5}>
                          <div
                            className="wrapper mt-2"
                            style={{
                              background: 'white',
                              borderRadius: '48px',
                              position: 'relative',
                              float: 'right',
                            }}
                          >
                            <div className="input-group">
                              <input
                                className="form-control py-2 border-0 "
                                type="search"
                                style={{
                                  borderRadius: '48px',
                                  borderWidth: hasFocus ? '0' : '0',
                                  // background: hasFocus ? 'red' : 'blue',
                                }}
                                onFocus={() => setonFocus(true)}
                                placeholder="Ticket No./ Name"
                                onChange={(event) => setSubmittedsearchTerm(event.target.value)}
                              />
                              <span className="input-group-append">
                                <button
                                  className="btn btn-outline-none border-0 bg-white shadow-none"
                                  type="button"
                                  style={{ borderRadius: '48px' }}
                                  onClick={() => setSubmittedFilteredRequests}
                                >
                                  <FeatherIcon icon="search" size={22} stroke-width={3} />
                                </button>
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Header>
                  {submittedRequestsLoading && submittedRequests.results.length == 0 && (
                    <Card.Body>
                      <Row>
                        <Col xs={12} className="text-center">
                          <Spinner animation="grow" variant="warning" />
                        </Col>
                      </Row>
                    </Card.Body>
                  )}
                  {!submittedRequestsLoading && submittedRequests.results.length == 0 && (
                    <Card.Body>
                      <Row>
                        <Col xs={12}>
                          <p>
                            You do not have any submitted requests. Head to the{' '}
                            <strong>Create section</strong> to submit a request.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  )}
                  {!submittedRequestsLoading && submittedRequests.results.length > 0 && (
                    <>
                      <div className="table-responsive">
                        <table className="table card-table table-hover table-outline text-nowrap table-vcenter card-table">
                          <thead>
                            <tr className="text-center">
                              <th>Request ID</th>
                              <th>Created</th>
                              <th colSpan="1">Request Type</th>
                              <th colSpan="1">Customer Request Type</th>
                              <th>{getLang('Country')}</th>
                              <th>{getLang('Name')}</th>
                              <th>Approver</th>
                              <th>Ticket With Approver</th>
                              <th>Total Age of Ticket</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {submittedSearchTerm.length == 0 &&
                              submittedRequests.results.map((ticket) => (
                                <tr key={ticket.Title}>
                                  <td>
                                    <Link
                                      to={{ pathname: `/request/${ticket.Title}` }}
                                      onClick={() =>
                                        handleTicketClick(
                                          ticket.Title,
                                          ticket.Request_Type,
                                          // ticket.CustomerRequest,
                                          SubmittedRequestType,
                                          ticket.CustomerRequest,
                                        )
                                      }
                                    >
                                      {ticket.Title}
                                    </Link>
                                  </td>
                                  <td className="text-nowrap">{ticket.Created}</td>

                                  <td>{ticket.Request_Type}</td>
                                  <td>{ticket.CustomerRequest}</td>
                                  <td>{ticket.MainCountry}</td>

                                  <td>{ticket.Name} </td>
                                  <>
                                    {ticket.Final_Status == 'In Progress' ? (
                                      <td>
                                        <a
                                          style={{ display: 'table-cell' }}
                                          href="/contactus"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          https://maven.ab-inbev.com/contactus
                                        </a>
                                      </td>
                                    ) : ticket.Approver.approver ==
                                      'Belinda.kemper@interbrew.net' ? (
                                      <td>marieke.vankwawegen@interbrew.net</td>
                                    ) : (
                                      <td>{ticket.Approver.approver}</td>
                                    )}
                                  </>
                                  <td>{ticket.Age} days</td>
                                  <td>{ticket.Total_Age} days</td>
                                  <td>
                                    <StatusPill status={ticket.Final_Status} />
                                  </td>
                                </tr>
                              ))}

                            {submittedSearchTerm.length > 0 &&
                              submittedFilteredRequests.results.map((ticket) => (
                                <tr key={ticket.Title}>
                                  <td>
                                    <Link
                                      to={{ pathname: `/request/${ticket.Title}` }}
                                      onClick={() =>
                                        handleTicketClick(
                                          ticket.Title,
                                          ticket.Request_Type,
                                          // ticket.CustomerRequest,
                                          SubmittedRequestType,
                                          ticket.CustomerRequest,
                                        )
                                      }
                                    >
                                      {ticket.Title}
                                    </Link>
                                  </td>
                                  <td className="text-nowrap">{ticket.Created}</td>

                                  <td>{ticket.Request_Type}</td>
                                  <td>{ticket.CustomerRequest}</td>
                                  <td>{ticket.MainCountry}</td>

                                  <td>{ticket.Name} </td>
                                  <>
                                    {ticket.Final_Status == 'In Progress' && (
                                      <td>
                                        <a
                                          style={{ display: 'table-cell' }}
                                          href="/contactus"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          https://maven.ab-inbev.com/contactus
                                        </a>
                                      </td>
                                    )}
                                    {ticket.Approver != '' && <td>{ticket.Approver.approver}</td>}
                                  </>
                                  {/* <td>Approver</td> */}
                                  <td>{ticket.Age} days</td>
                                  <td>{ticket.Total_Age} days</td>

                                  <td>
                                    <StatusPill status={ticket.Final_Status} />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      <Card.Footer>
                        <Container>
                          <Row>
                            {submittedSearchTerm.length == 0 && (
                              <>
                                <div className="col col-md text-left">
                                  {submittedRequests.page_list ? (
                                    <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                      Page {submittedRequests.page} of{' '}
                                      {
                                        submittedRequests.page_list[
                                          submittedRequests.page_list.length - 1
                                        ]
                                      }
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-auto col-md-auto text-right">
                                  <div className="btn-group" role="group">
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={submittedRequests.has_prev ? false : true}
                                      onClick={() =>
                                        getSubmittedRequests(submittedRequests.prev_num)
                                      }
                                    >
                                      Previous
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={submittedRequests.has_next ? false : true}
                                      onClick={() =>
                                        getSubmittedRequests(submittedRequests.next_num)
                                      }
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}

                            {submittedSearchTerm.length > 0 && (
                              <>
                                <div className="col col-md text-left">
                                  {submittedFilteredRequests.page_list ? (
                                    <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                      Page {submittedFilteredRequests.page} of{' '}
                                      {
                                        submittedFilteredRequests.page_list[
                                          submittedFilteredRequests.page_list.length - 1
                                        ]
                                      }
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-auto col-md-auto text-right">
                                  <div className="btn-group" role="group">
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={submittedFilteredRequests.has_prev ? false : true}
                                      onClick={() =>
                                        getSubmittedFilteredRequests(
                                          submittedFilteredRequests.prev_num,
                                        )
                                      }
                                    >
                                      Previous
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={submittedFilteredRequests.has_next ? false : true}
                                      onClick={() =>
                                        getSubmittedFilteredRequests(
                                          submittedFilteredRequests.next_num,
                                        )
                                      }
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                          </Row>
                        </Container>
                      </Card.Footer>
                    </>
                  )}
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Card style={{ borderRadius: '18px' }}>
                  <Card.Header
                    style={{
                      background: '#f4e00f',
                      borderTopRightRadius: '18px',
                      borderTopLeftRadius: '18px',
                    }}
                  >
                    <Container>
                      <Row>
                        <Col xs={6} md={7} className="d-flex">
                          <h3
                            className="card-title text-left"
                            style={{
                              marginTop: 'auto',
                              marginBottom: 'auto',
                            }}
                          >
                            <strong>{getLang(`Rejected Requests`)}</strong>
                          </h3>
                        </Col>
                        <Col xs={6} md={5}>
                          <div
                            className="wrapper mt-2"
                            style={{
                              background: 'white',
                              borderRadius: '48px',
                              position: 'relative',
                              float: 'right',
                            }}
                          >
                            <div className="input-group">
                              <input
                                className="form-control py-2 border-0"
                                type="search"
                                style={{ borderRadius: '48px' }}
                                placeholder="Ticket No./ Name"
                                onChange={(event) => setRejectedsearchTerm(event.target.value)}
                              />
                              <span className="input-group-append">
                                <button
                                  className="btn btn-outline-none border-0 bg-white shadow-none"
                                  type="button"
                                  style={{ borderRadius: '48px' }}
                                  onClick={() => setRejectedFilteredRequests}
                                >
                                  <FeatherIcon icon="search" size={22} strokeWidth={3} />
                                </button>
                              </span>
                            </div>
                          </div>
                        </Col>
                        {/* <Col lg={3} xs={6}>
                      <FormControl
                        as="select"
                        defaultValue={RejectedRequestType}
                        onChange={(e) => setRejectedRequestType(e.target.value)}
                      >
                        <option value="Customer">Customer</option>
                        <option value="Hierarchy">Hierarchy</option>
                        <option value="Plant">Plant</option>
                      </FormControl>
                    </Col> */}
                      </Row>
                    </Container>
                  </Card.Header>
                  {rejectedRequestsLoading && rejectedRequests.results.length == 0 && (
                    <Card.Body>
                      <Row>
                        <Col xs={12} className="text-center">
                          <Spinner animation="grow" variant="warning" />
                        </Col>
                      </Row>
                    </Card.Body>
                  )}
                  {!rejectedRequestsLoading && rejectedRequests.results.length == 0 && (
                    <Card.Body>
                      <Row>
                        <Col xs={12}>
                          <p>You do not have any rejected requests.</p>
                        </Col>
                      </Row>
                    </Card.Body>
                  )}
                  {!rejectedRequestsLoading && rejectedRequests.results.length > 0 && (
                    <>
                      <div className="table-responsive">
                        <table className="table card-table table-hover table-outline text-nowrap table-vcenter card-table">
                          <thead>
                            <tr className="text-center">
                              <th>Request ID</th>
                              <th>Created</th>
                              <th>Request Type</th>
                              <th>Customer Request Type</th>
                              <th>{getLang('Country')}</th>
                              <th>Name</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {rejectedSearchTerm.length == 0 &&
                              rejectedRequests.results.map((ticket) => (
                                <tr key={ticket.Title}>
                                  <td>
                                    <Link
                                      to={{ pathname: `/request/${ticket.Title}` }}
                                      onClick={() =>
                                        handleTicketClick(
                                          ticket.Title,
                                          ticket.Request_Type,

                                          RejectedRequestType,
                                          ticket.CustomerRequest,
                                        )
                                      }
                                    >
                                      {ticket.Title}
                                    </Link>
                                  </td>
                                  <td className="text-nowrap">{ticket.Created}</td>
                                  <td>{ticket.Request_Type}</td>
                                  <td>{ticket.CustomerRequest}</td>
                                  <td>{ticket.MainCountry}</td>

                                  <td>{ticket.Name} </td>
                                  <td>
                                    <StatusPill status={ticket.Status} />
                                  </td>
                                </tr>
                              ))}

                            {rejectedSearchTerm.length > 0 &&
                              rejectedFilteredRequests.results.map((ticket) => (
                                <tr key={ticket.Title}>
                                  <td>
                                    <Link
                                      to={{ pathname: `/request/${ticket.Title}` }}
                                      onClick={() =>
                                        handleTicketClick(
                                          ticket.Title,
                                          ticket.Request_Type,

                                          RejectedRequestType,
                                          ticket.CustomerRequest,
                                        )
                                      }
                                    >
                                      {ticket.Title}
                                    </Link>
                                  </td>
                                  <td className="text-nowrap">{ticket.Created}</td>
                                  <td>{ticket.Request_Type}</td>
                                  <td>{ticket.CustomerRequest}</td>
                                  <td>{ticket.MainCountry}</td>

                                  <td>{ticket.Name} </td>
                                  <td>
                                    <StatusPill status={ticket.Status} />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      <Card.Footer>
                        <Container>
                          <Row>
                            {rejectedSearchTerm.length == 0 && (
                              <>
                                <div className="col col-md text-left">
                                  {rejectedRequests.page_list ? (
                                    <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                      Page {rejectedRequests.page} of{' '}
                                      {
                                        rejectedRequests.page_list[
                                          rejectedRequests.page_list.length - 1
                                        ]
                                      }
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-auto col-md-auto text-right">
                                  <div className="btn-group" role="group">
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={rejectedRequests.has_prev ? false : true}
                                      onClick={() => getRejectedRequests(rejectedRequests.prev_num)}
                                    >
                                      Previous
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={rejectedRequests.has_next ? false : true}
                                      onClick={() => getRejectedRequests(rejectedRequests.next_num)}
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                            {rejectedSearchTerm.length > 0 && (
                              <>
                                <div className="col col-md text-left">
                                  {rejectedFilteredRequests.page_list ? (
                                    <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                      Page {rejectedFilteredRequests.page} of{' '}
                                      {
                                        rejectedFilteredRequests.page_list[
                                          rejectedFilteredRequests.page_list.length - 1
                                        ]
                                      }
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-auto col-md-auto text-right">
                                  <div className="btn-group" role="group">
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={rejectedFilteredRequests.has_prev ? false : true}
                                      onClick={() =>
                                        getRejectedFilteredRequests(
                                          rejectedFilteredRequests.prev_num,
                                        )
                                      }
                                    >
                                      Previous
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={rejectedFilteredRequests.has_next ? false : true}
                                      onClick={() =>
                                        getRejectedFilteredRequests(
                                          rejectedFilteredRequests.next_num,
                                        )
                                      }
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                          </Row>
                        </Container>
                      </Card.Footer>
                    </>
                  )}
                </Card>
              </Col>
            </Row>

            {/* <hr /> */}

            <Row>
              <Col xs={12}>
                <Card style={{ borderRadius: '18px' }}>
                  <Card.Header
                    style={{
                      background: '#f4e00f',
                      borderTopRightRadius: '18px',
                      borderTopLeftRadius: '18px',
                    }}
                  >
                    <Container>
                      <Row>
                        <Col xs={6} md={7} className="d-flex">
                          <h3
                            className="card-title text-left"
                            style={{
                              marginTop: 'auto',
                              marginBottom: 'auto',
                            }}
                          >
                            <strong>{getLang(`Duplicate Requests`)}</strong>
                          </h3>
                        </Col>
                        <Col xs={6} md={5}>
                          <div
                            className="wrapper mt-2"
                            style={{
                              background: 'white',
                              borderRadius: '48px',
                              position: 'relative',
                              float: 'right',
                            }}
                          >
                            <div className="input-group">
                              <input
                                className="form-control py-2 border-0"
                                type="search"
                                placeholder="Ticket No./ Name"
                                style={{ borderRadius: '48px' }}
                                onChange={(event) => setDuplicatesearchTerm(event.target.value)}
                              />
                              <span className="input-group-append">
                                <button
                                  className="btn btn-outline-none border-0 bg-white shadow-none"
                                  type="button"
                                  style={{ borderRadius: '48px' }}
                                  onClick={() => setDuplicateFilteredRequests}
                                >
                                  <FeatherIcon icon="search" size={22} stroke-width={3} />
                                </button>
                              </span>
                            </div>
                          </div>
                        </Col>
                        {/* <Col lg={3} xs={6}>
                      <FormControl
                        as="select"
                        defaultValue={DuplicateRequestType}
                        onChange={(e) => setDuplicateRequestType(e.target.value)}
                      >
                        <option value="Customer">Customer</option>
                        <option value="Hierarchy">Hierarchy</option>
                        <option value="Plant">Plant</option>
                      </FormControl>
                    </Col> */}
                      </Row>
                    </Container>
                  </Card.Header>
                  {duplicateRequestsLoading && duplicateRequests.results.length == 0 && (
                    <Card.Body>
                      <Row>
                        <Col xs={12} className="text-center">
                          <Spinner animation="grow" variant="warning" />
                        </Col>
                      </Row>
                    </Card.Body>
                  )}
                  {!duplicateRequestsLoading && duplicateRequests.results.length == 0 && (
                    <Card.Body>
                      <Row>
                        <Col xs={12}>
                          <p>{getLang('You do not have any duplicate requests.')}</p>
                        </Col>
                      </Row>
                    </Card.Body>
                  )}
                  {!duplicateRequestsLoading && duplicateRequests.results.length > 0 && (
                    <>
                      <div className="table-responsive">
                        <table className="table card-table table-hover table-outline text-nowrap table-vcenter card-table">
                          <thead>
                            <tr className="text-center">
                              {/* <th></th> */}
                              <th>Request ID</th>
                              <th>Created</th>
                              <th>Request Type</th>
                              <th>Customer Request Type</th>
                              <th>{getLang('Country')}</th>
                              <th>{getLang('Name')}</th>

                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {duplicateSearchTerm.length == 0 &&
                              duplicateRequests.results.map((ticket) => (
                                <tr key={ticket.Title}>
                                  <td>
                                    <Link
                                      to={{ pathname: `/request/${ticket.Title}` }}
                                      onClick={() =>
                                        handleTicketClick(
                                          ticket.Title,
                                          ticket.Request_Type,
                                          // ticket.CustomerRequest,
                                          DuplicateRequestType,
                                          ticket.CustomerRequest,
                                        )
                                      }
                                    >
                                      {ticket.Title}
                                    </Link>
                                  </td>
                                  <td className="text-nowrap">{ticket.Created}</td>
                                  <td>{ticket.Request_Type}</td>
                                  <td>{ticket.CustomerRequest}</td>
                                  <td>{ticket.MainCountry}</td>

                                  <td>{ticket.Name} </td>
                                  <td>
                                    <StatusPill status={ticket.Status} />
                                  </td>
                                </tr>
                              ))}
                            {duplicateSearchTerm.length > 0 &&
                              duplicateFilteredRequests.results.map((ticket) => (
                                <tr key={ticket.Title}>
                                  <td>
                                    <Link
                                      to={{ pathname: `/request/${ticket.Title}` }}
                                      onClick={() =>
                                        handleTicketClick(
                                          ticket.Title,
                                          ticket.Request_Type,
                                          // ticket.CustomerRequest,
                                          DuplicateRequestType,
                                          ticket.CustomerRequest,
                                        )
                                      }
                                    >
                                      {ticket.Title}
                                    </Link>
                                  </td>
                                  <td className="text-nowrap">{ticket.Created}</td>
                                  <td>{ticket.Request_Type}</td>
                                  <td>{ticket.CustomerRequest}</td>
                                  <td>{ticket.MainCountry}</td>

                                  <td>{ticket.Name} </td>
                                  <td>
                                    <StatusPill status={ticket.Status} />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      <Card.Footer>
                        <Container>
                          <Row>
                            {duplicateSearchTerm.length == 0 && (
                              <>
                                <div className="col col-md text-left">
                                  {duplicateRequests.page_list ? (
                                    <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                      Page {duplicateRequests.page} of{' '}
                                      {
                                        duplicateRequests.page_list[
                                          duplicateRequests.page_list.length - 1
                                        ]
                                      }
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-auto col-md-auto text-right">
                                  <div className="btn-group" role="group">
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={duplicateRequests.has_prev ? false : true}
                                      onClick={() =>
                                        getDuplicateRequests(duplicateRequests.prev_num)
                                      }
                                    >
                                      Previous
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={duplicateRequests.has_next ? false : true}
                                      onClick={() =>
                                        getDuplicateRequests(duplicateRequests.next_num)
                                      }
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}

                            {duplicateSearchTerm.length > 0 && (
                              <>
                                <div className="col col-md text-left">
                                  {duplicateFilteredRequests.page_list ? (
                                    <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                      Page {duplicateFilteredRequests.page} of{' '}
                                      {
                                        duplicateFilteredRequests.page_list[
                                          duplicateFilteredRequests.page_list.length - 1
                                        ]
                                      }
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-auto col-md-auto text-right">
                                  <div className="btn-group" role="group">
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={duplicateFilteredRequests.has_prev ? false : true}
                                      onClick={() =>
                                        getDuplicateFilteredRequests(
                                          duplicateFilteredRequests.prev_num,
                                        )
                                      }
                                    >
                                      Previous
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={duplicateRequests.has_next ? false : true}
                                      onClick={() =>
                                        getDuplicateFilteredRequests(
                                          duplicateFilteredRequests.next_num,
                                        )
                                      }
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                          </Row>
                        </Container>
                      </Card.Footer>
                    </>
                  )}
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Card style={{ borderRadius: '18px' }}>
                  <Card.Header
                    style={{
                      background: '#f4e00f',
                      borderTopRightRadius: '18px',
                      borderTopLeftRadius: '18px',
                    }}
                  >
                    <Container>
                      <Row>
                        <Col xs={6} md={7} className="d-flex">
                          <h3
                            className="card-title text-left"
                            style={{
                              marginTop: 'auto',
                              marginBottom: 'auto',
                            }}
                          >
                            <strong>{getLang(`Completed Requests`)}</strong>
                          </h3>
                        </Col>
                        <Col xs={6} md={5}>
                          <div
                            className="wrapper mt-2"
                            style={{
                              background: 'white',
                              borderRadius: '48px',
                              position: 'relative',
                              float: 'right',
                            }}
                          >
                            <div className="input-group">
                              <input
                                className="form-control py-2 border-0"
                                type="search"
                                placeholder="Ticket No./ Name"
                                style={{ borderRadius: '48px' }}
                                onChange={(event) => setCompletedsearchTerm(event.target.value)}
                              />
                              <span className="input-group-append">
                                <button
                                  className="btn btn-outline-none border-0 bg-white shadow-none"
                                  type="button"
                                  style={{ borderRadius: '48px' }}
                                  onClick={() => setCompletedFilteredRequests}
                                >
                                  <FeatherIcon icon="search" size={22} stroke-width={3} />
                                </button>
                              </span>
                            </div>
                          </div>
                        </Col>
                        {/* <Col lg={3} xs={6}>
                      <FormControl
                        as="select"
                        defaultValue={CompletedRequestType}
                        onChange={(e) => setCompletedRequestType(e.target.value)}
                      >
                        <option value="Customer">Customer</option>
                        <option value="Hierarchy">Hierarchy</option>
                        <option value="Plant">Plant</option>
                      </FormControl>
                    </Col> */}
                      </Row>
                    </Container>
                  </Card.Header>
                  {completedRequestsLoading && completedRequests.results.length == 0 && (
                    <Card.Body>
                      <Row>
                        <Col xs={12} className="text-center">
                          <Spinner animation="grow" variant="warning" />
                        </Col>
                      </Row>
                    </Card.Body>
                  )}
                  {!completedRequestsLoading && completedRequests.results.length == 0 && (
                    <Card.Body>
                      <Row>
                        <Col xs={12}>
                          <p>Requests that are processed will show here.</p>
                        </Col>
                      </Row>
                    </Card.Body>
                  )}
                  {!completedRequestsLoading && completedRequests.results.length > 0 && (
                    <>
                      <div className="table-responsive">
                        <table className="table card-table table-hover table-outline text-nowrap table-vcenter card-table">
                          <thead>
                            <tr className="text-center">
                              <th>Request ID</th>
                              <th>{getLang('Created')}</th>
                              <th>Salesforce Ticket</th>
                              <th colSpan="1">Request Type</th>
                              <th colSpan="1">Customer Request Type</th>
                              <th>{getLang('Country')}</th>
                              <th> {getLang('Name')} </th>
                              <th>{getLang('M1')}</th>
                              <th>{getLang('M2')}</th>
                              <th>{getLang('Ship to')} ID</th>
                              <th>{getLang('Payer')} ID</th>
                              <th>{getLang('Sold to')} ID</th>
                              <th>{getLang('Bill to')} ID</th>
                              <th>{getLang('A or D Node')}</th>
                              <th>Vendor Type</th>
                              <th>Vendor Num</th>
                              <th>{getLang('Status')}</th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {completedSearchTerm.length == 0 &&
                              completedRequests.results.map((ticket) => (
                                <tr key={ticket.Title}>
                                  <td>
                                    <Link
                                      to={{ pathname: `/request/${ticket.Title}` }}
                                      onClick={() =>
                                        handleTicketClick(
                                          ticket.Title,
                                          ticket.Request_Type,
                                          CompletedRequestType,
                                          ticket.CustomerRequest,
                                        )
                                      }
                                    >
                                      {ticket.Title}
                                    </Link>
                                  </td>
                                  <td>{ticket.Created}</td>
                                  <td>{ticket.SFTicketNo}</td>
                                  <td>{ticket.Request_Type}</td>
                                  <td>{ticket.CustomerRequest}</td>
                                  <td>{ticket.MainCountry}</td>
                                  <td>{ticket.Name}</td>
                                  <td>{ticket.MainRequest1}</td>
                                  <td>{ticket.MainRequest2}</td>
                                  <td>{ticket.Bot_ShipToID}</td>
                                  <td>{ticket.Bot_PayerID}</td>
                                  <td>{ticket.Bot_SoldToID}</td>
                                  <td>
                                    {ticket.Bot_BillToID == null
                                      ? ticket.Bot_PayerID
                                      : ticket.Bot_BillToID}
                                  </td>
                                  <td>{ticket.BotADNode}</td>
                                  <td>{ticket?.vendorType}</td>
                                  <td>{ticket?.vendorNum}</td>
                                  <td>
                                    <StatusPill status={ticket.Status} />
                                  </td>
                                </tr>
                              ))}
                            {completedSearchTerm.length > 0 &&
                              completedFilteredRequests.results.map((ticket) => (
                                <tr key={ticket.Title}>
                                  <td>
                                    <Link
                                      to={{ pathname: `/request/${ticket.Title}` }}
                                      onClick={() =>
                                        handleTicketClick(
                                          ticket.Title,
                                          ticket.Request_Type,
                                          CompletedRequestType,
                                          ticket.CustomerRequest,
                                        )
                                      }
                                    >
                                      {ticket.Title}
                                    </Link>
                                  </td>
                                  <td>{ticket.Created}</td>
                                  <td>{ticket.SFTicketNo}</td>
                                  <td>{ticket.Request_Type}</td>
                                  <td>{ticket.CustomerRequest}</td>
                                  <td>{ticket.MainCountry}</td>
                                  <td>{ticket.Name}</td>
                                  <td>{ticket.MainRequest1}</td>
                                  <td>{ticket.MainRequest2}</td>
                                  <td>{ticket.Bot_ShipToID}</td>
                                  <td>{ticket.Bot_PayerID}</td>
                                  <td>{ticket.Bot_SoldToID}</td>
                                  <td>
                                    {ticket.Bot_BillToID == null
                                      ? ticket.Bot_PayerID
                                      : ticket.Bot_BillToID}
                                  </td>
                                  <td>{ticket.BotADNode}</td>
                                  <td>{ticket?.vendorType}</td>
                                  <td>{ticket?.vendorNum}</td>
                                  <td>
                                    <StatusPill status={ticket.Status} />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      <Card.Footer>
                        <Container>
                          <Row>
                            {completedSearchTerm.length == 0 && (
                              <>
                                <div className="col col-md text-left">
                                  {completedRequests.page_list ? (
                                    <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                      Page {completedRequests.page} of{' '}
                                      {
                                        completedRequests.page_list[
                                          completedRequests.page_list.length - 1
                                        ]
                                      }
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-auto col-md-auto text-right">
                                  <div className="btn-group" role="group">
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={completedRequests.has_prev ? false : true}
                                      onClick={() =>
                                        getCompletedRequests(completedRequests.prev_num)
                                      }
                                    >
                                      Previous
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={completedRequests.has_next ? false : true}
                                      onClick={() =>
                                        getCompletedRequests(completedRequests.next_num)
                                      }
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}

                            {completedSearchTerm.length > 0 && (
                              <>
                                <div className="col col-md text-left">
                                  {completedFilteredRequests.page_list ? (
                                    <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                      Page {completedFilteredRequests.page} of{' '}
                                      {
                                        completedFilteredRequests.page_list[
                                          completedFilteredRequests.page_list.length - 1
                                        ]
                                      }
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-auto col-md-auto text-right">
                                  <div className="btn-group" role="group">
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={completedFilteredRequests.has_prev ? false : true}
                                      onClick={() =>
                                        getCompletedFilteredRequests(
                                          completedFilteredRequests.prev_num,
                                        )
                                      }
                                    >
                                      Previous
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      style={{ color: 'black' }}
                                      disabled={completedFilteredRequests.has_next ? false : true}
                                      onClick={() =>
                                        getCompletedFilteredRequests(
                                          completedFilteredRequests.next_num,
                                        )
                                      }
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                          </Row>
                        </Container>
                      </Card.Footer>
                    </>
                  )}
                </Card>
              </Col>
              {/* {!completedRequestsRes.loading && completedRequestsRes.completedRequests.length === 0 && <Col className="col-12">You do not have any completed requests.</Col>} */}
            </Row>
            {/* </Container> */}
          </>
        ))}
    </>
  );
};

export default Home;
