import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Button, ButtonGroup, Col, Row, Modal, Spinner } from 'react-bootstrap';
import Select, { components } from 'react-select';
import MandatoryText from '../../../components/MandatoryText';
import FeatherIcon from 'feather-icons-react';

import {
  FetchRegions,
  FetchDeliveringPlants,
  FetchShippingConditions,
  FetchPaymentTerms,
  FetchTransportationZones,
  FetchProfitCenters,
} from './FlowCallAPI';
import {
  languages,
  telephoneOptions,
  subChannels,
  exciseOptions,
  vatPlaceholder,
} from '../../../utils/Utils';
import { formTemplate } from '../Data';
import { useFormFields } from './HooksLib';
import { validateFields } from './FormValidation';

import {
  mobileLength,
  mobilePlaceholder,
  telephoneLength,
  telephonePlaceholder,
  postalCodeLength,
  numberRange,
  vatLength,
  shipToIDRegex,
} from '../../../utils/Utils';

const CreateProfileData = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) navigate('/');
  }, [isAuthenticated]);

  const { instance, accounts } = useMsal();

  const [isDisable, setIsDisable] = useState(false);

  const fileRegex = /\.[^.\\/:*?"<>|\r\n]+$/;

  const [linkForward, setLinkForward] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [isDisableFields, setIsDisableFields] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleClose1 = () => setShowModal1(false);
  const handleClose2 = () => setShowModal2(false);
  // const shipToForm = sessionStorage.getItem('createCustomerShipTo') === null ? shipToFormTemplate : JSON.parse(sessionStorage.getItem('createCustomerShipTo'));
  const [fetchSubSegments, setFetchSubSegments] = useState(false);
  const [inputs, setInputs] = useFormFields(props.formTemplate);
  const mainPageData = JSON.parse(sessionStorage.getItem('createCustomerMainPage'));
  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country || '';
  const companyCode =
    JSON.parse(sessionStorage.getItem('createCustomerMainPage')).companyCode || '';
  const salesOrg = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).salesOrg || '';
  const regionsRes = FetchRegions(country, isDisableFields);
  const deliveringPlantsRes = FetchDeliveringPlants(country, isDisableFields);
  const shippingConditionsRes = FetchShippingConditions(country, isDisableFields);
  const transportationZonesRes = FetchTransportationZones(country, isDisableFields);
  const paymentTermsRes = FetchPaymentTerms(country, isDisableFields);
  const profitCentersRes = FetchProfitCenters(country, isDisableFields);
  // const [isSave, setIsSave] = useState(false);
  // const [draftMsg, setDraftMsg] = useState('');
  // const saveDraftRes = SaveDraft(accounts.length > 0 ? accounts[0].username : '', props.draftTicketNum, mainPageData, inputs, isSave);

  // const saveDraft = (e) => {
  //   console.log(props.draftTicketNum);
  //   setIsSave(true);
  // };

  const paymentTermsOptions = paymentTermsRes.paymentTerms.map((val) => {
    if (val.termsOfPayment == '0000')
      return {
        ...val,
        termsOfPayment: val.termsOfPayment,
        paymentDesc: val.paymentDesc,
        paymentVal: val.termsOfPayment,
      };
    else
      return {
        ...val,
        termsOfPayment: val.termsOfPayment,
        paymentDesc: val.paymentDesc,
        paymentVal: `${val.termsOfPayment} - ${val.paymentDesc}`,
      };
  });

  useEffect(() => {
    if (
      props.draftTicketNum[0] !== 'D' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected'
    ) {
      setIsDisable(true);
    }

    switch (country) {
      case 'Netherlands':
        if (inputs.language == '') inputs.language = 'Dutch';
        if (inputs.shippingCondition == '') {
          inputs.shippingCondition = 'TJ';
        }
        break;

      case 'United Kingdom':
        if (inputs.language == '') inputs.language = 'English';
        break;

      case 'France':
        if (inputs.language == '') inputs.language = 'French';
        break;

      case 'Belgium':
        if (inputs.language == '') inputs.language = 'Dutch';
        if (inputs.transportationZone == '') inputs.transportationZone = 'BE15';
        break;

      case 'Germany':
        if (inputs.language == '') inputs.language = 'German';
        if (inputs.shippingCondition == '') inputs.shippingCondition = 'T2';
        break;

      case 'Austria':
        if (inputs.language == '') inputs.language = 'German';
        if (inputs.shippingCondition == '') inputs.shippingCondition = 'T1';
        break;

      case 'Denmark':
        inputs.shippingCondition = 'X1';
        if (inputs.language == '') inputs.language = 'Danish';
        break;

      case 'Finland':
        if (inputs.language == '') inputs.language = 'Finnish';
        inputs.shippingCondition = 'X1';
        if (inputs.transportationZone == '') inputs.transportationZone = '0000000001';
        break;

      case 'Italy':
        if (inputs.language == '') inputs.language = 'Italian';
        // if (inputs.profitCenter == '') inputs.profitCenter = '1151IT13';
        break;

      case 'Luxembourg':
        if (inputs.language == '') inputs.language = 'English';
        break;

      case 'Norway':
        if (inputs.language == '') inputs.language = 'Danish';
        inputs.shippingCondition = 'X1';
        if (inputs.transportationZone == '') inputs.transportationZone = '0000000001';
        break;

      case 'Poland':
        if (inputs.language == '') inputs.language = 'Polish';
        if (inputs.shippingCondition == '') inputs.shippingCondition = 'T1';
        break;

      case 'Sweden':
        if (inputs.language == '') inputs.language = 'Swedish';
        if (inputs.shippingCondition == '') inputs.shippingCondition = 'T1';
        break;

      case 'Switzerland':
        if (inputs.language == '') inputs.language = 'French';
        if (inputs.shippingCondition == '') inputs.shippingCondition = 'T1';
        break;

      default:
        break;
    }
    // inputs.exciseDutyType = country == 'United Kingdom' ? '002-Excise Duty Customer' : country == 'Belgium' && shipToType == 'Off-Trade – Direct' ? '001-Excise Exempt Custom' : '002-Excise Duty Customer';
    // if (inputs.exciseDutyType.startsWith('002')) inputs.exciseDutyTypeDesc = '002-Excise Duty Customer';
  }, []);

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    // props.setActivePage(e.target.getAttribute('data-link'));
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      // if (!isDisableFields) {
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(validateFields(inputs, country));
      // }
      setIsSubmitting(true);
      if (isDisable) props.setActivePage(e.target.getAttribute('data-link'));
    }
  };

  function getLang(id) {
    // console.log(props.translations);
    let obj = props.translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && isDisableFields) {
      // console.log(inputs.existingShipToID);
      console.log('Yo');
      Object.entries(formTemplate.plantData).forEach(([key, value]) => {
        if (!['language', 'contactPerson'].includes(key)) inputs[key] = value;
      });
    } else {
      setIsSubmitting(false);
      setIsCopying(false);
      // setCopyClick(false);
    }
  }, [errors]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  // useEffect(() => {

  //   setIsCopying(false);
  //   setFetchSubSegments(false);
  // }, [shipToDataRes.shipToData]);

  // useEffect(() => {
  //   if (inputs.existingShipToID !== '') setIsDisableFields(true);
  //   else setIsDisableFields(false);
  // }, [inputs.existingShipToID]);

  const Input = (props) => <components.Input {...props} maxLength={8} />;

  // const pocOptions = pocSuppliersRes.pocSuppliers.map((val) => {
  //   return {
  //     ...val,
  //     pocNumber: val.pocNumber,
  //     pocName: val.pocName,
  //     pocPayer: val.pocPayer,
  //     pocPriceListERP: val.pocPriceListERP,
  //     pocDesc: `${val.pocNumber} - ${val.pocName}`,
  //   };
  // });

  const regionOptions = regionsRes.regions.map((val) => {
    return {
      ...val,
      regionCode: val.regionCode,
      regionText: val.regionText,
      erpTranspZone: val.erpTranspZone,
      regionDesc: `${val.regionCode} - ${val.regionText}`,
    };
  });

  // const profitCenterOptions = profitCentersRes.profitCenters.map((val) => {
  //   return {
  //     ...val,

  //     pocNumber: val.pocNumber,
  //     pocName: val.pocName,
  //     pocPayer: val.pocPayer,
  //     pocPriceListERP: val.pocPriceListERP,
  //     pocDesc: `${val.pocNumber} - ${val.pocName}`,
  //   };
  // });

  return (
    isAuthenticated && (
      <form className="card" onSubmit={handleSubmit}>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={inputs.supportDocsBank}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModal1} onHide={handleClose1}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={inputs.supportDocsBank1}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModal2} onHide={handleClose2}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={inputs.supportDocsBank2}></img>
          </Modal.Body>
        </Modal>

        <Modal show={isCopying} centered>
          <Modal.Body>
            <Row>
              <div className="col-auto">
                <Spinner animation="border" variant="warning" />
              </div>
              <div className="text-left">
                <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
        {/* <div className="card-header">
          <div className="col-11 col-lg-12">
            <h3 className="card-title text-left">
              <strong>Plant Data {getLang('Creation')}</strong>
            </h3>
          </div>
          <a
            className={`sidebar-toggler col-auto ${isSidebarOpen ? '' : 'collapsed'}`}
            onClick={togglingSidebar}
          >
            <span className="sidebar-toggler-icon"></span>
          </a>
        </div> */}
        <Row className="sidebar-row">
          <div className="col-12 col-lg-12">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12 col-lg-4 text-left">
                      <h2
                        style={{
                          color: 'rgb(177, 31, 36)',
                        }}
                      >
                        {getLang('General Data')}
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Name')} *</label>
                        <input
                          type="text"
                          className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                          name="name"
                          placeholder={
                            ['Belgium', 'Germany'].includes(country)
                              ? getLang('POC Name')
                              : `Legal ${getLang('Name')}`
                          }
                          onChange={setInputs}
                          value={inputs.name}
                          maxLength="35"
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.name && (
                          <div className="invalid-feedback text-left">{errors.name}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Name 2')}</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name2"
                          onChange={setInputs}
                          value={inputs.name2}
                          maxLength="35"
                          disabled={isDisableFields || isDisable}
                        />
                      </div>
                    </div>
                    {country == 'Netherlands' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Personal Number (If Any)')}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="searchterm2"
                            onChange={setInputs}
                            value={inputs.searchterm2}
                            maxLength="35"
                            disabled={isDisableFields || isDisable}
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Postal Code')} *</label>
                        <input
                          type="text"
                          className={`form-control ${errors.postalCode ? 'is-invalid' : ''}`}
                          name="postalCode"
                          onChange={setInputs}
                          value={inputs.postalCode}
                          maxLength={postalCodeLength(country)}
                          // maxLength={['Germany', 'France'].includes(country) ? 5 : country === 'Belgium' ? 4 : 9}
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.postalCode && (
                          <div className="invalid-feedback text-left">{errors.postalCode}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Street')} *</label>
                        <input
                          type="text"
                          className={`form-control ${errors.street ? 'is-invalid' : ''}`}
                          name="street"
                          onChange={setInputs}
                          value={inputs.street}
                          maxLength="35"
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.street && (
                          <div className="invalid-feedback text-left">{errors.street}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('House Number')} {country !== 'United Kingdom' ? ' *' : ''}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.houseNumber ? 'is-invalid' : ''}`}
                          name="houseNumber"
                          maxLength="10"
                          onChange={setInputs}
                          value={inputs.houseNumber}
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.houseNumber && (
                          <div className="invalid-feedback text-left">{errors.houseNumber}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('City')} *</label>
                        <input
                          type="text"
                          className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                          name="city"
                          onChange={setInputs}
                          value={inputs.city}
                          maxLength="35"
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.city && (
                          <div className="invalid-feedback text-left">{errors.city}</div>
                        )}
                      </div>
                    </div>
                    {country != 'Luxembourg' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Region')} *</label>
                          <Select
                            className="text-left"
                            name="region"
                            value={regionOptions.filter((opt) => opt.regionCode == inputs.region)}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#cfcfcf',
                                primary50: '#cfcfcf',
                                primary: 'orange',
                              },
                            })}
                            isClearable
                            placeholder="Select"
                            isDisabled={isDisableFields || isDisable}
                            getOptionLabel={({ regionDesc }) => regionDesc}
                            getOptionValue={({ regionCode }) => regionCode}
                            options={regionOptions}
                            onChange={(e, action) => {
                              setInputs(e, action);
                            }}
                          />
                          {errors.region && (
                            <div className="invalid-feedback-file text-left">{errors.region}</div>
                          )}
                        </div>
                      </div>
                    )}
                    {/* {([
                      'Austria',
                      'Denmark',
                      'Finland',
                      'Italy',
                      'Norway',
                      'Poland',
                      'Sweden',
                      'Switzerland',
                    ].includes(country) ||
                      (country == 'Belgium')) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Shipping Conditions')}
                            {country == 'Italy' ? ' *' : ''}
                          </label>
                          <select
                            id=""
                            className={`form-control ${
                              errors.shippingCondition ? 'is-invalid' : ''
                            }`}
                            name="shippingCondition"
                            value={inputs.shippingCondition}
                            onChange={setInputs}
                            disabled={
                              isDisable || ['Denmark', 'Finland', 'Norway'].includes(country)
                            }
                          >
                            <option value="">Select</option>
                            {country == 'Belgium' &&
                              shippingConditionsRes.shippingConditions
                                .filter((e) => ['T1', 'T2', 'TJ'].includes(e.shippingConditionCode))
                                .map((val) => (
                                  <option
                                    key={val.shippingConditionCode}
                                    value={val.shippingConditionCode}
                                    data-shipcondn={`${val.shippingConditionCode} - ${val.shippingCondition}`}
                                  >
                                    {`${val.shippingConditionCode} - ${val.shippingCondition}`}
                                  </option>
                                ))}
                            {['Denmark', 'Finland', 'Norway'].includes(country) &&
                              shippingConditionsRes.shippingConditions.map((val) => (
                                <option
                                  key={val.shippingConditionCode}
                                  value={val.shippingConditionCode}
                                  data-shipcondn={`${val.shippingConditionCode} - ${val.shippingCondition}`}
                                >
                                  {`${val.shippingConditionCode} - ${val.shippingCondition}`}
                                </option>
                              ))}
                            {['Austria', 'Italy', 'Poland', 'Sweden', 'Switzerland'].includes(
                              country,
                            ) &&
                              shippingConditionsRes.shippingConditions
                                .filter((e) => ['T1', 'T2'].includes(e.shippingConditionCode))
                                .map((val) => (
                                  <option
                                    key={val.shippingConditionCode}
                                    value={val.shippingConditionCode}
                                    data-shipcondn={`${val.shippingConditionCode} - ${val.shippingCondition}`}
                                  >
                                    {`${val.shippingConditionCode} - ${val.shippingCondition}`}
                                  </option>
                                ))}
                          </select>
                          {errors.shippingCondition && (
                            <div className="invalid-feedback text-left">
                              {errors.shippingCondition}
                            </div>
                          )}
                        </div>
                      </div>
                    )} */}

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Language *')}</label>
                        <select
                          id="select-lang"
                          className="form-control"
                          name="language"
                          value={inputs.language}
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        >
                          <option value="">Select</option>
                          {languages.map((lang) => (
                            <option key={lang} value={lang}>
                              {lang}
                            </option>
                          ))}
                        </select>
                        {errors.language && (
                          <div className="invalid-feedback-file text-left">{errors.language}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{`${getLang('Telephone Number')} ${
                          [
                            'Austria',
                            'Denmark',
                            'Finland',
                            'Italy',
                            'Luxembourg',
                            'Norway',
                            'Poland',
                            'Sweden',
                            'Switzerland',
                            'United Kingdom',
                            'Belgium',
                          ].includes(country)
                            ? ' *'
                            : ''
                        }`}</label>
                        <input
                          type="tel"
                          placeholder={telephonePlaceholder(country)}
                          className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}
                          name="telephone"
                          maxLength={telephoneLength(country)}
                          // maxLength={['Belgium', 'Germany'].includes(country) ? 12 : country == 'Netherlands' ? 9 : 11}
                          value={inputs.telephone}
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.telephone && (
                          <div className="invalid-feedback text-left">{errors.telephone}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{`${getLang('Mobile')}${
                          ['France', 'Italy'].includes(country) ? ' *' : ''
                        }`}</label>
                        <input
                          type="tel"
                          placeholder={mobilePlaceholder(country)}
                          className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                          name="mobile"
                          maxLength={mobileLength(country)}
                          // maxLength={['Belgium', 'Germany'].includes(country) ? 12 : country == 'Netherlands' ? 9 : 11}
                          value={inputs.mobile}
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.mobile && (
                          <div className="invalid-feedback text-left">{errors.mobile}</div>
                        )}
                      </div>
                    </div>
                    {/* Create a new class for required? */}
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('E-mail Address')}</label>
                        <input
                          type="email"
                          className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                          name="email"
                          placeholder="Eg.: abc@company.com"
                          value={inputs.email}
                          onChange={setInputs}
                          maxLength="240"
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.email && (
                          <div className="invalid-feedback text-left">{errors.email}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Contact Person')}</label>
                        <input
                          type="text"
                          className={`form-control ${errors.contactPerson ? 'is-invalid' : ''}`}
                          name="contactPerson"
                          placeholder=""
                          value={inputs.contactPerson}
                          onChange={setInputs}
                          maxLength="35"
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.contactPerson && (
                          <div className="invalid-feedback text-left">{errors.contactPerson}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Profit Center')}
                          {' *'}
                        </label>

                        <input
                          type="text"
                          className={`form-control ${errors.profitCenter ? 'is-invalid' : ''}`}
                          name="profitCenter"
                          placeholder="Eg: 1234XX00"
                          maxLength="8"
                          value={inputs.profitCenter}
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        />

                        {/* {country === 'France' && (
                            <Select
                              className="text-left"
                              name="profitCenter"
                              value={profitCentersRes.profitCenters.filter(
                                (opt) => opt.profitCenter == inputs.profitCenter,
                              )}
                              components={{ Input }}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#cfcfcf',
                                  primary50: '#cfcfcf',
                                  primary: 'orange',
                                },
                              })}
                              isClearable
                              placeholder="Select"
                              isDisabled={isDisableFields || isDisable}
                              getOptionLabel={({ profitCenter }) => profitCenter}
                              getOptionValue={({ profitCenter }) => profitCenter}
                              options={profitCentersRes.profitCenters}
                              onChange={(e, action) => {
                                setInputs(e, action);
                              }}
                            />
                          )} */}
                        {errors.profitCenter && (
                          <div
                            className={`invalid-feedback${
                              country == 'France' ? '-file' : ''
                            } text-left`}
                          >
                            {errors.profitCenter}
                          </div>
                        )}
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-12 col-lg-4 text-left">
                        <h2
                          style={{
                            color: 'rgb(177, 31, 36)',
                          }}
                        >
                          {getLang('Sales Data')}
                        </h2>
                      </div>
                    </div>
                    {country == 'Italy' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Truck Type')} *</label>
                          <select
                            className={`form-control ${errors.truckType ? 'is-invalid' : ''}`}
                            name="truckType"
                            value={inputs.truckType}
                            onChange={setInputs}
                            disabled={isDisableFields || isDisable}
                          >
                            <option value="">Select</option>
                            <option value="ZITTR3 - TRACTOR 3 AXELS">{`ZITTR3 - TRACTOR 3 AXELS`}</option>
                          </select>
                          {errors.truckType && (
                            <div className="invalid-feedback text-left">{errors.truckType}</div>
                          )}
                        </div>
                      </div>
                    )}
                    {country == 'Italy' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Trailer Type')} *
                          </label>
                          <select
                            className={`form-control ${errors.trailerType ? 'is-invalid' : ''}`}
                            name="trailerType"
                            value={inputs.trailerType}
                            onChange={setInputs}
                            disabled={isDisableFields || isDisable}
                          >
                            <option value="">Select</option>
                            <option value="ZITMOTRI - MOTRICE (16 PLT)">{`ZITMOTRI - MOTRICE (16 PLT)`}</option>
                            <option value="ZITAN3 - TRAILER 3 AXELS">{`ZITAN3 - TRAILER 3 AXELS`}</option>
                          </select>
                          {errors.trailerType && (
                            <div className="invalid-feedback text-left">{errors.trailerType}</div>
                          )}
                        </div>
                      </div>
                    )}

                    {/* <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Delivery Priority')}{' '}
                          {['United Kingdom', 'Belgium', 'Italy', 'Luxembourg'].includes(country)
                            ? ' *'
                            : ''}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.contactPerson ? 'is-invalid' : ''}`}
                          name="deliveryPriority"
                          placeholder=""
                          value={inputs.deliveryPriority}
                          onChange={setInputs}
                          maxLength="5"
                          disabled={
                            isDisableFields || isDisable
                          }
                        />
                        {errors.deliveryPriority && (
                          <div className="invalid-feedback text-left">{errors.deliveryPriority}</div>
                        )}
                      </div>
                    </div> */}
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Delivering Plant')}
                          {country == 'Italy' || country == 'Belgium *'}
                        </label>
                        <select
                          id="select-delivering-plant"
                          className={`form-control ${errors.deliveringPlant ? 'is-invalid' : ''}`}
                          name="deliveringPlant"
                          //value={country === 'Netherlands' ? 'NL03' : inputs.deliveringPlant}
                          value={inputs.deliveringPlant}
                          disabled={
                            isDisableFields || isDisable
                            // ||
                            // country == 'France' ||
                            // country === 'Netherlands'
                          }
                          onChange={setInputs}
                        >
                          <option value="">Select</option>
                          {/* {country === 'Netherlands' && (
                            <option value="NL03">NL03 - EERSEL DC WINCANTON</option>
                          )} */}
                          {!(country == 'Belgium') &&
                            deliveringPlantsRes.deliveringPlants.map((val) => (
                              <option
                                key={val.plantCode}
                                value={val.plantCode}
                                data-plant={`${val.plantCode} - ${val.plantDesc}`}
                              >{`${val.plantCode} - ${val.plantDesc}`}</option>
                            ))}
                          {country == 'Belgium' &&
                            deliveringPlantsRes.deliveringPlants.map(
                              (val) =>
                                val.plantCode != 'BE15' && (
                                  <option
                                    key={val.plantCode}
                                    value={val.plantCode}
                                    data-plant={`${val.plantCode} - ${val.plantDesc}`}
                                  >{`${val.plantCode} - ${val.plantDesc}`}</option>
                                ),
                            )}
                        </select>
                        {errors.deliveringPlant && (
                          <div className="invalid-feedback text-left">{errors.deliveringPlant}</div>
                        )}
                      </div>
                    </div>
                    <br />
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </Row>

        <div className="card-footer">
          <div className="row">
            <div className="col-auto col-md-auto text-left">
              {!isDisable && (
                <Button
                  variant="outline-warning"
                  onClick={props.saveDraft}
                  // disabled={process.env.REACT_APP == 'prod'}
                >
                  Save Draft
                </Button>
              )}
            </div>
            <div className="col col-md text-left">
              <span className="align-middle">{props.draftMsg}</span>
            </div>
            {/* <div className="col col-md text-left justify-content">{props.draftMsg}</div> */}
            {/* <div className="col-auto col-md-auto text-right">
              <Link
                data-link="mainpage"
                data-back="true"
                className="btn btn-warning btn-block"
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Back
              </Link>
            </div> */}
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link="review"
                className={`btn btn-${
                  Object.keys(validateFields(inputs)).length == 0 || isDisable
                    ? 'danger'
                    : 'secondary'
                } btn-block`}
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Review
              </Link>
            </div>
          </div>
        </div>
      </form>
    )
  );
};

// inputs.name == '' ? null :

export default CreateProfileData;
