import {
  shipToIDRegex,
  postalCodeRegex,
  telephoneRegex,
  emailRegex,
  vatRegex,
  profitCenterRegex,
  glnIlnCodeRegex,
} from '../../../utils/Utils';

export function validateFields(values, forceValidate = false) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country;

  if (values.plantCode === '') {
    errors.plantCode = 'Plant Code required';
  } else if (values.name === '') {
    errors.name = 'Legal name is required';
  } else if (values.postalCode === '') {
    errors.postalCode = 'Postal code is required';
  } else if (values.postalCode !== '' && !postalCodeRegex[country].test(values.postalCode)) {
    errors.postalCode = 'Postal code is incorrect';
  } else if (values.street === '') {
    errors.street = 'Street is required';
  } else if (values.language === '') {
    errors.language = 'Language is required';
  } else if (values.houseNumber == '') {
    errors.houseNumber = 'House number is required';
  } else if (values.city === '') {
    errors.city = 'City is required';
  } else if (values.region == '' && country != 'Luxembourg') {
    errors.region = 'Region is required';
  } else if (values.telephone === '') {
    errors.telephone = 'Telephone is required';
  } else if (values.vatRegNumber != '' && !vatRegex[country].test(values.vatRegNumber)) {
    errors.vatRegNumber = 'VAT Reg Number is incorrect';
  } else if (values.mobile !== '' && !telephoneRegex.test(values.mobile)) {
    errors.mobile = 'Mobile number is incorrect';
  } else if (values.email !== '' && !emailRegex.test(values.email)) {
    errors.email = 'Email address is incorrect';
  } else if (values.shippingCondition === '') {
    errors.shippingCondition = 'Shipping Condition is required';
  } else if (values.deliveringPlant == '') {
    errors.deliveringPlant = 'Delivering Plant is required';
  } else if (values.price == '' && country != 'Netherlands') {
    errors.price = 'Price is required';
  }

  return errors;
}
