export function validateFields(values) {
    let errors = {};

    if (values.country === '') {
        errors.country = 'Country is required';
    
    } else if (values.shipToType === '') {
        errors.shipToType = 'Ship-to Type is required';
    }

    return errors;
}
