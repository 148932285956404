import { useState } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);
  console.log(fields);
  return [
    fields,
    function (event, action) {
      console.log('manage access', event, action);
      if (['email'].includes(event.target.name)) {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      } else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
        });
      }
    },
  ];
}
