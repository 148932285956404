import React, { useState, useEffect } from 'react';

// import WarningIcon from '@material-ui/icons/Warning';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Chip, { ChipProps } from '@mui/material/Chip';
import { red, blue, yellow, green, orange } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import Table from '../../components/layout/Tables/MUI-Table/Table';
import { FetchApprovalList, FetchApprovalFilterList } from '../FlowCallAPI';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { urls } from '../../utils/Utils';

const ApprovalsDashboard = (props) => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();

  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    Bot_SoldToID: props.hide,
    Bot_PayerID: props.hide,
    Bot_ShipToID: props.hide,
    BOT_BillToID: props.hide,
    // status: false,
    // traderName: false
  });
  const data = FetchApprovalList(
    accounts.length > 0 ? accounts[0].username : '',
    props.requestType,
  );
  function handleTicketClick(ticketNum, reqType, creationType, customerRequest) {
    console.log('TICKET CLICK', ticketNum, reqType);
    navigate({
      pathname: `/request/${ticketNum}`,
      state: {
        creationType: creationType,
      },
    });
    props.RequestCreationType(ticketNum, creationType, customerRequest, reqType);
    //props.openRequest(reqType,creationType);
    console.log('CUSTOMER DATA CLICK', ticketNum, reqType, creationType);
    // props.openRequest(ticketNum, reqType,creationType);
    // // props.openRequest(ticketNum, reqType);
    // console.log(e.target);
    // if (e.target.getAttribute('data-ticket')) props.openRequest(e.target.getAttribute('data-ticket'), e.target.getAttribute('data-reqtype'));
  }

  function getChipProps(params) {
    console.log('table', params);
    if (params.value == 'Rejected' || params.value.startsWith('Duplicate')) {
      return {
        // icon: <WarningIcon style={{ fill: red[500] }} />,
        label: params.value,
        style: {
          color: '#fff',
          borderColor: red[500],
          backgroundColor: red[500],
        },
      };
    } else if (params.value.startsWith('Awaiting')) {
      return {
        // icon: <CheckCircleIcon style={{ fill: blue[500] }} />,
        label: params.value,
        style: {
          color: '#000',
          borderColor: yellow[500],
          backgroundColor: yellow[500],
        },
      };
    } else if (params.value.startsWith('Completed')) {
      return {
        // icon: <CheckCircleIcon style={{ fill: blue[500] }} />,
        label: params.value,
        style: {
          color: '#fff',
          borderColor: green[500],
          backgroundColor: green[500],
        },
      };
    } else {
      return {
        // icon: <CheckCircleIcon style={{ fill: blue[500] }} />,
        label: params.value,
        style: {
          color: '#fff',
          borderColor: orange[500],
          backgroundColor: orange[500],
        },
      };
    }
  }
  const TABLE_COLUMNS = [
    {
      field: 'Request_ID',
      headerName: 'Request ID',
      flex: 1,
      cellClassName: 'dashboardCell',
      headerAlign: 'center',
      minWidth: 150,
      hideable: false,
      renderCell: (params) => {
        return (
          <Link
            to={{
              pathname:
                (props.requestType === 'pending' ? `/review` : `/request`) + `/${params.value}`,
              state: { creationType: params.value },
            }}
            onClick={() => {
              console.log('on click data grid', params);
              handleTicketClick(
                params.row.Request_ID,
                params.row.Request_Type,
                params.row.CustomerRequest,
                // SubmittedRequestType,
                params.row.CustomerRequest,
              );
            }}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'Created',
      headerName: 'Created Date',
      flex: 1,
      cellClassName: 'dashboardCell',
      headerAlign: 'center',
      minWidth: 150,
    },
    {
      field: 'MainCountry',
      headerName: 'Country',
      flex: 1,
      cellClassName: 'dashboardCell',
      headerAlign: 'center',
      minWidth: 150,
    },
    {
      field: 'Request_Type',
      headerName: 'Request Type',
      flex: 1,
      cellClassName: 'dashboardCell',
      headerAlign: 'center',
      minWidth: 100,
    },
    {
      field: 'CustomerRequest',
      headerName: 'Customer Request Type',
      flex: 1,
      cellClassName: 'dashboardCell',
      headerAlign: 'center',
      minWidth: 200,
    },
    {
      field: 'Bot_SoldToID',
      headerName: 'Sold To ID',
      flex: 1,
      cellClassName: 'dashboardCell',
      headerAlign: 'center',
      minWidth: 110,
    },
    {
      field: 'Bot_PayerID',
      headerName: 'Payer ID',
      flex: 1,
      cellClassName: 'dashboardCell',
      headerAlign: 'center',
      minWidth: 110,
    },
    {
      field: 'Bot_ShipToID',
      headerName: 'Ship To ID',
      flex: 1,
      cellClassName: 'dashboardCell',
      headerAlign: 'center',
      minWidth: 110,
    },
    {
      field: 'BOT_BillToID',
      headerName: 'Bill To ID',
      flex: 1,
      cellClassName: 'dashboardCell',
      headerAlign: 'center',
      minWidth: 110,
    },
    {
      field: 'Requestor_EmailID',
      headerName: 'Requestor ID',
      flex: 1,
      cellClassName: 'dashboardCell',
      headerAlign: 'center',
      minWidth: 200,
    },
    {
      field: 'FinalStatus',
      headerName: 'Status',
      flex: 1,
      cellClassName: 'dashboardCell',
      headerAlign: 'center',
      minWidth: 200,
      renderCell: (params) => {
        return <Chip variant="outlined" {...getChipProps(params)} />;
      },
    },
  ];
  useEffect(() => {
    setTableColumns(TABLE_COLUMNS);
    setTableData(
      data.resData.map((i, index) => {
        return {
          id: index,
          ...i,
        };
      }),
    );
  }, [data.resData]);

  const [isDone, setIsDone] = useState(false);
  const [activePage, setActivePage] = useState('mainpage');
  console.log('approvals', props.data);
  useEffect(() => {
    setIsDone(true);
  }, []);

  return (
    <>
      {activePage == 'mainpage' && (
        <Table
          tableData={tableData}
          loading={data.loading}
          tableColumns={tableColumns}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
          columns={tableColumns}
        />
      )}
    </>
  );
};

export default ApprovalsDashboard;
