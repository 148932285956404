const prod = { url:{
  fetchRegionsURL: 'https://mavenapis.ab-inbev.com/api/data/regions',
  fetchRiskCatURL: 'https://mavenapis.ab-inbev.com/api/data/riskcategory',
},
};
const test = { url:{
  fetchRegionsURL: 'https://mavenapistest.ab-inbev.com/api/data/regions',
  fetchRiskCatURL: 'https://mavenapistest.ab-inbev.com/api/data/riskcategory',
},
};
const local = { url:{
  fetchRegionsURL: 'http://localhost:1234/api/data/regions',
  fetchRiskCatURL: 'http://localhost:1234/api/data/riskcategory',
},
};
const configData  = process.env.REACT_APP_ENV === 'prod' ? prod.url : (process.env.REACT_APP_ENV === 'test' ? test.url : local.url);
export default configData;
