import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
// import { withRouter } from 'react-router';
import SuccessIcon from '../../../assets/images/success.png';
import { ProductFeedback } from '@abi-ds-beerblocs/product-feedback-module';
import { Button, ButtonGroup, Row, Col } from 'react-bootstrap';

const CreateCustSaveSuccess = (props) => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [opened, setOpened] = useState(true);
  const [token, setToken] = useState(localStorage.getItem('token-customscope'));
  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country || '';
  const customerReqType =
    JSON.parse(sessionStorage.getItem('createCustomerMainPage')).customerReqType || '';
  const name = JSON.parse(sessionStorage.getItem('createCustomerShipTo')).name || '';

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    props.setActivePage(e.target.getAttribute('data-link'));
  };

  function getLang(id) {
    let obj = props.translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }
  return (
    <>
      <form className="card">
        <div className="card-header">
          <div className="col-12 col-lg-12">
            <h3 className="card-title text-left">
              <strong style={{ color: 'rgb(177, 31, 36)' }}>
                {getLang('General Customer Creation')}
              </strong>{' '}
              - {getLang('Success')}
            </h3>
          </div>
        </div>
        <Row className="sidebar-row">
          <div className="col-12 col-lg-12">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-lg-auto">
                  <img src={SuccessIcon} height="50px" />
                </div>
                <div className="col-auto justify-content-center align-self-center">
                  <span>
                    Your request <strong>{`${props.draftTicketNum.slice(1)}`}</strong> has been
                    submitted successfully, you will receive the information shortly in your
                    registered email.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Row>

        <br />

        <div className="card-footer">
          <div className="row">
            <div className="col col-md text-left justify-content"></div>
            <div className="col-auto col-md-auto text-right">
              <Link
                to="#"
                className="btn btn-danger btn-block"
                style={{ textDecoration: 'none' }}
                onClick={() => navigate('/')}
              >
                View Requests
              </Link>
            </div>
          </div>
        </div>
      </form>
      <ProductFeedback
        env="dev"
        apiKey={''}
        token={token}
        feedbackMetadata={{
          Maven_Ticket: `${props.draftTicketNum.slice(1)}`,
          Country: country,
          Request_Type: 'Creation',
          CustomerRequestType: customerReqType,
          CustomerName: name,
          Requestor_EmailID: props.accounts[0].username,
        }}
        productId="D5936F4D-A609-4A66-A451-068DB7626495"
        productActivityId="nps_score_provided"
        modalOpened={opened}
        setModalOpened={setOpened}
      />
    </>
  );
};

export default CreateCustSaveSuccess;
