import { useState, useEffect } from 'react';
import { formTemplate } from '../../Data';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  const country = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')).country;

  const [fileB64Str, setFileB64Str] = useState('');
  const [fileName, setFileName] = useState('');

  const encodeFileB64 = (file, link) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var b64 = reader.result;
        console.log(`B64 = ${b64}`);
        setFileB64Str(b64);
        setFileName(file.name);
      };
      reader.onerror = (err) => {
        console.log(`Error: ${err}`);
      };
    }
  };

  useEffect(() => {
    sessionStorage.setItem('updateCustomerBlockUnblock', JSON.stringify(fields));
  }, [fields]);

  useEffect(() => {
    console.log('lalal');
    if (fileB64Str !== '' && fileName !== '') {
      setValues({
        ...fields,
        ['supportDocsBank']: fileB64Str,
        ['supportDocsBankName']: fileName,
      });
    }
  }, [fileB64Str, fileName]);

  return [
    fields,
    function (event) {
      if (event.target.name == 'existingShipToID') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          nameShipTo: '',
          orderBlockShipTo: ' _Not Required',
          deliveryBlockShipTo: ' _Not Required',
          billingBlockShipTo: ' _Not Required',
        });
      } else if (event.target.name == 'existingPayerID') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          namePayer: '',
          orderBlockPayer: ' _Not Required',
          deliveryBlockPayer: ' _Not Required',
          billingBlockPayer: ' _Not Required',
        });
      } else if (event.target.name == 'existingSoldToID') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          nameSoldTo: '',
          orderBlockSoldTo: ' _Not Required',
          deliveryBlockSoldTo: ' _Not Required',
          billingBlockSoldTo: ' _Not Required',
        });
      } else if (event.target.name == 'existingBillToID') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          nameBillTo: '',
          orderBlockBillTo: ' _Not Required',
          deliveryBlockBillTo: ' _Not Required',
          billingBlockBillTo: ' _Not Required',
        });
      } else if (['checkShipTo', 'checkPayer', 'checkSoldTo', 'checkBillTo'].includes(event.target.name)) {
        if (event.target.checked) {
          setValues({
            ...fields,
            [event.target.name]: 'Y',
          });
        } else {
          if (event.target.name == 'checkShipTo') {
            setValues({
              ...fields,
              [event.target.name]: 'N',
              existingShipToID: '',
              nameShipTo: '',
              orderBlockShipTo: ' _Not Required',
              deliveryBlockShipTo: ' _Not Required',
              billingBlockShipTo: ' _Not Required',
            });
          } else if (event.target.name == 'checkPayer') {
            setValues({
              ...fields,
              [event.target.name]: 'N',
              existingPayerID: '',
              namePayer: '',
              orderBlockPayer: ' _Not Required',
              deliveryBlockPayer: ' _Not Required',
              billingBlockPayer: ' _Not Required',
            });
          } else if (event.target.name == 'checkSoldTo') {
            setValues({
              ...fields,
              [event.target.name]: 'N',
              existingSoldToID: '',
              nameSoldTo: '',
              orderBlockSoldTo: ' _Not Required',
              deliveryBlockSoldTo: ' _Not Required',
              billingBlockSoldTo: ' _Not Required',
            });
          } else if (event.target.name == 'checkBillTo') {
            setValues({
              ...fields,
              [event.target.name]: 'N',
              existingBillToID: '',
              nameBillTo: '',
              orderBlockBillTo: ' _Not Required',
              deliveryBlockBillTo: ' _Not Required',
              billingBlockBillTo: ' _Not Required',
            });
          }
        }
      } else if (['blockUnblockOpt'].includes(event.target.name)) {
        console.log('clearing');
        // sessionStorage.setItem('updateCustomerBlockUnblock', JSON.stringify(formTemplate.blockUnblock));
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          existingShipToID: '',
          checkShipTo: '',
          nameShipTo: '',
          orderBlockShipTo: '',
          deliveryBlockShipTo: '',
          billingBlockShipTo: '',
          existingPayerID: '',
          checkPayer: '',
          namePayer: '',
          orderBlockPayer: '',
          deliveryBlockPayer: '',
          billingBlockPayer: '',
          existingSoldToID: '',
          checkSoldTo: '',
          nameSoldTo: '',
          orderBlockSoldTo: '',
          deliveryBlockSoldTo: '',
          billingBlockSoldTo: '',
          existingBillToID: '',
          checkBillTo: '',
          nameBillTo: '',
          orderBlockBillTo: '',
          deliveryBlockBillTo: '',
          billingBlockBillTo: '',
          supportDocsBank: '',
          supportDocsBankName: '',
        });
      } else if (event.target.name === 'supportDocsBank') {
        encodeFileB64(event.target.files[0], event.target.value);
        // console.log(`Res: ${fileB64Str}`);
      } else if (event.target.id === 'clearFile') {
        setValues({
          ...fields,
          ['supportDocsBank']: '',
          ['supportDocsBankName']: '',
        });
      } else if (['France', 'Belgium'].includes(country) && event.target.name == 'orderBlockPayer' && event.target.value == 'Z2_Closed') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          deliveryBlockPayer: 'Z2_Pre sale del block',
        });
      } else if (['France', 'Belgium'].includes(country) && event.target.name == 'orderBlockPayer' && event.target.value == ' _Not Required') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          deliveryBlockPayer: ' _Not Required',
        });
      } else if (['France', 'Belgium'].includes(country) && event.target.name == 'orderBlockSoldTo' && event.target.value == 'Z2_Closed') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          deliveryBlockSoldTo: 'Z2_Pre sale del block',
        });
      } else if (['France', 'Belgium'].includes(country) && event.target.name == 'orderBlockSoldTo' && [' _Not Required', 'ZX_Beer order block'].includes(event.target.value)) {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          deliveryBlockSoldTo: ' _Not Required',
        });
      } else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
        });
      }
    },
  ];
}
