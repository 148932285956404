import { emailRegex } from '../../../utils/Utils';

export function validateFields(values) {
  let errors = {};

  if (values.role == '') {
    errors.actionType = 'Role is required';
  } else if (values.country === '') {
    errors.country = 'Country is required';
  } else if (values.email == '') {
    errors.email = 'Email is required';
  } else if (values.email != '' && !emailRegex.test(values.email)) {
    errors.email = 'Email is incorrect';
  }

  return errors;
}
