const prod = { url:{
  fetchRegionsURL: 'https://mavenapis.ab-inbev.com/api/data/regions',
  fetchDeliveryPlantsURL: 'https://mavenapis.ab-inbev.com/api/data/deliveryplants',
  fetchShippingConditionsURL: 'https://mavenapis.ab-inbev.com/api/data/shippingconditions',
  fetchTransportationZonesURL: 'https://mavenapis.ab-inbev.com/api/data/transportationzones',
  fetchPaymentTermsURL: 'https://mavenapis.ab-inbev.com/api/data/paymentterms',
  fetchProfitCentersURL: 'https://mavenapis.ab-inbev.com/api/data/profitcenters',
},
};
const test = { url:{
  fetchRegionsURL: 'https://mavenapistest.ab-inbev.com/api/data/regions',
  fetchDeliveryPlantsURL: 'https://mavenapistest.ab-inbev.com/api/data/deliveryplants',
  fetchShippingConditionsURL: 'https://mavenapistest.ab-inbev.com/api/data/shippingconditions',
  fetchTransportationZonesURL: 'https://mavenapistest.ab-inbev.com/api/data/transportationzones',
  fetchPaymentTermsURL: 'https://mavenapistest.ab-inbev.com/api/data/paymentterms',
  fetchProfitCentersURL: 'https://mavenapistest.ab-inbev.com/api/data/profitcenters',
},
};
const local = { url:{
  fetchRegionsURL: 'http://localhost:1234/api/data/regions',
  fetchDeliveryPlantsURL: 'http://localhost:1234/api/data/deliveryplants',
  fetchShippingConditionsURL: 'http://localhost:1234/api/data/shippingconditions',
  fetchTransportationZonesURL: 'http://localhost:1234/api/data/transportationzones',
  fetchPaymentTermsURL: 'http://localhost:1234/api/data/paymentterms',
  fetchProfitCentersURL: 'http://localhost:1234/api/data/profitcenters',
},
};
const configData  = process.env.REACT_APP_ENV === 'prod' ? prod.url : (process.env.REACT_APP_ENV === 'test' ? test.url : local.url);

export default configData;
