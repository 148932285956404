import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Button, ButtonGroup, Row, Col, Modal, Spinner } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import Select, { components } from 'react-select';
import MandatoryText from '../../../components/MandatoryText';

import { validateFields } from './utils/FormValidation';
import { formTemplate } from '../Data';
import { FetchAcctgClerk } from './utils/FlowCallAPI';
import { FetchPayerBapi } from '../utils/FlowCallAPI';
import { useFormFields } from './utils/HooksLib';
import { fileRegex } from '../../../utils/Utils';
import {
  mobileLength,
  mobilePlaceholder,
  telephoneLength,
  telephonePlaceholder,
  postalCodeLength,
} from '../../../utils/Utils';

const UpdateAcctgClerkUpdate = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  const [translations, setTranslations] = useState(props.translations);

  useEffect(() => {
    // if (!isAuthenticated) navigate('/');
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    console.log(inputs);
    if (
      props.draftTicketNum[0] !== 'M' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected'
    ) {
      setIsDisable(true);
    }

    if (inputs.name != '') setIsDisableFields(false);
  }, []);

  const [inputs, setInputs] = useFormFields(props.formTemplate);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkForward, setLinkForward] = useState('');
  const [isCopying, setIsCopying] = useState(false);
  const [copyClick, setCopyClick] = useState(false);
  const [payerSap, setPayerSap] = useState(inputs.existingPayerID);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const [isDisableFields, setIsDisableFields] = useState(true);

  const mainPageForm = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')) || {};

  const acctgClerkRes = FetchAcctgClerk(mainPageForm.country);

  const payerDataRes = FetchPayerBapi(
    inputs.existingPayerID,
    mainPageForm.companyCode,
    mainPageForm.salesOrg,
    isCopying,
  );

  const acctgClerkOptions = acctgClerkRes.accountingClerk.map((val) => {
    return {
      ...val,
      acctgClerkCode: val.acctgClerkCode,
      acctgClerkText: val.acctgClerkText,
      acctgClerkDesc: `${val.acctgClerkCode} - ${val.acctgClerkText}`,
    };
  });

  console.log(acctgClerkOptions);

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(validateFields(inputs, payerSap, false));
      setIsSubmitting(true);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      props.setActivePage(linkForward);
    }
  }, [errors]);

  function getLang(id) {
    // console.log(props.translations);
    let obj = translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    setTranslations(props.translations);
  }, [props.translations]);

  function copyPayerData(e) {
    setCopyClick(true);
    setErrors(validateFields(inputs, payerSap, true));
    console.log(`I am true ${isCopying}`);
  }

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && copyClick) {
      console.log(inputs.name, isDisableFields, copyClick);
      setCopyClick(false);
      setIsCopying(true);
      Object.entries(formTemplate.acctgClerk).forEach(([key, value]) => {
        if (key !== 'existingPayerID' && key !== 'language') inputs[key] = value;
      });
    }
  }, [errors]);

  useEffect(() => {
    if (Object.keys(payerDataRes.payerData).length !== 0) {
      console.log('payer data', payerDataRes.payerData);
      if (inputs.existingPayerID != '') setPayerSap(inputs.existingPayerID);
      Object.entries(payerDataRes.payerData).forEach(([key, value]) => {
        // if (key == 'name' && value == '') errors.existingPayerID = 'Payer ID is incorrect';
        if (
          key == 'companyNumber' &&
          ['United Kingdom', 'Netherlands'].includes(mainPageForm.country)
        ) {
          inputs.name3 = value;
        }
        if (key in inputs) {
          // if (key === 'telephone' && value !== '') inputs.telephoneOption = 'General/Accounting';
          inputs[key] = value;
          if (key === 'acctgClerks') {
            console.log(acctgClerkRes.accountingClerk);
            if (acctgClerkRes.accountingClerk.length != 0) {
              if (inputs.acctgClerkRes != '') {
                let obj = acctgClerkRes.acctgClerks.find(
                  (o) => o.acctgClerkCode === inputs.acctgClerks,
                );
                // console.log('I AM IN REGION')
                // console.log(obj)
                if (obj) {
                  console.log(`Accounitng Clerk Desc = ${obj.acctgClerkText}`);
                  inputs.acctgClerkDesc = `${obj.acctgClerkCode} - ${obj.acctgClerkText}`;
                }
              }
            }
          }
        }
      });
      sessionStorage.setItem('updateAcctgClerkUpdate', JSON.stringify(inputs));
      setIsDisableFields(false);
    }
    setIsCopying(false);
  }, [payerDataRes.payerData]);

  return (
    <form className="card" onSubmit={handleSubmit}>
      <Modal show={isCopying} centered>
        <Modal.Body>
          <Row>
            <div className="col-auto">
              <Spinner animation="border" variant="warning" />
            </div>
            <div className="text-left">
              <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={inputs.supportDocsBank}></img>
        </Modal.Body>
      </Modal>

      <div className="card-header">
        <div className="col-12">
          <h3 className="card-title text-left">
            <strong>Customer Data {getLang('Update')}</strong>
          </h3>
        </div>
      </div>
      <Row className="sidebar-row">
        <div className="col-12">
          <div className="card-body">
            <Row>
              <Col sm={12} lg="auto">
                <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                  {getLang('Accounting Clerk Modification')}
                </h2>
              </Col>
            </Row>
            <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">
                        {getLang('Existing Payer ID')} *
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control${errors.existingPayerID ? ' is-invalid' : ''}`}
                          name="existingPayerID"
                          maxLength="8"
                          placeholder={`Enter ${getLang('Existing Payer ID')}`}
                          value={inputs.existingPayerID}
                          onChange={setInputs}
                          disabled={isDisable}
                        />
                        <span className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="button"
                            style={{
                              borderTopRightRadius: '3px',
                              borderBottomRightRadius: '3px',
                            }}
                            disabled={isDisable || inputs.existingPayerID == ''}
                            onClick={copyPayerData}
                          >
                            {getLang('Copy')}
                          </button>
                        </span>
                        {errors.existingPayerID && (
                          <div className="invalid-feedback text-left">{errors.existingPayerID}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Name')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                        name="name"
                        placeholder={`${getLang('Name')}`}
                        value={inputs.name}
                        onChange={setInputs}
                        disabled
                      />
                      {/* {errors.name && (
                        <div className="invalid-feedback text-left">{errors.name}</div>
                      )} */}
                    </div>
                  </div>
                  {/* <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Street')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.street ? 'is-invalid' : ''}`}
                        name="street"
                        value={inputs.street}
                        onChange={setInputs}
                        maxLength="35"
                        disabled
                      />
                      {errors.street && (
                        <div className="invalid-feedback text-left">{errors.street}</div>
                      )}
                    </div>
                  </div> */}
                  {/* <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Postal Code')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.postalCode ? 'is-invalid' : ''}`}
                        name="postalCode"
                        maxLength={postalCodeLength(mainPageForm?.country)}
                        value={inputs.postalCode}
                        onChange={setInputs}
                        disabled
                      />
                      {errors.postalCode && (
                        <div className="invalid-feedback text-left">{errors.postalCode}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('City')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                        name="city"
                        value={inputs.city}
                        maxLength="35"
                        onChange={setInputs}
                        // disabled={inputs.name == '' || isDisable}
                        disabled
                      />
                      {errors.city && (
                        <div className="invalid-feedback text-left">{errors.city}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Sales Org')}</label>
                      <input
                        type="text"
                        className="form-control"
                        value={mainPageForm.salesOrg}
                        readOnly
                      />
                    </div>
                  </div> */}
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">
                        {getLang('Accounting Clerk')} *
                      </label>
                      <Select
                        className="text-left"
                        name="acctgClerks"
                        value={acctgClerkOptions.filter(
                          (opt) => opt.acctgClerkCode == inputs.acctgClerks,
                        )}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: '#cfcfcf',
                            primary50: '#cfcfcf',
                            primary: 'orange',
                          },
                        })}
                        isClearable
                        placeholder="Select"
                        isDisabled={isDisableFields || isDisable}
                        getOptionLabel={({ acctgClerkDesc }) => acctgClerkDesc}
                        getOptionValue={({ acctgClerkCode }) => acctgClerkCode}
                        options={acctgClerkOptions}
                        onChange={(e, action) => {
                          setInputs(e, action);
                        }}
                      />
                      {errors.acctgClerks && (
                        <div className="invalid-feedback-file text-left">{errors.acctgClerks}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <br />

      <div className="card-footer">
        <div className="row">
          {!isDisable && (
            <div className="col-auto col-md-auto text-left">
              <Button variant="outline-warning" onClick={props.saveDraft}>
                Save Draft
              </Button>
            </div>
          )}
          <div className="col col-md text-left">
            <span className="align-middle">{props.draftMsg}</span>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="mainPage"
              data-back="true"
              className="btn btn-warning btn-block"
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Back
            </Link>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="acctgClerkReview"
              className={`btn btn-danger btn-block${
                inputs.existingPayerID == '' || inputs.name == '' ? ' disabled' : ''
              }`}
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Review
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateAcctgClerkUpdate;
