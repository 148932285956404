import React from 'react';
import { Button, ButtonGroup, Col, Row, Modal, Spinner } from 'react-bootstrap';
const CopyModal = ({isCopying}) => {
    return (
        <Modal show={isCopying} centered>
          <Modal.Body>
            <Row>
              <div className="col-auto">
                <Spinner animation="border" variant="warning" />
              </div>
              <div className="text-left">
                <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
    )
}

export default CopyModal;