import { useState, useEffect } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  useEffect(() => {
    sessionStorage.setItem('updateClosedBlockUnbUpdate', JSON.stringify(fields));
  }, [fields]);

  return [
    fields,
    function (event, action) {
      if (action?.name == 'orderBlock') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            orderBlock: '',
            orderBlockDesc: '',
            
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            orderBlock: event.blockCode,
            orderBlockDesc: event.orderBlockDesc,
            
          });
        }
      }
      else if (action?.name == 'deliveryBlock') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            deliveryBlock: '',
            deliveryBlockDesc: '',
            
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            deliveryBlock: event.deliveryBlockCode,
            deliveryBlockDesc: event.deliveryBlockDesc,
            
          });
        }
      }
      else if (action?.name == 'billingBlock') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            billingBlock: '',
            billingBlockDesc: '',
            
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            billingBlock: event.billingBlockCode,
            billingBlockDesc: event.billingBlockDesc,
            
          });
        }
      }
     else  if (event.target.name == 'existingShipToID') {
       
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          name: '',
          street: '',
          postalCode: '',
          city: '',
          orderBlock: '',
          deliveryBlock: '',
          billingBlock: '',
        });
      } else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
    },
  ];
}
