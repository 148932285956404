import { shipToIDRegex, postalCodeRegex } from '../../../../utils/Utils';

export function validateFields(values, copyShipClick, copySoldClick, copyPayerClick) {
  let errors = {};

  const mainPageForm = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')) || {};

  if (copyShipClick) {
    if (values.existingShipToID == '') {
      errors.existingShipToID = 'Ship To ID is required';
    } else if (values.existingShipToID != '' && !shipToIDRegex.test(values.existingShipToID)) {
      errors.existingShipToID = 'Ship To ID is incorrect';
    }
  } 

  else{
    if(values.name == ''){
      errors.name = "Name is required";
  } else if (values.postalCode === '') {
    errors.postalCode = 'Postal Code is required';
  } else if (
    values.postalCode !== '' &&
    !postalCodeRegex[mainPageForm.country].test(values.postalCode)
  ) {
  //   errors.postalCode = 'Postal code is incorrect';  
  } else if(values.city == ''){
    errors.city = 'City is required'
  } else if(values.street == ''){
    errors.street = 'Street is required'
  } else if(values.shipToType == ''){
    errors.shipToType = 'Ship-To Type is required'
  }  else if( values.m1 == ''){
    errors.m1 = 'M1 is required';
  }  else if(values.att7 == ''){
    errors.att7 = 'Use of Tank Beer is required';
  }


  }
  return errors;
}
