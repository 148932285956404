import {
  shipToIDRegex,
  postalCodeRegex,
  telephoneRegex,
  emailRegex,
  profitCenterRegex,
  glnIlnCodeRegex,
} from '../../../utils/Utils';

export function validateFields(values, forceValidate = false) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('createHierarchyMainPage')).country;

  // console.log('PostalCode Issue', values.postalCode);

  if (values.name === '') {
    errors.name = 'Legal name is required';
  } else if (values.postalCode === '') {
    errors.postalCode = 'Postal Code is required';
  } else if (values.postalCode !== '' && !postalCodeRegex[country].test(values.postalCode)) {
    console.log(values.postalCode);
    errors.postalCode = 'Postal code is incorrect';
  } else if (values.street === '') {
    errors.street = 'Street is required';
  } else if (values.city === '') {
    errors.city = 'City is required';
  } else if (values.region === '' && country != 'Luxembourg') {
    errors.region = 'Region is required';
  } else if (values.language === '') {
    console.log(values.language);
    errors.language = 'Language is Required';
  }
  return errors;
}
