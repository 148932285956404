import React, { useState, useEffect } from 'react';

import UpdateCustMainPage from './mainpage/MainPage';

import UpdateCustBlockUnblock from './blockunblock/BlockUnblock';
import UpdateCustBlockUnblockReview from './blockunblock/review/Review';

import UpdateCustPayerUpdate from './payerupdate/PayerUpdate';
import UpdateCustPayerUpdateReview from './payerupdate/review/Review';

import PaymentTermUpdate from './paymentTermChange/paymentTermChange';
import PaymentTermUpdateReview from './paymentTermChange/review/Review';

import UpdateCustNameDelivPlantUpdate from './namedelivplantupdate/NameDelivPlantUpdate';
import UpdateCustNameDelivPlantUpdateReview from './namedelivplantupdate/review/Review';

import UpdateCustVatTaxUpdate from './vattaxupdate/VatTaxUpdate';
import UpdateCustVatTaxUpdateReview from './vattaxupdate/review/Review';

import UpdateCustIbanUpdate from './ibanupdate/IbanUpdate';
import UpdateCustIbanUpdateReview from './ibanupdate/review/Review';

import UpdateCustEInvPayerUpdate from './einvpayerupdate/EInvPayerUpdate';
import UpdateCustEInvPayerUpdateReview from './einvpayerupdate/review/Review';

import UpdateCustEInvBillUpdate from './einvbillupdate/EInvBillUpdate';
import UpdateCustEInvBillUpdateReview from './einvbillupdate/review/Review';

import UpdateCustOthersWarehouseUpdate from './otherswarehouseupdate/OthersWarehouseUpdate';
import UpdateCustOthersWarehouseUpdateReview from './otherswarehouseupdate/review/Review';

import UpdateCustOthersProfitUpdate from './othersprofitupdate/OthersProfitUpdate';
import UpdateCustOthersProfitUpdateReview from './othersprofitupdate/review/Review';

import UpdateCustOthersGlnUpdate from './othersglnupdate/OthersGlnUpdate';
import UpdateCustOthersGlnUpdateReview from './othersglnupdate/review/Review';

import UpdateCustOthersTextUpdate from './otherstextupdate/OthersTextUpdate';
import UpdateCustOthersTextUpdateReview from './otherstextupdate/review/Review';

import UpdateCustldeUpdate from './ldeupdate/ldeUpdate';
import UpdateCustldeUpdateReview from './ldeupdate/review/Review';

import UpdateM1mapping from './M1mapping/M1mapping';
import UpdateM1mappingReview from './M1mapping/review/Review';

import RouteToMarket from './routeToMarketChange/routeToMarketChange';
import RouteToMarketReview from './routeToMarketChange/review/Review';

import UpdateCreditLimit from './creditLimitUpdate/creditLimitUpdate';
import UpdateCreditLimitReview from './creditLimitUpdate/review/Review';

import UpdatePriceListUpdate from './priceListUpdate/priceListUpdate';
import UpdatePriceListUpdateReview from './priceListUpdate/review/Review';

import UpdateAcctgClerkUpdate from './acctgClerk/acctgClerk';
import UpdateAcctgClerkReview from './acctgClerk/review/Review';

import UpdatePaymentMethodsUpdate from './paymentMethodUpdate/paymentMethodUpdate';
import UpdatePaymentMethodsReview from './paymentMethodUpdate/review/Review';

import UpdateSegmentUpdate from './segmentUpdate/segmentUpdate';
import UpdateSegmentUpdateReview from './segmentUpdate/review/Review';

import UpdateRegulatorUpdate from './regulatorUpdate/regulatorUpdate';
import UpdateRegulatorUpdateReview from './regulatorUpdate/review/Review';

import UpdateTruckTrailerUpdate from './truckTrailerUpdate/truckTrailerUpdate';
import UpdateTruckTrailerUpdateReview from './truckTrailerUpdate/review/Review';

import UpdateHierarchyMapUpdate from './hierarchyMapUpdate/hierarchyMapUpdate';
import UpdateHierarchyMapUpdateReview from './hierarchyMapUpdate/review/Review';

import UpdateAddressUpdate from './addressUpdate/addressUpdate';
import UpdateAddressUpdateReview from './addressUpdate/review/Review';

import UpdateDeliveryPlanUpdate from './deliveryPlanUpdate/deliveryPlanUpdate';
import UpdateDeliveryPlanUpdateReview from './deliveryPlanUpdate/review/Review';

import UpdateExciseDutyUpdate from './exciseDutyUpdate/exciseDutyUpdate';
import UpdateExciseDutyUpdateReview from './exciseDutyUpdate/review/Review';

import UpdateClosedBlockUnbUpdate from './closedBlockUnbUpdate/closedBlockUnbUpdate';
import UpdateClosedBlockUnbUpdateReview from './closedBlockUnbUpdate/review/Review';

import UpdateCustSaveSuccess from './success/Success';
import UpdateCustSaveError from './error/Error';

import { formTemplate } from './Data';
import { SaveDraft } from './utils/FlowCallAPI';

const UpdateCustomer = (props) => {
  // var draftTicketNum ='';
  const [newTicket, setNewTicket] = useState(props.createNew);

  const [activePage, setActivePage] = useState('mainPage');

  const [draftTicketNum, setDraftTicketNum] = useState('');

  const [newRequest, setNewRequest] = useState(false);

  const [isSave, setIsSave] = useState(false);

  const [draftMsg, setDraftMsg] = useState('');

  const [ticketStatus, setTicketStatus] = useState('');

  const [done, setDone] = useState(false);

  console.log('Modification page', props);
  console.log(
    'MOdification check',
    props.data,
    props.data['requestType'],
    props.data.requestType == 'Modification',
    Object.keys(props.data).requestType == 'Modification',
  );
  console.log('new Ticetk', newTicket);
  const saveDraft = (e) => {
    console.log('Clicked Save Draft');
    console.log(`Draft num: ${draftTicketNum}`);
    if (draftTicketNum != '') {
      console.log(draftTicketNum);
      console.log(mainPageForm);
      setIsSave(true);
    }
  };

  useEffect(() => {
    console.log(`Active: ${activePage}`);
    console.log(props);
    setDraftMsg('');
  }, [activePage]);

  // useEffect(() => {
  //   setActivePage('update');
  //   session storage stuff

  // },[activePage])

  useEffect(() => {
    console.log(`Clearing1232131241294921481284: `, newTicket);
    console.log('MOdification check', props.data?.requestType == 'Modification');
    setDone(false);

    if (Object.keys(props.data).length !== 0 && props.data['requestType'] == 'Modification') {
      console.log('Doing');
      setTicketStatus(props.data.status);
      sessionStorage.setItem('updateCustomerMainPage', JSON.stringify(props.data.mainPage));
      sessionStorage.setItem('updateCustomerBlockUnblock', JSON.stringify(props.data.blockUnblock));
      sessionStorage.setItem('updateCustomerPayerUpdate', JSON.stringify(props.data.payerUpdate));
      sessionStorage.setItem(
        'updateCustomerNameDelivPlantUpdate',
        JSON.stringify(props.data.nameDelivPlantUpdate),
      );
      sessionStorage.setItem(
        'updatePaymentTermChange',
        JSON.stringify(props.data.paymentTermChange),
      );
      sessionStorage.setItem('updateCustomerVatTaxUpdate', JSON.stringify(props.data.vatTaxUpdate));
      sessionStorage.setItem('updateCustomerIbanUpdate', JSON.stringify(props.data.ibanUpdate));
      sessionStorage.setItem(
        'updateCustomerEInvPayerUpdate',
        JSON.stringify(props.data.eInvPayerUpdate),
      );
      sessionStorage.setItem(
        'updateCustomerEInvBillUpdate',
        JSON.stringify(props.data.eInvBillUpdate),
      );
      sessionStorage.setItem(
        'updateCustomerOthersWarehouseUpdate',
        JSON.stringify(props.data.othersWarehouseUpdate),
      );
      sessionStorage.setItem(
        'updateCustomerOthersProfitUpdate',
        JSON.stringify(props.data.othersProfitUpdate),
      );
      sessionStorage.setItem(
        'updateCustomerOthersGlnUpdate',
        JSON.stringify(props.data.othersGlnUpdate),
      );
      sessionStorage.setItem(
        'updateCustomerOthersTextUpdate',
        JSON.stringify(props.data.othersTextUpdate),
      );
      sessionStorage.setItem('updateM1mappingUpdate', JSON.stringify(props.data.M1mapping));
      sessionStorage.setItem('updateRouteToMarketUpdate', JSON.stringify(props.data.routeToMarket));
      sessionStorage.setItem('updateCreditLimitUpdate', JSON.stringify(props.data.creditLimit));
      sessionStorage.setItem('updateAcctgClerkUpdate', JSON.stringify(props.data.acctgClerk));
      sessionStorage.setItem('updatePriceListUpdate', JSON.stringify(props.data.priceList));

      sessionStorage.setItem(
        'updatePaymentMethodsUpdate',
        JSON.stringify(props.data.paymentMethodsUpdate),
      );

      sessionStorage.setItem('updateSegmentUpdate', JSON.stringify(props.data.segmentUpdate));
      sessionStorage.setItem('updateRegulatorUpdate', JSON.stringify(props.data.regulatorUpdate));
      sessionStorage.setItem(
        'updateTruckTrailerUpdate',
        JSON.stringify(props.data.truckTrailerUpdate),
      );

      sessionStorage.setItem('updateCustomerldeUpdate', JSON.stringify(props.data.ldeUpdate));

      sessionStorage.setItem(
        'updateHierarchyMapUpdate',
        JSON.stringify(props.data.hierarchyMapUpdate),
      );

      sessionStorage.setItem('updateAddressUpdate', JSON.stringify(props.data.addressUpdate));

      sessionStorage.setItem('updateDeliveryPlanUpdate', JSON.stringify(props.data.deliveryPlan));

      sessionStorage.setItem('updateExciseDutyUpdate', JSON.stringify(props.data.exciseDutyUpdate));

      sessionStorage.setItem(
        'updateClosedBlockUnbUpdate',
        JSON.stringify(props.data.closedBlockUnbUpdate),
      );
      if (props.data.status == 'Rejected' || props.data.status == 'Duplicate') {
        setNewRequest(true);
        var today = new Date();
        var ticketNum = `M${today.getDate()}${
          today.getMonth() + 1
        }${today.getFullYear()}${today.getHours()}${today.getMinutes()}${today.getSeconds()}`;
        // draftTicketNum = ticketNum;
        console.log(ticketNum);
        setDraftTicketNum(ticketNum);
        console.log(`Draft: ${draftTicketNum}`);

        setNewTicket(false);
      } else {
        setDraftTicketNum(props.data.title);
      }
    } else if (newTicket) {
      console.log('CreateNew Mod called');
      setNewRequest(true);

      sessionStorage.setItem('updateCustomerMainPage', JSON.stringify(formTemplate.mainPage));
      sessionStorage.setItem(
        'updateCustomerBlockUnblock',
        JSON.stringify(formTemplate.blockUnblock),
      );
      sessionStorage.setItem(
        'updatePaymentTermChange',
        JSON.stringify(formTemplate.paymentTermChange),
      );
      sessionStorage.setItem('updateCustomerPayerUpdate', JSON.stringify(formTemplate.payerUpdate));
      sessionStorage.setItem(
        'updateCustomerNameDelivPlantUpdate',
        JSON.stringify(formTemplate.nameDelivPlantUpdate),
      );
      sessionStorage.setItem(
        'updateCustomerVatTaxUpdate',
        JSON.stringify(formTemplate.vatTaxUpdate),
      );
      sessionStorage.setItem('updateCustomerIbanUpdate', JSON.stringify(formTemplate.ibanUpdate));
      sessionStorage.setItem(
        'updateCustomerEInvPayerUpdate',
        JSON.stringify(formTemplate.eInvPayerUpdate),
      );
      sessionStorage.setItem(
        'updateCustomerEInvBillUpdate',
        JSON.stringify(formTemplate.eInvBillUpdate),
      );
      sessionStorage.setItem(
        'updateCustomerOthersWarehouseUpdate',
        JSON.stringify(formTemplate.othersWarehouseUpdate),
      );
      sessionStorage.setItem(
        'updateCustomerOthersProfitUpdate',
        JSON.stringify(formTemplate.othersProfitUpdate),
      );
      sessionStorage.setItem(
        'updateCustomerOthersGlnUpdate',
        JSON.stringify(formTemplate.othersGlnUpdate),
      );
      sessionStorage.setItem(
        'updateCustomerOthersTextUpdate',
        JSON.stringify(formTemplate.othersTextUpdate),
      );
      sessionStorage.setItem('updateCustomerldeUpdate', JSON.stringify(formTemplate.ldeUpdate));
      // sessionStorage.setItem('updateCustomerOthersUpdate', JSON.stringify(formTemplate.othersUpdate));
      sessionStorage.setItem('updateM1mappingUpdate', JSON.stringify(formTemplate.M1mapping));
      sessionStorage.setItem(
        'updateRouteToMarketUpdate',
        JSON.stringify(formTemplate.routeToMarket),
      );
      sessionStorage.setItem('updateCreditLimitUpdate', JSON.stringify(formTemplate.creditLimit));
      sessionStorage.setItem('updateAcctgClerkUpdate', JSON.stringify(formTemplate.acctgClerk));
      sessionStorage.setItem('updatePriceListUpdate', JSON.stringify(formTemplate.priceList));
      // sessionStorage.setItem('updatePaymentMethodUpdate', JSON.stringify(formTemplate.paymentMethod));
      sessionStorage.setItem('updateSegmentUpdate', JSON.stringify(formTemplate.segmentUpdate));
      sessionStorage.setItem('updateRegulatorUpdate', JSON.stringify(formTemplate.regulatorUpdate));
      sessionStorage.setItem(
        'updateTruckTrailerUpdate',
        JSON.stringify(formTemplate.truckTrailerUpdate),
      );
      sessionStorage.setItem(
        'updatePaymentMethodsUpdate',
        JSON.stringify(formTemplate.paymentMethodsUpdate),
      );
      sessionStorage.setItem(
        'updateHierarchyMapUpdate',
        JSON.stringify(formTemplate.hierarchyMapUpdate),
      );
      sessionStorage.setItem('updateAddressUpdate', JSON.stringify(formTemplate.addressUpdate));
      sessionStorage.setItem('updateDeliveryPlanUpdate', JSON.stringify(formTemplate.deliveryPlan));
      sessionStorage.setItem(
        'updateExciseDutyUpdate',
        JSON.stringify(formTemplate.exciseDutyUpdate),
      );
      sessionStorage.setItem(
        'updateClosedBlockUnbUpdate',
        JSON.stringify(formTemplate.closedBlockUnbUpdate),
      );

      today = new Date();
      ticketNum = `M${today.getDate()}${
        today.getMonth() + 1
      }${today.getFullYear()}${today.getHours()}${today.getMinutes()}${today.getSeconds()}`;
      // draftTicketNum = ticketNum;
      console.log(ticketNum);
      setDraftTicketNum(ticketNum);
      console.log(`Draft: ${draftTicketNum}`);

      setNewTicket(false);
    } else {
      console.log('this');
    }

    setDone(true);
  }, []);

  const mainPageForm = props.data.mainPage
    ? JSON.parse(sessionStorage.getItem('updateCustomerMainPage')) || props.data.mainPage
    : JSON.parse(sessionStorage.getItem('updateCustomerMainPage')) || formTemplate.mainPage;

  const paymentTermChangeForm = props.data.paymentTermChange
    ? JSON.parse(sessionStorage.getItem('updatePaymentTermChange')) || props.data.paymentTermChange
    : JSON.parse(sessionStorage.getItem('updatePaymentTermChange')) ||
      formTemplate.paymentTermChange;

  const blockUnblockForm = props.data.blockUnblock
    ? JSON.parse(sessionStorage.getItem('updateCustomerBlockUnblock')) || props.data.blockUnblock
    : JSON.parse(sessionStorage.getItem('updateCustomerBlockUnblock')) || formTemplate.blockUnblock;

  const payerUpdateForm = props.data.payerUpdate
    ? JSON.parse(sessionStorage.getItem('updateCustomerPayerUpdate')) || props.data.payerUpdate
    : JSON.parse(sessionStorage.getItem('updateCustomerPayerUpdate')) || formTemplate.payerUpdate;

  const nameDelivPlantUpdateForm = props.data.nameDelivPlantUpdate
    ? JSON.parse(sessionStorage.getItem('updateCustomerNameDelivPlantUpdate')) ||
      props.data.nameDelivPlantUpdate
    : JSON.parse(sessionStorage.getItem('updateCustomerNameDelivPlantUpdate')) ||
      formTemplate.nameDelivPlantUpdate;

  const vatTaxUpdateForm = props.data.vatTaxUpdate
    ? JSON.parse(sessionStorage.getItem('updateCustomerVatTaxUpdate')) || props.data.vatTaxUpdate
    : JSON.parse(sessionStorage.getItem('updateCustomerVatTaxUpdate')) || formTemplate.vatTaxUpdate;

  const ibanUpdateForm = props.data.ibanUpdate
    ? JSON.parse(sessionStorage.getItem('updateCustomerIbanUpdate')) || props.data.ibanUpdate
    : JSON.parse(sessionStorage.getItem('updateCustomerIbanUpdate')) || formTemplate.ibanUpdate;

  const eInvPayerUpdateForm = props.data.eInvPayerUpdate
    ? JSON.parse(sessionStorage.getItem('updateCustomerEInvPayerUpdate')) ||
      props.data.eInvPayerUpdate
    : JSON.parse(sessionStorage.getItem('updateCustomerEInvPayerUpdate')) ||
      formTemplate.eInvPayerUpdate;

  const eInvBillUpdateForm = props.data.eInvBillUpdate
    ? JSON.parse(sessionStorage.getItem('updateCustomerEInvBillUpdate')) ||
      props.data.eInvBillUpdate
    : JSON.parse(sessionStorage.getItem('updateCustomerEInvBillUpdate')) ||
      formTemplate.eInvBillUpdate;

  const othersWarehouseUpdateForm = props.data.othersWarehouseUpdate
    ? JSON.parse(sessionStorage.getItem('updateCustomerOthersWarehouseUpdate')) ||
      props.data.othersWarehouseUpdate
    : JSON.parse(sessionStorage.getItem('updateCustomerOthersWarehouseUpdate')) ||
      formTemplate.othersWarehouseUpdate;

  const othersProfitUpdateForm = props.data.othersProfitUpdate
    ? JSON.parse(sessionStorage.getItem('updateCustomerOthersProfitUpdate')) ||
      props.data.othersProfitUpdate
    : JSON.parse(sessionStorage.getItem('updateCustomerOthersProfitUpdate')) ||
      formTemplate.othersProfitUpdate;

  const othersGlnUpdateForm = props.data.othersGlnUpdate
    ? JSON.parse(sessionStorage.getItem('updateCustomerOthersGlnUpdate')) ||
      props.data.othersGlnUpdate
    : JSON.parse(sessionStorage.getItem('updateCustomerOthersGlnUpdate')) ||
      formTemplate.othersGlnUpdate;

  const othersTextUpdateForm = props.data.othersTextUpdate
    ? JSON.parse(sessionStorage.getItem('updateCustomerOthersTextUpdate')) ||
      props.data.othersTextUpdate
    : JSON.parse(sessionStorage.getItem('updateCustomerOthersTextUpdate')) ||
      formTemplate.othersTextUpdate;

  const ldeUpdateForm = props.data.ldeUpdate
    ? JSON.parse(sessionStorage.getItem('updateCustomerldeUpdate')) || props.data.ldeUpdate
    : JSON.parse(sessionStorage.getItem('updateCustomerldeUpdate')) || formTemplate.ldeUpdate;

  const routeToMarketForm = props.data.routeToMarket
    ? JSON.parse(sessionStorage.getItem('updateRouteToMarketUpdate')) || props.data.routeToMarket
    : JSON.parse(sessionStorage.getItem('updateRouteToMarketUpdate')) || formTemplate.routeToMarket;

  const M1mappingForm = props.data.M1mapping
    ? JSON.parse(sessionStorage.getItem('updateM1mappingUpdate')) || props.data.M1mapping
    : JSON.parse(sessionStorage.getItem('updateM1mappingUpdate')) || formTemplate.M1mapping;

  const creditLimitForm = props.data.creditLimit
    ? JSON.parse(sessionStorage.getItem('updateCreditLimitUpdate')) || props.data.creditLimit
    : JSON.parse(sessionStorage.getItem('updateCreditLimitUpdate')) || formTemplate.creditLimit;

  const acctgClerkForm = props.data.acctgClerk
    ? JSON.parse(sessionStorage.getItem('updateAcctgClerkUpdate')) || props.data.acctgClerk
    : JSON.parse(sessionStorage.getItem('updateAcctgClerkUpdate')) || formTemplate.acctgClerk;

  const priceListUpdateForm = props.data.priceList
    ? JSON.parse(sessionStorage.getItem('updatePriceListUpdate')) || props.data.priceList
    : JSON.parse(sessionStorage.getItem('updatePriceListUpdate')) || formTemplate.priceList;

  // const paymentMethodUpdateForm = props.data.paymentMethod
  // ? JSON.parse(sessionStorage.getItem('updatePaymentMethodUpdate')) || props.data.paymentMethod
  // : JSON.parse(sessionStorage.getItem('updatePaymentMethodUpdate')) || formTemplate.paymentMethod;

  const paymentMethodsUpdateForm = props.data.paymentMethodsUpdate
    ? JSON.parse(sessionStorage.getItem('updatePaymentMethodsUpdate')) ||
      props.data.paymentMethodsUpdate
    : JSON.parse(sessionStorage.getItem('updatePaymentMethodsUpdate')) ||
      formTemplate.paymentMethodsUpdate;

  const segmentUpdateForm = props.data.segmentUpdate
    ? JSON.parse(sessionStorage.getItem('updateSegmentUpdate')) || props.data.segmentUpdate
    : JSON.parse(sessionStorage.getItem('updateSegmentUpdate')) || formTemplate.segmentUpdate;

  const regulatorUpdateForm = props.data.regulatorUpdate
    ? JSON.parse(sessionStorage.getItem('updateRegulatorUpdate')) || props.data.regulatorUpdate
    : JSON.parse(sessionStorage.getItem('updateRegulatorUpdate')) || formTemplate.regulatorUpdate;

  const truckTrailerUpdateForm = props.data.truckTrailerUpdate
    ? JSON.parse(sessionStorage.getItem('updateTruckTrailerUpdate')) ||
      props.data.truckTrailerUpdate
    : JSON.parse(sessionStorage.getItem('updateTruckTrailerUpdate')) ||
      formTemplate.truckTrailerUpdate;

  const hierarchyMapUpdateForm = props.data.hierarchyMapUpdate
    ? JSON.parse(sessionStorage.getItem('updateHierarchyMapUpdate')) ||
      props.data.hierarchyMapUpdate
    : JSON.parse(sessionStorage.getItem('updateHierarchyMapUpdate')) ||
      formTemplate.hierarchyMapUpdate;

  const addressUpdateForm = props.data.addressUpdate
    ? JSON.parse(sessionStorage.getItem('updateAddressUpdate')) || props.data.addressUpdate
    : JSON.parse(sessionStorage.getItem('updateAddressUpdate')) || formTemplate.addressUpdate;

  const deliveryPlanUpdateForm = props.data.deliveryPlan
    ? JSON.parse(sessionStorage.getItem('updateDeliveryPlanUpdate')) || props.data.deliveryPlan
    : JSON.parse(sessionStorage.getItem('updateDeliveryPlanUpdate')) || formTemplate.deliveryPlan;

  const exciseDutyUpdateForm = props.data.exciseDutyUpdate
    ? JSON.parse(sessionStorage.getItem('updateExciseDutyUpdate')) || props.data.exciseDutyUpdate
    : JSON.parse(sessionStorage.getItem('updateExciseDutyUpdate')) || formTemplate.exciseDutyUpdate;

  const closedBlockUnbUpdateForm = props.data.closedBlockUnbUpdate
    ? JSON.parse(sessionStorage.getItem('updateClosedBlockUnbUpdate')) ||
      props.data.closedBlockUnbUpdate
    : JSON.parse(sessionStorage.getItem('updateClosedBlockUnbUpdate')) ||
      formTemplate.closedBlockUnbUpdate;

  const saveDraftRes = SaveDraft(
    props.accounts.length > 0 ? props.accounts[0].username : '',
    draftTicketNum,
    isSave,
  );

  useEffect(() => {
    // if (saveDraftRes.result !== '') {
    //   setIsSave(false);
    //   let timeSave = new Date();

    //   if (saveDraftRes.result.result === 'Created') {
    //     setDraftMsg(`Draft created at ${timeSave.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`);
    //   } else if (saveDraftRes.result.result === 'Updated') setDraftMsg(`Draft updated at ${timeSave.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`);
    // }
    setIsSave(false);
    if ('result' in saveDraftRes.result) setDraftMsg(saveDraftRes.result.result);
    else if ('error' in saveDraftRes.result) setDraftMsg(saveDraftRes.result.error);
  }, [saveDraftRes.result]);

  return (
    draftTicketNum && (
      <>
        {activePage === 'mainPage' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateCustMainPage
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            newRequest={newRequest}
            formTemplate={mainPageForm}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'blockUnblock' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateCustBlockUnblock
            setActivePage={setActivePage}
            formTemplate={blockUnblockForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'blockUnblockReview' && done && (
          <UpdateCustBlockUnblockReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'payerUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateCustPayerUpdate
            setActivePage={setActivePage}
            formTemplate={payerUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'payerUpdateReview' && done && (
          <UpdateCustPayerUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'paymentTermupdate' && (draftTicketNum !== '' || props.data) && done && (
          <PaymentTermUpdate
            setActivePage={setActivePage}
            formTemplate={paymentTermChangeForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'paymentTermUpdateReview' && done && (
          <PaymentTermUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'nameDelivPlantUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateCustNameDelivPlantUpdate
            setActivePage={setActivePage}
            formTemplate={nameDelivPlantUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'nameDelivPlantUpdateReview' && done && (
          <UpdateCustNameDelivPlantUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'vatTaxUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateCustVatTaxUpdate
            setActivePage={setActivePage}
            formTemplate={vatTaxUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'vatTaxUpdateReview' && done && (
          <UpdateCustVatTaxUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'ibanUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateCustIbanUpdate
            setActivePage={setActivePage}
            formTemplate={ibanUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'ibanUpdateReview' && done && (
          <UpdateCustIbanUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'eInvPayerUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateCustEInvPayerUpdate
            setActivePage={setActivePage}
            formTemplate={eInvPayerUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'eInvPayerUpdateReview' && done && (
          <UpdateCustEInvPayerUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'eInvBillUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateCustEInvBillUpdate
            setActivePage={setActivePage}
            formTemplate={eInvBillUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'eInvBillUpdateReview' && done && (
          <UpdateCustEInvBillUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}

        {activePage === 'othersWarehouseUpdate' &&
          (draftTicketNum !== '' || props.data) &&
          done && (
            <UpdateCustOthersWarehouseUpdate
              setActivePage={setActivePage}
              formTemplate={othersWarehouseUpdateForm}
              draftTicketNum={draftTicketNum}
              saveDraft={saveDraft}
              draftMsg={draftMsg}
              translations={props.translations}
              status={props.data.status}
            />
          )}
        {activePage === 'othersWarehouseUpdateReview' && done && (
          <UpdateCustOthersWarehouseUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'othersProfitUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateCustOthersProfitUpdate
            setActivePage={setActivePage}
            formTemplate={othersProfitUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'othersProfitUpdateReview' && done && (
          <UpdateCustOthersProfitUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'othersGlnUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateCustOthersGlnUpdate
            setActivePage={setActivePage}
            formTemplate={othersGlnUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'othersGlnUpdateReview' && done && (
          <UpdateCustOthersGlnUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'othersTextUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateCustOthersTextUpdate
            setActivePage={setActivePage}
            formTemplate={othersTextUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'othersTextUpdateReview' && done && (
          <UpdateCustOthersTextUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'ldeUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateCustldeUpdate
            setActivePage={setActivePage}
            formTemplate={ldeUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'ldeUpdateReview' && done && (
          <UpdateCustldeUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}

        {activePage === 'M1mappingUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateM1mapping
            setActivePage={setActivePage}
            formTemplate={M1mappingForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}

        {activePage === 'M1mappingUpdateReview' && done && (
          <UpdateM1mappingReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}

        {activePage === 'routeToMarket' && (draftTicketNum !== '' || props.data) && done && (
          <RouteToMarket
            setActivePage={setActivePage}
            formTemplate={routeToMarketForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'routeToMarketReview' && done && (
          <RouteToMarketReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'creditLimit' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateCreditLimit
            setActivePage={setActivePage}
            formTemplate={creditLimitForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'creditLimitReview' && done && (
          <UpdateCreditLimitReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'acctgClerk' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateAcctgClerkUpdate
            setActivePage={setActivePage}
            formTemplate={acctgClerkForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'acctgClerkReview' && done && (
          <UpdateAcctgClerkReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'priceList' && (draftTicketNum !== '' || props.data) && done && (
          <UpdatePriceListUpdate
            setActivePage={setActivePage}
            // formTemplate={acctgClerkForm}
            formTemplate={priceListUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'priceListReview' && done && (
          <UpdatePriceListUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}

        {activePage === 'segmentUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateSegmentUpdate
            setActivePage={setActivePage}
            formTemplate={segmentUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'segmentUpdateReview' && done && (
          <UpdateSegmentUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}

        {activePage === 'regulatorUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateRegulatorUpdate
            setActivePage={setActivePage}
            formTemplate={regulatorUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'regulatorUpdateReview' && done && (
          <UpdateRegulatorUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'truckTrailerUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateTruckTrailerUpdate
            setActivePage={setActivePage}
            formTemplate={truckTrailerUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'truckTrailerUpdateReview' && done && (
          <UpdateTruckTrailerUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}

        {activePage === 'paymentMethodsUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdatePaymentMethodsUpdate
            setActivePage={setActivePage}
            formTemplate={paymentMethodsUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}

        {activePage === 'paymentMethodsUpdateReview' && done && (
          <UpdatePaymentMethodsReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}

        {activePage === 'hierarchyMapUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateHierarchyMapUpdate
            setActivePage={setActivePage}
            formTemplate={hierarchyMapUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}

        {activePage === 'hierarchyMapUpdateReview' && done && (
          <UpdateHierarchyMapUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'addressUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateAddressUpdate
            setActivePage={setActivePage}
            formTemplate={addressUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'addressUpdateReview' && done && (
          <UpdateAddressUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'deliveryPlanUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateDeliveryPlanUpdate
            setActivePage={setActivePage}
            formTemplate={deliveryPlanUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'deliveryPlanUpdateReview' && done && (
          <UpdateDeliveryPlanUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}

        {activePage === 'exciseDutyUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateExciseDutyUpdate
            setActivePage={setActivePage}
            formTemplate={exciseDutyUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'exciseDutyUpdateReview' && done && (
          <UpdateExciseDutyUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'closedBlockUnbUpdate' && (draftTicketNum !== '' || props.data) && done && (
          <UpdateClosedBlockUnbUpdate
            setActivePage={setActivePage}
            formTemplate={closedBlockUnbUpdateForm}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
            status={props.data.status}
          />
        )}
        {activePage === 'closedBlockUnbUpdateReview' && done && (
          <UpdateClosedBlockUnbUpdateReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
            status={props.data.status}
          />
        )}

        {activePage === 'success' && done && (
          <UpdateCustSaveSuccess
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            accounts={props.accounts}
            translations={props.translations}
          />
        )}
        {activePage === 'error' && done && (
          <UpdateCustSaveError
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            accounts={props.accounts}
            translations={props.translations}
          />
        )}
      </>
    )
  );
};

export default UpdateCustomer;
