import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Button, ButtonGroup, Col, Row, Modal, Spinner } from 'react-bootstrap';
import Select, { components } from 'react-select';
import MandatoryText from '../../../components/MandatoryText';
import Sidebar from '../../../components/layout/Sidebar/Sidebar';

import {
  FetchShipToBapi,
  FetchRegions,
  FetchSegments,
  FetchSubSegments,
  FetchDeliveringPlants,
  FetchShippingConditions,
  FetchPOCSuppliers,
  FetchProfitCenters,
  FetchTransportationZones,
} from './FlowCallAPI';
import { languages, telephoneOptions, subChannels, exciseOptions } from '../../../utils/Utils';
import { formTemplate } from '../Data';
import { useFormFields } from './HooksLib';
import { validateFields } from './FormValidation';

import {
  mobileLength,
  mobilePlaceholder,
  telephoneLength,
  telephonePlaceholder,
  postalCodeLength,
  numberRange,
  shipToIDRegex,
} from '../../../utils/Utils';

const CreateCustShipTo = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) navigate('/');
  }, [isAuthenticated]);

  const { instance, accounts } = useMsal();

  const [isDisable, setIsDisable] = useState(false);

  const [linkForward, setLinkForward] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [copyClick, setCopyClick] = useState(false);
  const [isDisableFields, setIsDisableFields] = useState(false);
  // const shipToForm = sessionStorage.getItem('createCustomerShipTo') === null ? shipToFormTemplate : JSON.parse(sessionStorage.getItem('createCustomerShipTo'));
  const [fetchSubSegments, setFetchSubSegments] = useState(false);
  const [inputs, setInputs] = useFormFields(props.formTemplate);
  const mainPageData = JSON.parse(sessionStorage.getItem('createCustomerMainPage'));
  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country || '';
  const companyCode =
    JSON.parse(sessionStorage.getItem('createCustomerMainPage')).companyCode || '';
  const salesOrg = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).salesOrg || '';
  const customerReqType =
    JSON.parse(sessionStorage.getItem('createCustomerMainPage')).customerReqType || '';
  const shipToType = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).shipToType || '';
  const regionsRes = FetchRegions(country, isDisableFields);
  const segmentsRes = FetchSegments(country, shipToType, isDisableFields);
  const subSegmentsRes = FetchSubSegments(
    country,
    shipToType,
    inputs.segment,
    isDisableFields,
    fetchSubSegments,
  );
  const deliveringPlantsRes = FetchDeliveringPlants(country, isDisableFields);
  const shippingConditionsRes = FetchShippingConditions(country, isDisableFields);
  const pocSuppliersRes = FetchPOCSuppliers(country, shipToType, isDisableFields);
  const profitCentersRes = FetchProfitCenters(country, isDisableFields);
  const transportationZonesRes = FetchTransportationZones(country, isDisableFields);
  const shipToDataRes = FetchShipToBapi(inputs.existingShipToID, companyCode, salesOrg, isCopying);
  // const [isSave, setIsSave] = useState(false);
  // const [draftMsg, setDraftMsg] = useState('');
  // const saveDraftRes = SaveDraft(accounts.length > 0 ? accounts[0].username : '', props.draftTicketNum, mainPageData, inputs, isSave);

  // const saveDraft = (e) => {
  //   console.log(props.draftTicketNum);
  //   setIsSave(true);
  // };

  useEffect(() => {
    if (
      props.draftTicketNum[0] !== 'D' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected' &&
      props.status !== 'Duplicate'
    ) {
      //if ((props.draftTicketNum[0] === 'D')) {
      setIsDisable(true);
    }

    inputs.telephoneOption = 'General/Accounting';

    if (['Netherlands', 'United Kingdom', 'France', 'Germany'].includes(country)) {
      if (inputs.ediOption == '') inputs.ediOption = 'No';
    }

    // inputs.ediOption = 'No';

    if (['Netherlands', 'Belgium'].includes(country)) {
      if (
        shipToType.startsWith('On-Trade') ||
        shipToType.startsWith('Off-Trade') ||
        shipToType === 'Miscellaneous'
      ) {
        inputs.subChannel = 'XXX - Not Applicable';
      } else if (shipToType.startsWith('National')) inputs.subChannel = '001 - National Customer';
    } else if (country == 'France') {
      if (!shipToType.startsWith('Wholesale') && shipToType != 'Off-Trade – Direct') {
        inputs.subChannel = 'XXX - Not Applicable';
      } else inputs.subChannel = '008 - Wholesalers';
    }

    switch (country) {
      case 'Netherlands':
        if (inputs.language == '') inputs.language = 'Dutch';
        if (inputs.shippingCondition == '') {
          if (shipToType.includes('Indirect')) inputs.shippingCondition = 'T1';
          else inputs.shippingCondition = 'TJ';
        }
        break;

      case 'United Kingdom':
        if (inputs.language == '') inputs.language = 'English';
        break;

      case 'France':
        if (inputs.language == '') inputs.language = 'French';
        if (inputs.telephoneOption == '') inputs.telephoneOption = 'General/Accounting';
        if (shipToType == 'On-Trade – Direct') inputs.deliveringPlant = 'FR06';
        if (shipToType.includes('On-Trade') || shipToType.includes('Wholesale')) {
          if (inputs.profitCenter == '') inputs.profitCenter = '1200FR00';
        }
        if (shipToType == 'Wholesale– Direct') {
          inputs.segment = '16';
          inputs.segmentDesc = 'Wholesaler';
          inputs.subSegment = '16';
          inputs.subSegmentDesc = 'Wholesaler';
        } else if (shipToType == 'Wholesale– Indirect') {
          inputs.segment = '16';
          inputs.segmentDesc = 'Wholesaler';
          inputs.subSegment = 'BG';
          inputs.subSegmentDesc = 'Sub Agent';
        }
        //  else if (shipToType == 'Off-Trade – Direct') {
        //   inputs.segment = '41';
        //   inputs.segmentDesc = 'Wholesaler / C&Carry';
        //   inputs.subSegment = '70';
        //   inputs.subSegmentDesc = 'Cash & Carry';
        // }
        break;

      case 'Belgium':
        if (inputs.language == '') inputs.language = 'Dutch';
        if (inputs.transportationZone == '') inputs.transportationZone = 'BE15';
        if (shipToType.startsWith('Wholesaler')) {
          inputs.segment = '16';
          inputs.segmentDesc = 'Wholesaler';
        }
        break;

      case 'Germany':
        if (inputs.language == '') inputs.language = 'German';
        if (inputs.shippingCondition == '') inputs.shippingCondition = 'T2';
        if (shipToType.includes('Wholesale')) {
          inputs.segment = '16';
          inputs.segmentDesc = 'Wholesaler';
          inputs.subSegment = '16';
          inputs.subSegmentDesc = 'Wholesaler';
        }
        break;

      case 'Austria':
        if (inputs.language == '') inputs.language = 'German';
        if (inputs.shippingCondition == '') inputs.shippingCondition = 'T1';
        break;

      case 'Denmark':
        inputs.shippingCondition = 'X1';
        if (inputs.language == '') inputs.language = 'Danish';
        break;

      case 'Finland':
        if (inputs.language == '') inputs.language = 'Finnish';
        inputs.shippingCondition = 'X1';
        if (inputs.transportationZone == '') inputs.transportationZone = '0000000001';
        break;

      case 'Italy':
        if (inputs.language == '') inputs.language = 'Italian';
        // if (inputs.profitCenter == '') inputs.profitCenter = '1151IT13';
        break;

      case 'Luxembourg':
        if (inputs.language == '') inputs.language = 'English';
        break;

      case 'Norway':
        if (inputs.language == '') inputs.language = 'Danish';
        inputs.shippingCondition = 'X1';
        if (inputs.transportationZone == '') inputs.transportationZone = '0000000001';
        break;

      case 'Poland':
        if (inputs.language == '') inputs.language = 'Polish';
        if (inputs.shippingCondition == '') inputs.shippingCondition = 'T1';
        break;

      case 'Sweden':
        if (inputs.language == '') inputs.language = 'Swedish';
        if (inputs.shippingCondition == '') inputs.shippingCondition = 'T1';
        break;

      case 'Switzerland':
        if (inputs.language == '') inputs.language = 'French';
        if (inputs.shippingCondition == '') inputs.shippingCondition = 'T1';
        break;

      default:
        break;
    }
    if (inputs.exciseDutyType == '') {
      if (country == 'Belgium' && shipToType == 'Off-Trade – Direct') {
        inputs.exciseDutyType = '001';
        inputs.exciseDutyTypeDesc = '001-Excise Exempt Custom';
      } else if (country == 'United Kingdom') {
        inputs.exciseDutyType = '002-Excise Duty Customer';
        inputs.exciseDutyTypeDesc = '002-Excise Duty Customer';
      } else {
        inputs.exciseDutyType = '002';
        inputs.exciseDutyTypeDesc = '002-Excise Duty Customer';
      }
      // inputs.exciseDutyType = country == 'United Kingdom' ? '002-Excise Duty Customer' : country == 'Belgium' && shipToType == 'Off-Trade – Direct' ? '001-Excise Exempt Custom' : '002-Excise Duty Customer';
      // if (inputs.exciseDutyType.startsWith('002')) inputs.exciseDutyTypeDesc = '002-Excise Duty Customer';
    }
  }, []);

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    // props.setActivePage(e.target.getAttribute('data-link'));
    if (e.target.getAttribute('data-back') === 'true') {
      // console.log('Entering if condition');
      props.setActivePage(e.target.getAttribute('data-link'));
    } else {
      // if (!isDisableFields) {
      // console.log('Entering else condition');
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(validateFields(inputs, country, customerReqType, shipToType));
      // }
      setIsSubmitting(true);
      if (isDisable) props.setActivePage(e.target.getAttribute('data-link'));
    }
  };

  function getLang(id) {
    // console.log(props.translations);
    let obj = props.translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && isDisableFields) {
      setCopyClick(false);
      setIsCopying(true);
      console.log(inputs.existingShipToID);
      console.log('Yo');
      Object.entries(formTemplate.shipto).forEach(([key, value]) => {
        if (
          ![
            'existingShipToID',
            'language',
            'telephoneOption',
            'subChannel',
            'profitCenter',
            'contactPerson',
          ].includes(key)
        )
          inputs[key] = value;
      });
    } else {
      setIsSubmitting(false);
      setIsCopying(false);
      setCopyClick(false);
    }
  }, [errors]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  function copyShipToData(e) {
    // setIsCopying(true);

    if (!isDisable && shipToIDRegex.test(inputs.existingShipToID)) {
      setCopyClick(true);
      setIsCopying(true);
      console.log(`I am true ${isCopying}`);
      console.log(isCopying, inputs.existingShipToID, companyCode, salesOrg);
    }

    // if (!isDisable) {
    //   setErrors(validateFields(inputs, country, customerReqType, shipToType));
    //   setCopyClick(true);
    //   console.log(`I am true ${isCopying}`);
    //   console.log(isCopying, inputs.existingShipToID, companyCode, salesOrg);
    // }

    // Object.entries(formTemplate.shipto).forEach(([key, value]) => {
    //   console.log(`Key = ${key}`)
    //   if (key !== 'existingShipToID' && key !== 'language') inputs[key] = value;
    // });
  }

  useEffect(() => {
    if (Object.keys(shipToDataRes.shipToData).length !== 0 && isCopying) {
      Object.entries(shipToDataRes.shipToData).forEach(([key, value]) => {
        if (key === 'telephone' && value !== '') {
          inputs.telephoneOption = 'General/Accounting';
          inputs.generalTelephone = value;
          console.log('Done');
        }

        if (key in inputs) {
          if (key === 'dNodeCpt' && value !== '') {
            inputs.hierarchyTypeDNode = 'Existing';
            inputs[key] = value;
          } else if (key == 'segment' && value != '') {
            setFetchSubSegments(true);
            inputs[key] = value;
          } else if (key == 'exciseDutyType' && value != '') {
            if (value == '001') inputs.exciseDutyTypeDesc = '001-Excise Exempt Custom';
            else if (value == '002') inputs.exciseDutyTypeDesc = '002-Excise Duty Customer';
            inputs[key] = value;
          } else if (key == 'subChannel') {
            subChannels
              .filter((e) => e.key == value)
              .map((val) => {
                console.log('Found sub channel ', val);
                inputs.subChannel = val.value;
              });
          } else inputs[key] = value;
        }
      });
      sessionStorage.setItem('createCustomerShipTo', JSON.stringify(inputs));
    }
    setIsCopying(false);
    setFetchSubSegments(false);
  }, [shipToDataRes.shipToData]);

  useEffect(() => {
    if (inputs.existingShipToID !== '') setIsDisableFields(true);
    else setIsDisableFields(false);
  }, [inputs.existingShipToID]);

  const Input = (props) => <components.Input {...props} maxLength={8} />;

  const pocOptions = pocSuppliersRes.pocSuppliers.map((val) => {
    return {
      ...val,
      pocNumber: val.pocNumber,
      pocName: val.pocName,
      pocPayer: val.pocPayer,
      pocPriceListERP: val.pocPriceListERP,
      pocDesc: `${val.pocNumber} - ${val.pocName}`,
    };
  });

  const regionOptions = regionsRes.regions.map((val) => {
    return {
      ...val,
      regionCode: val.regionCode,
      regionText: val.regionText,
      erpTranspZone: val.erpTranspZone,
      regionDesc: `${val.regionCode} - ${val.regionText}`,
    };
  });

  // const profitCenterOptions = profitCentersRes.profitCenters.map((val) => {
  //   return {
  //     ...val,

  //     pocNumber: val.pocNumber,
  //     pocName: val.pocName,
  //     pocPayer: val.pocPayer,
  //     pocPriceListERP: val.pocPriceListERP,
  //     pocDesc: `${val.pocNumber} - ${val.pocName}`,
  //   };
  // });

  useEffect(() => {
    console.log(JSON.stringify(errors));
  }, [errors]);

  const sidebarItems = [
    {
      title: getLang('Main Page'),
      ['data-link']: 'mainpage',
      ['data-back']: 'true',
      onClick: handleSubmit,
      active: false,
    },
    {
      title: getLang('Vendor'),
      onClick: handleSubmit,
      ['data-link']: 'vendor',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Ship-To'),
      onClick: handleSubmit,
      active: true,
      ['data-link']: 'shipto',
      ['data-back']: 'true',
      // type: 'submit',
    },
    {
      title: getLang('Payer'),
      onClick: handleSubmit,
      ['data-link']: 'payer',
      // ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Sold-To'),
      onClick: handleSubmit,
      ['data-link']: 'soldto',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Bill-To'),
      onClick: handleSubmit,
      ['data-link']: 'billto',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
  ];

  return (
    isAuthenticated && (
      <form className="card" onSubmit={handleSubmit}>
        <Modal show={isCopying} centered>
          <Modal.Body>
            <Row>
              <div className="col-auto">
                <Spinner animation="border" variant="warning" />
              </div>
              <div className="text-left">
                <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
        <div className="card-header">
          <div className="col-11 col-lg-12">
            <h3 className="card-title text-left">
              <strong style={{ color: 'rgb(177, 31, 36)' }}>
                Vendor Customer {getLang('Creation')}
              </strong>{' '}
              - {getLang('Ship To')}
            </h3>
          </div>
          <a
            className={`sidebar-toggler col-auto ${isSidebarOpen ? '' : 'collapsed'}`}
            // onClick={togglingSidebar}
          >
            <Sidebar sidebarItems={sidebarItems} />
            {/* <span className="sidebar-toggler-icon"></span> */}
          </a>
        </div>
        <Row className="sidebar-row">
          <div
            className={`col-12 col-lg-2 text-left sidebar ${
              isMobile ? (isSidebarOpen ? '' : 'collapse') : ''
            }`}
          >
            <Row>
              <ButtonGroup vertical className="btn-block">
                <Link
                  data-link="mainpage"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {getLang('Main Page')}
                </Link>
                <Link to="" data-link="vendor" className="btn btn-warning" onClick={handleSubmit}>
                  {getLang('Vendor')}
                </Link>
                <Button className="btn-danger">{getLang('Ship To')}</Button>
                {customerReqType !== 'Ship To' && (
                  <>
                    <Link data-link="payer" className="btn btn-warning" onClick={handleSubmit}>
                      {getLang('Payer')}
                    </Link>
                    <Link data-link="soldto" className="btn btn-warning" onClick={handleSubmit}>
                      {getLang('Sold To')}
                    </Link>
                    <Link data-link="billto" className="btn btn-warning" onClick={handleSubmit}>
                      {getLang('Bill To')}
                    </Link>
                  </>
                )}
              </ButtonGroup>
            </Row>
          </div>
          <div className="col-12 col-lg-10">
            <div className="card-body">
              <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

              <div className="row">
                <div className="col-lg-12">
                  {/* {!['Full Creation', 'Listing Fees', 'Ship To'].includes(customerReqType) && (
                    <>
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Ship-to ID')}
                              {['New Owner', '2nd Sold To'].includes(customerReqType) ? ' *' : ''}
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.existingShipToID ? 'is-invalid' : ''
                                }`}
                                name="existingShipToID"
                                maxLength="8"
                                placeholder="Enter Existing Ship-to ID"
                                onChange={setInputs}
                                value={inputs.existingShipToID}
                                disabled={
                                  isDisable ||
                                  ['Full Creation', 'Listing Fees'].includes(customerReqType)
                                }
                              />
                              <span className="input-group-append">
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  style={{
                                    borderTopRightRadius: '3px',
                                    borderBottomRightRadius: '3px',
                                  }}
                                  onClick={copyShipToData}
                                  disabled={isDisable || inputs.existingShipToID === ''}
                                >
                                  {getLang('Copy')}
                                </button>
                              </span>
                              {errors.existingShipToID && (
                                <div className="invalid-feedback text-left">
                                  {errors.existingShipToID}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </>
                  )} */}

                  <div className="row">
                    <div className="col-12 col-lg-4 text-left">
                      <h2
                        style={{
                          color: 'rgb(177, 31, 36)',
                        }}
                      >
                        {getLang('General Data')}
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Name')} *</label>
                        <input
                          type="text"
                          className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                          name="name"
                          placeholder={
                            ['Belgium', 'Germany'].includes(country)
                              ? getLang('Name')
                              : `Legal ${getLang('Name')}`
                          }
                          onChange={setInputs}
                          value={inputs.name}
                          maxLength="35"
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.name && (
                          <div className="invalid-feedback text-left">{errors.name}</div>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Name 2')}</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name2"
                          onChange={setInputs}
                          value={inputs.name2}
                          maxLength="35"
                          disabled={
                            isDisableFields || isDisable || customerReqType == '2nd Sold To'
                          }
                        />
                      </div>
                    </div> */}

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Postal Code')} *</label>
                        <input
                          type="text"
                          className={`form-control ${errors.postalCode ? 'is-invalid' : ''}`}
                          name="postalCode"
                          onChange={setInputs}
                          value={inputs.postalCode}
                          maxLength={postalCodeLength(country)}
                          // maxLength={['Germany', 'France'].includes(country) ? 5 : country === 'Belgium' ? 4 : 9}
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.postalCode && (
                          <div className="invalid-feedback text-left">{errors.postalCode}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Street')} *</label>
                        <input
                          type="text"
                          className={`form-control ${errors.street ? 'is-invalid' : ''}`}
                          name="street"
                          onChange={setInputs}
                          value={inputs.street}
                          maxLength="35"
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.street && (
                          <div className="invalid-feedback text-left">{errors.street}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('House Number')} {country !== 'United Kingdom' ? ' *' : ''}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.houseNumber ? 'is-invalid' : ''}`}
                          name="houseNumber"
                          maxLength="10"
                          onChange={setInputs}
                          value={inputs.houseNumber}
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.houseNumber && (
                          <div className="invalid-feedback text-left">{errors.houseNumber}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('City')} *</label>
                        <input
                          type="text"
                          className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                          name="city"
                          onChange={setInputs}
                          value={inputs.city}
                          maxLength="35"
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.city && (
                          <div className="invalid-feedback text-left">{errors.city}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Country')}</label>
                        <input
                          type="text"
                          className="form-control"
                          name="country"
                          placeholder=""
                          value={country}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Company Code')}*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="companyCode"
                          placeholder=""
                          value={companyCode}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Sales Org')}*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="salesOrg"
                          placeholder=""
                          value={salesOrg}
                          readOnly
                        />
                      </div>
                    </div>

                    {/* {!(customerReqType === 'New Owner' || country == 'Luxembourg') && ( */}
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Region')} *</label>
                        <Select
                          className="text-left"
                          name="region"
                          value={regionOptions.filter((opt) => opt.regionCode == inputs.region)}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: '#cfcfcf',
                              primary50: '#cfcfcf',
                              primary: 'orange',
                            },
                          })}
                          isClearable
                          placeholder="Select"
                          isDisabled={isDisableFields || isDisable}
                          getOptionLabel={({ regionDesc }) => regionDesc}
                          getOptionValue={({ regionCode }) => regionCode}
                          options={regionOptions}
                          onChange={(e, action) => {
                            setInputs(e, action);
                          }}
                        />
                        {errors.region && (
                          <div className="invalid-feedback-file text-left">{errors.region}</div>
                        )}
                      </div>
                    </div>
                    {/* )} */}

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Language')}</label>
                        <select
                          id="select-lang"
                          className="form-control"
                          name="language"
                          value={inputs.language}
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        >
                          <option value="">Select</option>
                          {/* {languages.map((lang) => (
                              <option key={lang} value={lang}>
                                {lang}
                              </option>
                            ))} */}
                          {/* {languages
                                .filter((e) => ['Dutch', 'French'].includes(e))
                                .map((val) => (
                                  <option key={val.lang} value={val.lang}>
                                    {val.lang}
                                  </option>
                                ))} */}
                          <option value="Dutch">Dutch</option>
                          <option value="French">French</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Telephone')}
                          {[
                            'Austria',
                            'Denmark',
                            'Finland',
                            'Italy',
                            'Luxembourg',
                            'Norway',
                            'Poland',
                            'Sweden',
                            'Switzerland',
                            'United Kingdom',
                            'Belgium',
                          ].includes(country) || country === 'Netherlands'
                            ? ' *'
                            : ''}
                        </label>
                        <select
                          id="select-telephone"
                          className={`form-control ${errors.telephoneOption ? 'is-invalid' : ''}`}
                          name="telephoneOption"
                          value={inputs.telephoneOption}
                          onChange={setInputs}
                          disabled={country == 'France' || isDisableFields || isDisable}
                        >
                          <option value="">Select</option>
                          {telephoneOptions.map((tel) => (
                            <option key={tel} value={tel}>
                              {tel}
                            </option>
                          ))}
                        </select>
                        {errors.telephoneOption && (
                          <div className="invalid-feedback text-left">{errors.telephoneOption}</div>
                        )}
                      </div>
                    </div>
                    {inputs.telephoneOption !== '' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Telephone Number')}{' '}
                            {['United Kingdom', 'Belgium', 'Finland', 'Italy'].includes(country) ||
                            country === 'Netherlands'
                              ? ' *'
                              : ''}
                          </label>
                          {inputs.telephoneOption === 'General/Accounting' && (
                            <input
                              type="tel"
                              className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}
                              name="generalTelephone"
                              placeholder={telephonePlaceholder(country)}
                              maxLength={telephoneLength(country)}
                              value={inputs.generalTelephone}
                              onChange={setInputs}
                              disabled={isDisableFields || isDisable}
                            />
                          )}
                          {inputs.telephoneOption === 'Logistics' && (
                            <input
                              type="tel"
                              className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}
                              name="logTelephone"
                              placeholder={telephonePlaceholder(country)}
                              maxLength={telephoneLength(country)}
                              value={inputs.logTelephone}
                              onChange={setInputs}
                              disabled={isDisableFields || isDisable}
                            />
                          )}
                          {inputs.telephoneOption === 'Invoicing' && (
                            <input
                              type="tel"
                              className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}
                              name="invTelephone"
                              placeholder={telephonePlaceholder(country)}
                              maxLength={telephoneLength(country)}
                              value={inputs.invTelephone}
                              onChange={setInputs}
                              disabled={isDisableFields || isDisable}
                            />
                          )}
                          {inputs.telephoneOption === 'Ordering' && (
                            <input
                              type="tel"
                              className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}
                              name="orderTelephone"
                              placeholder={telephonePlaceholder(country)}
                              maxLength={telephoneLength(country)}
                              value={inputs.orderTelephone}
                              onChange={setInputs}
                              disabled={isDisableFields || isDisable}
                            />
                          )}
                          {errors.telephone && (
                            <div className="invalid-feedback text-left">{errors.telephone}</div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{`${getLang('Mobile')}${
                          ['France', 'Italy'].includes(country) ? ' *' : ''
                        }`}</label>
                        <input
                          type="tel"
                          placeholder={mobilePlaceholder(country)}
                          className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                          name="mobile"
                          maxLength={mobileLength(country)}
                          // maxLength={['Belgium', 'Germany'].includes(country) ? 12 : country == 'Netherlands' ? 9 : 11}
                          value={inputs.mobile}
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.mobile && (
                          <div className="invalid-feedback text-left">{errors.mobile}</div>
                        )}
                      </div>
                    </div>
                    {/* Create a new class for required? */}
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('E-mail Address')}</label>
                        <input
                          type="email"
                          className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                          name="email"
                          placeholder="Eg.: abc@company.com"
                          value={inputs.email}
                          onChange={setInputs}
                          maxLength="240"
                          disabled={isDisableFields || isDisable}
                        />
                        {/* {errors.email && (
                          <div className="invalid-feedback text-left">{errors.email}</div>
                        )} */}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Contact Person')}</label>
                        <input
                          type="text"
                          className={`form-control ${errors.contactPerson ? 'is-invalid' : ''}`}
                          name="contactPerson"
                          placeholder=""
                          value={inputs.contactPerson}
                          onChange={setInputs}
                          maxLength="35"
                          disabled={isDisableFields || isDisable}
                        />
                        {/* {errors.contactPerson && (
                          <div className="invalid-feedback text-left">{errors.contactPerson}</div>
                        )} */}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-12 col-lg-4 text-left">
                      <h2
                        style={{
                          color: 'rgb(177, 31, 36)',
                        }}
                      >
                        {getLang('Sales Data')}
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Segment')} *</label>
                        <select
                          id="select-segment"
                          className={`form-control ${errors.segment ? 'is-invalid' : ''}`}
                          onChange={setInputs}
                          name="segment"
                          value={inputs.segment}
                          disabled={
                            isDisableFields ||
                            isDisable ||
                            shipToType.startsWith('Wholesale') ||
                            (country == 'France' && shipToType.startsWith('Wholesale'))
                          }
                        >
                          <option value="">Select</option>
                          {segmentsRes.segments.map((val) =>
                            ['Italy'].includes(country) ? (
                              <option
                                key={val.segment}
                                value={val.segmentCode}
                                data-segment={val.segment}
                              >
                                {`${val.segmentCode} - ${val.segment}`}
                              </option>
                            ) : (
                              <option
                                key={val.segment}
                                value={val.segmentCode}
                                data-segment={val.segment}
                              >
                                {val.segment}
                              </option>
                            ),
                          )}
                        </select>
                        {errors.segment && (
                          <div className="invalid-feedback text-left">{errors.segment}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Sub Segment')} *</label>
                        <select
                          id="select-sub-segment"
                          className={`form-control ${errors.subSegment ? 'is-invalid' : ''}`}
                          value={inputs.subSegment}
                          name="subSegment"
                          onChange={setInputs}
                          disabled={
                            isDisableFields ||
                            !inputs.segment ||
                            isDisable ||
                            (country == 'France' && shipToType.startsWith('Wholesale'))
                          }
                        >
                          <option value="">Select</option>
                          {/* {shipToType.startsWith('Wholesale') && (
                            <option value="16" data-subsegment="Wholesaler">
                              Wholesaler
                            </option>
                          )} */}
                          {subSegmentsRes.subSegments.map((val) =>
                            ['Italy'].includes(country) ? (
                              <option
                                key={val.subSegment}
                                value={val.subSegmentCode}
                                data-subsegment={val.subSegment}
                              >
                                {`${val.subSegmentCode} - ${val.subSegment}`}
                              </option>
                            ) : (
                              <option
                                key={val.subSegment}
                                value={val.subSegmentCode}
                                data-subsegment={val.subSegment}
                              >
                                {val.subSegment}
                              </option>
                            ),
                          )}
                        </select>
                        {errors.subSegment && (
                          <div className="invalid-feedback text-left">{errors.subSegment}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Excise duty type')}{' '}
                          {country === 'Belgium' && shipToType === 'On-Trade – Indirect'
                            ? ' *'
                            : ''}
                        </label>
                        {country === 'United Kingdom' && (
                          <input
                            type="text"
                            className="form-control"
                            name="exciseDutyType"
                            value={inputs.exciseDutyType}
                            disabled
                          />
                        )}
                        {country !== 'United Kingdom' && (
                          <select
                            id="select-excise"
                            className="form-control"
                            value={inputs.exciseDutyType}
                            name="exciseDutyType"
                            onChange={setInputs}
                            disabled={
                              isDisableFields ||
                              isDisable ||
                              (country == 'France' &&
                                ['On-Trade – Indirect', 'Off-Trade – Direct'].includes(
                                  shipToType,
                                )) ||
                              (country == 'Belgium' && shipToType == 'Off-Trade - Indirect')
                            }
                          >
                            {/* <option value="">Select</option> */}
                            {exciseOptions.map((opt) => (
                              <option
                                key={opt.key}
                                value={opt.key}
                                data-excise={`${opt.key}-${opt.val}`}
                              >
                                {`${opt.key}-${opt.val}`}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                    {/* {shipToType.startsWith('Wholesaler') && (
                      <>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Tax Warehouse Number')}
                              {inputs.exciseDutyType == '001' ? ' *' : ''}
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                errors.taxWarehouseNumber ? 'is-invalid' : ''
                              }`}
                              name="taxWarehouseNumber"
                              maxLength="15"
                              value={inputs.taxWarehouseNumber}
                              onChange={setInputs}
                              disabled={
                                isDisableFields || isDisable || customerReqType == '2nd Sold To'
                              }
                            />
                            {errors.taxWarehouseNumber && (
                              <div className="invalid-feedback text-left">
                                {errors.taxWarehouseNumber}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('External ED Number')}
                              {inputs.exciseDutyType == '001' ? ' *' : ''}
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                errors.warehouseExternalEdNumber ? 'is-invalid' : ''
                              }`}
                              name="warehouseExternalEdNumber"
                              maxLength="35"
                              value={inputs.warehouseExternalEdNumber}
                              onChange={setInputs}
                              disabled={
                                isDisableFields || isDisable || customerReqType == '2nd Sold To'
                              }
                            />
                            {errors.warehouseExternalEdNumber && (
                              <div className="invalid-feedback text-left">
                                {errors.warehouseExternalEdNumber}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )} */}

                    {/* {['France', 'Belgium', 'Germany'].includes(country) &&
                      shipToType.includes('Indirect') && (
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('If POC Supplier not in list please specify here')} *
                            </label>
                            {
                              <input
                                type="text"
                                className={`form-control ${errors.pocExtra ? 'is-invalid' : ''}`}
                                name="pocSupplierShipTo"
                                maxLength="8"
                                value={inputs.pocSupplierShipTo}
                                onChange={setInputs}
                                disabled={
                                  isDisableFields || isDisable || customerReqType == '2nd Sold To'
                                }
                              />
                            }
                            {errors.pocExtra && (
                              <div className="invalid-feedback text-left">{errors.pocExtra}</div>
                            )}
                          </div>
                        </div>
                      )} */}
                    {((country == 'France' &&
                      ['On-Trade – Direct', 'Wholesale– Direct'].includes(shipToType)) ||
                      (country == 'United Kingdom' && shipToType == 'Off-Trade - Retail') ||
                      (country == 'Netherlands' && shipToType.endsWith('Direct')) ||
                      country == 'Italy' ||
                      (country == 'Belgium' &&
                        [
                          'On-Trade – Direct',
                          'National On-Trade Direct',
                          'Off-Trade – Direct',
                          'Off-Trade - Indirect',
                          'Wholesaler - Sub Agent',
                          'Wholesaler - Main Agent',
                        ].includes(shipToType))) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Delivering Plant')}
                            {country == 'Italy' ||
                            (country == 'Belgium' &&
                              [
                                'On-Trade – Direct',
                                'National On-Trade Direct',
                                'Off-Trade – Direct',
                                'Off-Trade - Indirect',
                              ].includes(shipToType))
                              ? ' *'
                              : ''}
                          </label>
                          <select
                            id="select-delivering-plant"
                            className={`form-control ${errors.deliveringPlant ? 'is-invalid' : ''}`}
                            name="deliveringPlant"
                            value={
                              country === 'Netherlands' && shipToType === 'Off-Trade - Indirect'
                                ? 'NL03'
                                : inputs.deliveringPlant
                            }
                            disabled={
                              isDisableFields ||
                              isDisable ||
                              (country == 'France' && shipToType == 'On-Trade – Direct') ||
                              (country === 'Netherlands' && shipToType === 'Off-Trade - Indirect')
                            }
                            onChange={setInputs}
                          >
                            <option value="">Select</option>
                            {country === 'Netherlands' && shipToType === 'Off-Trade - Indirect' && (
                              <option value="NL03">NL03 - EERSEL DC WINCANTON</option>
                            )}
                            {!(country == 'Belgium' && shipToType.includes('Wholesaler')) &&
                              deliveringPlantsRes.deliveringPlants.map((val) => (
                                <option
                                  key={val.plantCode}
                                  value={val.plantCode}
                                  data-plant={`${val.plantCode} - ${val.plantDesc}`}
                                >{`${val.plantCode} - ${val.plantDesc}`}</option>
                              ))}
                            {country == 'Belgium' &&
                              shipToType.includes('Wholesaler') &&
                              deliveringPlantsRes.deliveringPlants.map(
                                (val) =>
                                  val.plantCode != 'BE15' && (
                                    <option
                                      key={val.plantCode}
                                      value={val.plantCode}
                                      data-plant={`${val.plantCode} - ${val.plantDesc}`}
                                    >{`${val.plantCode} - ${val.plantDesc}`}</option>
                                  ),
                              )}
                          </select>
                          {errors.deliveringPlant && (
                            <div className="invalid-feedback text-left">
                              {errors.deliveringPlant}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Sub Channel')}</label>
                        <select
                          className={`form-control ${errors.subChannel ? 'is-invalid' : ''}`}
                          name="subChannel"
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        >
                          {/* <option value="">Select</option> */}
                          <option value="">Select</option>

                          {subChannels
                            .filter((e) => ['XXX', '010'].includes(e.key))
                            .map((val) => (
                              <option key={val.key} value={val.value}>
                                {val.value}
                              </option>
                            ))}

                          {/* <option value="XXX">XXX</option>
                            <option value="010">010</option> */}
                        </select>
                        {errors.subChannel && (
                          <div className="invalid-feedback text-left">{errors.subChannel}</div>
                        )}
                      </div>
                    </div>

                    {/* 
                    {(country === 'France' ||
                      country === 'Italy' ||
                      (country == 'Belgium' &&
                        (shipToType.startsWith('National') ||
                          shipToType.startsWith('On-Trade') ||
                          shipToType.startsWith('Off-Trade') ||
                          shipToType.endsWith('Indirect')))) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Attribute Type 7')}
                            {country == 'Belgium' ||
                            country == 'Italy' ||
                            (country == 'France' && shipToType == 'Off-Trade – Direct')
                              ? ' *'
                              : ''}
                          </label>
                          <select
                            className={`form-control ${errors.subChannel ? 'is-invalid' : ''}`}
                            name="subChannel"
                            value={inputs.subChannel}
                            onChange={setInputs}
                            disabled={
                              isDisableFields ||
                              isDisable ||
                              (country == 'France' && shipToType.startsWith('Wholesale')) ||
                              customerReqType == '2nd Sold To'
                            }
                          >
                            <option value="">Select</option>
                            {country == 'Netherlands' &&
                              subChannels
                                .filter((e) => ['XXX', '001'].includes(e.key))
                                .map((val) => (
                                  <option key={val.key} value={val.value}>
                                    {val.value}
                                  </option>
                                ))}
                            {country == 'France' &&
                              shipToType == 'Off-Trade – Direct' &&
                              subChannels
                                .filter((e) => ['001', '008'].includes(e.key))
                                .map((val) => (
                                  <option key={val.key} value={val.value}>
                                    {val.value}
                                  </option>
                                ))}
                            {country == 'France' &&
                              shipToType != 'Off-Trade – Direct' &&
                              subChannels
                                .filter((e) => ['XXX', '001', '003', '008'].includes(e.key))
                                .map((val) => (
                                  <option key={val.key} value={val.value}>
                                    {val.value}
                                  </option>
                                ))}
                            {(country == 'Belgium' || country == 'Italy') &&
                              shipToType.startsWith('On-Trade') &&
                              subChannels
                                .filter((e) => ['XXX', '001', '007', '016', '017'].includes(e.key))
                                .map((val) => (
                                  <option key={val.key} value={val.value}>
                                    {val.value}
                                  </option>
                                ))}
                            {(country == 'Belgium' || country == 'Italy') &&
                              shipToType.startsWith('Off-Trade') &&
                              subChannels
                                .filter((e) => ['XXX', '002', '007', '010'].includes(e.key))
                                .map((val) => (
                                  <option key={val.key} value={val.value}>
                                    {val.value}
                                  </option>
                                ))}
                            {country == 'Belgium' &&
                              shipToType.startsWith('National') &&
                              subChannels
                                .filter((e) => ['001', '003'].includes(e.key))
                                .map((val) => (
                                  <option key={val.key} value={val.value}>
                                    {val.value}
                                  </option>
                                ))}
                          </select>
                          {errors.subChannel && (
                            <div className="invalid-feedback text-left">{errors.subChannel}</div>
                          )}
                        </div>
                      </div>
                    )} */}

                    {country == 'Italy' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Truck Type')} *</label>
                          <select
                            className={`form-control ${errors.truckType ? 'is-invalid' : ''}`}
                            name="truckType"
                            value={inputs.truckType}
                            onChange={setInputs}
                            disabled={isDisableFields || isDisable}
                          >
                            <option value="">Select</option>
                            <option value="ZITTR3 - TRACTOR 3 AXELS">{`ZITTR3 - TRACTOR 3 AXELS`}</option>
                          </select>
                          {errors.truckType && (
                            <div className="invalid-feedback text-left">{errors.truckType}</div>
                          )}
                        </div>
                      </div>
                    )}
                    {country == 'Italy' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Trailer Type')} *
                          </label>
                          <select
                            className={`form-control ${errors.trailerType ? 'is-invalid' : ''}`}
                            name="trailerType"
                            value={inputs.trailerType}
                            onChange={setInputs}
                            disabled={isDisableFields || isDisable}
                          >
                            <option value="">Select</option>
                            <option value="ZITMOTRI - MOTRICE (16 PLT)">{`ZITMOTRI - MOTRICE (16 PLT)`}</option>
                            <option value="ZITAN3 - TRAILER 3 AXELS">{`ZITAN3 - TRAILER 3 AXELS`}</option>
                          </select>
                          {errors.trailerType && (
                            <div className="invalid-feedback text-left">{errors.trailerType}</div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>

        <div className="card-footer">
          <div className="row">
            <div className="col-auto col-md-auto text-left">
              {!isDisable && (
                <Button
                  variant="outline-warning"
                  onClick={props.saveDraft}
                  // disabled={process.env.REACT_APP == 'prod'}
                >
                  Save Draft
                </Button>
              )}
            </div>
            <div className="col col-md text-left justify-content">{props.draftMsg}</div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link="vendor"
                data-back="true"
                className="btn btn-warning btn-block"
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Back
              </Link>
            </div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link={customerReqType === 'Ship To' ? 'review' : 'payer'}
                className={`btn btn-${
                  Object.keys(validateFields(inputs)).length == 0 || isDisable
                    ? 'danger'
                    : 'secondary'
                } btn-block`}
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                {customerReqType === 'Ship To' ? 'Review' : 'Next'}
              </Link>
            </div>
          </div>
        </div>
      </form>
    )
  );
};

// inputs.name == '' ? null :

export default CreateCustShipTo;
