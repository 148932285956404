import {
  ibanRegex,
  postalCodeRegex,
  telephoneRegex,
  emailRegex,
  payerIDRegex,
  vatRegex,
  companyNumRegex,
} from '../../../utils/Utils';

export function validateFields(values) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country;

  const customerReqType = JSON.parse(sessionStorage.getItem('createCustomerMainPage'))
    .customerReqType;

  const shipToType = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).shipToType;

  const title = JSON.parse(sessionStorage.getItem('createCustomerPayer')).title;

  if (values.existingPayerID !== '' && !payerIDRegex.test(values.existingPayerID)) {
    errors.existingPayerID = 'Payer ID is incorrect';
  } else if (payerIDRegex.test(values.existingPayerID) && values.name == '') {
    errors.existingPayerID = 'Click Copy';
  } else if (values.existingPayerID === '') {
    // if (country == 'Germany' && customerReqType == '2nd Sold To') {
    //   errors.existingPayerID = 'Payer ID is required';
    // } else
    if (['Italy'].includes(country) && values.title == '') {
      errors.title = 'Title is required';
    } else if (
      values.vatRegNumber === '' &&
      ((country == 'United Kingdom' && shipToType != 'On-Trade – Indirect') ||
        [
          'Austria',
          'Denmark',
          'Finland',
          'Italy',
          'Luxembourg',
          'Norway',
          'Poland',
          'Sweden',
          'Switzerland',
        ].includes(country) ||
        (country == 'Netherlands' && customerReqType != 'Prospect') ||
        (country == 'France' && values.title != 'ASBL') ||
        (country == 'Belgium' && shipToType == 'Off-Trade – Direct') ||
        values.vatExist == 'Yes')
    ) {
      errors.vatRegNumber = 'VAT Reg Number is required';
    } else if (
      !['No', 'In Progress'].includes(values.vatExist) &&
      values.vatRegNumber != '' &&
      !vatRegex[values.payerCountry].test(values.vatRegNumber)
    ) {
      errors.vatRegNumber = 'VAT Reg Number is incorrect';
    } else if (country == 'Italy' && values.taxNumber == '') {
      errors.taxNumber == 'Tax Number is required';
    } else if (values.name === '') {
      errors.name = 'Name is required';
    } else if (values.postalCode === '') {
      errors.postalCode = 'Postal Code is required';
    } else if (
      values.postalCode !== '' &&
      !postalCodeRegex[values.payerCountry].test(values.postalCode)
    ) {
      errors.postalCode = 'Postal code is incorrect';
    } else if (values.houseNumber === '' && country !== 'United Kingdom') {
      errors.houseNumber = 'House number is required';
    } else if (values.street === '') {
      errors.street = 'Street is required';
    } else if (values.city === '') {
      errors.city = 'City is required';
    } else if (values.payerCountry == '') {
      errors.payerCountry = 'Country is required';
    } else if (values.payerCountry != 'Luxembourg' && values.region === '') {
      errors.region = 'Region is required';
    } else if (
      values.mobile === '' &&
      ['Netherlands', 'United Kingdom', 'France', 'Italy'].includes(country)
    ) {
      errors.mobile = 'Mobile is required';
    } else if (values.mobile !== '' && !telephoneRegex.test(values.mobile)) {
      errors.mobile = 'Mobile number is incorrect';
    } else if (
      values.telephone === '' &&
      [
        'Austria',
        'Denmark',
        'Finland',
        'Italy',
        'Norway',
        'Poland',
        'Sweden',
        'Switzerland',
        'Netherlands',
        'Belgium',
      ].includes(country)
    ) {
      errors.telephone = 'Telephone is required';
    } else if (values.telephone !== '' && !telephoneRegex.test(values.telephone)) {
      errors.telephone = 'Telephone number is incorrect';
    } else if (values.email == '') {
      errors.email = 'Email is required';
    } else if (values.email !== '' && !emailRegex.test(values.email)) {
      errors.email = 'Email is incorrect';
    } else if (country == 'Belgium' && values.contactPerson === '') {
      errors.contactPerson = 'Contact Person is required';
    } else if (
      (country == 'Netherlands' ||
        ['Austria', 'Italy', 'Norway', 'Poland', 'Switzerland'].includes(country) ||
        country == 'United Kingdom' ||
        country == 'Belgium' ||
        country == 'France') &&
      values.iban == ''
    ) {
      console.log(`IBAN: ${values.iban}`);
      errors.iban = 'IBAN is required';
    } else if (values.iban != '' && !ibanRegex[country].test(values.iban)) {
      errors.iban = 'IBAN is incorrect';
    } else if (['Italy'].includes(country) && values.swiftBicCode == '') {
      errors.swiftBicCode = 'SWIFT/BIC Code is required';
    } else if (values.iban != '' && values.supportDocsBank == '') {
      errors.supportDocsBank = 'Support document is required';
    } else if (
      values.payerCountry != 'Italy' &&
      values.eInvOption == 'Yes' &&
      values.eInvEmailType === ''
    ) {
      errors.eInvEmailType = 'E-Invoicing Email is required';
    } else if (values.payerCountry != 'Italy' && values.eInvOption == 'Yes') {
      if (
        values.eInvEmailType === 'General/Accounting' &&
        values.eInvGeneralEmail != '' &&
        !emailRegex.test(values.eInvGeneralEmail)
      ) {
        errors.eInvEmail = 'Accounting Email is incorrect';
      } else if (values.eInvEmailType === 'General/Accounting' && values.eInvGeneralEmail == '') {
        errors.eInvEmail = 'Accounting Email is required';
      } else if (
        values.eInvEmailType === 'Logistics' &&
        values.eInvLogEmail != '' &&
        !emailRegex.test(values.eInvLogEmail)
      ) {
        errors.eInvEmail = 'Logistics Email is incorrect';
      } else if (values.eInvEmailType === 'Logistics' && values.eInvLogEmail == '') {
        errors.eInvEmail = 'Logistics Email is required';
      } else if (
        values.eInvEmailType === 'Invoicing' &&
        values.eInvInvEmail != '' &&
        !emailRegex.test(values.eInvInvEmail)
      ) {
        errors.eInvEmail = 'Invoicing Email is incorrect';
      } else if (values.eInvEmailType === 'Invoicing' && values.eInvInvEmail == '') {
        errors.eInvEmail = 'Invoicing Email is required';
      } else if (
        values.eInvEmailType === 'Ordering' &&
        values.eInvOrderEmail != '' &&
        !emailRegex.test(values.eInvOrderEmail)
      ) {
        errors.eInvEmail = 'Ordering Email is incorrect';
      } else if (values.eInvEmailType === 'Ordering' && values.eInvOrderEmail == '') {
        errors.eInvEmail = 'Ordering Email is required';
      }
    } else if (values.isVendor == 'Yes' && values.vendorNumber == '') {
      errors.vendorNumber = 'Vendor number is required';
    } else if (values.paymentTerms === '') {
      errors.paymentTerms = 'Payment Term is required';
    }
  }
  return errors;
}
