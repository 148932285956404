import React from 'react';
import { Row, Col } from 'react-bootstrap';

const MandatoryText = (props) => (
  <>
    <Row>
      <Col>
        <h5 className="text-left red-text">{props.text}</h5>
      </Col>
    </Row>
    <br />
  </>
);

export default MandatoryText;
