import React, { createContext, useReducer } from 'react';

const UserContext = createContext();

const initialState = {
  role: 'requestor',
};

let reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER': {
      return { ...state, ...action.payload };
    }
  }
  return state;
};

function UserContextProvider(props) {
  let [user, userDispatch] = useReducer(reducer, { ...initialState });
  let value = { user, userDispatch };

  return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>;
}

let UserContextConsumer = UserContext.Consumer;
export { UserContext, UserContextProvider, UserContextConsumer };
