import { useState, useEffect } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  const [selectedFile, setSelectedFile] = useState([]);

  const [fileB64Str, setFileB64Str] = useState('');
  const [fileName, setFileName] = useState('');

  const [fileB64Str1, setFileB64Str1] = useState('');
  const [fileName1, setFileName1] = useState('');

  const [fileB64Str2, setFileB64Str2] = useState('');
  const [fileName2, setFileName2] = useState('');

  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country;

  const encodeFileB64 = (file, link) => {
    var reader = new FileReader();
    if (file) {
      if (file.size < 1000000) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var b64 = reader.result;
          console.log(`B64 = ${b64}`);
          console.log(file);
          setFileB64Str(b64);
          setFileName(file.name);
        };
        reader.onerror = (err) => {
          console.log(`Error: ${err}`);
        };
      }
    }
  };

  const encodeFile1B64 = (file, link) => {
    var reader = new FileReader();
    if (file) {
      if (file.size < 1000000) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var b64 = reader.result;
          console.log(`B64 = ${b64}`);
          console.log(file);
          setFileB64Str1(b64);
          setFileName1(file.name);
        };
        reader.onerror = (err) => {
          console.log(`Error: ${err}`);
        };
      }
    }
  };

  const encodeFile2B64 = (file, link) => {
    var reader = new FileReader();
    if (file) {
      if (file.size < 1000000) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var b64 = reader.result;
          console.log(`B64 = ${b64}`);
          console.log(file);
          setFileB64Str2(b64);
          setFileName2(file.name);
        };
        reader.onerror = (err) => {
          console.log(`Error: ${err}`);
        };
      }
    }
  };

  useEffect(() => {
    sessionStorage.setItem('createCustomerPlantData', JSON.stringify(fields));
  }, [fields]);

  useEffect(() => {
    console.log('lalal');
    if (fileB64Str !== '' && fileName !== '') {
      setValues({
        ...fields,
        ['supportDocsBank']: fileB64Str,
        ['supportDocsBankName']: fileName,
      });
    }
  }, [fileB64Str, fileName]);

  useEffect(() => {
    console.log('lalal');
    if (fileB64Str1 !== '' && fileName1 !== '') {
      setValues({
        ...fields,
        ['supportDocsBank1']: fileB64Str1,
        ['supportDocsBankName1']: fileName1,
      });
    }
  }, [fileB64Str1, fileName1]);

  useEffect(() => {
    console.log('lalal');
    if (fileB64Str2 !== '' && fileName2 !== '') {
      setValues({
        ...fields,
        ['supportDocsBank2']: fileB64Str2,
        ['supportDocsBankName2']: fileName2,
      });
    }
  }, [fileB64Str2, fileName2]);

  return [
    fields,
    function (event, action) {
      if (action?.name == 'paymentTerms') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            paymentTerms: '',
            paymentTermsDesc: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            paymentTerms: event.termsOfPayment,
            paymentTermsDesc: event.paymentVal,
          });
        }
      } else if (action?.name == 'region') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            region: '',
            regionDesc: '',
            erpTranspZone: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            region: event.regionCode,
            regionDesc: event.regionDesc,
            erpTranspZone: event.erpTranspZone,
          });
        }
      } else if (action?.name == 'price') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            price: '',
            priceDesc: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            price: event.priceCode,
            priceDesc: event.priceDesc,
          });
        }
      } else if (event.target.name === 'supportDocsBank') {
        encodeFileB64(event.target.files[0], event.target.value);
        // console.log(`Res: ${fileB64Str}`);
      } else if (event.target.id === 'clearFile') {
        console.log('sup');
        setValues({
          ...fields,
          ['supportDocsBank']: '',
          ['supportDocsBankName']: '',
        });
      } else if (event.target.name === 'supportDocsBank1') {
        encodeFile1B64(event.target.files[0], event.target.value);
        console.log(`Res: ${fileB64Str}`);
      } else if (event.target.id === 'clearFile1') {
        console.log('sup1');
        setValues({
          ...fields,
          ['supportDocsBank1']: '',
          ['supportDocsBankName1']: '',
        });
      } else if (event.target.name === 'supportDocsBank2') {
        encodeFile2B64(event.target.files[0], event.target.value);
        console.log(`Res: ${fileB64Str}`);
      } else if (event.target.id === 'clearFile2') {
        console.log('sup2');
        setValues({
          ...fields,
          ['supportDocsBank2']: '',
          ['supportDocsBankName2']: '',
        });
      } else if (event.target.name === 'deliveringPlant' && event.target.value !== '') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          deliveringPlantDesc: event.target.selectedOptions[0].getAttribute('data-plant'),
        });
      } else if (event.target.name === 'shippingCondition' && event.target.value !== '') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          shippingConditionDesc: event.target.selectedOptions[0].getAttribute('data-shipcondn'),
        });
      } else if (['language'].includes(event.target.name)) {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
        });
      } else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
    },
  ];
}

// else if (event.target.name === 'pocSupplierShipTo' && event.target.value !== '' && event.target.type !== 'text') {
//     console.log(event.target)
//     setValues({
//         ...fields,
//         [event.target.name]: event.target.value,
//         pocPayer: event.target.selectedOptions[0].getAttribute('data-payer'),
//         pocPriceList: event.target.selectedOptions[0].getAttribute('data-pricelist')
//     })
// }

// else if (event.target.name === 'region' && event.target.value !== '') {
//   setValues({
//     ...fields,
//     [event.target.name]: event.target.value,
//     regionDesc: event.target.selectedOptions[0].getAttribute('data-region'),
//     transportationZone: event.target.selectedOptions[0].getAttribute('data-transpzone'),
//   });
// }
