export function validateFields(values, copyClick) {
  let errors = {};
  const mainPageForm = sessionStorage.getItem('createCustomerMainPage') === null ? {} : JSON.parse(sessionStorage.getItem('createCustomerMainPage'));

  const soldToIDRegex = /^(1)\d{7}$/;

  if (copyClick) {
    if (values.existingSoldToID == '') {
      errors.existingSoldToID = 'Sold To ID is required';
    } else if (values.existingSoldToID != '' && !soldToIDRegex.test(values.existingSoldToID)) {
      errors.existingSoldToID = 'Sold To ID is incorrect';
    }
  } else {
    // console.log(soldToSap);
    if (values.name != '') {
    //   if (soldToSap != values.existingSoldToID) {
    //     console.log(soldToSap);
    //     errors.existingSoldToID = 'Sold To ID does not match';
    //   } else if (values.telephone != '' && !telephoneRegex[country].test(values.telephone)) {
    //     errors.telephone = 'Telephone number is incorrect';
    //   } else if (country != 'Netherlands' && values.mobile === '') {
    //     errors.mobile = 'Mobile number is required';
    //   } else if (country != 'Netherlands' && values.mobile !== '' && !telephoneRegex[country].test(values.mobile)) {
    //     errors.mobile = 'Mobile number is incorrect';
    //   } else if (values.email === '') {
    //     errors.email = 'Email is required';
    //   } else if (values.email !== '' && !emailRegex.test(values.email)) {
    //     errors.email = 'Email is incorrect';
    //   }
    }else if((mainPageForm.country == 'Netherlands' || mainPageForm.country == 'Belgium' ||
     mainPageForm.country == 'United Kingdom') && (values.hierarchyType == '')){
      errors.hierarchyType = 'Hierarchy Type is required'
    }else if((mainPageForm.country == 'Netherlands' || mainPageForm.country == 'Belgium' ||
    mainPageForm.country == 'United Kingdom') && (values.pricingHierarchy == '')){
     errors.hierarchyType = 'Pricing Hierarchy is required'
    }
  }

  return errors;
}
