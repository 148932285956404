import { ibanRegex } from '../../../../utils/Utils';

export function validateFields(values, copyClick, payerSap, ibanSap) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')).country;

  const payerIDRegex = /^(3)\d{7}$/;

  if (copyClick) {
    if (values.existingPayerID == '') {
      errors.existingPayerID = 'Payer ID is required';
    } else if (values.existingPayerID != '' && !payerIDRegex.test(values.existingPayerID)) {
      errors.existingPayerID = 'Payer ID is incorrect';
    }
  } else {
    console.log(payerSap);
    if (values.name != '') {
      if (payerSap != values.existingPayerID) {
        console.log(payerSap);
        errors.existingPayerID = 'Payer ID does not match';
      } else if (values.iban == '') {
        errors.iban = 'IBAN is required';
      } else if (values.iban != '' && !ibanRegex[country].test(values.iban)) {
        errors.iban = 'IBAN is incorrect';
      } else if (values.bankTypes == '' && country == 'Belgium') {
        errors.bankTypes = 'Bank type is required';
      } else if (values.iban != ibanSap && values.supportDocsBank == '') {
        errors.supportDocsBank = 'Support document is required';
      } else if (values.ibanOpt == '') {
        errors.ibanOpt = 'Option is required';
      }
    }
  }

  // if (values.existingPayerID == '') {
  //   errors.existingPayerID = 'Payer ID is required';
  // } else if (values.existingPayerID != '' && !payerIDRegex.test(values.existingPayerID)) {
  //   errors.existingPayerID = 'Payer ID is incorrect';
  // } else if (values.name != '') {
  //   if (values.existingPayerID != payerSap) {
  //     errors.existingPayerID = 'Payer ID does not match';
  //   } else if (values.iban == '') {
  //     errors.iban = 'IBAN is required';
  //   } else if (values.iban != ibanSap && values.supportDocsBank == '') {
  //     errors.supportDocsBank = 'Support document is required';
  //   }
  // }

  return errors;
}
