import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import TopBar from '../../../components/layout/TopBar';
import { Button, ButtonGroup, Row, Col, Modal, Spinner } from 'react-bootstrap';
import Sidebar from '../../../components/layout/Sidebar/Sidebar';

import { validateFields as mainPageValidate } from '../mainpage/FormValidation';
import { validateFields as shipToValidate } from '../shipto/FormValidation';
import { validateFields as payerValidate } from '../payer/FormValidation';
import { validateFields as soldToValidate } from '../soldto/FormValidation';
import { validateFields as billToValidate } from '../billto/FormValidation';
import { PostToSharepoint } from './FlowCallAPI';

const CreateCustReview = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) navigate('/');
  }, [isAuthenticated]);

  const [isDisable, setIsDisable, isDisableRef] = useState(false);

  const [isMobile, setIsMobile, isMobileRef] = useState(window.innerWidth < 992);

  const [isSave, setIsSave, isSaveRef] = useState(false);

  const [isSaveRequest, setIsSaveRequest, isSaveRequestRef] = useState(false);

  const fileRegex = /\.[^.\\/:*?"<>|\r\n]+$/;

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen, isSidebarOpenRef] = useState(false);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  const mainPageForm =
    sessionStorage.getItem('createCustomerMainPage') === null
      ? {}
      : JSON.parse(sessionStorage.getItem('createCustomerMainPage'));
  const shipToForm =
    sessionStorage.getItem('createCustomerShipTo') === null
      ? {}
      : JSON.parse(sessionStorage.getItem('createCustomerShipTo'));
  const payerForm =
    sessionStorage.getItem('createCustomerPayer') === null
      ? {}
      : JSON.parse(sessionStorage.getItem('createCustomerPayer'));
  const soldToForm =
    sessionStorage.getItem('createCustomerSoldTo') === null
      ? {}
      : JSON.parse(sessionStorage.getItem('createCustomerSoldTo'));
  const billToForm =
    sessionStorage.getItem('createCustomerBillTo') === null
      ? {}
      : JSON.parse(sessionStorage.getItem('createCustomerBillTo'));

  // const postToSharepointRes = PostToSharepoint(mainPageForm, shipToForm, payerForm, soldToForm, billToForm);

  const postToSharepointRes = PostToSharepoint(
    props.accounts.length > 0 ? props.accounts[0].username : '',
    props.draftTicketNum,
    isSaveRequestRef.current,
  );

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    props.setActivePage(e.target.getAttribute('data-link'));
  };

  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const handleClose = () => setShowModal(false);

  const [showSubmitModal, setShowSubmitModal] = useState(false);

  const [mainPageErrors, setMainPageErrors] = useState({});
  const [shipToErrors, setShipToErrors] = useState({});
  const [payerErrors, setPayerErrors] = useState({});
  const [soldToErrors, setSoldToErrors] = useState({});
  const [billToErrors, setBillToErrors] = useState({});

  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleSave = () => {
    // setShowSubmitModal(true);
    console.log('CUSTOMER TYPE', mainPageForm.customerReqType);
    setIsSave(true);
    console.log(isSaveRef.current, mainPageForm.customerReqType == 'Ship To');
    if (mainPageForm.customerReqType == 'Ship To') {
      setMainPageErrors(mainPageValidate(mainPageForm));
      setShipToErrors(shipToValidate(shipToForm, true));
    } else {
      setMainPageErrors(mainPageValidate(mainPageForm));
      setShipToErrors(shipToValidate(shipToForm, true));
      setPayerErrors(payerValidate(payerForm));
      setSoldToErrors(soldToValidate(soldToForm));
      setBillToErrors(billToValidate(billToForm));
      // props.saveDraft();
    }
  };

  useEffect(
    () => {
      console.log(
        'Error Check',
        `${JSON.stringify(mainPageErrors)} ${JSON.stringify(shipToErrors)} ${JSON.stringify(
          payerErrors,
        )} ${JSON.stringify(soldToErrors)} ${JSON.stringify(billToErrors)} ${isSaveRef.current}`,
      );
      if (
        Object.keys(mainPageErrors).length == 0 &&
        Object.keys(shipToErrors).length == 0 &&
        Object.keys(payerErrors).length == 0 &&
        Object.keys(soldToErrors).length == 0 &&
        Object.keys(billToErrors).length == 0 &&
        isSaveRef.current
      ) {
        console.log('Can save');
        props.saveDraft();
      } else if (
        Object.keys(mainPageErrors).length != 0 ||
        Object.keys(shipToErrors).length != 0 ||
        Object.keys(payerErrors).length != 0 ||
        Object.keys(soldToErrors).length != 0 ||
        Object.keys(billToErrors).length != 0
      ) {
        console.log('Found errors');
        setShowErrorModal(true);
        setIsSave(true);
      } else {
        console.log('Some FAilure');
        setIsSave(false);
      }
    },
    [billToErrors, mainPageErrors],
    isSaveRef,
  );

  function getLang(id) {
    // console.log(props.translations);
    let obj = props.translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    if (props.draftMsg !== '') {
      console.log(props.draftMsg);
      setIsSaveRequest(true);
    }
  }, [props.draftMsg]);

  useEffect(() => {
    setIsSaveRequest(false);
    setIsSave(false);
    if (Object.keys(postToSharepointRes.result).length !== 0) {
      props.setActivePage('success');
    } else if (postToSharepointRes.isError) {
      props.setActivePage('error');
    }
  }, [postToSharepointRes.result, postToSharepointRes.isError]);

  useEffect(() => {
    if (
      props.draftTicketNum[0] !== 'D' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected' &&
      props.status !== 'Duplicate'
    ) {
      setIsDisable(true);
    }
  }, []);

  const sidebarItems = [
    {
      title: getLang('Main Page'),
      onClick: handleSubmit,
      ['data-link']: 'mainpage',
      ['data-back']: 'true',
      active: false,
    },
    {
      title: getLang('Vendor'),
      onClick: handleSubmit,
      ['data-link']: 'vendor',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Ship-To'),
      onClick: handleSubmit,
      ['data-link']: 'shipto',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Payer'),
      onClick: handleSubmit,
      ['data-link']: 'payer',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Sold-To'),
      onClick: handleSubmit,
      ['data-link']: 'soldto',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Bill-To'),
      onClick: handleSubmit,
      ['data-link']: 'billto',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
  ];

  return (
    isAuthenticated && (
      <form className="card">
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={payerForm.supportDocsBank}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModal1} onHide={() => setShowModal1(false)}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={payerForm.supportDocsBank1}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModal2} onHide={() => setShowModal2(false)}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={payerForm.supportDocsBank2}></img>
          </Modal.Body>
        </Modal>

        <Modal show={isSave} centered>
          <Modal.Body>
            <Row>
              <div className="col-auto">
                <Spinner animation="border" variant="warning" />
              </div>
              <div className="text-left">
                <span className="align-middle">
                  &nbsp;Please wait while your request is submitted
                </span>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered>
          <Modal.Header>
            <Modal.Title>Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                Errors found in:
                <ul>
                  {Object.keys(mainPageErrors).length != 0 && <li>Main Page</li>}
                  {Object.keys(shipToErrors).length != 0 && <li>Ship to</li>}
                  {Object.keys(payerErrors).length != 0 && <li>Payer</li>}
                  {Object.keys(soldToErrors).length != 0 && <li>Sold To</li>}
                  {Object.keys(billToErrors).length != 0 && <li>Bill To</li>}
                </ul>
                Please correct them and try again.
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <div className="col col-md"></div>
              <div className="col-auto col-md-auto text-right">
                <Button
                  className="btn-danger btn-block"
                  style={{ textDecoration: 'none' }}
                  onClick={() => {
                    setShowErrorModal(false);
                    setIsSave(false);
                  }}
                >
                  OK
                </Button>
              </div>
            </Row>
          </Modal.Footer>
        </Modal>
        <div className="card-header">
          <div className="col-11 col-lg-12">
            <h3 className="card-title text-left">
              <strong style={{ color: 'rgb(177, 31, 36)' }}>
                Vendor Customer {getLang('Creation')}
              </strong>{' '}
              - {getLang('Review')}
            </h3>
          </div>
          <a
            className={`sidebar-toggler col-auto ${isSidebarOpen ? '' : 'collapsed'}`}
            // onClick={togglingSidebar}
          >
            <Sidebar sidebarItems={sidebarItems} />
            {/* <span className="sidebar-toggler-icon"></span> */}
          </a>
        </div>
        <Row className="sidebar-row">
          <div
            className={`col-12 col-lg-2 text-left sidebar ${
              isMobile ? (isSidebarOpen ? '' : 'collapse') : ''
            }`}
          >
            <Row>
              <ButtonGroup vertical className="btn-block">
                <Link data-link="mainpage" className="btn btn-warning" onClick={handleSubmit}>
                  {getLang('Main Page')}
                </Link>
                <Link data-link="shipto" className="btn btn-warning" onClick={handleSubmit}>
                  {getLang('Ship To')}
                </Link>
                {mainPageForm.customerReqType !== 'Ship To' && (
                  <>
                    <Link data-link="payer" className="btn btn-warning" onClick={handleSubmit}>
                      {getLang('Payer')}
                    </Link>
                    <Link data-link="soldto" className="btn btn-warning" onClick={handleSubmit}>
                      {getLang('Sold To')}
                    </Link>
                    <Link data-link="billto" className="btn btn-warning" onClick={handleSubmit}>
                      {getLang('Bill To')}
                    </Link>
                  </>
                )}
              </ButtonGroup>
            </Row>
          </div>
          <div className="col-12 col-lg-10">
            <div className="card-body text-left">
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col sm={12} lg={4}>
                      <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Main Page')}</h2>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Country')}</label>
                        <div className="form-control-textfield">{mainPageForm.country}</div>
                      </div>
                    </Col>
                    {/* <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Customer Request')}
                        </label>
                        <div className="form-control-textfield">{mainPageForm.customerReqType}</div>
                      </div>
                    </Col> */}
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Ship-to Type')}</label>
                        <div className="form-control-textfield">{mainPageForm.shipToType}</div>
                      </div>
                    </Col>
                    {/* <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('M1')}</label>
                        <div className="form-control-textfield">{mainPageForm.m1}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('M2')}</label>
                        <div className="form-control-textfield">{mainPageForm.m2}</div>
                      </div>
                    </Col> */}
                  </Row>

                  <hr />

                  <Row>
                    <Col xs={12} lg={4}>
                      <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Name')}</label>
                        <div className="form-control-textfield">{shipToForm.name}</div>
                      </div>
                    </Col>

                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Postal Code')}</label>
                        <div className="form-control-textfield">{shipToForm.postalCode}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Street')}</label>
                        <div className="form-control-textfield">{shipToForm.street}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('House Number')}</label>
                        <div className="form-control-textfield">{shipToForm.houseNumber}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('City')}</label>
                        <div className="form-control-textfield">{shipToForm.city}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Country')}</label>
                        <div className="form-control-textfield">{mainPageForm.country}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Region')}</label>
                        <div className="form-control-textfield">{shipToForm.regionDesc}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Language')}</label>
                        <div className="form-control-textfield">{shipToForm.language}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Telephone')}</label>
                        <div className="form-control-textfield">{shipToForm.telephoneOption}</div>
                      </div>
                    </Col>
                    {/* {shipToForm.telephoneOption === 'General/Accounting' && ( */}
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Accounting Telephone')}
                        </label>
                        <div className="form-control-textfield">{shipToForm.generalTelephone}</div>
                      </div>
                    </Col>
                    {/* // )} */}
                    {/* // {shipToForm.telephoneOption === 'Logistics' && ( */}
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Logistics Telephone')}
                        </label>
                        <div className="form-control-textfield">{shipToForm.logTelephone}</div>
                      </div>
                    </Col>
                    {/* // )} */}
                    {/* // {shipToForm.telephoneOption === 'Invoicing' && ( */}
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Invoicing Telephone')}
                        </label>
                        <div className="form-control-textfield">{shipToForm.invTelephone}</div>
                      </div>
                    </Col>
                    {/* // )} */}
                    {/* // {shipToForm.telephoneOption === 'Ordering' && ( */}
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Ordering Telephone')}
                        </label>
                        <div className="form-control-textfield">{shipToForm.orderTelephone}</div>
                      </div>
                    </Col>
                    {/* // )} */}
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Mobile')}</label>
                        <div className="form-control-textfield">{shipToForm.mobile}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('E-mail Address')}</label>
                        <div className="form-control-textfield">{shipToForm.email}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Contact Person')}</label>
                        <div className="form-control-textfield">{shipToForm.contactPerson}</div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12} lg={4}>
                      <h3 style={{ color: '#e0702f' }}>{getLang('Sales Data')}</h3>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Segment')}</label>
                        <div className="form-control-textfield">{shipToForm.segmentDesc}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Sub Segment')}</label>
                        <div className="form-control-textfield">{shipToForm.subSegmentDesc}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Excise duty type')}
                        </label>
                        <div className="form-control-textfield">{shipToForm.exciseDutyType}</div>
                      </div>
                    </Col>
                    {/* {((mainPageForm.country === 'United Kingdom' &&
                      mainPageForm.shipToType in ['On-Trade – Indirect', 'Miscellaneous']) ||
                      (mainPageForm.country in ['Netherlands', 'France'] &&
                        mainPageForm.shipToType.endsWith('Indirect'))) && (
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('POC Supplier')}</label>
                          <div className="form-control-textfield">
                            {shipToForm.pocSupplierShipTo}
                          </div>
                        </div>
                      </Col>
                    )} */}
                    {/* {(mainPageForm.country === 'United Kingdom' ||
                      mainPageForm.country === 'Netherlands') && (
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Shipping Condition')}
                          </label>
                          <div className="form-control-textfield">
                            {shipToForm.shippingCondition}
                          </div>
                        </div>
                      </Col>
                    )} */}
                    {/* {mainPageForm.country === 'United Kingdom' && (
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Hierarchy type - D Node')}
                          </label>
                          <div className="form-control-textfield">
                            {shipToForm.hierarchyTypeDNode}
                          </div>
                        </div>
                      </Col>
                    )} */}
                    {mainPageForm.country === 'France' && (
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Attribute Type 7')}
                          </label>
                          <div className="form-control-textfield">{shipToForm.subChannel}</div>
                        </div>
                      </Col>
                    )}
                    {mainPageForm?.country == 'Belgium' &&
                      (mainPageForm?.shipToType.startsWith('National') ||
                        mainPageForm?.shipToType.startsWith('On-Trade') ||
                        mainPageForm?.shipToType.startsWith('Off-Trade') ||
                        mainPageForm?.shipToType.endsWith('Indirect')) && (
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Attribute Type 7')}
                            </label>
                            <div className="form-control-textfield">{shipToForm?.subChannel}</div>
                          </div>
                        </Col>
                      )}
                    {/* {mainPageForm.country == 'Belgium' && mainPageForm.shipToType == 'Off-Trade – Direct' && (
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Tier')}</label>
                          <div className="form-control-textfield">{shipToForm.tier}</div>
                        </div>
                      </Col>
                    )} */}
                    {(mainPageForm.country == 'France' &&
                      ['On-Trade – Direct', 'Wholesale– Direct'].includes(
                        mainPageForm.shipToType,
                      )) ||
                      (mainPageForm.country == 'United Kingdom' &&
                        mainPageForm.shipToType == 'Off-Trade - Retail') ||
                      (mainPageForm.country == 'Netherlands' &&
                        mainPageForm.shipToType.endsWith('Direct') && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Delivering Plant')}
                              </label>
                              <div className="form-control-textfield">
                                {shipToForm.deliveringPlant}
                              </div>
                            </div>
                          </Col>
                        ))}
                  </Row>

                  <hr />
                  {mainPageForm.customerReqType !== 'Ship To' && (
                    <>
                      <Row>
                        <Col xs={6} lg={2}>
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Payer')}</h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Title')}</label>
                            <div className="form-control-textfield">{payerForm.title}</div>
                          </div>
                        </Col>
                        {/* {mainPageForm.country == 'Belgium' && ( */}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Does the customer have a VAT number?')}
                            </label>
                            <div className="form-control-textfield">{payerForm.vatExist}</div>
                          </div>
                        </Col>
                        {/* )} */}

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('VAT Registration No')}.
                            </label>
                            <div className="form-control-textfield">{payerForm.vatRegNumber}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              Legal Entity / {getLang('Name')}
                            </label>
                            <div className="form-control-textfield">{payerForm.name}</div>
                          </div>
                        </Col>

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">{payerForm.postalCode}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('House Number')}
                            </label>
                            <div className="form-control-textfield">{payerForm.houseNumber}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">{payerForm.street}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">{payerForm.city}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Country')}</label>
                            <div className="form-control-textfield">{payerForm.payerCountry}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Region')}</label>
                            <div className="form-control-textfield">{payerForm.regionDesc}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Language')}</label>
                            <div className="form-control-textfield">{payerForm.language}</div>
                          </div>
                        </Col>
                        {/* <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Reason for Payment Term')}
                            </label>
                            <div className="form-control-textfield">
                              {payerForm.ReasonPaymentTerm}
                            </div>
                          </div>
                        </Col> */}
                      </Row>

                      <Row>
                        <Col sm={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('Communication')}</h3>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Telephone/ Fax numbers')}
                            </label>
                            <div className="form-control-textfield">{payerForm.telephone}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Mobile')}</label>
                            <div className="form-control-textfield">{payerForm.mobile}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('E-mail Address')}
                            </label>
                            <div className="form-control-textfield">{payerForm.email}</div>
                          </div>
                        </Col>
                        {/* <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Yearly Volume Forecast')}</label>
                        <div className="form-control-textfield">{payerForm.yearlyVolForecastHL}</div>
                      </div>
                      </Col> */}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Contact Person')}
                            </label>
                            <div className="form-control-textfield">{payerForm.contactPerson}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('E-Invoicing')}</label>
                            <div className="form-control-textfield">{payerForm.eInvOption}</div>
                          </div>
                        </Col>
                        {payerForm.eInvOption && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('E-Invoicing')} Email Type
                                </label>
                                <div className="form-control-textfield">
                                  {payerForm.eInvEmailType}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  General/Accounting Email
                                </label>
                                <div className="form-control-textfield">
                                  {payerForm.eInvGeneralEmail}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">Logistics Email</label>
                                <div className="form-control-textfield">
                                  {payerForm.eInvLogEmail}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">Invoicing Email</label>
                                <div className="form-control-textfield">
                                  {payerForm.eInvInvEmail}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">Ordering Email</label>
                                <div className="form-control-textfield">
                                  {payerForm.eInvOrderEmail}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Is Vendor')}</label>
                            <div className="form-control-textfield">{payerForm.isVendor}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Vendor No.')}</label>
                            <div className="form-control-textfield">{payerForm.vendorNumber}</div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('Bank and Tax Details')}</h3>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('IBAN')}</label>
                            <div className="form-control-textfield">{payerForm.iban}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Account Holder Name')}
                            </label>
                            <div className="form-control-textfield">{payerForm.accHolderName}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Bank Key')}</label>
                            <div className="form-control-textfield">{payerForm.bankKey}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Bank Account')}
                            </label>
                            <div className="form-control-textfield">{payerForm.bankAcc}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('SWIFT/ BIC Code')}
                            </label>
                            <div className="form-control-textfield">{payerForm.swiftBicCode}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Please Attach The Supportings For IBAN & Bank Details')}
                            </label>
                            <div className="form-control-textfield">
                              <a
                                href=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (
                                    ['.jpeg', '.jpg', '.png'].includes(
                                      fileRegex.exec(payerForm.supportDocsBankName)[0],
                                    )
                                  ) {
                                    setShowModal(true);
                                  }
                                }}
                              >
                                {payerForm.supportDocsBankName}
                              </a>
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Attachment 2')}
                            </label>
                            <div className="form-control-textfield">
                              <a
                                href=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (
                                    ['.jpeg', '.jpg', '.png'].includes(
                                      fileRegex.exec(payerForm.supportDocsBankName1)[0],
                                    )
                                  ) {
                                    setShowModal1(true);
                                  }
                                }}
                              >
                                {payerForm.supportDocsBankName1}
                              </a>
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Attachment 3')}
                            </label>
                            <div className="form-control-textfield">
                              <a
                                href=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (
                                    ['.jpeg', '.jpg', '.png'].includes(
                                      fileRegex.exec(payerForm.supportDocsBankName2)[0],
                                    )
                                  ) {
                                    setShowModal2(true);
                                  }
                                }}
                              >
                                {payerForm.supportDocsBankName2}
                              </a>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('Company Data')}</h3>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Terms of payment')}
                            </label>
                            <div className="form-control-textfield">{payerForm.paymentTerms}</div>
                          </div>
                        </Col>
                      </Row>
                      <hr />

                      <Row>
                        <Col sm={12} lg={4}>
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Sold To')}</h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')} </label>
                            <div className="form-control-textfield">{soldToForm.name}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">{soldToForm.postalCode}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('House Number')}
                            </label>
                            <div className="form-control-textfield">{soldToForm.houseNumber}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">{soldToForm.street}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">{soldToForm.city}</div>
                          </div>
                        </Col>

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Country')}</label>
                            <div className="form-control-textfield">{soldToForm.soldCountry}</div>
                          </div>
                        </Col>

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Region')}</label>
                            <div className="form-control-textfield">{soldToForm.regionDesc}</div>
                          </div>
                        </Col>

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Language')}</label>
                            <div className="form-control-textfield">{soldToForm.language}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Telephone/Fax numbers')}
                            </label>
                            <div className="form-control-textfield">{soldToForm.telephone}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Mobile')}</label>
                            <div className="form-control-textfield">{soldToForm.mobile}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('E-mail Address')}
                            </label>
                            <div className="form-control-textfield">{soldToForm.email}</div>
                          </div>
                        </Col>
                      </Row>

                      <hr />

                      <Row>
                        <Col xs={6} lg={2}>
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Bill To')}</h2>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Option for Bill to')}
                            </label>
                            <div className="form-control-textfield">{billToForm.showBillTo}</div>
                          </div>
                        </Col>
                      </Row>

                      {billToForm.showBillTo === 'Yes-Fill details' && (
                        <>
                          <Row>
                            <Col sm={12} lg={4}>
                              <h3 style={{ color: '#e0702f' }}>{getLang('Bill To')}</h3>
                            </Col>
                          </Row>

                          <Row>
                            {mainPageForm.country != 'United Kingdom' && (
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">{getLang('Title')}</label>
                                  <div className="form-control-textfield">{billToForm.title}</div>
                                </div>
                              </Col>
                            )}
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Name')}</label>
                                <div className="form-control-textfield">{billToForm.name}</div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Postal Code')}
                                </label>
                                <div className="form-control-textfield">
                                  {billToForm.postalCode}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('House Number')}
                                </label>
                                <div className="form-control-textfield">
                                  {billToForm.houseNumber}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Street')}</label>
                                <div className="form-control-textfield">{billToForm.street}</div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('City')}</label>
                                <div className="form-control-textfield">{billToForm.city}</div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Country')}</label>
                                <div className="form-control-textfield">
                                  {billToForm.billCountry}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Region')}</label>
                                <div className="form-control-textfield">
                                  {billToForm.regionDesc}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Language')}
                                </label>
                                <div className="form-control-textfield">{billToForm.language}</div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Telephone')}
                                </label>
                                <div className="form-control-textfield">{billToForm.telephone}</div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Mobile')}</label>
                                <div className="form-control-textfield">{billToForm.mobile}</div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('E-mail Address')}
                                </label>
                                <div className="form-control-textfield">
                                  {billToForm.emailAddress}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('E-Invoicing')}
                                </label>
                                <div className="form-control-textfield">
                                  {billToForm.eInvOption}
                                </div>
                              </div>
                            </Col>
                            {billToForm.eInvOption && (
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('E-mail address (E-invoicing)')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {billToForm.eInvEmail}
                                  </div>
                                </div>
                              </Col>
                            )}
                            {mainPageForm.country == 'France' && (
                              <>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">{getLang('EDI')}</label>
                                    <div className="form-control-textfield">
                                      {billToForm.ediOption}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('GLN/ILN CODE')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {billToForm.glnIlnCode}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('EDI Email Address')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {billToForm.ediEmail}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('EDI Type')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {billToForm.ediType}
                                    </div>
                                  </div>
                                </Col>
                              </>
                            )}
                          </Row>
                        </>
                      )}
                    </>
                  )}
                </Col>
              </Row>
              <br />
            </div>
          </div>
        </Row>

        {/* <br /> */}

        <div className="card-footer">
          <div className="row">
            <div className="col col-md"></div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link={mainPageForm.customerReqType === 'Ship To' ? 'shipto' : 'billto'}
                className="btn btn-warning btn-block"
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Back
              </Link>
            </div>
            {!isDisable && (
              <div className="col-auto col-md-auto text-right">
                <Link
                  data-link="success"
                  className="btn btn-danger btn-block"
                  style={{ textDecoration: 'none' }}
                  onClick={handleSave}
                >
                  {getLang('Submit')}
                </Link>
              </div>
            )}
          </div>
        </div>
      </form>
    )
  );
};

export default CreateCustReview;
