import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Button, ButtonGroup, Row, Col, Modal, Spinner } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import Select, { components } from 'react-select';
import MandatoryText from '../../../components/MandatoryText';

import { validateFields } from './utils/FormValidation';
import { formTemplate } from '../Data';
import { FetchRegions } from './utils/FlowCallAPI';
import { FetchPayerBapi } from '../utils/FlowCallAPI';
import { useFormFields } from './utils/HooksLib';
import { fileRegex } from '../../../utils/Utils';
import {
  mobileLength,
  mobilePlaceholder,
  telephoneLength,
  telephonePlaceholder,
  postalCodeLength,
} from '../../../utils/Utils';

const UpdateCustPayerUpdate = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  const [translations, setTranslations] = useState(props.translations);

  useEffect(() => {
    // if (!isAuthenticated) navigate('/');
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    console.log(inputs);
    if (
      props.draftTicketNum[0] !== 'M' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected'
    ) {
      setIsDisable(true);
    }

    if (inputs.name != '') setIsDisableFields(false);
  }, []);

  const [inputs, setInputs] = useFormFields(props.formTemplate);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkForward, setLinkForward] = useState('');
  const [isCopying, setIsCopying] = useState(false);
  const [copyClick, setCopyClick] = useState(false);
  const [payerSap, setPayerSap] = useState(inputs.existingPayerID);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const [isDisableFields, setIsDisableFields] = useState(true);

  const mainPageForm = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')) || {};

  const regionsRes = FetchRegions(mainPageForm.country, isDisableFields);

  const payerDataRes = FetchPayerBapi(
    inputs.existingPayerID,
    mainPageForm.companyCode,
    mainPageForm.salesOrg,
    isCopying,
  );

  const regionOptions = regionsRes.regions.map((val) => {
    return {
      ...val,
      regionCode: val.regionCode,
      regionText: val.regionText,
      erpTranspZone: val.erpTranspZone,
      regionDesc: `${val.regionCode} - ${val.regionText}`,
    };
  });

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(validateFields(inputs, payerSap, false));
      setIsSubmitting(true);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      props.setActivePage(linkForward);
    }
  }, [errors]);

  function getLang(id) {
    // console.log(props.translations);
    let obj = translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    setTranslations(props.translations);
  }, [props.translations]);

  function copyPayerData(e) {
    setCopyClick(true);
    setErrors(validateFields(inputs, payerSap, true));
    console.log(`I am true ${isCopying}`);
  }

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && copyClick) {
      console.log(inputs.name, isDisableFields, copyClick);
      setCopyClick(false);
      setIsCopying(true);
      Object.entries(formTemplate.payerUpdate).forEach(([key, value]) => {
        if (key !== 'existingPayerID' && key !== 'language') inputs[key] = value;
      });
    }
  }, [errors]);

  useEffect(() => {
    if (Object.keys(payerDataRes.payerData).length !== 0) {
      console.log('payer data', payerDataRes.payerData);
      if (inputs.existingPayerID != '') setPayerSap(inputs.existingPayerID);
      Object.entries(payerDataRes.payerData).forEach(([key, value]) => {
        // if (key == 'name' && value == '') errors.existingPayerID = 'Payer ID is incorrect';
        if (
          key == 'companyNumber' &&
          ['United Kingdom', 'Netherlands'].includes(mainPageForm.country)
        ) {
          inputs.name3 = value;
        }
        if (key in inputs) {
          // if (key === 'telephone' && value !== '') inputs.telephoneOption = 'General/Accounting';
          inputs[key] = value;
          if (key === 'region') {
            console.log(regionsRes.regions);
            if (regionsRes.regions.length != 0) {
              if (inputs.region != '') {
                let obj = regionsRes.regions.find((o) => o.regionCode === inputs.region);
                // console.log('I AM IN REGION')
                // console.log(obj)
                if (obj) {
                  console.log(`Region Desc = ${obj.regionText}`);
                  inputs.regionDesc = `${obj.regionCode} - ${obj.regionText}`;
                }
              }
            }
          }
        }
      });
      sessionStorage.setItem('updateCustomerPayerUpdate', JSON.stringify(inputs));
      setIsDisableFields(false);
    }
    setIsCopying(false);
  }, [payerDataRes.payerData]);

  return (
    <form className="card" onSubmit={handleSubmit}>
      <Modal show={isCopying} centered>
        <Modal.Body>
          <Row>
            <div className="col-auto">
              <Spinner animation="border" variant="warning" />
            </div>
            <div className="text-left">
              <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={inputs.supportDocsBank}></img>
        </Modal.Body>
      </Modal>

      <div className="card-header">
        <div className="col-12">
          <h3 className="card-title text-left">
            <strong>Customer Data {getLang('Update')}</strong>
          </h3>
        </div>
      </div>
      <Row className="sidebar-row">
        <div className="col-12">
          <div className="card-body">
            <Row>
              <Col sm={12} lg={4}>
                <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Payer Modification')}</h2>
              </Col>
            </Row>
            <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">
                        {getLang('Existing Payer ID')} *
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control${errors.existingPayerID ? ' is-invalid' : ''}`}
                          name="existingPayerID"
                          maxLength="8"
                          placeholder={`Enter ${getLang('Existing Payer ID')}`}
                          value={inputs.existingPayerID}
                          onChange={setInputs}
                          disabled={isDisable}
                        />
                        <span className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="button"
                            style={{
                              borderTopRightRadius: '3px',
                              borderBottomRightRadius: '3px',
                            }}
                            disabled={isDisable || inputs.existingPayerID == ''}
                            onClick={copyPayerData}
                          >
                            {getLang('Copy')}
                          </button>
                        </span>
                        {errors.existingPayerID && (
                          <div className="invalid-feedback text-left">{errors.existingPayerID}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Name')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                        name="name"
                        placeholder={`${getLang('Name')}`}
                        value={inputs.name}
                        onChange={setInputs}
                        disabled
                      />
                      {errors.name && (
                        <div className="invalid-feedback text-left">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Name 2')}</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name2"
                        value={inputs.name2}
                        onChange={setInputs}
                        maxLength="35"
                        disabled={inputs.name == '' || isDisable}
                      />
                    </div>
                  </div>
                  {!['France', 'Belgium'].includes(mainPageForm.country) && (
                    <div className="col-12 col-lg-3">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {mainPageForm.country == 'United Kingdom'
                            ? getLang('Company number')
                            : mainPageForm.country == 'Netherlands'
                            ? getLang('KVK number')
                            : `${getLang('Name')} 3`}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.name3 ? 'is-invalid' : ''}`}
                          name="name3"
                          maxLength={
                            ['United Kingdom', 'Netherlands'].includes(mainPageForm.country)
                              ? '8'
                              : '35'
                          }
                          value={inputs.name3}
                          onChange={setInputs}
                          disabled={
                            inputs.name == '' || isDisable || mainPageForm.country == 'Netherlands'
                          }
                        />
                        {errors.name3 && (
                          <div className="invalid-feedback text-left">{errors.name3}</div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Street')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.street ? 'is-invalid' : ''}`}
                        name="street"
                        value={inputs.street}
                        onChange={setInputs}
                        maxLength="35"
                        disabled={inputs.name == '' || isDisable}
                      />
                      {errors.street && (
                        <div className="invalid-feedback text-left">{errors.street}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('House Number')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.houseNumber ? 'is-invalid' : ''}`}
                        name="houseNumber"
                        value={inputs.houseNumber}
                        onChange={setInputs}
                        maxLength="35"
                        disabled={inputs.name == '' || isDisable}
                      />
                      {errors.houseNumber && (
                        <div className="invalid-feedback text-left">{errors.houseNumber}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Postal Code')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.postalCode ? 'is-invalid' : ''}`}
                        name="postalCode"
                        maxLength={postalCodeLength(mainPageForm?.country)}
                        value={inputs.postalCode}
                        onChange={setInputs}
                        disabled={inputs.name == '' || isDisable}
                      />
                      {errors.postalCode && (
                        <div className="invalid-feedback text-left">{errors.postalCode}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('City')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                        name="city"
                        value={inputs.city}
                        maxLength="35"
                        onChange={setInputs}
                        disabled={inputs.name == '' || isDisable}
                      />
                      {errors.city && (
                        <div className="invalid-feedback text-left">{errors.city}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Country')}</label>
                      <input
                        type="text"
                        className="form-control"
                        value={mainPageForm.country}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Region')}</label>
                      <Select
                        className="text-left"
                        name="region"
                        value={regionOptions.filter((opt) => opt.regionCode == inputs.region)}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: '#cfcfcf',
                            primary50: '#cfcfcf',
                            primary: 'orange',
                          },
                        })}
                        isClearable
                        placeholder="Select"
                        isDisabled={inputs.name == '' || isDisable}
                        getOptionLabel={({ regionDesc }) => regionDesc}
                        getOptionValue={({ regionCode }) => regionCode}
                        options={regionOptions}
                        onChange={(e, action) => {
                          setInputs(e, action);
                        }}
                      />
                      {errors.region && (
                        <div className="invalid-feedback-file text-left">{errors.region}</div>
                      )}
                    </div>
                  </div>
                  {['Netherlands', 'Germany'].includes(mainPageForm.country) && (
                    <>
                      <div className="col-12 col-lg-3">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('PO Box')}</label>
                          <input
                            type="text"
                            className="form-control"
                            name="poBox"
                            value={inputs.poBox}
                            onChange={setInputs}
                            maxLength="35"
                            disabled={inputs.name == '' || isDisable}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-3">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('PO Box Postal Code')}
                          </label>
                          <input
                            type="text"
                            className={`form-control ${errors.poBoxPostalCode ? 'is-invalid' : ''}`}
                            name="poBoxPostalCode"
                            maxLength={
                              ['Belgium', 'France'].includes(mainPageForm.country)
                                ? 5
                                : mainPageForm.country === 'Belgium'
                                ? 4
                                : 9
                            }
                            value={inputs.poBoxPostalCode}
                            onChange={setInputs}
                            disabled={inputs.name == '' || isDisable}
                          />
                          {errors.poBoxPostalCode && (
                            <div className="invalid-feedback text-left">
                              {errors.poBoxPostalCode}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Telephone')}</label>
                      <input
                        type="tel"
                        placeholder={telephonePlaceholder(mainPageForm.country)}
                        className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}
                        name="telephone"
                        maxLength={telephoneLength(mainPageForm.country)}
                        value={inputs.telephone}
                        onChange={setInputs}
                        disabled={inputs.name == '' || isDisable}
                      />
                      {errors.telephone && (
                        <div className="invalid-feedback text-left">{errors.telephone}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Mobile')}</label>
                      <input
                        type="tel"
                        placeholder={mobilePlaceholder(mainPageForm.country)}
                        className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                        maxLength={mobileLength(mainPageForm.country)}
                        name="mobile"
                        value={inputs.mobile}
                        onChange={setInputs}
                        disabled={inputs.name == '' || isDisable}
                      />
                      {errors.mobile && (
                        <div className="invalid-feedback text-left">{errors.mobile}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('E-mail Address')}</label>
                      <input
                        type="email"
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        name="email"
                        placeholder="Eg.: abc@company.com"
                        value={inputs.email}
                        onChange={setInputs}
                        maxLength="240"
                        disabled={inputs.name == '' || isDisable}
                      />
                      {errors.email && (
                        <div className="invalid-feedback text-left">{errors.email}</div>
                      )}
                    </div>
                  </div>
                  {['Belgium', 'France', 'Netherlands'].includes(mainPageForm.country) && (
                    <div className="col-12 col-lg-3">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {mainPageForm.country == 'France'
                            ? getLang('Please attach a screenshot of www.societe.com')
                            : getLang('Please Attach The Supportings Documents')}{' '}
                        </label>
                        {!inputs.supportDocsBank && (
                          <input
                            type="file"
                            id="upload-support-docs"
                            accept="image/png, image/jpeg, .doc, .docx, application/msword, .msg, .pdf, .zip"
                            name="supportDocsBank"
                            onChange={setInputs}
                            disabled={inputs.name == '' || isDisable}
                          />
                        )}
                        {inputs.supportDocsBank && (
                          <div className="form-control-textfield text-left">
                            <a
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                                if (
                                  ['.jpeg', '.png', '.jpg'].includes(
                                    fileRegex.exec(inputs.supportDocsBankName)[0],
                                  )
                                ) {
                                  setShowModal(true);
                                }
                              }}
                            >
                              {inputs.supportDocsBankName}
                            </a>
                            &nbsp;
                            <FeatherIcon
                              id="clearFile"
                              icon="x"
                              size={14}
                              name="clearFile"
                              onClick={setInputs}
                              className="fe-icon"
                            />
                          </div>
                        )}
                        {errors.supportDocsBank && (
                          <div className="invalid-feedback-file text-left">
                            {errors.supportDocsBank}
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-12 text-left color-text">
                          <p>
                            <strong>{`Attachment limit is 1 MB`}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <br />

      <div className="card-footer">
        <div className="row">
          {!isDisable && (
            <div className="col-auto col-md-auto text-left">
              <Button variant="outline-warning" onClick={props.saveDraft}>
                Save Draft
              </Button>
            </div>
          )}
          <div className="col col-md text-left">
            <span className="align-middle">{props.draftMsg}</span>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="mainPage"
              data-back="true"
              className="btn btn-warning btn-block"
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Back
            </Link>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="payerUpdateReview"
              className={`btn btn-danger btn-block${
                inputs.existingPayerID == '' || inputs.name == '' ? ' disabled' : ''
              }`}
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Review
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateCustPayerUpdate;
