import {
  shipToIDRegex,
  postalCodeRegex,
  telephoneRegex,
  emailRegex,
  profitCenterRegex,
  glnIlnCodeRegex,
} from '../../../utils/Utils';

export function validateFields(values, forceValidate = false) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country;


  if (values.name === '') {
    errors.name = 'Legal name is required';
  } else if (values.postalCode === '') {
    errors.postalCode = 'Postal code is required';
  } else if (values.postalCode !== '' && !postalCodeRegex[country].test(values.postalCode)) {
    errors.postalCode = 'Postal code is incorrect';
  } else if (values.street === '') {
    errors.street = 'Street is required';
  } else if (values.profitCenter === '') {
    errors.profitCenter = 'Profit Center is required';
  } else if (values.language === '') {
    errors.language = 'Language is required';
  } else if (
    values.houseNumber == '' &&
    !(country == 'United Kingdom')
  ) {
    errors.houseNumber = 'House number is required';
  } else if (values.city === '') {
    errors.city = 'City is required';
  } else if (country != 'Luxembourg' && values.region == '') {
    errors.region = 'Region is required';
  } else if (
    ['Austria', 'Denmark', 'Finland', 'Norway', 'Poland', 'Sweden', 'Switzerland'].includes(
      country,
    ) &&
    values.transportationZone == ''
  ) {
    errors.transportationZone = 'Transportation Zone is required';
  } else if (
    ([
      'Austria',
      'Denmark',
      'Finland',
      'Italy',
      'Luxembourg',
      'Norway',
      'Poland',
      'Sweden',
      'Switzerland',
      'United Kingdom',
      'Belgium',
    ].includes(country)) &&
    values.telephone === ''
  ) {
    errors.telephone = 'Telephone is required';
  } else if (country === 'France' && values.mobile === '') {
    errors.mobile = 'Mobile is required';
  } else if (values.mobile !== '' && !telephoneRegex.test(values.mobile)) {
    errors.mobile = 'Mobile number is incorrect';
  } else if (values.email !== '' && !emailRegex.test(values.email)) {
    errors.email = 'Email address is incorrect';
    // } else if (
    //   ['United Kingdom', 'Belgium', 'Italy', 'Luxembourg'].includes(country) &&
    //   values.contactPerson === ''
    // ) {
    //   errors.contactPerson = 'Contact person is required';
  } else if (
    (country == 'Italy'
    ) &&
    values.deliveringPlant == ''
  ) {
    errors.deliveringPlant = 'Delivering Plant is required';
  }


  return errors;
}
