export const formTemplate = {
  mainpage: {
    country: '',
    salesOrg: '',
    companyCode: '',
    customerReqType: '',
    shipToType: '',
    m1: '',
    m2: '',
  },
  profileData: {
    existingShipToID: '',
    name: '',
    name2: '',
    searchterm2: '',
    postalCode: '',
    street: '',
    houseNumber: '',
    city: '',
    region: '',
    regionDesc: '',
    language: '',
    telephone: '',
    telephoneOption: '',
    generalTelephone: '',
    logTelephone: '',
    invTelephone: '',
    orderTelephone: '',
    mobile: '',
    email: '',
    contactPerson: '',
    profitCenter: '',
    ediOption: '',
    glnIlnCode: '',
    ediEmail: '',
    ediType: '',
    segment: '',
    segmentDesc: '',
    subSegment: '',
    subSegmentDesc: '',
    exciseDutyType: '',
    exciseDutyTypeDesc: '',
    pocSupplierShipTo: '',
    pocPayer: '',
    pocPriceList: '',
    deliveringPlant: '',
    deliveringPlantDesc: '',
    dNodeCpt: '',
    aNode: '',
    shippingCondition: '',
    shippingConditionDesc: '',
    hierarchyTypeDNode: '',
    subChannel: '',
    transportationZone: '',
    tier: '',
    customerClassification: '',
    taxWarehouseNumber: '',
    warehouseExternalEdNumber: '',
    truckType: '',
    trailerType: '',
  },
};
