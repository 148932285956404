import { useState, useEffect } from 'react';
import { formTemplate } from '../Data';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);
  // console.log("HOOKS MAIN ",fields);
  useEffect(() => {
    sessionStorage.setItem('createHierarchyMainPage', JSON.stringify(fields));
    //sessionStorage.setItem('createCustomerProfileData', JSON.stringify(fields));
  }, [fields]);

  return [
    fields,
    function (event, action) {
      console.log('target', event.target.name);
      if (event.target.name == 'country') {
        sessionStorage.setItem('createHierarchyMainPage', JSON.stringify(formTemplate.mainpage));
        // sessionStorage.setItem(
        //   'createCustomerProfileData',
        //   JSON.stringify(formTemplate.profileData),
        // );
        console.log(event.target);
        setValues({
          ...fields,
          country: event.target.value,
          salesOrg: event.target.selectedOptions[0].getAttribute('data-salesorg'),
          companyCode: event.target.selectedOptions[0].getAttribute('data-companycode'),
        });
      } else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
        });
      }
    },
  ];
}
