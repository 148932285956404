import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Button, ButtonGroup, Row, Col } from 'react-bootstrap';
import Select, { components } from 'react-select';
import MandatoryText from '../../../components/MandatoryText';

import { formTemplate } from '../Data';
import { validateFields } from '../profileData/FormValidation';
import { FetchCountries } from './FlowCallAPI';
import { fetchUserRoleAccessCountry } from '../../createcustomer/mainpage/FlowCallAPI';
import { SaveDraft } from '../FlowCallAPI';

import { useFormFields } from './HooksLib';
import Sidebar from '../../../components/layout/Sidebar/Sidebar';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import PageFooter from '../../../components/layout/PageFooter/PageFooter';
import CustomSelect from '../../../components/core/InputFields/CustomSelect/CustomSelect';
import CreateCustProfileData from '../profileData/ProfileData';

const CreateCustMainPage = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();
  const [copyClick, setCopyClick] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isDisableFields, setIsDisableFields] = useState(false);
  const { instance, accounts } = useMsal();
  const [isCopying, setIsCopying] = useState(false);
  const [translations, setTranslations] = useState(props.translations);
  const [activePage, setActivePage] = useState('mainpage');
  const profileDataForm = props.data.profileData
    ? JSON.parse(sessionStorage.getItem('createCustomerProfileData')) || props.data.profileData
    : JSON.parse(sessionStorage.getItem('createCustomerProfileData')) || formTemplate.profileData;
  //const ProfileData = JSON.parse(sessionStorage.getItem('createHierarchyMainPage'));
  //const shipToDataRes = FetchShipToBapi(inputs.existingShipToID, companyCode, salesOrg, isCopying);

  useEffect(() => {
    // if (!isAuthenticated) navigate('/');
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  useEffect(() => {
    console.log(inputs);
    if (
      props.draftTicketNum[0] !== 'D' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected' &&
      props.status !== 'Duplicate'
    ) {
      setIsDisable(true);
    }
  }, []);

  const countriesRes = fetchUserRoleAccessCountry(
    accounts[0].username != '' ? accounts[0].username : '',
  );
  console.log('Heir');
  console.log(countriesRes);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkForward, setLinkForward] = useState('');

  const [isSave, setIsSave] = useState(false);
  const [draftMsg, setDraftMsg] = useState('');

  const saveDraft = (e) => {
    console.log(props.draftTicketNum);
    if (inputs.country) {
      setIsSave(true);
    }
  };

  const saveDraftRes = SaveDraft(
    props.accounts.length > 0 ? props.accounts[0].username : '',
    props.draftTicketNum,
    isSave,
    'Hierarchy',
  );

  console.log('Savedraft result', saveDraftRes.result);

  const handleSubmit = (e) => {
    console.log('Submitting Profile data');
    if (e) e.preventDefault();
    setErrors(validateFields(inputs));
    setIsSubmitting(true);
    setLinkForward(e.target.getAttribute('data-link'));
  };
  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      // navigate(linkForward);
      props.setActivePage(linkForward);
    }
  }, [errors]);

  const [inputs, setInputs] = useFormFields(props.formTemplate);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  useEffect(() => {
    // if (saveDraftRes.result !== '') {
    //   setIsSave(false);
    //   let timeSave = new Date();

    //   if (saveDraftRes.result.result === 'Created') {
    //     setDraftMsg(`Draft created at ${timeSave.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`);
    //   } else if (saveDraftRes.result.result === 'Updated') setDraftMsg(`Draft updated at ${timeSave.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`);
    // }
    setIsSave(false);
    if ('result' in saveDraftRes.result) setDraftMsg(saveDraftRes.result.result);
    else if ('error' in saveDraftRes.result) setDraftMsg(saveDraftRes.result.error);
  }, [saveDraftRes.result]);

  const [isSidebarOpen, setisSidebarOpen] = useState(true);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  function countryChange(event) {
    // Call this function onChange of country, then setInput(e) here.
    inputs.companyCode = event.target.selectedOptions[0].getAttribute('data-companycode');
    inputs.salesOrg = event.target.selectedOptions[0].getAttribute('data-salesorg');
    setInputs(event);
  }

  function getLang(id) {
    // console.log(props.translations);
    let obj = translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    setTranslations(props.translations);
  }, [props.translations]);

  // ${Object.keys(validateFields(inputs)).length != 0 ? 'disabled' : '' }
  const sidebarItems = [
    {
      title: getLang('Main Page'),
      active: true,
    },
  ];
  const footerItems = [
    {
      title: 'Submit',
      onClick: handleSubmit,
      ['data-link']: 'profileData',
      ['data-back']: '',
      type: 'submit',
    },
  ];

  const CountryOption = (val) => {
    console.log(val.val);
    return (
      <option
        key={val.val.countryCode}
        value={val.val.country}
        data-companycode={val.val.companyCode}
        data-salesorg={val.val.salesOrg}
      >
        {val.val.country}
      </option>
    );
  };

  // useEffect(() => {
  //   if (inputs.country !== '') {
  //     setInputs({
  //       ...inputs,
  //       companyCode: inputs.country.companyCode,
  //       salesOrg: inputs.country.salesOrg,
  //     });
  //   }
  // }),
  //   [inputs.country];

  return (
    <form className="card">
      {/* onSubmit={handleSubmit} */}
      <PageHeader
        title={getLang('Create Hierarchy')}
        isSidebarOpen={isSidebarOpen}
        togglingSidebar={togglingSidebar}
      />

      <Row className="sidebar-row">
        {/* <Sidebar sidebarItems={sidebarItems} /> */}
        {/* <div className="col-12 col-lg-10"> */}
        <div>
          <div className="card-body">
            <Row>
              <Col sm={12} lg={4}>
                <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Hierarchy Creation')}</h2>
              </Col>
            </Row>

            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="form-group">
                  <label className="form-label text-left">{getLang('Country')} *</label>
                  <select
                    id="select-country"
                    className={`form-control ${errors.country ? 'is-invalid-select' : ''}`}
                    name="country"
                    onChange={setInputs}
                    value={inputs.country}
                    required
                    disabled={isDisable}
                  >
                    <option value="">Select</option>
                    {countriesRes.countries.map((val) => (
                      <option
                        key={val.countryCode}
                        value={val.country}
                        data-companycode={val.companyCode}
                        data-salesorg={val.salesOrg}
                      >
                        {val.country}
                      </option>
                    ))}
                  </select>
                  {errors.country && (
                    <div className="invalid-feedback text-left">{errors.country}</div>
                  )}
                </div>
              </div>
            </div>
            {inputs.country !== '' && (
              <CreateCustProfileData
                {...props}
                formTemplate={profileDataForm}
                saveDraft={saveDraft}
                draftMsg={draftMsg}
                translations={props.translations}
              />
            )}
          </div>
        </div>
      </Row>

      {/* <PageFooter 
        validateFields={validateFields}
        isDisable={isDisable} 
        saveDraft={props.saveDraft} 
        draftMsg={props.draftMSg} 
        handleSubmit={handleSubmit} 
        inputs={inputs}
        footerItems={footerItems}
      /> */}
    </form>
  );
};

export default CreateCustMainPage;
