import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import { FetchApproversList } from '../pages/FlowCallAPI';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Modal } from 'react-bootstrap';
import Button from '@mui/material/Button';
function ApprovalListModal(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const approverRes = FetchApproversList(props.country, props.role, props.channel);
  const rows = approverRes.result.map((item, index) => {
    return (
      <ListItem key={item.approver}>
        <ListItemAvatar>
          <Avatar>
            <AccountCircleIcon
              style={{ backgroundColor: 'white', borderRadius: '50%', fontSize: '40px' }}
            />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={item.name} secondary={item.approver} />
      </ListItem>
    );
  });

  return (
    <div>
      {approverRes.result.length != 0 ? (
        <>
          <Button
            sx={{ width: '10%', backgroundColor: '#ebda26', color: 'black' }}
            variant="contained"
            onClick={handleClickOpen}
          >
            Approver List
          </Button>
          <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Approver List</DialogTitle>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>{rows}</List>
          </Dialog>
        </>
      ) : (
        <>
          <Button
            sx={{ width: '10%', backgroundColor: '#ebda26', color: 'black' }}
            variant="contained"
            onClick={handleClickOpen}
          >
            Approver List
          </Button>
          <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Approver List</DialogTitle>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <ListItem>
                <ListItemText primary={'No Approvers Found'} />
              </ListItem>
            </List>
          </Dialog>
        </>
      )}
    </div>
  );
}

export default ApprovalListModal;
