import {
  shipToIDRegex,
  postalCodeRegex,
  telephoneRegex,
  emailRegex,
  profitCenterRegex,
  glnIlnCodeRegex,
  specialCharacterRegex,
  streetCharacterRegex,
  houseNumCharacterRegex,
  contactPersonRegex,
  cityRegex,
} from '../../../utils/Utils';

export function validateFields(values, forceValidate = false) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country;

  const customerReqType = JSON.parse(
    sessionStorage.getItem('createCustomerMainPage'),
  ).customerReqType;

  const shipToType = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).shipToType;

  if (values.existingShipToID !== '' && !shipToIDRegex.test(values.existingShipToID)) {
    errors.existingShipToID = 'Ship-to ID is incorrect';
  } else if (shipToIDRegex.test(values.existingShipToID) && values.name == '') {
    errors.existingShipToID = 'Click Copy';
  } else if (values.existingShipToID === '') {
    if (values.existingShipToID === '' && ['New Owner', '2nd Sold To'].includes(customerReqType)) {
      errors.existingShipToID = 'Ship-to ID is required';
    } else if (values.name === '') {
      errors.name = 'Legal name is required';
    } else if (
      values.name !== '' &&
      (specialCharacterRegex.test(values.name) ||
        values['name'].includes('[') ||
        values['name'].includes(']') ||
        values['name'].includes('\\'))
    ) {
      errors.name = 'Special Characters Not Allowed';
    } else if (
      values.name2 !== '' &&
      (specialCharacterRegex.test(values.name2) ||
        values['name2'].includes('[') ||
        values['name2'].includes(']') ||
        values['name'].includes('\\'))
    ) {
      errors.name2 = 'Special Characters Not Allowed';
    } else if (values.postalCode === '') {
      errors.postalCode = 'Postal code is required';
    } else if (values.postalCode !== '' && !postalCodeRegex[country].test(values.postalCode)) {
      errors.postalCode = 'Postal code is incorrect';
    } else if (values.street === '') {
      errors.street = 'Street is required';
    } else if (
      values.street !== '' &&
      (streetCharacterRegex.test(values.street) ||
        values['street'].includes('[') ||
        values['street'].includes(']') ||
        values['street'].includes('\\'))
    ) {
      errors.street = 'Special Characters Not Allowed';
    } else if (
      values.houseNumber == '' &&
      !(country == 'United Kingdom' || customerReqType == 'New Owner')
    ) {
      errors.houseNumber = 'House number is required';
    } else if (
      values.houseNumber !== '' &&
      (houseNumCharacterRegex.test(values.houseNumber) ||
        values['houseNumber'].includes('[') ||
        values['houseNumber'].includes(']') ||
        values['houseNumber'].includes('"'))
    ) {
      errors.houseNumber = 'Special Characters Not Allowed';
    } else if (values.city === '') {
      errors.city = 'City is required';
    } else if (
      values.city !== '' &&
      (cityRegex.test(values.city) ||
        values['city'].includes('[') ||
        values['city'].includes(']') ||
        values['city'].includes('\\') ||
        values['city'].includes('-'))
    ) {
      errors.city = 'Special Characters Not Allowed';
    } else if (country != 'Luxembourg' && values.region == '') {
      errors.region = 'Region is required';
    } else if (
      ['Austria', 'Denmark', 'Finland', 'Norway', 'Poland', 'Sweden', 'Switzerland'].includes(
        country,
      ) &&
      values.transportationZone == ''
    ) {
      errors.transportationZone = 'Transportation Zone is required';
      // } else if (
      //   ([
      //     'Austria',
      //     'Denmark',
      //     'Finland',
      //     'Italy',
      //     'Luxembourg',
      //     'Norway',
      //     'Poland',
      //     'Sweden',
      //     'Switzerland',
      //     'United Kingdom',
      //     'Belgium',
      //   ].includes(country) ||
      //     (country === 'Netherlands' && customerReqType !== 'Prospect')) &&
      //   values.telephoneOption === ''
      // ) {
      //   errors.telephoneOption = 'Telephone is required';
    } else if (country == 'Sweden' && values.delivWindow === '') {
      errors.delivWindow = 'Delivery Window is required';
    } else if (
      ([
        'Austria',
        'Denmark',
        'Finland',
        'Italy',
        'Luxembourg',
        'Norway',
        'Poland',
        'Sweden',
        'Switzerland',
        'United Kingdom',
        'Belgium',
      ].includes(country) ||
        (country === 'Netherlands' && customerReqType !== 'Prospect')) &&
      values.generalTelephone === ''
    ) {
      errors.telephone = 'Accounting Telephone number is required';
    } else if (values.generalTelephone !== '' && !telephoneRegex.test(values.generalTelephone)) {
      console.log(values.generalTelephone);
      errors.telephone = 'Telephone number is incorrect';
    } else if (values.mobile !== '' && !telephoneRegex.test(values.mobile)) {
      errors.mobile = 'Mobile number is incorrect';
    } else if (values.email == '') {
      errors.email = 'Email address is required';
    } else if (values.email !== '' && !emailRegex.test(values.email)) {
      errors.email = 'Email address is incorrect';
    } else if (
      ['United Kingdom', 'Belgium', 'Italy', 'Luxembourg'].includes(country) &&
      values.contactPerson === ''
    ) {
      errors.contactPerson = 'Contact person is required';
    } else if (
      values.contactPerson !== '' &&
      (contactPersonRegex.test(values.contactPerson) ||
        values['contactPerson'].includes('[') ||
        values['contactPerson'].includes(']') ||
        values['contactPerson'].includes('\\'))
    ) {
      errors.contactPerson = 'Special Characters Not Allowed';
    } else if (
      ([
        'Austria',
        'Denmark',
        'Finland',
        'Italy',
        'Luxembourg',
        'Norway',
        'Poland',
        'Sweden',
        'Switzerland',
        'United Kingdom',
      ].includes(country) ||
        (country == 'France' && shipToType.includes('Off-Trade'))) &&
      values.profitCenter == ''
    ) {
      errors.profitCenter = 'Profit center is required';
    } else if (values.profitCenter !== '' && !profitCenterRegex.test(values.profitCenter)) {
      errors.profitCenter = 'Profit center is incorrect';
    } else if (country == 'France' && shipToType.includes('Off-Trade') && values.ediOption == '') {
      errors.ediOption = 'EDI is required';
    } else if (values.ediOption === 'Yes' && values.glnIlnCode === '') {
      errors.glnIlnCode = 'GLN / ILN is required';
    } else if (
      values.ediOption === 'Yes' &&
      values.glnIlnCode != '' &&
      !glnIlnCodeRegex.test(values.glnIlnCode) &&
      country !== 'Italy'
    ) {
      errors.glnIlnCode = 'GLN / ILN is incorrect';
    } else if (values.ediOption === 'Yes' && values.ediEmail === '') {
      errors.ediEmail = 'EDI email is required';
    } else if (
      values.ediOption === 'Yes' &&
      values.ediEmail !== '' &&
      !emailRegex.test(values.ediEmail)
    ) {
      errors.ediEmail = 'Email address is incorrect';
      // } else if (values.ediOption === 'Yes' && values.ediType === '') {
      //   errors.ediType = 'EDI type is required';
    } else if (
      ((country == 'France' && customerReqType != 'Ship To') ||
        (['Belgium', 'Germany'].includes(country) &&
          shipToType.endsWith('Indirect') &&
          customerReqType != 'Ship To')) &&
      values.pocSupplierShipTo === ''
    ) {
      errors.pocExtra = 'POC Supplier is required';
    }
    // } else if (values.pocSupplierShipTo !== '') {
    //   // && !shipToIDRegex.test(values.pocSupplierShipTo)) {

    //   errors.pocExtra = 'POC Supplier is incorrect';
    // }
    else if (values.segment === '' && shipToType != 'Miscellaneous') {
      errors.segment = 'Segment is required';
    } else if (values.subSegment === '' && shipToType != 'Miscellaneous') {
      errors.subSegment = 'Sub Segment is required';
    } else if (values.hierarchyTypeDNode == 'Existing' && values.dNodeCpt == '') {
      errors.dNodeCpt = 'D-Node is required';
    } else if (
      ((country == 'Belgium' && !shipToType.startsWith('Wholesaler')) ||
        country == 'France' ||
        country == 'Italy') &&
      values.subChannel == ''
    ) {
      errors.subChannel = 'Sub Channel is required';
    } else if (
      (country == 'Italy' ||
        country == 'United Kingdom' ||
        (country == 'Netherlands' && shipToType.endsWith('Direct')) ||
        (country == 'Belgium' &&
          ['On-Trade – Direct', 'National On-Trade Direct', 'Off-Trade – Direct'].includes(
            shipToType,
          ))) &&
      values.deliveringPlant == ''
    ) {
      errors.deliveringPlant = 'Delivering Plant is required';
    } else if (
      country == 'Belgium' &&
      shipToType.startsWith('Wholesaler') &&
      values.exciseDutyType == '001' &&
      values.taxWarehouseNumber == ''
    ) {
      errors.taxWarehouseNumber = 'Tax Warehouse Number is required';
    } else if (
      country == 'Belgium' &&
      shipToType.startsWith('Wholesaler') &&
      values.exciseDutyType == '001' &&
      values.warehouseExternalEdNumber == ''
    ) {
      errors.warehouseExternalEdNumber = 'Warehouse External ED Number is required';
    } else if (country == 'Italy' && values.truckType == '') {
      errors.truckType = 'Truck Type is required';
    } else if (country == 'Italy' && values.trailerType == '') {
      errors.trailerType = 'Trailer Type is required';
    } else if (values.ldeOption == 'Yes') {
      if (values.ldeType == '') {
        errors.glnIlnCode = 'LDE Type is required';
      }
    }
  }

  return errors;
}
