export const payerFormTemplate = {
  existingPayerID: '',
  ReasonPaymentTerm: '',
  title: '',
  titleDesc: '',
  taxNumber: '',
  vatRegNumber: '',
  name: '',
  name2: '',
  companyNumber: '',
  postalCode: '',
  houseNumber: '',
  street: '',
  city: '',
  region: '',
  regionCode: '',
  language: '',
  telephone: '',
  mobile: '',
  email: '',
  yearlyVolForecastHL: '',
  contactPerson: '',
  eInvOption: '',
  eInvEmailType: '',
  eInvGeneralEmail: '',
  eInvLogEmail: '',
  eInvInvEmail: '',
  eInvOrderEmail: '',
  isVendor: '',
  vendorNumber: '',
  glnIlnCode: '',
  ediEmail: '',
  ediType: '',
  forecastVolumeHL: '',
  iban: '',
  accHolderName: '',
  bankKey: '',
  bankAcc: '',
  swiftBicCode: '',
  supportDocsBank: '',
  supportDocsBankName: '',
  paymentTerms: '',
  paymentMethod: '',
  reasonDirectDebit: '',
};

const LDEs = ['16-23 LDE', '24-29 LDE', '>=30 LDE'];

const einvoicingOptions = ['General/Accounting', 'Logistics', 'Invoicing', 'Ordering'];

const languages = ['English', 'Espanol', 'Italiano', 'French', 'Dutch', 'German'];

const languageMap = {
  N: 'Dutch',
  I: 'Italian',
  E: 'English',
  D: 'German',
  F: 'French',
};

const LdeMap = {
  16: '16-23 LDE',
  24: '24-29 LDE',
  30: '>=30 LDE',
};

const countries = ['United Kingdom', 'France', 'Belgium', 'Netherlands', 'Germany'];

export { einvoicingOptions, languages, languageMap, LDEs, LdeMap };
