import React, { useEffect, useMemo, useState, useRef } from 'react';
import MaterialReactTable from 'material-react-table';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FloatRight } from 'tabler-icons-react';
import { amber, deepOrange, grey, goldenrod } from '@mui/material/colors';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { CSVLink } from 'react-csv';
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Typography } from '@mui/material';
import ApprovalListModal from '../../../ApprovalList';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

import cs from 'classnames';
import './materialReactTableStyles.scss';
import { light } from '@mui/material/styles/createPalette';

const exportTheme = createTheme({
  palette: {
    primary: {
      main: '#DAA520',
    },
    // secondary: pink,
  },
});

const Table2 = ({
  tableData,
  tableColumns,
  className,
  autoHeight = true,
  components = {},
  componentsProps = {},
  classes,
  setEditTableIndex,
  loading,
  Is_Expanding_Detail_Panel = { Is_Expanding: false },
}) => {
  const [rowSelection, setRowSelection] = useState({});
  const [exportLoading, setExportLoading] = useState(false);
  const [loaderButton, setLoaderButton] = useState(false);
  const columnSequence = [
    'Request_ID',
    'Name',
    'Request_Type',
    'CustomerRequest',
    'MainCountry',
    'Bot_SoldToID',
    'Bot_PayerID',
    'Bot_ShipToID',
    'BOT_BillToID',
    'Created',
    'Status',
    'FinalStatus',
    'RejectFeed',
    'RejectedBy',
    'pocuShipToID',
    'duplicateCustID',
  ];
  console.log(tableData, 'Table data');
  const ExportToExcel = ({ fileName }) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';
    const fileExtension = '.xlsx';

    console.log('Excel data ', tableData);

    const exportExcel = async () => {
      console.log('got in the export function');
      const ws = XLSX.utils.json_to_sheet(tableData, { header: columnSequence });
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      setExportLoading(false);
    };
    exportExcel();

    return true;

    // return (
    //   <>
    //     <Button
    //       size="sm"
    //       onClick={(e) => exportExcel(fileName)}
    //       style={{
    //         cursor: 'pointer',
    //         borderRadius: '15px',
    //         marginLeft: '16px',
    //         maxWidth: '120px',
    //         padding: '4px',
    //         fontSize: '0.75rem',
    //         maxHeight: '44px',
    //         marginTop: '-0.75px',
    //         marginBottom: '-0.75px',
    //       }}
    //     >
    //       Export To Excel
    //     </Button>
    //   </>
    // );
  };

  useEffect(() => {
    //do something when the row selection changes...
    //console.info({ rowSelection }, Object.keys(rowSelection));
    console.info('keys', Object.keys(rowSelection));
    setEditTableIndex && setEditTableIndex(Object.keys(rowSelection));
  }, [rowSelection]);

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };

  const csvLinkRef = useRef(null);

  const handleExportRows = (rows) => {
    const csvData = rows.map((row) => row.original);
    csvLinkRef.current.link.click();
  };

  const handleExportData = async () => {
    setExportLoading(true);

    ExportToExcel({ fileName: 'data' });
    setExportLoading(false);
  };
  useEffect(() => {
    console.log('we came here');
    if (exportLoading == true) {
      setLoaderButton(true);
    } else {
      setLoaderButton(false);
    }
  }, [exportLoading]);
  const Expanding_Detail_Panel =
    Is_Expanding_Detail_Panel.Is_Expanding === true
      ? ({ row }) => (
          <Box
            sx={{
              display: 'grid',
              margin: 'auto',
              gridTemplateColumns: '1fr',
              width: '100%',
              textAlign: 'left',
              paddingLeft: '10rem',
            }}
          >
            <>
              {row.original.FinalStatus == 'Awaiting Approval' &&
                (row.original.Status.includes('CX') ? (
                  <>
                    <Typography>Awaiting Approval from: {'CX Team'}</Typography>
                    <ApprovalListModal
                      role="CX"
                      country={row.original.MainCountry}
                      channel={row.original.ShipToType}
                    />
                  </>
                ) : row.original.Status.includes('2nd lvl Cr Manager') ? (
                  <>
                    <Typography>Awaiting Approval from: {'Second Cr Manager'}</Typography>
                    <ApprovalListModal
                      role="Second Cr Manager"
                      country={row.original.MainCountry}
                    />
                  </>
                ) : row.original.Status.includes('Awaiting Cr Manager') ? (
                  <>
                    <Typography>Awaiting Approval from: {'Credit Manager'}</Typography>
                    <ApprovalListModal role="Cr Manager" country={row.original.MainCountry} />
                  </>
                ) : row.original.Status.includes('Risk') ? (
                  <>
                    <Typography>Awaiting Approval from: {'Risk Advisor'}</Typography>
                    <ApprovalListModal role="Risk Advisor" country={row.original.MainCountry} />
                  </>
                ) : row.original.Status.includes('Retail') ? (
                  <>
                    <Typography>Awaiting Approval from: {'Retail Manager'}</Typography>
                    <ApprovalListModal role="Retail Manager" country={row.original.MainCountry} />
                  </>
                ) : row.original.Status.includes('National') ? (
                  <>
                    <Typography>Awaiting Approval from: {'National Customer Manager'}</Typography>
                    <ApprovalListModal
                      role="National Customer Manager"
                      country={row.original.MainCountry}
                    />
                  </>
                ) : row.original.Status == 'Awaiting Approval' ? (
                  <>
                    <Typography>Awaiting Approval from: {'CMD Team'}</Typography>
                    <ApprovalListModal role="GCC CMD" country={row.original.MainCountry} />
                  </>
                ) : (
                  ''
                ))}
              {row.original.FinalStatus == 'Rejected' && (
                <>
                  <Typography>Rejected By: {row.original.RejectedBy}</Typography>
                  <Typography>Rejected Reason: {row.original.RejectFeed}</Typography>
                </>
              )}
              {row.original.FinalStatus == 'Duplicate' && (
                <>
                  <Typography>
                    Found Duplicate On:{' '}
                    {row.original.Status.includes('Payer') ? 'Payer Level' : 'Ship To Level'}
                  </Typography>
                  <Typography>
                    Duplicate Customer:{' '}
                    {row.original.Status.includes('Payer')
                      ? row.original.duplicateCustID
                      : row.original.pocuShipToID}
                  </Typography>
                </>
              )}
              {row.original.FinalStatus == 'Completed' && <Typography>Completed</Typography>}
              {row.original.FinalStatus == 'In Progress' && <Typography>In Progress</Typography>}
            </>
          </Box>
        )
      : null;

  return (
    // <ThemeProvider theme={darkTheme}>
    <div className="materialReactTableWrapper">
      <MaterialReactTable
        columns={tableColumns}
        data={tableData}
        initialState={{ density: 'compact' }}
        enablePinning
        enableStickyHeader
        // enableRowSelection
        getRowId={(row) => row.id} //give each row a more useful id
        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
        state={{ rowSelection, isLoading: loading }} //pass our managed row selection state to the table to use
        renderTopToolbarCustomActions={({ table }) => (
          <div style={{ padding: '4px 10px' }}>
            <>
              <Box
                sx={{
                  display: 'flex',
                  //  gap: '1rem', flexWrap: 'wrap'
                }}
              >
                <ThemeProvider theme={exportTheme}>
                  <LoadingButton
                    loading={loaderButton || loading}
                    onClick={handleExportData}
                    loadingPosition="start"
                    variant="contained"
                    startIcon={<FileDownloadIcon style={{ color: '#000000' }} />}
                  >
                    Export All Data
                  </LoadingButton>
                  <Tooltip title="Guide to the new All Ticket Page" arrow>
                    <Button
                      color="primary"
                      onClick={() => {
                        window.open(
                          `https://cmdeuautomationcoesa.blob.core.windows.net/assets/All Ticket Page Guide.pdf`,
                        );
                      }}
                      //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                      //href="https://cmdeuautomationcoesa.blob.core.windows.net/assets/All Ticket Page Guide.pdf"
                      // startIcon={<FileDownloadIcon style={{ color: '#000000' }} />}
                      endIcon={<InfoIcon style={{ color: '#000000' }} />}
                      variant="contained"
                      style={{ marginLeft: '1rem' }}
                    >
                      Know More
                    </Button>
                  </Tooltip>

                  {/* <LoadingButton
                    loading={loading || exportLoading}
                    loadingPosition="start"
                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                    //export all rows, including from the next page, (still respects filtering and sorting)
                    onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
                    startIcon={<FileDownloadIcon style={{ color: '#000000' }} />}
                    variant="contained"
                  >
                    Export All Rows
                  </LoadingButton> */}
                </ThemeProvider>
                {/* <LoadingButton
                    disabled={table.getRowModel().rows.length === 0}
                    //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                    onClick={() => handleExportRows(table.getRowModel().rows)}
                    startIcon={<FileDownloadIcon />}
                    variant="contained"
                  >
                    Export Page Rows
                  </LoadingButton> */}
                {/* <LoadingButton
                    disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                    //only export selected rows
                    onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                    startIcon={<FileDownloadIcon />}
                    variant="contained"
                  >
                    Export Selected Rows
                  </LoadingButton> */}
              </Box>
            </>
          </div>
        )}
        renderDetailPanel={Expanding_Detail_Panel}
      />
      <CSVLink
        data={[]}
        filename="data.csv"
        ref={csvLinkRef}
        target="_blank"
        style={{ display: 'none' }}
      />
    </div>
    // </ThemeProvider>
  );

  //   return (
  //     <div>
  //       <DataGrid
  //         sx={{ width: '100%' }}
  //         rows={tableData}
  //         className={cs('remove-search-boarder', { [className]: className })}
  //         componentsProps={{
  //           toolbar: { showQuickFilter: true },
  //           ...componentsProps,
  //         }}
  //         components={{
  //           Toolbar: GridToolbar,
  //           ...components,
  //         }}
  //         columns={tableColumns}
  //         autoHeight={autoHeight}
  //         classes={{
  //           root: 'main-table-wrapper',
  //           footerContainer: 'main-table-wrapper-footer',
  //           columnHeaderTitleContainer: 'justify-content-center',
  //           iconSeparator: 'opacity-0',
  //           toolbarContainer: 'table-toolbar-wrapper',
  //           panel: 'table-panel-wrapper',
  //           ...classes,
  //         }}
  //         pageSize={5}
  //         pagination
  //         loading={loading}
  //         checkboxSelection
  //         onSelectionModelChange={(selected) => setEditTableIndex(selected)}
  //         disableSelectionOnClick
  //       />
  //     </div>
  //   );
};

export default Table2;
