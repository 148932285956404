import {
  postalCodeRegex,
  telephoneRegex,
  companyNumRegex,
  emailRegex,
  payerIDRegex,
} from '../../../../utils/Utils';

export function validateFields(values, payerSap, copyClick) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')).country;

  if (copyClick) {
    if (values.existingPayerID == '') {
      errors.existingPayerID = 'Payer ID is required';
    } else if (values.existingPayerID != '' && !payerIDRegex.test(values.existingPayerID)) {
      errors.existingPayerID = 'Payer ID is incorrect';
    }
  } else {
     if(values.riskCategory == ''){
      errors.riskCategory = 'Risk Category is required'
     }
     else if(values.creditLimits == ''){
      errors.creditLimits = 'Credit Limit is required'
     }else if (
      [
        'Austria',
        'Denmark',
        'Finland',
        'Italy',
        'Luxembourg',
        'Norway',
        'Poland',
        'Sweden',
        'Switzerland',
      ].includes(country) &&
      values.paymentTerms == ''
    ) {
      errors.paymentTerms = 'Payment Terms is required';
    }
  }
  

  return errors;
}

// else if (values.mobile === '' && ['Netherlands', 'United Kingdom', 'France'].includes(country)) {
//   errors.mobile = 'Mobile is required';
// else if (values.email === '') {
//   errors.email = 'Email is required';
// }
