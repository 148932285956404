import { useState, useEffect } from 'react';
import configData from './Config.js';
import { urls } from '../../utils/Utils';
//import axios from 'axios';
import axios from './../../utils/axios';

export function FetchDraftRequests(requestor) {
  const url = urls.fetchDraftRequestsURL;
  const [loading, setLoading] = useState(false);
  const [draftRequests, setDraftRequests] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { requestor: requestor });
    setDraftRequests(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (requestor !== '') getData();
  }, [requestor]);

  return { loading, draftRequests, getData };
}

export function FetchSubmittedRequests(requestor, type) {
  const url = urls.fetchSubmittedRequestsURL;
  const [loading, setLoading] = useState(false);
  const [submittedRequests, setSubmittedRequests] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { requestor: requestor, type: type });
    setSubmittedRequests(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (requestor !== '') getData();
  }, [requestor]);

  return { loading, submittedRequests, getData };
}

export function FetchRejectedRequests(requestor) {
  const url = urls.fetchRejectedRequestsURL;
  const [loading, setLoading] = useState(false);
  const [rejectedRequests, setRejectedRequests] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { requestor: requestor });
    setRejectedRequests(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (requestor !== '') getData();
  }, [requestor]);

  return { loading, rejectedRequests, getData };
}

export function FetchDuplicateRequests(requestor) {
  const url = urls.fetchDuplicateRequestsURL;
  const [loading, setLoading] = useState(false);
  const [duplicateRequests, setDuplicateRequests] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { requestor: requestor });
    setDuplicateRequests(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (requestor !== '') getData();
  }, [requestor]);

  return { loading, duplicateRequests, getData };
}

export function FetchCompletedRequests(requestor) {
  const url = urls.fetchCompletedRequestsURL;
  const [loading, setLoading] = useState(false);
  const [completedRequests, setCompletedRequests] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { requestor: requestor });
    setCompletedRequests(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (requestor !== '') getData();
  }, [requestor]);

  return { loading, completedRequests, getData };
}

export function FetchFilteredData(requestor) {
  const url = urls.fetchTicketDataURL;
  const [loading, setLoading] = useState(false);
  const [filteredData, setfilteredData] = useState([]);

  const loadPosts = async () => {
    setLoading(true);
    const res = await axios.post(url, { requestor: requestor });
    setfilteredData(res.data ?? []);
    setLoading(false);
  };
  useEffect(() => {
    if (requestor !== '') loadPosts();
  }, [requestor]);

  return { loading, filteredData, loadPosts };
}

export function FetchListingFeesPendingRequests(requestor) {
  const url = urls.fetchListingFeesPendingRequestsURL;
  const [loading, setLoading] = useState(false);
  const [completedRequests, setCompletedRequests] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { requestor: requestor });
    setCompletedRequests(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (requestor !== '') getData();
  }, [requestor]);

  return { loading, completedRequests, getData };
}
