export function validateFields(values, copyClick, payerSap) {
  let errors = {};

  const payerIDRegex = /^(3)\d{7}$/;

  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (copyClick) {
    if (values.existingPayerID == '') {
      errors.existingPayerID = 'Payer ID is required';
    } else if (values.existingPayerID != '' && !payerIDRegex.test(values.existingPayerID)) {
      errors.existingPayerID = 'Payer ID is incorrect';
    }
  } else {
    if (values.name != '') {
      if (payerSap != values.existingPayerID) {
        console.log(payerSap);
        errors.existingPayerID = 'Payer ID does not match';
      } else if (values.contactEmail != '' && !emailRegex.test(values.contactEmail)) {
        errors.contactEmail = 'Contact email is incorrect';
      } else if (values.eInvPayerOpt == '') {
        errors.eInvPayerOpt = 'Option is required';
      } else if (values.function == '') {
        errors.function = 'Invoicing Function is required';
      } else if (values.firstName == '' && values.lastName == '') {
        errors.firstName = 'Contact Name is required';
      } else if (values.contactEmail == '') {
        errors.contactEmail = 'Email is required';
      }
    }
  }

  return errors;
}
