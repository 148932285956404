export function validateFields(values) {
  let errors = {};

  if (values.country === '') {
    errors.country = 'Country is required';
  } else if (values.reqType === '') {
    errors.reqType = 'Request Type is required';
  } else if (values.country === 'Belgium' && values.shipToType === '') {
    errors.shipToType = 'Ship To Type is required';
  }

  return errors;
}
