import { useState, useEffect } from 'react';
import configData from './Config.js';
import { urls } from '../../../../utils/Utils';
//import axios from 'axios';
import axios from './../../../../utils/axios';

export function FetchAcctgClerk(country) {
  const url = configData.fetchAcctgClerkURL;
  const [loading, setLoading] = useState(false);
  const [accountingClerk, setAccountingClerk] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setAccountingClerk(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country != '') getData();
  }, [country]);

  return { loading, accountingClerk, getData };
}

export function FetchPayerBapi(payerID, companyCode, salesOrg, isCopying) {
  const url = urls.fetchPayerBapiURL;
  const [loading, setLoading] = useState(false);
  const [payerData, setPayerData] = useState({});

  const getData = async () => {
    setLoading(true);
    const res = await axios.get(url, {
      params: {
        payerID: payerID,
        companyCode: companyCode,
        salesOrg: salesOrg,
      },
    });
    setPayerData(res.data ?? {});
    setLoading(false);
  };

  useEffect(() => {
    console.log(isCopying, payerID, companyCode, salesOrg);
    if (isCopying && payerID !== '' && companyCode !== '' && salesOrg !== '') {
      console.log('hello');
      getData();
    }
  }, [payerID, companyCode, salesOrg, isCopying]);

  return { loading, payerData, getData };
}
