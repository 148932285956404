import { useState, useEffect } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  useEffect(() => {
    sessionStorage.setItem('updateCustomerEInvPayerUpdate', JSON.stringify(fields));
  }, [fields]);

  return [
    fields,
    function (event) {
      console.log('INV EVENT', event);
      if (['function', 'eInvPayerOpt'].includes(event.target.name)) {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
        });
      } else if (event.target.name == 'existingPayerID') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          name: '',
          contactName: '',
          function: '',
          contactEmail: '',
        });
      } else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
    },
  ];
}
