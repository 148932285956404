import { useState, useEffect } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  useEffect(() => {
    sessionStorage.setItem('updateHierarchyMapUpdate', JSON.stringify(fields));
  }, [fields]);

  return [
    fields,
    function (event) {
      if (event.target.name == 'existingSoldToID') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          name: '',
          street: '',
          city: '',
          postalCode: '',
          
        });
       
    } else if (
      [
        'hierarchyType'
      ].includes(event.target.name)
    ) {
      setValues({
        ...fields,
        [event.target.name]: event.target.value,
      });
    }else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
    },
  ];
}
