import React from 'react';

import { Button, Container, Row, Col, Card } from 'react-bootstrap';

const NoAccess = (props) => {
  return (
    <Container className="center-page">
      <Row>
        <Col>
          <h3>{`Sorry, but it seems you don't have access to submit requests.`}</h3>
        </Col>
      </Row>
    </Container>
  );
};

export default NoAccess;
