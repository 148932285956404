import {
  ibanRegex,
  postalCodeRegex,
  telephoneRegex,
  emailRegex,
  payerIDRegex,
  vatRegex,
  companyNumRegex,
  glnIlnCodeRegex,
} from '../../../utils/Utils';

export function validateFields(values) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country;

  const customerReqType = JSON.parse(
    sessionStorage.getItem('createCustomerMainPage'),
  ).customerReqType;

  const shipToType = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).shipToType;

  const title = JSON.parse(sessionStorage.getItem('createCustomerPayer')).title;

  if (values.existingPayerID !== '' && !payerIDRegex.test(values.existingPayerID)) {
    errors.existingPayerID = 'Payer ID is incorrect';
  } else if (payerIDRegex.test(values.existingPayerID) && values.name == '') {
    errors.existingPayerID = 'Click Copy';
  } else if (values.existingPayerID === '') {
    if (['Italy'].includes(country) && values.title == '') {
      errors.title = 'Title is required';
    } else if (
      values.vatRegNumber === '' &&
      ((country == 'United Kingdom' && shipToType != 'On-Trade – Indirect') ||
        [
          'Austria',
          'Denmark',
          'Finland',
          'Italy',
          'Luxembourg',
          'Norway',
          'Poland',
          'Sweden',
          'Switzerland',
        ].includes(country) ||
        (country == 'Netherlands' && customerReqType != 'Prospect') ||
        (country == 'France' && values.title != 'ASBL') ||
        (country == 'Belgium' && shipToType == 'Off-Trade – Direct') ||
        values.vatExist == 'Yes')
    ) {
      errors.vatRegNumber = 'VAT Reg Number is required';
    } else if (
      !['No', 'In Progress'].includes(values.vatExist) &&
      values.vatRegNumber != '' &&
      !vatRegex[values.payerCountry].test(values.vatRegNumber)
    ) {
      errors.vatRegNumber = 'VAT Reg Number is incorrect';
    } else if (country == 'Netherlands' && values.paymentMethod == '') {
      errors.paymentMethod = 'Payment Method is required';
    } else if (country == 'Netherlands' && values.rent == 'No' && values.reasonDirectDebit == '') {
      errors.reasonDirectDebit = 'Field is required';
    } else if (
      (country == 'Netherlands' && values.paymentTerms == '028I') ||
      (values.paymentTerms == '030I' && values.ReasonPaymentTerm == '')
    ) {
      errors.ReasonPaymentTerm = 'Field is required';
    } else if (country == 'France' && shipToType.includes('Off-Trade') && values.ediOption == '') {
      errors.ediOption = 'EDI is required';
    } else if (values.ediOption === 'Yes' && values.glnIlnCode === '') {
      errors.glnIlnCode = 'GLN / ILN is required';
    } else if (
      values.ediOption === 'Yes' &&
      values.glnIlnCode != '' &&
      !glnIlnCodeRegex.test(values.glnIlnCode) &&
      country !== 'Italy'
    ) {
      errors.glnIlnCode = 'GLN / ILN is incorrect';
    } else if (values.ediOption === 'Yes' && values.ediEmail === '') {
      errors.ediEmail = 'EDI email is required';
    } else if (
      values.ediOption === 'Yes' &&
      values.ediEmail !== '' &&
      !emailRegex.test(values.ediEmail)
    ) {
      errors.ediEmail = 'Email address is incorrect';
      // } else if (values.ediOption === 'Yes' && values.ediType === '') {
      //   errors.ediType = 'EDI type is required';
    } else if (country == 'Italy' && values.taxNumber == '') {
      errors.taxNumber = 'Tax Number is required';
    } else if (country == 'France' && values.taxNumber == '') {
      errors.taxNumber = 'Tax Number is required';
    } else if (country == 'France' && values.taxNumber2 == '') {
      errors.taxNumber2 = 'Tax Number 2 is required';
    } else if (values.name === '') {
      errors.name = 'Name is required';
    } else if (
      ['United Kingdom', 'Netherlands'].includes(country) &&
      customerReqType != 'Prospect' &&
      values.companyNumber === ''
    ) {
      errors.companyNumber = `${
        country === 'United Kingdom' ? 'Company' : 'KVK'
      } Number is required`;
    } else if (
      ['United Kingdom', 'Netherlands', 'Italy'].includes(country) &&
      values.companyNumber !== '' &&
      !companyNumRegex.test(values.companyNumber)
    ) {
      errors.companyNumber = `${
        country === 'United Kingdom' ? 'Company' : 'KVK'
      } Number is incorrect`;
    } else if (values.postalCode === '') {
      errors.postalCode = 'Postal Code is required';
    } else if (
      values.postalCode !== '' &&
      !postalCodeRegex[values.payerCountry].test(values.postalCode)
    ) {
      errors.postalCode = 'Postal code is incorrect';
    } else if (values.houseNumber === '' && country !== 'United Kingdom') {
      errors.houseNumber = 'House number is required';
    } else if (values.street === '') {
      errors.street = 'Street is required';
    } else if (values.city === '') {
      errors.city = 'City is required';
    } else if (values.payerCountry == '') {
      errors.payerCountry = 'Country is required';
    } else if (values.payerCountry != 'Luxembourg' && values.region === '') {
      errors.region = 'Region is required';
    } else if (
      values.mobile === '' &&
      ['Netherlands', 'United Kingdom', 'Italy'].includes(country)
    ) {
      errors.mobile = 'Mobile is required';
    } else if (values.mobile !== '' && !telephoneRegex.test(values.mobile)) {
      errors.mobile = 'Mobile number is incorrect';
    } else if (
      values.telephone === '' &&
      [
        'Austria',
        'Denmark',
        'Finland',
        'Italy',
        'Norway',
        'Poland',
        'Sweden',
        'Switzerland',
        'Netherlands',
        'Belgium',
      ].includes(country)
    ) {
      errors.telephone = 'Telephone is required';
    } else if (values.telephone !== '' && !telephoneRegex.test(values.telephone)) {
      errors.telephone = 'Telephone number is incorrect';
    } else if (values.email == '') {
      errors.email = 'Email is required';
    } else if (values.email !== '' && !emailRegex.test(values.email)) {
      errors.email = 'Email is incorrect';
    } else if (
      (country == 'Belgium' ||
        country == 'France' ||
        country == 'Netherlands' ||
        country == 'Luxembourg') &&
      ['On-Trade – Direct', 'National On-Trade Direct', 'Wholesale– Direct'].includes(shipToType) &&
      !shipToType.startsWith('Off-Trade') &&
      values.yearlyVolForecastHL == ''
    ) {
      errors.yearlyVolForecastHL = 'Forecast volume is required';
    } else if (
      (country == 'Belgium' ||
        country == 'France' ||
        country == 'Netherlands' ||
        country == 'Luxembourg') &&
      ['On-Trade – Direct', 'National On-Trade Direct', 'Wholesale– Direct'].includes(shipToType) &&
      !shipToType.startsWith('Off-Trade') &&
      Number(values.yearlyVolForecastHL) <= 0
    ) {
      errors.yearlyVolForecastHL = 'Forecast volume needs to be greater than 0';
    } else if (
      (country == 'Belgium' || country == 'France' || 'Netherlands' || 'Luxembourg') &&
      ['On-Trade – Direct', 'National On-Trade Direct', 'Wholesale– Direct'].includes(shipToType) &&
      !shipToType.startsWith('Off-Trade') &&
      values.paymentTerms == ''
    ) {
      errors.paymentTerms = 'Payment Terms is required';
    } else if (country == 'Belgium' && values.contactPerson === '') {
      errors.contactPerson = 'Contact Person is required';
    } else if (
      ((country == 'Netherlands' && ['Full Creation', 'New Owner'].includes(customerReqType)) ||
        ['Austria', 'Italy', 'Norway', 'Poland', 'Switzerland'].includes(country) ||
        (country == 'United Kingdom' && customerReqType == 'Listing Fees') ||
        (country == 'France' &&
          [
            'Wholesale– Direct',
            'Wholesale– Indirect',
            'On-Trade – Direct',
            'Off-Trade – Direct',
            'Off-Trade - Indirect',
          ].includes(shipToType))) &&
      values.iban == ''
    ) {
      console.log(`IBAN: ${values.iban}`);
      errors.iban = 'IBAN is required';
    } else if (values.iban != '' && !ibanRegex[country].test(values.iban)) {
      errors.iban = 'IBAN is incorrect';
    } else if (
      (['Italy'].includes(country) ||
        (country == 'France' &&
          [
            'Wholesale– Direct',
            'Wholesale– Indirect',
            'Off-Trade – Direct',
            'Off-Trade - Indirect',
          ].includes(shipToType))) &&
      values.swiftBicCode == ''
    ) {
      errors.swiftBicCode = 'SWIFT/BIC Code is required';
    } else if (values.iban != '' && values.supportDocsBank == '') {
      errors.supportDocsBank = 'Support document is required';
      // } else if (
      //   values.payerCountry != 'Italy' &&
      //   values.eInvOption == 'Yes' &&
      //   values.eInvEmailType === ''
      // ) {
      //   errors.eInvEmailType = 'E-Invoicing Email is required';
    } else if (values.payerCountry != 'Italy' && values.eInvOption == 'Yes') {
      if (
        values.eInvEmailType === 'General/Accounting' &&
        values.eInvGeneralEmail != '' &&
        !emailRegex.test(values.eInvGeneralEmail)
      ) {
        errors.eInvEmail = 'Accounting Email is incorrect';
      } else if (values.eInvEmailType === 'General/Accounting' && values.eInvGeneralEmail == '') {
        errors.eInvEmail = 'Accounting Email is required';
      } else if (
        values.eInvEmailType === 'Logistics' &&
        values.eInvLogEmail != '' &&
        !emailRegex.test(values.eInvLogEmail)
      ) {
        errors.eInvEmail = 'Logistics Email is incorrect';
      } else if (values.eInvEmailType === 'Logistics' && values.eInvLogEmail == '') {
        errors.eInvEmail = 'Logistics Email is required';
      } else if (
        values.eInvEmailType === 'Invoicing' &&
        values.eInvInvEmail != '' &&
        !emailRegex.test(values.eInvInvEmail)
      ) {
        errors.eInvEmail = 'Invoicing Email is incorrect';
      } else if (values.eInvEmailType === 'Invoicing' && values.eInvInvEmail == '') {
        errors.eInvEmail = 'Invoicing Email is required';
      } else if (
        values.eInvEmailType === 'Ordering' &&
        values.eInvOrderEmail != '' &&
        !emailRegex.test(values.eInvOrderEmail)
      ) {
        errors.eInvEmail = 'Ordering Email is incorrect';
      } else if (values.eInvEmailType === 'Ordering' && values.eInvOrderEmail == '') {
        errors.eInvEmail = 'Ordering Email is required';
      }
    } else if (values.isVendor == 'Yes' && values.vendorNumber == '') {
      errors.vendorNumber = 'Vendor number is required';
    } else if (
      [
        'Austria',
        'Denmark',
        'Finland',
        'Italy',
        'Luxembourg',
        'Norway',
        'Poland',
        'Sweden',
        'Switzerland',
      ].includes(country) &&
      values.paymentTerms == ''
    ) {
      errors.paymentTerms = 'Payment Term is required';
    } else if (['Italy'].includes(country) && values.riskCategory == '') {
      errors.riskCategory = 'Risk Category is required';
    } else if (
      ['Belgium', 'Netherlands'].includes(country) &&
      shipToType.startsWith('On-Trade') &&
      values.rent == ''
    ) {
      errors.rent = 'Field is required';
    } else if (values.payerCountry == 'Italy' && values.sdiCode == '') {
      errors.sdiCode = 'SDI Code is required';
    }
  }

  return errors;
}
