import { useState, useEffect } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  useEffect(() => {
    sessionStorage.setItem('updateM1mappingUpdate', JSON.stringify(fields));
  }, [fields]);

  return [
    fields,
    function (event, action) {
      // if (['updateOption', 'warehouseEdType'].includes(event.target.name)) {
      //   setValues({
      //     ...fields,
      //     [event.target.name]: event.target.value,
      //   });
      if (action?.name == 'm1') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            m1: '',
            m2: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            m1: event.m1,
            m2: event.m2,
          });
        }
      }
       else if (event.target.name == 'existingShipToID') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          // glnShipTo: '',
          name: '',
          houseNumber: '',
          postalCode: '',
          city: '',
          street: '',
          truckType: '',
          trailerType: ''
        });  
      }else if (['shipToType','att7'].includes(event.target.name)) {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
        }); 
      }else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
    },
  ];
}
