import React from 'react';
const PageHeader = ({title, isSidebarOpen, togglingSidebar}) => {
    return (
        <div className="card-header">
        <div className="col-11 col-lg-12">
          <h3 className="card-title text-left">
            <strong>{title}</strong>
          </h3>
        </div>
        <a
          className={`sidebar-toggler col-auto ${isSidebarOpen ? '' : 'collapsed'}`}
          onClick={togglingSidebar}
        >
          <span className="sidebar-toggler-icon"></span>
        </a>
      </div>
    )
}

export default PageHeader;