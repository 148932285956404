const prod = { ur: {
  fetchRegionsURL: 'https://mavenapis.ab-inbev.com/api/data/regions',
  fetchDeliveryPlantsURL: 'https://mavenapis.ab-inbev.com/api/data/deliveryplants',
  fetchShippingConditionsURL: 'https://mavenapis.ab-inbev.com/api/data/shippingconditions',
},
};
const test = { ur: {
  fetchRegionsURL: 'https://mavenapistest.ab-inbev.com/api/data/regions',
  fetchDeliveryPlantsURL: 'https://mavenapistest.ab-inbev.com/api/data/deliveryplants',
  fetchShippingConditionsURL: 'https://mavenapistest.ab-inbev.com/api/data/shippingconditions',
},
};
const local = { ur: {
  fetchRegionsURL: 'http://localhost:1234/api/data/regions',
  fetchDeliveryPlantsURL: 'http://localhost:1234/api/data/deliveryplants',
  fetchShippingConditionsURL: 'http://localhost:1234/api/data/shippingconditions',
},
};

const configData  = process.env.REACT_APP_ENV === 'prod' ? prod.url : (process.env.REACT_APP_ENV === 'test' ? test.url : local.url);
export default configData;
