import { shipToIDRegex } from '../../../../utils/Utils';

export function validateFields(values, copyClick) {
  let errors = {};

  if (copyClick) {
    if (values.existingShipToID == '') {
      errors.existingShipToID = 'Ship To ID is required';
    } else if (values.existingShipToID != '' && !shipToIDRegex.test(values.existingShipToID)) {
      errors.existingShipToID = 'Ship To ID is incorrect';
    }
  } else {
    // console.log(soldToSap);
    if (values.name != '') {
      //   if (soldToSap != values.existingSoldToID) {
      //     console.log(soldToSap);
      //     errors.existingSoldToID = 'Sold To ID does not match';
      //   } else if (values.telephone != '' && !telephoneRegex[country].test(values.telephone)) {
      //     errors.telephone = 'Telephone number is incorrect';
      //   } else if (country != 'Netherlands' && values.mobile === '') {
      //     errors.mobile = 'Mobile number is required';
      //   } else if (country != 'Netherlands' && values.mobile !== '' && !telephoneRegex[country].test(values.mobile)) {
      //     errors.mobile = 'Mobile number is incorrect';
      //   } else if (values.email === '') {
      //     errors.email = 'Email is required';
      //   } else if (values.email !== '' && !emailRegex.test(values.email)) {
      //     errors.email = 'Email is incorrect';
      //   }
    }
  }

  return errors;
}
