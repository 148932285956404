import { shipToIDRegex } from '../../../../utils/Utils';

export function validateFields(values, copyClick) {
  let errors = {};

  if (copyClick) {
    if (values.existingShipToID == '') {
      errors.existingShipToID = 'Ship To ID is required';
    } else if (values.existingShipToID != '' && !shipToIDRegex.test(values.existingShipToID)) {
      errors.existingShipToID = 'Ship To ID is incorrect';
    }
  } else {
    if (values.name != '') {
      if (values.edValidFrom == '') {
        errors.edValidFrom = 'Valid From is required';
      } else if (values.taxWarehouseNumber == '') {
        errors.taxWarehouseNumber = 'Tax warehouse number is required';
      } else if (values.externalEdNumber == '') {
        errors.externalEdNumber = 'ED number is required';
      } else if (values.partnerType == '') {
        errors.partnerType = 'Partner Type is required';
      }
    }
  }

  return errors;
}
