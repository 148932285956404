import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Row, Offcanvas, CloseButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Sidebar.css';
const Sidebar = (sidebarItems) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // console.log(sidebarItems);
  // console.log(typeof sidebarItems);
  // console.log(sidebarItems[0]);
  {
    /* Performs similarly to componentDidMount in classes */
  }
  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
    console.log(isSidebarOpen);
  };

  // Object.keys(sidebarItems).forEach(item => {
  //   console.log(sidebarItems[item]);
  // });

  return (
    <>
      <a className="sidebar-toggler col-auto" onClick={handleShow}>
        <span className="sidebar-toggler-icon"></span>
      </a>
      {/* <Button  onClick={handleShow} className="sidebar-toggler-icon">
        
        </Button> */}
      <Offcanvas
        show={show}
        onHide={handleClose}
        className={`off-canvas d-lg-none d-sm-block  ${
          isMobile ? (isSidebarOpen ? '' : 'collapse') : ''
          // isMobile ?  : ''
        }`}
        style={{ background: 'linear-gradient(90deg,#e3af32 50%,#f4e00f 100%)' }}
        // style={{ background: 'black' }}
        placement="end"
      >
        <Offcanvas.Header closeButton />
        <Offcanvas.Body>
          {sidebarItems.sidebarItems &&
            sidebarItems.sidebarItems.length > 0 &&
            sidebarItems.sidebarItems.map((item) =>
              item.active ? (
                <Button className="btn btn-danger btn-lg btn-block" key={item.title}>
                  {item.title}
                </Button>
              ) : (
                <Link
                  key={item.title}
                  data-link={item['data-link']}
                  data-back={item['data-back']}
                  className="btn btn-warning btn-lg btn-block"
                  // className="btn btn-dark btn-lg btn-block"
                  onClick={item.onClick}
                >
                  {item.title}
                </Link>
              ),
            )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Sidebar;
