import { useState, useEffect } from 'react';
import configData from './Config';
// import axios from 'axios';
import axios from '../../../utils/axios';

export function FetchPricingHierarchies(country, shipToType) {
  const url = configData.fetchPricingHierarchiesURL;
  const [loading, setLoading] = useState(false);
  const [pricingHierarchies, setPricingHierarchies] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setPricingHierarchies(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (
      country == 'Italy' ||
      country == 'France' ||
      (country == 'Netherlands' && !shipToType.startsWith('On-Trade')) ||
      (country == 'Belgium' &&
        (shipToType.startsWith('National') ||
          ['Off-Trade – Direct', 'On-Trade – Direct', 'On-Trade – Indirect'].includes(shipToType)))
    ) {
      console.log('Ok hierarchy');
      getData();
    }
  }, [country, shipToType]);

  return { loading, pricingHierarchies, getData };
}

export function FetchConditionGroup2(country) {
  const url = configData.fetchConditionGroup2URL;
  const [loading, setLoading] = useState(false);
  const [condGrp2, setcondGrp2] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setcondGrp2(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country !== '') {
      getData();
    }
  }, [country]);

  return { loading, condGrp2, getData };
}

export function FetchCustomerGroup3(country) {
  const url = configData.fetchCustomerGroup3URL;
  const [loading, setLoading] = useState(false);
  const [custGrp3, setcustGrp3] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setcustGrp3(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country !== '') {
      getData();
    }
  }, [country]);

  return { loading, custGrp3, getData };
}
