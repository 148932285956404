import React from 'react';
const CustomTelInput = ({
  errors,
  title,
  maxLength,
  placeholder,
  name,
  setInputs,
  inputs,
  isDisableFields,
  isDisable,
  getLang,
  disabled,
  mandatory,
}) => {
  return (
    <div className="col-12 col-lg-4">
      <div className="form-group">
        <label className="form-label text-left">
          {getLang(title)} {mandatory == true ? ' *' : ''}
        </label>
        <input
          type="tel"
          className={`form-control ${errors[name] ? 'is-invalid' : ''}`}
          name={name}
          placeholder={placeholder}
          onChange={setInputs}
          value={inputs[name]}
          maxLength={maxLength}
          disabled={disabled || isDisableFields || isDisable}
        />
        {errors[name] && <div className="invalid-feedback text-left">{getLang(errors[name])}</div>}
      </div>
    </div>
  );
};

export default CustomTelInput;
