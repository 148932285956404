import React, { useState, useEffect } from 'react';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
function UpdateInvData({
  open,
  columns,
  onClose,
  onSubmit,
  getLang,
  inputs,
  setInputs,
  errors,
  setErrors,
  isDisable,
  handleSubmit,

  rowData,
  activeRow,
}) {
  // useEffect(() => {
  //   if (rowData[activeRow] !== undefined) {
  //     setInputs({ target: { name: 'firstName', value: rowData[activeRow].Z_FIRSTNAME } });
  //     setInputs({ target: { name: 'lastName', value: rowData[activeRow].Z_NAME1 } });
  //   }
  // }, [rowData, activeRow]);
  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Update Contact Person Details</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            width: '100%',
            minWidth: { xs: '300px', sm: '360px', md: '400px' },
            gap: '1.5rem',
          }}
        >
          {columns.map((column) =>
            column.accessorKey === 'Z_CONTACT_PERSON_FUNCTION' ? (
              <div className="form-group" key={column.accessorKey}>
                <label className="form-label text-left">{getLang('Function')}</label>
                <select
                  className={`form-control ${errors.function ? 'is-invalid' : ''}`}
                  name="function"
                  onChange={setInputs}
                  value={inputs.function}
                  disabled={inputs.name == '' || isDisable}
                >
                  <option value="">Select</option>
                  <option value="E-Invoicing">E-Invoicing</option>
                  <option value="E-Payment">E-Payment</option>
                </select>
                {errors.function && (
                  <div className="invalid-feedback text-left">{errors.function}</div>
                )}
              </div>
            ) : (
              <div className="form-group" key={column.accessorKey}>
                <label className="form-label text-left">{getLang(column.header)}</label>
                <input
                  type="text"
                  name={column.id}
                  className={`form-control ${errors[column.id] ? 'is-invalid' : ''}`}
                  onChange={setInputs}
                  value={inputs[column.id]}
                  maxLength="35"
                  disabled={inputs.name == '' || isDisable || column.id === 'contactID'}
                />
                {errors[column.id] && (
                  <div className="invalid-feedback text-left">{errors[column.id]}</div>
                )}
              </div>
            ),
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          data-link="eInvPayerUpdateReview"
          color="primary"
          onClick={handleSubmit}
          variant="contained"
        >
          Review
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateInvData;
