import { shipToIDRegex, soldToIDRegex, payerIDRegex } from '../../../../utils/Utils';

export function validateFields(values, copyShipClick, copySoldClick, copyPayerClick) {
  let errors = {};

  if (copyShipClick) {
    if (values.existingShipToID == '') {
      errors.existingShipToID = 'Ship To ID is required';
    } else if (values.existingShipToID != '' && !shipToIDRegex.test(values.existingShipToID)) {
      errors.existingShipToID = 'Ship To ID is incorrect';
    }
  } else if (copySoldClick) {
    if (values.existingSoldToID == '') {
      errors.existingSoldToID = 'Sold To ID is required';
    } else if (values.existingSoldToID != '' && !soldToIDRegex.test(values.existingSoldToID)) {
      errors.existingSoldToID = 'Sold To ID is incorrect';
    }
  } else if (copyPayerClick) {
    if (values.existingPayerID == '') {
      errors.existingPayerID = 'Payer ID is required';
    } else if (values.existingPayerID != '' && !payerIDRegex.test(values.existingPayerID)) {
      errors.existingPayerID = 'Payer ID is incorrect';
    }
  } else {
    // console.log(soldToSap);
    if (values.name != '') {
      // no op
    }
  }

  return errors;
}
