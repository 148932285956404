import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Row, Col, ButtonGroup, Button, Modal, Spinner } from 'react-bootstrap';
import Select, { components } from 'react-select';
import FeatherIcon from 'feather-icons-react';
import MandatoryText from '../../../components/MandatoryText';

import { FetchRegions } from '../shipto/FlowCallAPI';
import { FetchTitles, FetchPaymentTerms, FetchPayerBapi } from './FlowCallAPI';
import { FetchCountries } from '../mainpage/FlowCallAPI';
import { languages, einvoicingOptions, languageMap, vatPlaceholder } from '../../../utils/Utils';
import { formTemplate } from '../Data';
import { useFormFields } from './HooksLib';
import { validateFields } from './FormValidation';
import Sidebar from '../../../components/layout/Sidebar/Sidebar';

import {
  mobileLength,
  mobilePlaceholder,
  telephoneLength,
  telephonePlaceholder,
  ibanLength,
  ibanPlaceholder,
  vatLength,
  postalCodeLength,
  payerIDRegex,
} from '../../../utils/Utils';

const CreateCustPayer = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) navigate('/');
  }, [isAuthenticated]);

  const { instance, accounts } = useMsal();

  const [isDisable, setIsDisable] = useState(false);

  const fileRegex = /\.[^.\\/:*?"<>|\r\n]+$/;

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [copyClick, setCopyClick] = useState(false);
  const [isDisableFields, setIsDisableFields] = useState(false);
  const [linkForward, setLinkForward] = useState('');
  // const [payerForm, setPayerForm] = useState(sessionStorage.getItem('createCustomerPayer') === null ? payerFormTemplate : JSON.parse(sessionStorage.getItem('createCustomerPayer')));
  // const [inputs, setInputs] = useFormFields(payerForm);
  const [inputs, setInputs] = useFormFields(props.formTemplate);
  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country || '';
  const companyCode =
    JSON.parse(sessionStorage.getItem('createCustomerMainPage')).companyCode || '';
  const salesOrg = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).salesOrg || '';
  const shipToType = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).shipToType || '';
  const customerReqType =
    JSON.parse(sessionStorage.getItem('createCustomerMainPage')).customerReqType || '';
  const mainPageData = JSON.parse(sessionStorage.getItem('createCustomerMainPage'));
  const shipToData = JSON.parse(sessionStorage.getItem('createCustomerShipTo'));
  const titlesRes = FetchTitles(country, isDisableFields);
  const regionsRes = FetchRegions(inputs.payerCountry, isDisableFields);
  const paymentTermsRes = FetchPaymentTerms(country, isDisableFields, shipToType);
  const payerDataRes = FetchPayerBapi(inputs.existingPayerID, companyCode, salesOrg, isCopying);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleClose1 = () => setShowModal1(false);
  const handleClose2 = () => setShowModal2(false);
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState('');
  const countriesRes = FetchCountries();

  function xyz(e) {
    e.preventDefault();
  }

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    if (
      props.draftTicketNum[0] !== 'D' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected' &&
      props.status !== 'Duplicate'
    ) {
      //if ((props.draftTicketNum[0] === 'D')) {
      setIsDisable(true);
    }

    if (inputs.isVendor == '') inputs.isVendor = 'No';
    if (inputs.telephone == '') inputs.telephone = shipToData.generalTelephone;
    if (inputs.mobile == '') inputs.mobile = shipToData.mobile;
    if (inputs.email == '') inputs.email = shipToData.email;
    if (inputs.payerCountry == '') inputs.payerCountry = country;

    switch (country) {
      case 'Belgium':
        if (inputs.vatRegNumber == '0111111111') inputs.vatExist = 'No';
        else if (inputs.vatRegNumber == '0999999999') inputs.vatExist = 'In Progress';
        else inputs.vatExist = 'Yes';

        if (shipToType.endsWith('Direct') && inputs.paymentTerms == '')
          inputs.paymentTerms = '014I';
        else if (shipToType.endsWith('Indirect') && inputs.paymentTerms == '')
          inputs.paymentTerms = '000A';
        else if (!shipToType.startsWith('Wholesaler')) inputs.paymentTerms = '014I';

        if (inputs.language == '') inputs.language = 'English';

        if (shipToType == 'Off-Trade - Indirect' && inputs.eInvOption == '')
          inputs.eInvOption = 'No';

        // if (shipToType.includes('Wholesaler') && inputs.onInvoicingDiscount == '')
        //   inputs.onInvoicingDiscount = 'Yes';

        if (shipToType.startsWith('On-Trade') && inputs.rent == '') inputs.rent = 'No';
        break;
      case 'Netherlands':
        if (inputs.language == '') inputs.language = 'Dutch';
        if (shipToType.endsWith('Indirect') && inputs.paymentTerms == '')
          inputs.paymentTerms = '000A';

        if (shipToType.startsWith('On-Trade') && inputs.rent == '') inputs.rent = 'No';
        break;

      case 'United Kingdom':
        if (inputs.language == '') inputs.language = 'English';
        if (shipToType.endsWith('Indirect') && inputs.paymentTerms == '')
          inputs.paymentTerms = '0000';
        break;

      case 'France':
        if (inputs.language == '') inputs.language = 'French';

        if (inputs.paymentTerms == '') {
          if (shipToType == 'Wholesale– Direct') inputs.paymentTerms = '030M';
          else if (shipToType == 'Wholesale– Indirect') inputs.paymentTerms = '030I';
          else if (shipToType.startsWith('On-Trade')) inputs.paymentTerms = '030M';
          else inputs.paymentTerms = '045M';
        }

        // inputs.paymentTerms = shipToType.startsWith('On-Trade') ? '030M' : '045M';
        break;

      case 'Germany':
        if (inputs.language == '') inputs.language = 'German';
        if (inputs.paymentTerms == '') inputs.paymentTerms = '014I';
        break;

      case 'Finland':
        if (inputs.language == '') inputs.language = 'Finnish';
        if (inputs.paymentTerms == '') inputs.paymentTerms = '014I';
        break;

      case 'Austria':
        if (inputs.language == '') inputs.language = 'German';
        break;

      case 'Denmark':
        if (inputs.language == '') inputs.language = 'Danish';
        break;

      case 'Norway':
        if (inputs.language == '') inputs.language = 'Norwegian';
        break;

      case 'Poland':
        if (inputs.language == '') inputs.language = 'Polish';
        break;

      case 'Sweden':
        if (inputs.language == '') inputs.language = 'Swedish';
        break;

      case 'Switzerland':
        if (inputs.language == '') inputs.language = 'French';
        break;

      case 'Italy':
        if (inputs.language == '') inputs.language = 'Italian';
        inputs.eInvOption = 'Yes';
        inputs.eInvEmailType = 'Invoicing';
        break;

      default:
        break;
    }
  }, []);

  useEffect(() => {
    setFileName(inputs.supportDocsBankName);
    setFile(inputs.supportDocsBank);
  }, [inputs.supportDocsBank, inputs.supportDocsBankName]);

  function getLang(id) {
    // console.log(props.translations);
    let obj = props.translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      // if (!isDisableFields) {
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(
        validateFields(
          inputs,
          country,
          mainPageData.shipToType,
          mainPageData.customerReqType,
          inputs.title,
        ),
      );
      // }
      setIsSubmitting(true);
      // if (isDisableFields || isDisable) props.setActivePage(e.target.getAttribute('data-link'));
      if (isDisable) props.setActivePage(e.target.getAttribute('data-link'));
    }
  };

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && copyClick) {
      setCopyClick(false);
      setIsCopying(true);
      Object.entries(formTemplate.payer).forEach(([key, value]) => {
        if (
          !['existingPayerID', 'language', 'paymentTerms', 'isVendor', 'payerCountry'].includes(key)
        )
          inputs[key] = value;
      });
    } else {
      setIsSubmitting(false);
      setCopyClick(false);
      setIsCopying(false);
    }
  }, [errors]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  const [showEInvSection, setShowEInvSection] = useState(false);

  function eInvOptionChange(e) {
    if (e.target.value === 'Yes') setShowEInvSection(true);
    else setShowEInvSection(false);
    setInputs(e);
  }

  function copyPayerData(e) {
    if (!isDisable && payerIDRegex.test(inputs.existingPayerID)) {
      setCopyClick(true);
      setIsCopying(true);
    }
    // setIsCopying(true);
    // setCopyClick(true);
    // setErrors(validateFields(inputs, country, mainPageData.shipToType, mainPageData.customerReqType, inputs.title));
    // console.log(`I am true ${isCopying}`);
    // console.log(isCopying, inputs.existingPayerID, companyCode, salesOrg);
  }

  useEffect(() => {
    if (Object.keys(payerDataRes.payerData).length !== 0 && isCopying) {
      Object.entries(payerDataRes.payerData).forEach(([key, value]) => {
        if (key == 'contactName') inputs.contactPerson = value;
        else if (key in inputs) {
          if (key === 'telephone' && value !== '') {
            inputs.telephoneOption = 'General/Accounting';
            inputs[key] = value;
          } else if (key == 'vatRegNumber' && value != '') {
            inputs.vatExist = 'Yes';
            inputs[key] = value;
          } else if (key == 'language') inputs.language = languageMap[value];
          else inputs[key] = value;
        }
      });
      sessionStorage.setItem('createCustomerPayer', JSON.stringify(inputs));
    }
    setIsCopying(false);
  }, [payerDataRes.payerData]);

  useEffect(() => {
    if (inputs.existingPayerID !== '') {
      setErrors({});
      setIsDisableFields(true);
    } else setIsDisableFields(false);
  }, [inputs.existingPayerID]);

  function clearFile() {
    inputs.supportDocsBank = '';
    inputs.supportDocsBankName = '';
    setFile('');
    setFileName('');
    sessionStorage.setItem('createCustomerPayer', JSON.stringify(inputs));
  }

  const Input = (props) => <components.Input {...props} maxLength={8} />;

  const paymentTermsOptions = paymentTermsRes.paymentTerms.map((val) => {
    if (val.termsOfPayment == '0000')
      return {
        ...val,
        termsOfPayment: val.termsOfPayment,
        paymentDesc: val.paymentDesc,
        paymentVal: val.termsOfPayment,
      };
    else
      return {
        ...val,
        termsOfPayment: val.termsOfPayment,
        paymentDesc: val.paymentDesc,
        paymentVal: `${val.termsOfPayment} - ${val.paymentDesc}`,
      };
  });

  const regionOptions = regionsRes.regions.map((val) => {
    return {
      ...val,
      regionCode: val.regionCode,
      regionText: val.regionText,
      erpTranspZone: val.erpTranspZone,
      regionDesc: `${val.regionCode} - ${val.regionText}`,
    };
  });

  const sidebarItems = [
    {
      title: getLang('Main Page'),
      onClick: handleSubmit,
      ['data-link']: 'mainpage',
      ['data-back']: 'true',
      active: false,
    },
    {
      title: getLang('Vendor'),
      onClick: handleSubmit,
      ['data-link']: 'vendor',
      ['data-back']: true,
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Ship-To'),
      onClick: handleSubmit,
      ['data-link']: 'shipto',
      ['data-back']: true,
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Payer'),
      onClick: handleSubmit,
      ['data-link']: 'payer',
      ['data-back']: 'true',
      // type: 'submit',
      active: true,
    },
    {
      title: getLang('Sold-To'),
      onClick: handleSubmit,
      ['data-link']: 'soldto',
      // ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Bill-To'),
      onClick: handleSubmit,
      ['data-link']: 'billto',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
  ];

  return (
    isAuthenticated && (
      <form className="card" onSubmit={handleSubmit}>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={inputs.supportDocsBank}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModal1} onHide={handleClose1}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={inputs.supportDocsBank1}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModal2} onHide={handleClose2}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={inputs.supportDocsBank2}></img>
          </Modal.Body>
        </Modal>

        <Modal show={isCopying} centered>
          <Modal.Body>
            <Row>
              <div className="col-auto">
                <Spinner animation="border" variant="warning" />
              </div>
              <div className="text-left">
                <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
        <div className="card-header">
          <div className="col-11 col-lg-12">
            <h3 className="card-title text-left">
              <strong style={{ color: 'rgb(177, 31, 36)' }}>
                Vendor Customer {getLang('Creation')}
              </strong>{' '}
              - {getLang('Payer')}
            </h3>
          </div>
          <a
            className={`sidebar-toggler col-auto ${isSidebarOpen ? '' : 'collapsed'}`}
            // onClick={togglingSidebar}
          >
            <Sidebar sidebarItems={sidebarItems} />
            {/* <span className="sidebar-toggler-icon"></span> */}
          </a>
        </div>
        <Row className="sidebar-row">
          <div
            className={`col-12 col-lg-2 text-left sidebar ${
              isMobile ? (isSidebarOpen ? '' : 'collapse') : ''
            }`}
          >
            <Row>
              <ButtonGroup vertical className="btn-block">
                <Link
                  data-link="mainpage"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {getLang('Main Page')}
                </Link>
                <Link to="" data-link="vendor" className="btn btn-warning" onClick={handleSubmit}>
                  {getLang('Vendor')}
                </Link>
                <Link
                  data-link="shipto"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {getLang('Ship To')}
                </Link>
                <Button className="btn-danger">{getLang('Payer')}</Button>
                <Link data-link="soldto" className="btn btn-warning" onClick={handleSubmit}>
                  {getLang('Sold To')}
                </Link>
                <Link data-link="billto" className="btn btn-warning" onClick={handleSubmit}>
                  {getLang('Bill To')}
                </Link>
              </ButtonGroup>
            </Row>
          </div>
          <div className="col-12 col-lg-10">
            <div className="card-body">
              <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12 col-lg-auto text-left">
                      <h2
                        style={{
                          color: 'rgb(177, 31, 36)',
                        }}
                      >
                        {getLang('General Data')}
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    {!['Norway', 'Switzerland', 'United Kingdom', 'Netherlands'].includes(
                      country,
                    ) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Title')}
                            {['Italy'].includes(country) ? ' *' : ''}
                          </label>
                          <select
                            id="select-title"
                            className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                            name="title"
                            value={inputs.title}
                            onChange={setInputs}
                            disabled={isDisableFields || isDisable}
                          >
                            <option value="">Select</option>
                            {titlesRes.titles.map((val) => (
                              <option key={val.title} value={val.title} data-title={val.title}>
                                {val.title}
                              </option>
                            ))}
                          </select>
                          {errors.title && (
                            <div className="invalid-feedback text-left">{errors.title}</div>
                          )}
                        </div>
                      </div>
                    )}
                    {/* {country == 'Belgium' && !shipToType.includes('Wholesaler') && ( */}
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Does the customer have a VAT number?')}
                        </label>
                        <select
                          className="form-control"
                          name="vatExist"
                          value={inputs.vatExist}
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                          <option value="In Progress">In Progress</option>
                        </select>
                      </div>
                    </div>
                    {/* )} */}
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('VAT Registration No')}.
                          {country == 'United Kingdom' && shipToType == 'On-Trade – Indirect'
                            ? ''
                            : country == 'United Kingdom' ||
                              [
                                'Austria',
                                'Denmark',
                                'Finland',
                                'Italy',
                                'Luxembourg',
                                'Norway',
                                'Poland',
                                'Sweden',
                                'Switzerland',
                              ].includes(country) ||
                              (country == 'Netherlands' &&
                                country == 'France' &&
                                inputs.title != 'ASBL') ||
                              (country == 'Belgium' && shipToType == 'Off-Trade – Direct') ||
                              inputs.vatExist == 'Yes'
                            ? ' *'
                            : ''}
                          {/* {!(country === 'Netherlands' && mainPageData.customerReqType === 'Prospect') && !(country === 'France' && inputs.title === 'ASBL') ? ' *' : ''} */}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.vatRegNumber ? 'is-invalid' : ''}`}
                          name="vatRegNumber"
                          maxLength={vatLength(inputs.payerCountry)}
                          placeholder={vatPlaceholder(inputs.payerCountry)}
                          value={inputs.vatRegNumber}
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.vatRegNumber && (
                          <div className="invalid-feedback text-left">{errors.vatRegNumber}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {['United Kingdom', 'Netherlands'].includes(country)
                            ? getLang('Legal Entity / Name')
                            : country == 'France'
                            ? getLang('NamePayer')
                            : getLang('Name')}{' '}
                          *
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                          name="name"
                          value={inputs.name}
                          placeholder={getLang('Name')}
                          onChange={setInputs}
                          maxLength="35"
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.name && (
                          <div className="invalid-feedback text-left">{errors.name}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Postal Code')} *</label>
                        <input
                          type="text"
                          className={`form-control ${errors.postalCode ? 'is-invalid' : ''}`}
                          name="postalCode"
                          maxLength={postalCodeLength(inputs.payerCountry)}
                          // maxLength={['Germany', 'France'].includes(inputs.payerCountry) ? 5 : inputs.payerCountry === 'Belgium' ? 4 : 9}
                          value={inputs.postalCode}
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.postalCode && (
                          <div className="invalid-feedback text-left">{errors.postalCode}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('House Number')} {country !== 'United Kingdom' ? ' *' : ''}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.houseNumber ? 'is-invalid' : ''}`}
                          name="houseNumber"
                          value={inputs.houseNumber}
                          onChange={setInputs}
                          maxLength="10"
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.houseNumber && (
                          <div className="invalid-feedback text-left">{errors.houseNumber}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Street')} *</label>
                        <input
                          type="text"
                          className={`form-control ${errors.street ? 'is-invalid' : ''}`}
                          name="street"
                          value={inputs.street}
                          onChange={setInputs}
                          maxLength="35"
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.street && (
                          <div className="invalid-feedback text-left">{errors.street}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('City')} *</label>
                        <input
                          type="text"
                          className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                          name="city"
                          value={inputs.city}
                          onChange={setInputs}
                          maxLength="35"
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.city && (
                          <div className="invalid-feedback text-left">{errors.city}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Country')}
                          {country == 'Italy' ? ' *' : ''}
                        </label>
                        {/* <input type="text" className="form-control" name="country" value={country} readOnly /> */}
                        <select
                          className={`form-control ${errors.payerCountry ? 'is-invalid' : ''}`}
                          name="payerCountry"
                          onChange={setInputs}
                          value={inputs.payerCountry}
                          required
                          disabled={isDisableFields || isDisable}
                        >
                          <option value="">Select</option>
                          {/* {countriesRes.countries.filter(e => ['France', 'Finland', 'Belgium', 'Netherlands', 'United Kingdom', 'Germany'].includes(e.country)).map((val) => (
                            <option key={val.countryCode} value={val.country}>
                              {val.country}
                            </option>
                          ))} */}
                          {countriesRes.countries.map((val) => (
                            <option key={val.countryCode} value={val.country}>
                              {val.country}
                            </option>
                          ))}
                        </select>
                        {errors.payerCountry && (
                          <div className="invalid-feedback text-left">{errors.payerCountry}</div>
                        )}
                      </div>
                    </div>
                    {inputs.payerCountry != 'Luxembourg' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Region')} *</label>
                          <Select
                            className="text-left"
                            name="region"
                            value={regionOptions.filter((opt) => opt.regionCode == inputs.region)}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#cfcfcf',
                                primary50: '#cfcfcf',
                                primary: 'orange',
                              },
                            })}
                            isClearable
                            placeholder="Select"
                            isDisabled={isDisableFields || isDisable}
                            getOptionLabel={({ regionDesc }) => regionDesc}
                            getOptionValue={({ regionCode }) => regionCode}
                            options={regionOptions}
                            onChange={(e, action) => {
                              setInputs(e, action);
                            }}
                          />
                          {errors.region && (
                            <div className="invalid-feedback-file text-left">{errors.region}</div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Language')}</label>
                        <select
                          id="select-lang"
                          className="form-control"
                          name="language"
                          value={inputs.language}
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        >
                          {/* <option value="">Select</option>
                          {languages.map((lang) => (
                            <option key={lang} value={lang}>
                              {lang}
                            </option>
                          ))} */}
                          <option value="">Select</option>
                          <option value="Dutch">Dutch</option>
                          <option value="French">French</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-12 col-lg-auto text-left">
                      <h2
                        style={{
                          color: 'rgb(177, 31, 36)',
                        }}
                      >
                        {getLang('Communication')}
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Telephone/Fax numbers')}
                          {[
                            'Netherlands',
                            'Austria',
                            'Denmark',
                            'Finland',
                            'Italy',
                            'Norway',
                            'Poland',
                            'Sweden',
                            'Switzerland',
                            'Belgium',
                          ].includes(country)
                            ? ' *'
                            : ''}
                        </label>
                        <input
                          type="tel"
                          className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}
                          name="telephone"
                          placeholder={telephonePlaceholder(country)}
                          maxLength={telephoneLength(country)}
                          value={inputs.telephone}
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.telephone && (
                          <div className="invalid-feedback text-left">{errors.telephone}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Mobile')}
                          {['Netherlands', 'United Kingdom', 'France', 'Italy'].includes(country)
                            ? ' *'
                            : ''}
                        </label>
                        <input
                          type="tel"
                          className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                          placeholder={mobilePlaceholder(country)}
                          maxLength={mobileLength(country)}
                          name="mobile"
                          value={inputs.mobile}
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.mobile && (
                          <div className="invalid-feedback text-left">{errors.mobile}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('E-mail Address')} *{' '}
                        </label>
                        <input
                          type="email"
                          className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                          name="email"
                          placeholder="Eg.: abc@company.com"
                          value={inputs.email}
                          onChange={setInputs}
                          maxLength="240"
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.email && (
                          <div className="invalid-feedback text-left">{errors.email}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Contact Person')} {country == 'Belgium' ? ' *' : ''}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.contactPerson ? 'is-invalid' : ''}`}
                          name="contactPerson"
                          value={inputs.contactPerson}
                          onChange={setInputs}
                          maxLength="35"
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.contactPerson && (
                          <div className="invalid-feedback text-left">{errors.contactPerson}</div>
                        )}
                      </div>
                    </div>
                    {!['Italy'].includes(inputs?.payerCountry) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('E-Invoicing')}</label>
                          <select
                            className="form-control"
                            name="eInvOption"
                            value={inputs.eInvOption}
                            onChange={eInvOptionChange}
                            disabled={
                              isDisableFields || isDisable || inputs.payerCountry == 'Italy'
                            }
                          >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                    )}
                    {!['Italy'].includes(inputs?.payerCountry) && inputs.eInvOption === 'Yes' && (
                      <>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('E-Invoicing Email')} Type{` *`}
                            </label>
                            <select
                              className={`form-control ${errors.eInvEmailType ? 'is-invalid' : ''}`}
                              name="eInvEmailType"
                              value={inputs.eInvEmailType}
                              onChange={setInputs}
                              disabled={
                                isDisableFields || isDisable || inputs.payerCountry == 'Italy'
                              }
                            >
                              <option value="">Select</option>
                              {einvoicingOptions.map((opt) => (
                                <option key={opt} value={opt}>
                                  {opt}
                                </option>
                              ))}
                            </select>
                            {errors.eInvEmailType && (
                              <div className="invalid-feedback text-left">
                                {errors.eInvEmailType}
                              </div>
                            )}
                          </div>
                        </div>
                        {inputs.eInvEmailType != '' && (
                          <div className="col-12 col-lg-4">
                            <div className="form-group">
                              <label className="form-label text-left">
                                {inputs.eInvEmailType} Email
                              </label>
                              {inputs.eInvEmailType === 'General/Accounting' && (
                                <input
                                  type="email"
                                  className={`form-control ${errors.eInvEmail ? 'is-invalid' : ''}`}
                                  name="eInvGeneralEmail"
                                  placeholder="Enter the General/Accounting email"
                                  value={inputs.eInvGeneralEmail}
                                  onChange={setInputs}
                                  maxLength="240"
                                  disabled={isDisableFields || isDisable}
                                />
                              )}
                              {inputs.eInvEmailType === 'Logistics' && (
                                <input
                                  type="email"
                                  className={`form-control ${errors.eInvEmail ? 'is-invalid' : ''}`}
                                  name="eInvLogEmail"
                                  placeholder="Enter the Logistics email"
                                  value={inputs.eInvLogEmail}
                                  onChange={setInputs}
                                  maxLength="240"
                                  disabled={isDisableFields || isDisable}
                                />
                              )}
                              {inputs.eInvEmailType === 'Invoicing' && (
                                <input
                                  type="email"
                                  className={`form-control ${errors.eInvEmail ? 'is-invalid' : ''}`}
                                  name="eInvInvEmail"
                                  placeholder="Enter the Invoicing email"
                                  value={inputs.eInvInvEmail}
                                  onChange={setInputs}
                                  maxLength="240"
                                  disabled={isDisableFields || isDisable}
                                />
                              )}
                              {inputs.eInvEmailType === 'Ordering' && (
                                <input
                                  type="email"
                                  className={`form-control ${errors.eInvEmail ? 'is-invalid' : ''}`}
                                  name="eInvOrderEmail"
                                  placeholder="Enter the Ordering email"
                                  value={inputs.eInvOrderEmail}
                                  onChange={setInputs}
                                  maxLength="240"
                                  disabled={isDisableFields || isDisable}
                                />
                              )}
                              {errors.eInvEmail && (
                                <div className="invalid-feedback text-left">{errors.eInvEmail}</div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {!['Italy'].includes(inputs.payerCountry) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Is Vendor')}</label>
                          <select
                            className="form-control"
                            name="isVendor"
                            value={inputs.isVendor}
                            onChange={setInputs}
                            disabled={
                              isDisableFields ||
                              isDisable ||
                              !(
                                ['Germany', 'Netherlands'].includes(country) ||
                                (country === 'Belgium' && shipToType !== 'Off-Trade - Indirect') ||
                                (country === 'France' &&
                                  (shipToType.includes('Wholesale') ||
                                    shipToType.includes('Direct')))
                              )
                            }
                          >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                    )}
                    {inputs.isVendor === 'Yes' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Vendor No.')}</label>
                          <input
                            type="text"
                            className={`form-control ${errors.vendorNumber ? 'is-invalid' : ''}`}
                            name="vendorNumber"
                            value={inputs.vendorNumber}
                            onChange={setInputs}
                            maxLength="35"
                            disabled={isDisableFields || isDisable}
                          />
                          {errors.vendorNumber && (
                            <div className="invalid-feedback text-left">{errors.vendorNumber}</div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-12 col-lg-auto text-left">
                      <h2
                        style={{
                          color: 'rgb(177, 31, 36)',
                        }}
                      >
                        {getLang('Bank and Tax Details')}
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-left color-text">
                      <p>
                        <strong>{`Attachment limit is 1 MB`}</strong>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('IBAN')}
                          {['Austria', 'Italy', 'Norway', 'Poland', 'Switzerland'].includes(
                            country,
                          ) ||
                          country == 'Netherlands' ||
                          (country == 'Belgium' && shipToType.startsWith('Wholesaler')) ||
                          country == 'United Kingdom' ||
                          (country == 'France' &&
                            [
                              'Wholesale– Direct',
                              'Wholesale– Indirect',
                              'On-Trade – Direct',
                            ].includes(shipToType))
                            ? ' *'
                            : ''}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.iban ? 'is-invalid' : ''}`}
                          name="iban"
                          maxLength={ibanLength(country)}
                          placeholder={ibanPlaceholder(country)}
                          value={inputs.iban}
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.iban && (
                          <div className="invalid-feedback text-left">{errors.iban}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Account Holder Name')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="accHolderName"
                          value={inputs.accHolderName}
                          onChange={setInputs}
                          maxLength="35"
                          disabled={isDisableFields || isDisable}
                        />
                      </div>
                    </div>
                    {!['Italy'].includes(country) && (
                      <>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Bank Key')}</label>
                            <input
                              type="text"
                              className="form-control"
                              name="bankKey"
                              value={inputs.bankKey}
                              onChange={setInputs}
                              maxLength="35"
                              disabled={isDisableFields || isDisable}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Bank Account')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="bankAcc"
                              value={inputs.bankAcc}
                              onChange={setInputs}
                              maxLength="35"
                              disabled={isDisableFields || isDisable}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('SWIFT/ BIC Code')}
                          {['Italy'].includes(country) ? ' *' : ''}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.swiftBicCode ? 'is-invalid' : ''}`}
                          maxLength="30"
                          name="swiftBicCode"
                          value={inputs.swiftBicCode}
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.swiftBicCode && (
                          <div className="invalid-feedback text-left">{errors.swiftBicCode}</div>
                        )}
                      </div>
                    </div>
                    {/* Attachment goes here */}
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Please Attach The Supportings For IBAN & Bank Details')}
                        </label>
                        {!inputs.supportDocsBank && (
                          <input
                            type="file"
                            id="upload-support-docs"
                            accept="image/png, image/jpeg, .doc, .docx, application/msword, .msg, .pdf, .zip"
                            name="supportDocsBank"
                            onChange={setInputs}
                            disabled={isDisableFields || isDisable}
                          />
                        )}
                        {inputs.supportDocsBank && (
                          <div className="form-control-textfield text-left">
                            <a
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                                if (
                                  ['.jpeg', '.jpg', '.png'].includes(
                                    fileRegex.exec(inputs.supportDocsBankName)[0],
                                  )
                                ) {
                                  setShowModal(true);
                                }
                              }}
                            >
                              {inputs.supportDocsBankName}
                            </a>
                            &nbsp;
                            <FeatherIcon
                              id="clearFile"
                              icon="x"
                              size={14}
                              name="clearFile"
                              onClick={setInputs}
                              className="fe-icon"
                            />
                          </div>
                        )}
                        {errors.supportDocsBank && (
                          <div className="invalid-feedback-file text-left">
                            {errors.supportDocsBank}
                          </div>
                        )}
                      </div>
                    </div>

                    {
                      <>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Attachment 2')}
                            </label>
                            {!inputs.supportDocsBank1 && (
                              <input
                                type="file"
                                accept="image/png, image/jpeg, .doc, .docx, application/msword, .msg, .pdf, .zip"
                                name="supportDocsBank1"
                                onChange={setInputs}
                                disabled={isDisableFields || isDisable}
                              />
                            )}
                            {inputs.supportDocsBank1 && (
                              <div className="form-control-textfield text-left">
                                <a
                                  href=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                      ['.jpeg', '.jpg', '.png'].includes(
                                        fileRegex.exec(inputs.supportDocsBankName1)[0],
                                      )
                                    ) {
                                      setShowModal1(true);
                                    }
                                  }}
                                >
                                  {inputs.supportDocsBankName1}
                                </a>
                                &nbsp;
                                <FeatherIcon
                                  id="clearFile1"
                                  icon="x"
                                  size={14}
                                  name="clearFile1"
                                  onClick={setInputs}
                                  className="fe-icon"
                                />
                              </div>
                            )}
                            {errors.supportDocsBank1 && (
                              <div className="invalid-feedback-file text-left">
                                {errors.supportDocsBank1}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Attachment 3')}
                            </label>
                            {!inputs.supportDocsBank2 && (
                              <input
                                type="file"
                                accept="image/png, image/jpeg, .doc, .docx, application/msword, .msg, .pdf, .zip"
                                name="supportDocsBank2"
                                onChange={setInputs}
                                disabled={isDisableFields || isDisable}
                              />
                            )}
                            {inputs.supportDocsBank2 && (
                              <div className="form-control-textfield text-left">
                                <a
                                  href=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                      ['.jpeg', '.jpg', '.png'].includes(
                                        fileRegex.exec(inputs.supportDocsBankName2)[0],
                                      )
                                    ) {
                                      setShowModal2(true);
                                    }
                                  }}
                                >
                                  {inputs.supportDocsBankName2}
                                </a>
                                &nbsp;
                                <FeatherIcon
                                  id="clearFile2"
                                  icon="x"
                                  size={14}
                                  name="clearFile2"
                                  onClick={setInputs}
                                  className="fe-icon"
                                />
                              </div>
                            )}
                            {errors.supportDocsBank2 && (
                              <div className="invalid-feedback-file text-left">
                                {errors.supportDocsBank2}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    }
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-12 col-lg-auto text-left">
                      <h2
                        style={{
                          color: 'rgb(177, 31, 36)',
                        }}
                      >
                        {getLang('Company Data')}
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    {/* Get a state variable to define disabled and value in case of On-Trade – Indirect, Off-Trade - Retail, TPD2/ TPD3, Miscellaneous. 
                                Just make a new select based on the country */}
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Terms of payment')}
                          {[
                            'Belgium',
                            'Austria',
                            'Denmark',
                            'Finland',
                            'Italy',
                            'Luxembourg',
                            'Norway',
                            'Poland',
                            'Sweden',
                            'Switzerland',
                          ].includes(country)
                            ? ' *'
                            : ''}
                        </label>
                        <select
                          className={`form-control ${errors.paymentTerms ? 'is-invalid' : ''}`}
                          name="paymentTerms"
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        >
                          <option value="">Select</option>
                          <option value="000A">000A</option>
                          <option value="Others">Others</option>
                        </select>

                        {errors.paymentTerms && (
                          <div className="invalid-feedback-file text-left">
                            {errors.paymentTerms}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Row>
        <div className="card-footer">
          <div className="row">
            <div className="col-auto col-md-auto text-left">
              {!isDisable && (
                <Button variant="outline-warning" onClick={props.saveDraft}>
                  Save Draft
                </Button>
              )}
            </div>
            <div className="col col-md text-left justify-content">{props.draftMsg}</div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link="shipto"
                data-back="true"
                className="btn btn-warning btn-block"
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Back
              </Link>
            </div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link="soldto"
                className={`btn btn-${
                  Object.keys(validateFields(inputs)).length == 0 || isDisable
                    ? 'danger'
                    : 'secondary'
                } btn-block`}
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Next
              </Link>
            </div>
          </div>
        </div>
      </form>
    )
  );
};

export default CreateCustPayer;
