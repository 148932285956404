import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
const PageFooter = ({isDisable, saveDraft, draftMsg, handleSubmit, validateFields, footerItems, inputs}) => {
    return (
        <div className="card-footer">
          <div className="row">
            <div className="col-auto col-md-auto text-left">
              {!isDisable && (
                <Button
                  variant="outline-warning"
                  onClick={saveDraft}
                  // disabled={process.env.REACT_APP == 'prod'}
                >
                  Save Draft
                </Button>
              )}
            </div>
            <div className="col col-md text-left justify-content">{draftMsg}</div>
            {footerItems && footerItems.length>0 && footerItems.map((item, index) => (
              item.type == 'submit' ? 
              <div key={index} className="col-auto col-md-auto text-right">
                <Link
                  data-link={item['data-link']}
                  className={`btn btn-${
                    Object.keys(validateFields(inputs)).length == 0 || isDisable
                      ? 'danger'
                      : 'secondary'
                  } btn-block`}
                  style={{ textDecoration: 'none' }}
                  onClick={item.onClick}
                >
                  {item.title}
                </Link>
              </div>
              :
              item.type == 'review' ?
              <div key={index} className="col-auto col-md-auto text-right">
                <Link
                  data-link={item['data-link']}
                  data-back={item['data-back']}
                  className={`btn btn-${
                    Object.keys(validateFields(inputs)).length == 0 || isDisable
                      ? 'danger'
                      : 'secondary'
                  } btn-block`}
                  style={{ textDecoration: 'none' }}
                  onClick={item.onClick}
                >
                  {item.title}
                </Link>
              </div>
              :
              <div key={index} className="col-auto col-md-auto text-right">
                <Link
                  data-link={item['data-link']}
                  data-back={item['data-back']}
                  className="btn btn-warning btn-block"
                  style={{ textDecoration: 'none' }}
                  onClick={item.onClick}
                >
                  {item.title}
                </Link>
              </div>
            ))}
          </div>
        </div>
    )
}
export default PageFooter;