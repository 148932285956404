import React, { useState } from 'react';
import { Tabs, Tab, Paper } from '@mui/material';
import ApprovalsDashboard from './approval_dashboard';
import { Bold } from 'feather-icons-react/build/IconComponents';

const MyDataGrid = (props) => {
  const [currentView, setCurrentView] = useState('pending');

  const handleTabChange = (event, newValue) => {
    setCurrentView(newValue);
  };

  return (
    <div>
      <Paper square>
        <Tabs
          value={currentView}
          onChange={handleTabChange}
          // indicatorColor="primary"
          // textColor="primary"
          centered
        >
          <Tab
            value="pending"
            label={<span style={{ color: 'goldenrod', fontWeight: Bold }}>Pending</span>}
          />
          <Tab
            textColor="red"
            value="rejected"
            label={<span style={{ color: 'red', fontWeight: Bold }}>Rejected</span>}
          />
          <Tab
            textColor="teal"
            value="approved"
            label={<span style={{ color: 'teal', fontWeight: Bold }}>Approved</span>}
          />
        </Tabs>
      </Paper>

      {currentView === 'pending' && (
        <ApprovalsDashboard
          requestType="pending"
          RequestCreationType={props.RequestCreationType}
          hide={false}
        />
      )}
      {currentView === 'rejected' && (
        <ApprovalsDashboard
          requestType="rejected"
          RequestCreationType={props.RequestCreationType}
          hide={false}
        />
      )}
      {currentView === 'approved' && (
        <ApprovalsDashboard
          requestType="approved"
          RequestCreationType={props.RequestCreationType}
          hide={true}
        />
      )}
    </div>
  );
};

export default MyDataGrid;
