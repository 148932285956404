const prod = { url : {
  fetchRegionsURL: 'https://mavenapis.ab-inbev.com/api/data/regions',
},
};

const test = { url : {
  fetchRegionsURL: 'https://mavenapistest.ab-inbev.com/api/data/regions',
},
};

const local = { url : {
  fetchRegionsURL: 'http://localhost:1234/api/data/regions',
},
};
const configData  = process.env.REACT_APP_ENV === 'prod' ? prod.url : (process.env.REACT_APP_ENV === 'test' ? test.url : local.url);
export default configData;
