import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import { Button, ButtonGroup, Row, Col, Modal, Spinner } from 'react-bootstrap';

// import { validateFields as mainPageValidate } from '../mainpage/FormValidation';
// import { validateFields as shipToValidate } from '../shipto/FormValidation';
// import { validateFields as payerValidate } from '../payer/FormValidation';
// import { validateFields as soldToValidate } from '../soldto/FormValidation';
// import { validateFields as billToValidate } from '../billto/FormValidation';
// import { PostToSharepoint } from './FlowCallAPI';
import { PostToSharepoint } from '../../utils/FlowCallAPI';

const UpdateM1mappingReview = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) navigate('/');
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  const [isSave, setIsSave] = useState(false);

  const [isSaveRequest, setIsSaveRequest] = useState(false);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  const mainPageForm =
    sessionStorage.getItem('updateCustomerMainPage') === null
      ? {}
      : JSON.parse(sessionStorage.getItem('updateCustomerMainPage'));
  const M1mappingForm =
    sessionStorage.getItem('updateM1mappingUpdate') === null
      ? {}
      : JSON.parse(sessionStorage.getItem('updateM1mappingUpdate'));

  const postToSharepointRes = PostToSharepoint(
    props.accounts.length > 0 ? props.accounts[0].username : '',
    props.draftTicketNum,
    isSaveRequest,
  );

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    props.setActivePage(e.target.getAttribute('data-link'));
  };

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const [showSubmitModal, setShowSubmitModal] = useState(false);

  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleSave = () => {
    setShowSubmitModal(true);
    setIsSave(true);
    setIsSaveRequest(true);
  };

  function getLang(id) {
    // console.log(props.translations);
    let obj = props.translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    if (props.draftMsg !== '') {
      console.log(props.draftMsg);
      setIsSaveRequest(true);
    }
  }, [props.draftMsg]);

  useEffect(() => {
    setIsSaveRequest(false);
    setIsSave(false);
    if (Object.keys(postToSharepointRes.result).length !== 0) {
      console.log('Successfully saved.');
      props.setActivePage('success');
    } else if (postToSharepointRes.isError) {
      props.setActivePage('error');
    }
  }, [postToSharepointRes.result, postToSharepointRes.isError]);

  useEffect(() => {
    if (
      props.draftTicketNum[0] !== 'M' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected'
    ) {
      setIsDisable(true);
    }
  }, []);

  return (
    isAuthenticated && (
      <form className="card">
        <Modal show={isSave} centered>
          <Modal.Body>
            <Row>
              <div className="col-auto">
                <Spinner animation="border" variant="warning" />
              </div>
              <div className="text-left">
                <span className="align-middle">
                  &nbsp;Please wait while your request is submitted
                </span>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
        <div className="card-header">
          <div className="col-11 col-lg-12">
            <h3 className="card-title text-left">
              <strong>Customer Data {getLang('Update')}</strong> - {getLang('Review')}
            </h3>
          </div>
          <a
            className={`sidebar-toggler col-auto ${isSidebarOpen ? '' : 'collapsed'}`}
            onClick={togglingSidebar}
          >
            <span className="sidebar-toggler-icon"></span>
          </a>
        </div>
        <Row className="sidebar-row">
          <div className="col-12">
            <div className="card-body text-left">
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col sm={12} lg="auto">
                      <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('M1 Mapping Update')}</h2>
                    </Col>
                  </Row>

                  {M1mappingForm.existingShipToID != '' && (
                    <>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Ship-to ID')}
                            </label>
                            <div className="form-control-textfield">
                              {M1mappingForm.existingShipToID}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">{M1mappingForm.name}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Ship To Type')}
                            </label>
                            <div className="form-control-textfield">{M1mappingForm.shipToType}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">{M1mappingForm.postalCode}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">{M1mappingForm.city}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">{M1mappingForm.street}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('M1')}</label>
                            <div className="form-control-textfield">{M1mappingForm.m1}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('M2')}</label>
                            <div className="form-control-textfield">{M1mappingForm.m2}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Use Of Tank Beer')}
                            </label>
                            <div className="form-control-textfield">{M1mappingForm.att7}</div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        {mainPageForm.country == 'Italy' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Truck Type')}
                                </label>
                                <div className="form-control-textfield">
                                  {M1mappingForm.truckType}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Trailer Type')}
                                </label>
                                <div className="form-control-textfield">
                                  {M1mappingForm.trailerType}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Row>

        <br />

        <div className="card-footer">
          <div className="row">
            <div className="col col-md"></div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link="M1mappingUpdate"
                className="btn btn-warning btn-block"
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Back
              </Link>
            </div>
            {!isDisable && (
              <div className="col-auto col-md-auto text-right">
                <Link
                  data-link="success"
                  className="btn btn-danger btn-block"
                  style={{ textDecoration: 'none' }}
                  onClick={handleSave}
                >
                  {getLang('Submit')}
                </Link>
              </div>
            )}
          </div>
        </div>
      </form>
    )
  );
};

export default UpdateM1mappingReview;
