import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Button, ButtonGroup, Row, Col, Container, Modal, Spinner } from 'react-bootstrap';
import MandatoryText from '../../../components/MandatoryText';

import { validateFields } from './utils/FormValidation';
import { FetchShipToBapi, FetchSoldToBapi, FetchPayerBapi } from '../utils/FlowCallAPI';
import { useFormFields } from './utils/HooksLib';
import { formTemplate } from '../Data';

const UpdateCustOthersGlnUpdate = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  const [translations, setTranslations] = useState(props.translations);

  const [inputs, setInputs] = useFormFields(props.formTemplate);

  useEffect(() => {
    // if (!isAuthenticated) navigate('/');
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    console.log(inputs);
    if (
      props.draftTicketNum[0] !== 'M' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected'
    ) {
      setIsDisable(true);
    }

    if (inputs.shipToName != '') setIsDisableShipToFields(false);

    if (inputs.payerName != '') setIsDisablePayerFields(false);

    if (inputs.soldToName != '') setIsDisableSoldToFields(false);
  }, []);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkForward, setLinkForward] = useState('');

  const [isShipCopying, setIsShipCopying] = useState(false);
  const [copyShipClick, setCopyShipClick] = useState(false);

  const [isSoldCopying, setIsSoldCopying] = useState(false);
  const [copySoldClick, setCopySoldClick] = useState(false);

  const [isPayerCopying, setIsPayerCopying] = useState(false);
  const [copyPayerClick, setCopyPayerClick] = useState(false);

  // const [isDisableFields, setIsDisableFields] = useState(true);
  const [isDisableShipToFields, setIsDisableShipToFields] = useState(true);
  const [isDisableSoldToFields, setIsDisableSoldToFields] = useState(true);
  const [isDisablePayerFields, setIsDisablePayerFields] = useState(true);

  const mainPageForm = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')) || {};

  const shipToDataRes = FetchShipToBapi(
    inputs.existingShipToID,
    mainPageForm.companyCode,
    mainPageForm.salesOrg,
    isShipCopying,
  );

  const soldToDataRes = FetchSoldToBapi(
    inputs.existingSoldToID,
    mainPageForm.companyCode,
    mainPageForm.salesOrg,
    isSoldCopying,
  );

  const payerDataRes = FetchPayerBapi(
    inputs.existingPayerID,
    mainPageForm.companyCode,
    mainPageForm.salesOrg,
    isPayerCopying,
  );

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(validateFields(inputs, false, false, false));
      setIsSubmitting(true);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      props.setActivePage(linkForward);
    }
  }, [errors]);

  function getLang(id) {
    // console.log(props.translations);
    let obj = translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    setTranslations(props.translations);
  }, [props.translations]);

  function copyShipToData(e) {
    setCopyShipClick(true);
    setErrors(validateFields(inputs, true, false, false));
    console.log(`I am true ${isShipCopying}`);
  }

  function copySoldToData(e) {
    setCopySoldClick(true);
    setErrors(validateFields(inputs, false, true, false));
    console.log(`I am true ${isSoldCopying}`);
  }

  function copyPayerData(e) {
    setCopyPayerClick(true);
    setErrors(validateFields(inputs, false, false, true));
    console.log(`I am true ${isPayerCopying}`);
  }

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && copyShipClick) {
      console.log(inputs.name, isDisableShipToFields, copyShipClick);
      setCopyShipClick(false);
      setIsShipCopying(true);
      Object.entries(formTemplate.othersGlnUpdate).forEach(([key, value]) => {
        if (['shipToName'].includes(key)) inputs[key] = value;
      });
    } else if (Object.keys(errors).length === 0 && copySoldClick) {
      console.log(inputs.name, isDisableShipToFields, copySoldClick);
      setCopySoldClick(false);
      setIsSoldCopying(true);
      Object.entries(formTemplate.othersGlnUpdate).forEach(([key, value]) => {
        if (['soldToName'].includes(key)) inputs[key] = value;
      });
    } else if (Object.keys(errors).length === 0 && copyPayerClick) {
      console.log(inputs.name, isDisableShipToFields, copyPayerClick);
      setCopyPayerClick(false);
      setIsPayerCopying(true);
      Object.entries(formTemplate.othersGlnUpdate).forEach(([key, value]) => {
        if (['payerName'].includes(key)) inputs[key] = value;
      });
    }
  }, [errors]);

  useEffect(() => {
    if (Object.keys(shipToDataRes.shipToData).length !== 0) {
      Object.entries(shipToDataRes.shipToData).forEach(([key, value]) => {
        if (key == 'name') inputs.shipToName = value;
        else if (key == 'glnIlnCode') inputs.glnShipTo = value;
        else if (key in inputs) {
          inputs[key] = value;
        }
      });
      sessionStorage.setItem('updateCustomerOthersGlnUpdate', JSON.stringify(inputs));
      setIsDisableShipToFields(false);
    }
    setIsShipCopying(false);
  }, [shipToDataRes.shipToData]);

  useEffect(() => {
    if (Object.keys(soldToDataRes.soldToData).length !== 0) {
      Object.entries(soldToDataRes.soldToData).forEach(([key, value]) => {
        if (key == 'name') inputs.soldToName = value;
        else if (key == 'glnIlnCode') inputs.glnSoldTo = value;
        else if (key in inputs) {
          if (!['existingShipToID', 'existingBillToID', 'existingPayerID'].includes(key))
            inputs[key] = value;
        }
      });
      sessionStorage.setItem('updateCustomerOthersGlnUpdate', JSON.stringify(inputs));
      setIsDisableSoldToFields(false);
    }
    setIsSoldCopying(false);
  }, [soldToDataRes.soldToData]);

  useEffect(() => {
    if (Object.keys(payerDataRes.payerData).length !== 0) {
      Object.entries(payerDataRes.payerData).forEach(([key, value]) => {
        if (key == 'name') inputs.payerName = value;
        else if (key == 'glnIlnCode') inputs.glnPayer = value;
        else if (key in inputs) {
          inputs[key] = value;
        }
      });
      sessionStorage.setItem('updateCustomerOthersGlnUpdate', JSON.stringify(inputs));
      setIsDisablePayerFields(false);
    }
    setIsPayerCopying(false);
  }, [payerDataRes.payerData]);

  return (
    <form className="card" onSubmit={handleSubmit}>
      <Modal show={isPayerCopying || isShipCopying || isSoldCopying} centered>
        <Modal.Body>
          <Row>
            <div className="col-auto">
              <Spinner animation="border" variant="warning" />
            </div>
            <div className="text-left">
              <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
      <div className="card-header">
        <div className="col-12">
          <h3 className="card-title text-left">
            <strong>Customer Data {getLang('Update')}</strong>
          </h3>
        </div>
      </div>
      <Row className="sidebar-row">
        <div className="col-12">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                <Row>
                  <Col sm={12} lg="auto">
                    <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                      {getLang('Others - GLN/EDI Update')}
                    </h2>
                  </Col>
                </Row>
                <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

                <Row>
                  <Container>
                    <Row>
                      <div className="col-12 col-lg-auto">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Country')}</label>
                          <input
                            type="text"
                            className="form-control"
                            name="country"
                            value={mainPageForm.country}
                            disabled
                          />
                          {errors.soldToName && (
                            <div className="invalid-feedback text-left">{errors.soldToName}</div>
                          )}
                        </div>
                      </div>
                    </Row>
                    <hr />
                    <Row>
                      <div className="col-12 col-lg-3">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Ship To Number')}
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className={`form-control${
                                errors.existingShipToID ? ' is-invalid' : ''
                              }`}
                              name="existingShipToID"
                              maxLength="8"
                              placeholder={`Enter ${getLang('Existing Ship-to ID')}`}
                              value={inputs.existingShipToID}
                              onChange={setInputs}
                              disabled={isDisable}
                            />
                            <span className="input-group-append">
                              <button
                                className="btn btn-primary"
                                type="button"
                                style={{
                                  borderTopRightRadius: '3px',
                                  borderBottomRightRadius: '3px',
                                }}
                                onClick={copyShipToData}
                                disabled={isDisable || inputs.existingShipToID == ''}
                              >
                                {getLang('Copy')}
                              </button>
                            </span>
                            {errors.existingShipToID && (
                              <div className="invalid-feedback text-left">
                                {errors.existingShipToID}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-auto">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Ship To Name')}</label>
                          <input
                            type="text"
                            className={`form-control ${errors.shipToName ? 'is-invalid' : ''}`}
                            name="shipToName"
                            placeholder={`${getLang('Name')}`}
                            value={inputs.shipToName}
                            onChange={setInputs}
                            disabled
                          />
                          {errors.shipToName && (
                            <div className="invalid-feedback text-left">{errors.shipToName}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-auto">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Ship To GLN')}</label>
                          <input
                            type="text"
                            className="form-control"
                            name="glnShipTo"
                            maxLength="13"
                            placeholder="Eg: 1234567890123"
                            value={inputs.glnShipTo}
                            onChange={setInputs}
                            disabled={inputs.shipToName == '' || isDisable}
                          />
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-12 col-lg-3">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Sold To Number')}
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className={`form-control${
                                errors.existingSoldToID ? ' is-invalid' : ''
                              }`}
                              maxLength="8"
                              name="existingSoldToID"
                              placeholder={`Enter ${getLang('Existing Sold To ID')}`}
                              value={inputs.existingSoldToID}
                              onChange={setInputs}
                              disabled={isDisable}
                            />
                            <span className="input-group-append">
                              <button
                                className="btn btn-primary"
                                type="button"
                                style={{
                                  borderTopRightRadius: '3px',
                                  borderBottomRightRadius: '3px',
                                }}
                                onClick={copySoldToData}
                                disabled={isDisable || inputs.existingSoldToID == ''}
                              >
                                {getLang('Copy')}
                              </button>
                            </span>
                            {errors.existingSoldToID && (
                              <div className="invalid-feedback text-left">
                                {errors.existingSoldToID}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-auto">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Sold To Name')}</label>
                          <input
                            type="text"
                            className={`form-control ${errors.soldToName ? 'is-invalid' : ''}`}
                            name="soldToName"
                            placeholder={`${getLang('Name')}`}
                            value={inputs.soldToName}
                            onChange={setInputs}
                            disabled
                          />
                          {errors.soldToName && (
                            <div className="invalid-feedback text-left">{errors.soldToName}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-auto">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Sold To GLN')}</label>
                          <input
                            type="text"
                            className="form-control"
                            name="glnSoldTo"
                            maxLength="13"
                            placeholder="Eg: 1234567890123"
                            value={inputs.glnSoldTo}
                            onChange={setInputs}
                            disabled={inputs.soldToName == '' || isDisable}
                          />
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-12 col-lg-3">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Payer Number')}</label>
                          <div className="input-group">
                            <input
                              type="text"
                              className={`form-control${
                                errors.existingPayerID ? ' is-invalid' : ''
                              }`}
                              name="existingPayerID"
                              maxLength="8"
                              placeholder={`Enter ${getLang('Existing Payer ID')}`}
                              value={inputs.existingPayerID}
                              onChange={setInputs}
                              disabled={isDisable}
                            />
                            <span className="input-group-append">
                              <button
                                className="btn btn-primary"
                                type="button"
                                style={{
                                  borderTopRightRadius: '3px',
                                  borderBottomRightRadius: '3px',
                                }}
                                onClick={copyPayerData}
                                disabled={isDisable || inputs.existingPayerID == ''}
                              >
                                {getLang('Copy')}
                              </button>
                            </span>
                            {errors.existingPayerID && (
                              <div className="invalid-feedback text-left">
                                {errors.existingPayerID}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-auto">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Payer Name')}</label>
                          <input
                            type="text"
                            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                            name="payerName"
                            placeholder={`${getLang('Name')}`}
                            value={inputs.payerName}
                            onChange={setInputs}
                            disabled
                          />
                          {errors.payerName && (
                            <div className="invalid-feedback text-left">{errors.payerName}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-auto">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Payer GLN')}</label>
                          <input
                            type="text"
                            className="form-control"
                            name="glnPayer"
                            maxLength="13"
                            placeholder="Eg: 1234567890123"
                            value={inputs.glnPayer}
                            onChange={setInputs}
                            disabled={inputs.payerName == '' || isDisable}
                          />
                        </div>
                      </div>
                    </Row>
                  </Container>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <br />

      <div className="card-footer">
        <div className="row">
          {!isDisable && (
            <div className="col-auto col-md-auto text-left">
              <Button variant="outline-warning" onClick={props.saveDraft}>
                Save Draft
              </Button>
            </div>
          )}
          <div className="col col-md text-left">
            <span className="align-middle">{props.draftMsg}</span>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="mainPage"
              data-back="true"
              className="btn btn-warning btn-block"
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Back
            </Link>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="othersGlnUpdateReview"
              className={`btn btn-danger btn-block${
                (inputs.existingShipToID != '' && inputs.shipToName != '') ||
                (inputs.existingSoldToID != '' && inputs.soldToName != '') ||
                (inputs.existingPayerID != '' && inputs.payerName != '')
                  ? ''
                  : ' disabled'
              }`}
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Review
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateCustOthersGlnUpdate;
