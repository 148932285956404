const prod = { url: {
  fetchOrderBlocksURL: 'https://mavenapis.ab-inbev.com/api/data/orderBlock',
  fetchDeliveryBlocksURL: 'https://mavenapis.ab-inbev.com/api/data/deliveryBlock',
  fetchBillingBlocksURL: 'https://mavenapis.ab-inbev.com/api/data/billingBlock',
},
};
const test = { url: {
  fetchOrderBlocksURL: 'https://mavenapistest.ab-inbev.com/api/data/orderBlock',
  fetchDeliveryBlocksURL: 'https://mavenapistest.ab-inbev.com/api/data/deliveryBlock',
  fetchBillingBlocksURL: 'https://mavenapistest.ab-inbev.com/api/data/billingBlock',
},
};
const local = { url: {
  fetchOrderBlocksURL: 'http://localhost:1234/api/data/orderBlock',
  fetchDeliveryBlocksURL: 'http://localhost:1234/api/data/deliveryBlock',
  fetchBillingBlocksURL: 'http://localhost:1234/api/data/billingBlock',
},
};
const configData  = process.env.REACT_APP_ENV === 'prod' ? prod.url : (process.env.REACT_APP_ENV === 'test' ? test.url : local.url);
export default configData;
