export const formTemplate = {
  mainpage: {
    country: '',
    salesOrg: '',
    companyCode: '',
    customerReqType: '',
    shipToType: '',
    m1: '',
    m2: '',
  },
  plantData: {
    plantCode: '',
    name: '',
    name2: '',
    postalCode: '',
    street: '',
    houseNumber: '',
    city: '',
    region: '',
    regionDesc: '',
    regionCode: '',
    regionText: '',
    price: '',
    priceDesc: '',
    priceCode: '',
    priceText: '',
    erpTranspZone: '',
    language: '',
    telephone: '',
    mobile: '',
    email: '',
    contactPerson: '',
    vatRegNumber: '',
    deliveringPlant: '',
    deliveringPlantDesc: '',
    shippingCondition: '',
    shippingConditionDesc: '',
    transportationZone: '',
    tradingPartner: '',
    paymentTerms: '',
    paymentTermsDesc: '',
    supportDocsBank: '',
    supportDocsBankName: '',
    supportDocsBank1: '',
    supportDocsBankName1: '',
    supportDocsBank2: '',
    supportDocsBankName2: '',
  },
};
