const prod = {
  url: {
  fetchCountriesURL: 'https://mavenapis.ab-inbev.com/api/data/countries',
  fetchM1M2URL: 'https://mavenapis.ab-inbev.com/api/data/m1m2',
  fetchCustReqTypesURL: 'https://mavenapis.ab-inbev.com/api/data/customerrequesttypes',
  fetchShipToTypesURL: 'https://mavenapis.ab-inbev.com/api/data/shiptotypes',
  },
};

const test = {
  url: {
  fetchCountriesURL: 'https://mavenapistest.ab-inbev.com/api/data/countries',
  fetchM1M2URL: 'https://mavenapistest.ab-inbev.com/api/data/m1m2',
  fetchCustReqTypesURL: 'https://mavenapistest.ab-inbev.com/api/data/customerrequesttypes',
  fetchShipToTypesURL: 'https://mavenapistest.ab-inbev.com/api/data/shiptotypes',
  },
};

const local = {
  url : {
  fetchCountriesURL: 'http://localhost:1234/api/data/countries',
  fetchM1M2URL: 'http://localhost:1234/api/data/m1m2',
  fetchCustReqTypesURL: 'http://localhost:1234/api/data/customerrequesttypes',
  fetchShipToTypesURL: 'http://localhost:1234/api/data/shiptotypes',
  },
};

const configData  = process.env.REACT_APP_ENV === 'prod' ? prod.url : (process.env.REACT_APP_ENV === 'test' ? test.url : local.url);



export default configData;
