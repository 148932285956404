import {
  shipToIDRegex,
  postalCodeRegex,
  telephoneRegex,
  emailRegex,
  profitCenterRegex,
  glnIlnCodeRegex,
} from '../../../utils/Utils';

export function validateFields(values, forceValidate = false) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country;

  const customerReqType = JSON.parse(sessionStorage.getItem('createCustomerMainPage'))
    .customerReqType;

  const shipToType = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).shipToType;

  

    if (values.name === '') {
      errors.name = 'Legal name is required';
    } else if (values.postalCode === '') {
      errors.postalCode = 'Postal code is required';
    } else if (values.postalCode !== '' && !postalCodeRegex[country].test(values.postalCode)) {
      errors.postalCode = 'Postal code is incorrect';
    } else if (values.street === '') {
      errors.street = 'Street is required';
    } else if (
      values.houseNumber == '' &&
      !(country == 'United Kingdom')
    ) {
      errors.houseNumber = 'House number is required';
    } else if (values.city === '') {
      errors.city = 'City is required';
    } else if (country != 'Luxembourg' && values.region == '') {
      errors.region = 'Region is required';
    } else if (
      ([
        'Austria',
        'Denmark',
        'Finland',
        'Italy',
        'Luxembourg',
        'Norway',
        'Poland',
        'Sweden',
        'Switzerland',
        'United Kingdom',
        'Belgium',
      ].includes(country) ||
        (country === 'Netherlands')) &&
      values.telephoneOption === ''
    ) {
      errors.telephoneOption = 'Telephone is required';
    } else if (
      ([
        'Austria',
        'Denmark',
        'Finland',
        'Italy',
        'Luxembourg',
        'Norway',
        'Poland',
        'Sweden',
        'Switzerland',
        'United Kingdom',
        'Belgium',
      ].includes(country) ||
        (country === 'Netherlands')) &&
      values.telephoneOption === 'General/Accounting' &&
      values.generalTelephone === ''
    ) {
      errors.telephone = 'Accounting Telephone number is required';
    } else if (
      values.telephoneOption === 'General/Accounting' &&
      values.generalTelephone !== '' &&
      !telephoneRegex.test(values.generalTelephone)
    ) {
      console.log(values.generalTelephone);
      errors.telephone = 'Accounting Telephone number is incorrect';
    } else if (
      // ([
      //   'Austria',
      //   'Denmark',
      //   'Finland',
      //   'Norway',
      //   'Poland',
      //   'Sweden',
      //   'Switzerland',
      //   'United Kingdom',
      //   'Belgium',
      // ].includes(country) ||
      //   (country === 'Netherlands' ) &&
      values.telephoneOption === 'Logistics' &&
      values.logTelephone === ''
    ) {
      errors.telephone = 'Logistics Telephone number is required';
    } else if (
      values.telephoneOption === 'Logistics' &&
      values.logTelephone !== '' &&
      !telephoneRegex.test(values.logTelephone)
    ) {
      errors.telephone = 'Logistics Telephone number is incorrect';
    } else if (
      // ([
      //   'Austria',
      //   'Denmark',
      //   'Finland',
      //   'Norway',
      //   'Poland',
      //   'Sweden',
      //   'Switzerland',
      //   'United Kingdom',
      //   'Belgium',
      // ].includes(country) ||
      //   (country === 'Netherlands' )) &&
      values.telephoneOption === 'Invoicing' &&
      values.invTelephone === ''
    ) {
      errors.telephone = 'Invoicing Telephone number is required';
    } else if (
      values.telephoneOption === 'Invoicing' &&
      values.invTelephone !== '' &&
      !telephoneRegex.test(values.invTelephone)
    ) {
      errors.telephone = 'Invoicing Telephone number is incorrect';
    } else if (
      ([
        'Austria',
        'Denmark',
        'Finland',
        'Norway',
        'Poland',
        'Sweden',
        'Switzerland',
        'United Kingdom',
        'Belgium',
      ].includes(country) ||
        (country === 'Netherlands' )) &&
      values.telephoneOption === 'Ordering' &&
      values.orderTelephone === ''
    ) {
      errors.telephone = 'Ordering Telephone number is required';
    } else if (
      values.telephoneOption === 'Ordering' &&
      values.orderTelephone !== '' &&
      !telephoneRegex.test(values.orderTelephone)
    ) {
      errors.telephone = 'Ordering Telephone number is incorrect';
    } else if (country === 'France' && values.mobile === '') {
      errors.mobile = 'Mobile is required';
    } else if (values.mobile !== '' && !telephoneRegex.test(values.mobile)) {
      errors.mobile = 'Mobile number is incorrect';
    } else if (values.segment === '' && shipToType != 'Miscellaneous') {
      errors.segment = 'Segment is required';
    } else if (values.subSegment === '' && shipToType != 'Miscellaneous') {
      errors.subSegment = 'Sub Segment is required';
 
    } else if (
      values.subChannel === ''
    ) {
      errors.subChannel = 'Sub Channel is required';
    } else if (
      (country == 'Italy' ||
        (country == 'Belgium' &&
          [
            'On-Trade – Direct',
            'National On-Trade Direct',
            'Off-Trade – Direct',
            'Off-Trade - Indirect',
          ].includes(shipToType))) &&
      values.deliveringPlant == ''
    ) {
      errors.deliveringPlant = 'Delivering Plant is required';
    } else if (country == 'Italy' && values.truckType == '') {
      errors.truckType = 'Truck Type is required';
    } else if (country == 'Italy' && values.trailerType == '') {
      errors.trailerType = 'Trailer Type is required';
    }
    return errors;
  }

  // return errors;


