const prod = { url : { 
  fetchDeliveryPlantsURL: 'https://mavenapis.ab-inbev.com/api/data/deliveryplants',
  fetchShippingConditionsURL: 'https://mavenapis.ab-inbev.com/api/data/shippingconditions',
  fetchPOCSuppliersURL: 'https://mavenapis.ab-inbev.com/api/data/pocsuppliers',
},
};
const test = { url : { 
  fetchDeliveryPlantsURL: 'https://mavenapistest.ab-inbev.com/api/data/deliveryplants',
  fetchShippingConditionsURL: 'https://mavenapistest.ab-inbev.com/api/data/shippingconditions',
  fetchPOCSuppliersURL: 'https://mavenapistest.ab-inbev.com/api/data/pocsuppliers',
},
};
const local = { url : { 
  fetchDeliveryPlantsURL: 'http://localhost:1234/api/data/deliveryplants',
  fetchShippingConditionsURL: 'http://localhost:1234/api/data/shippingconditions',
  fetchPOCSuppliersURL: 'http://localhost:1234/api/data/pocsuppliers',
},
};
const configData  = process.env.REACT_APP_ENV === 'prod' ? prod.url : (process.env.REACT_APP_ENV === 'test' ? test.url : local.url);
export default configData;
