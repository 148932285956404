import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Button, Row } from 'react-bootstrap';
import Select, { components } from 'react-select';
import MandatoryText from '../../../components/MandatoryText';

import {
  FetchCountries,
  FetchRegions,
  FetchTransportationZones,
  FetchShipToBapi,
} from './FlowCallAPI';
import { languages, telephoneOptions } from '../../../utils/Utils';
import { formTemplate } from '../Data';
import { useFormFields } from './HooksLib';
import { validateFields } from './FormValidation';

import {
  mobileLength,
  mobilePlaceholder,
  telephoneLength,
  telephonePlaceholder,
  postalCodeLength,
  shipToIDRegex,
} from '../../../utils/Utils';
import Sidebar from '../../../components/layout/Sidebar/Sidebar';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import CopyModal from '../../../components/core/CopyModal/CopyModal';
import PageFooter from '../../../components/layout/PageFooter/PageFooter';
import CustomTextInput from '../../../components/core/InputFields/CustomTextInput/CustomTextInput';
import CustomSelect from '../../../components/core/InputFields/CustomSelect/CustomSelect';
import RegionSelect from '../../../components/core/InputFields/RegionSelect/RegionSelect';
import TelOptionsInput from '../../../components/core/InputFields/TelOptionsInput';

const CreateCustProfileData = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) navigate('/');
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);
  const [linkForward, setLinkForward] = useState('');
  const [errors, setErrors] = useState({});
  const [copyClick, setCopyClick] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [isDisableFields, setIsDisableFields] = useState(false);
  const [inputs, setInputs] = useFormFields(props.formTemplate);
  const mainPageData = JSON.parse(sessionStorage.getItem('createHierarchyMainPage'));
  const country = JSON.parse(sessionStorage.getItem('createHierarchyMainPage')).country || '';
  const companyCode =
    JSON.parse(sessionStorage.getItem('createHierarchyMainPage')).companyCode || '';
  const salesOrg = JSON.parse(sessionStorage.getItem('createHierarchyMainPage')).salesOrg || '';
  const regionsRes = FetchRegions(country, isDisableFields);
  const transportationZonesRes = FetchTransportationZones(country, isDisableFields);
  const shipToDataRes = FetchShipToBapi(inputs.existingID, companyCode, salesOrg, isCopying);

  // useEffect(() => {
  //   // if (props.draftTicketNum[0] !== 'D' &&
  // props.status !== 'Duplicate' &&
  // props.status !== 'Rejected' && props.status !== 'Duplicate') {
  //   //   setIsDisable(true);
  //   // }

  //   switch (country) {
  //     case 'Netherlands':
  //       if (inputs.language == '') inputs.language = 'Dutch';
  //       break;

  //     case 'United Kingdom':
  //       if (inputs.language == '') inputs.language = 'English';
  //       break;

  //     case 'France':
  //       if (inputs.language == '') inputs.language = 'French';
  //       break;

  //     case 'Belgium':
  //       if (inputs.language == '') inputs.language = 'Dutch';
  //       break;

  //     case 'Germany':
  //       if (inputs.language == '') inputs.language = 'German';
  //       break;

  //     case 'Austria':
  //       if (inputs.language == '') inputs.language = 'German';
  //       break;

  //     case 'Denmark':
  //       if (inputs.language == '') inputs.language = 'Danish';
  //       break;

  //     case 'Finland':
  //       if (inputs.language == '') inputs.language = 'Finnish';
  //       break;

  //     case 'Italy':
  //       if (inputs.language == '') inputs.language = 'Italian';
  //       break;

  //     case 'Luxembourg':
  //       if (inputs.language == '') inputs.language = 'English';
  //       break;

  //     case 'Norway':
  //       if (inputs.language == '') inputs.language = 'Danish';
  //       break;

  //     case 'Poland':
  //       if (inputs.language == '') inputs.language = 'Polish';
  //       break;

  //     case 'Sweden':
  //       if (inputs.language == '') inputs.language = 'Swedish';
  //       break;

  //     case 'Switzerland':
  //       if (inputs.language == '') inputs.language = 'French';
  //       break;

  //     default:
  //       break;
  //   }
  // }, []);

  function getLang(id) {
    // console.log(props.translations);
    let obj = props.translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    // props.setActivePage(e.target.getAttribute('data-link'));
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      // if (!isDisableFields) {
      console.log('data-link');
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(validateFields(inputs));
      // }
      setIsSubmitting(true);
      if (isDisable) props.setActivePage(e.target.getAttribute('data-link'));
    }
    // if (e) e.preventDefault();
    // props.setActivePage(e.target.getAttribute('data-link'));
  };

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`, errors.language);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward', linkForward);
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && isDisableFields) {
      setIsCopying(true);
      console.log(inputs.existingID);
      console.log('Yo');
      Object.entries(formTemplate.profileData).forEach(([key, value]) => {
        if (!['language', 'contactPerson'].includes(key)) inputs[key] = value;
      });
    } else {
      setIsSubmitting(false);
      setIsCopying(false);
    }
  }, [errors]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };
  function copyShipToData(e) {
    // setIsCopying(true);

    if (!isDisable && shipToIDRegex.test(inputs.existingID)) {
      setCopyClick(true);
      setIsCopying(true);
      console.log(`I am true ${isCopying}`);
      console.log(isCopying, inputs.existingID, companyCode, salesOrg);
    }
  }

  useEffect(() => {
    if (Object.keys(shipToDataRes.shipToData).length !== 0 && isCopying) {
      Object.entries(shipToDataRes.shipToData).forEach(([key, value]) => {
        if (key === 'telephone' && value !== '') {
          inputs.telephone = value;
          console.log('Done');
        }
        if (key in inputs) {
          inputs[key] = value;
        }
      });
      sessionStorage.setItem('createCustomerProfileData', JSON.stringify(inputs));
    }
    setIsCopying(false);
  }, [shipToDataRes.shipToData]);

  const Input = (props) => <components.Input {...props} maxLength={8} />;

  const regionOptions = regionsRes.regions.map((val) => {
    return {
      ...val,
      regionCode: val.regionCode,
      regionText: val.regionText,
      erpTranspZone: val.erpTranspZone,
      regionDesc: `${val.regionCode} - ${val.regionText}`,
    };
  });

  const sidebarItems = [
    {
      title: getLang('Main Page'),
      ['data-link']: 'mainpage',
      ['data-back']: 'true',
      active: false,
      onClick: handleSubmit,
    },
    {
      title: getLang('Profile Data'),
      active: true,
    },
  ];
  const footerItems = [
    {
      title: 'Review',
      onClick: handleSubmit,
      ['data-link']: 'review',
      ['data-back']: '',
      type: 'review',
    },
  ];

  // useEffect(() => {
  //   sessionStorage.setItem('createCustomerProfileData', JSON.stringify(inputs));
  // }, [inputs]);
  // console.log('fIELD VALIDATION', validateFields(inputs));

  return (
    isAuthenticated && (
      <form className="card" onSubmit={handleSubmit}>
        <CopyModal isCopying={isCopying} />
        {/* <PageHeader title={getLang('Create Hierarchy')} isSidebarOpen={isSidebarOpen} togglingSidebar={togglingSidebar} /> */}
        <Row className="sidebar-row">
          {/* <Sidebar sidebarItems={sidebarItems}/> */}
          <div className="col-12 col-lg-12">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-lg-4">
                  {/* {country !== '' && (
                    <div className="form-group">
                      <label className="form-label text-left">
                        {getLang('Reference Customer')}
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control ${errors.existingID ? 'is-invalid' : ''}`}
                          name="existingID"
                          maxLength="8"
                          placeholder="Enter Reference Customer ID"
                          onChange={setInputs}
                          value={inputs.existingID}
                          disabled={isDisable}
                        />
                        <span className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="button"
                            style={{
                              borderTopRightRadius: '3px',
                              borderBottomRightRadius: '3px',
                            }}
                            onClick={copyShipToData}
                            disabled={isDisable || inputs.existingID === ''}
                          >
                            {getLang('Copy')}
                          </button>
                        </span>
                        {errors.existingID && (
                          <div className="invalid-feedback text-left">
                            {errors.existingID}
                          </div>
                        )}
                      </div>
                    </div>
                  )} */}
                </div>
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12 col-lg-4 text-left">
                      <h2
                        style={{
                          color: 'rgb(177, 31, 36)',
                        }}
                      >
                        {getLang('General Data')}
                      </h2>
                    </div>
                  </div>
                  <MandatoryText
                    text={getLang(`Fields marked with an asterisk (*) are required`)}
                  />
                  <div className="row">
                    <CustomTextInput
                      placeholder={
                        ['Belgium', 'Germany'].includes(country)
                          ? getLang('POC Name')
                          : `Legal ${getLang('Name')}`
                      }
                      errors={errors}
                      title={`${getLang('Name')} *`}
                      name={'name'}
                      setInputs={setInputs}
                      inputs={inputs}
                      isDisableFields={isDisableFields}
                      isDisable={isDisable}
                      maxLength="35"
                      getLang={getLang}
                    />
                    <CustomTextInput
                      placeholder={''}
                      errors={errors}
                      title={`${getLang('Name 2')}`}
                      name={'name2'}
                      setInputs={setInputs}
                      inputs={inputs}
                      isDisableFields={isDisableFields}
                      isDisable={isDisable}
                      maxLength="35"
                      getLang={getLang}
                    />
                    <CustomTextInput
                      placeholder={''}
                      errors={errors}
                      title={`${getLang('Postal Code')} *`}
                      name={'postalCode'}
                      setInputs={setInputs}
                      inputs={inputs}
                      isDisableFields={isDisableFields}
                      isDisable={isDisable}
                      maxLength={postalCodeLength(country)}
                      getLang={getLang}
                    />
                    <CustomTextInput
                      placeholder={''}
                      errors={errors}
                      title={`${getLang('Street')}*`}
                      name={'street'}
                      setInputs={setInputs}
                      inputs={inputs}
                      isDisableFields={isDisableFields}
                      isDisable={isDisable}
                      maxLength={'35'}
                      getLang={getLang}
                    />
                    <CustomTextInput
                      placeholder={''}
                      errors={errors}
                      title={`${getLang('House Number')}`}
                      name={'houseNumber'}
                      setInputs={setInputs}
                      inputs={inputs}
                      isDisableFields={isDisableFields}
                      isDisable={isDisable}
                      maxLength="10"
                      getLang={getLang}
                    />
                    <CustomTextInput
                      placeholder={''}
                      errors={errors}
                      title={`${getLang('City')} *`}
                      name={'city'}
                      setInputs={setInputs}
                      inputs={inputs}
                      isDisableFields={isDisableFields}
                      isDisable={isDisable}
                      maxLength={'35'}
                      getLang={getLang}
                    />
                    {country != 'Luxembourg' && (
                      <RegionSelect
                        errors={errors}
                        inputs={inputs}
                        setInputs={setInputs}
                        getLang={getLang}
                        regionOptions={regionOptions}
                        isDisable={isDisable}
                        isDisableFields={isDisableFields}
                      />
                    )}
                    {/* {[
                      'Austria',
                      'Denmark',
                      'Finland',
                      'Norway',
                      'Poland',
                      'Sweden',
                      'Switzerland',
                      'France',
                      'Luxembourg',
                      'UK',
                      'Italy',
                    ].includes(country) && (
                      <CustomSelect
                        errors={errors}
                        name="transportationZone"
                        inputs={inputs}
                        id={'transportation-zone'}
                        setInputs={setInputs}
                        isDisable={isDisable}
                        title={`${getLang('Transportation Zone')} *`}
                        data={transportationZonesRes.transportationZones}
                        OptionComp={(val) => (
                          <option key={val.val.code} value={val.val.code}>
                            {val.val.code} - {val.val.transportationZone}
                          </option>
                        )}
                      />
                    )} */}
                    {/* <CustomSelect
                      errors={errors}
                      name="language"
                      inputs={inputs}
                      id={'select-lang'}
                      setInputs={setInputs}
                      isDisable={isDisable}
                      title={`${getLang('Language')} *`}
                      data={languages}
                      OptionComp={(val) => (
                        <option key={val.val} value={val.val}>
                          {val.val}
                        </option>
                      )}
                    /> */}
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Language *')}</label>
                        <select
                          id="select-lang"
                          className={`form-control ${errors.language ? 'is-invalid' : ''}`}
                          name="language"
                          value={inputs.language}
                          onChange={setInputs}
                        >
                          <option value="">Select</option>
                          {languages.map((lang) => (
                            <option key={lang} value={lang}>
                              {lang}
                            </option>
                          ))}
                        </select>
                        {errors.language && (
                          <div className="invalid-feedback text-left">{errors.language}</div>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{`${getLang(
                          'Telephone Number',
                        )}`}</label>
                        <input
                          type="tel"
                          placeholder={telephonePlaceholder(country)}
                          className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}
                          name="telephone"
                          maxLength={telephoneLength(country)}
                          // maxLength={['Belgium', 'Germany'].includes(country) ? 12 : country == 'Netherlands' ? 9 : 11}
                          value={inputs.telephone}
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.telephone && (
                          <div className="invalid-feedback text-left">{errors.telephone}</div>
                        )}
                      </div>
                    </div> */}
                    {/* <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">{`${getLang('Mobile')}${
                          ['France', 'Italy'].includes(country) ? ' *' : ''
                        }`}</label>
                        <input
                          type="tel"
                          placeholder={mobilePlaceholder(country)}
                          className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                          name="mobile"
                          maxLength={mobileLength(country)}
                          // maxLength={['Belgium', 'Germany'].includes(country) ? 12 : country == 'Netherlands' ? 9 : 11}
                          value={inputs.mobile}
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.mobile && (
                          <div className="invalid-feedback text-left">{errors.mobile}</div>
                        )}
                      </div>
                    </div> */}
                    {/* <CustomTextInput
                      placeholder={'Eg.: abc@company.com'}
                      errors={errors}
                      title={`${getLang('E-mail Address')}`}
                      name={'email'}
                      setInputs={setInputs}
                      inputs={inputs}
                      isDisableFields={isDisableFields}
                      isDisable={isDisable}
                      maxLength={'240'}
                    /> */}
                    {/* <CustomTextInput
                      placeholder={''}
                      errors={errors}
                      title={`${getLang('Contact Person')}`}
                      name={'contactPerson'}
                      setInputs={setInputs}
                      inputs={inputs}
                      isDisableFields={isDisableFields}
                      isDisable={isDisable}
                      maxLength={'35'}
                    /> */}
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </Row>

        <PageFooter
          validateFields={validateFields}
          isDisable={isDisable}
          saveDraft={props.saveDraft}
          draftMsg={props.draftMsg}
          handleSubmit={handleSubmit}
          inputs={inputs}
          footerItems={footerItems}
        />
      </form>
    )
  );
};

// inputs.name == '' ? null :

export default CreateCustProfileData;
