import { useState } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  return [
    fields,
    function (event, action) {
      if (action?.name == 'soldToPayer2') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            soldToPayer2: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            soldToPayer2: event.payer,
          });
        }
      } else if (action?.name == 'pricingHierarchy') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            pricingHierarchy: '',
            pricingHierarchyDesc: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            pricingHierarchy: event.pricingHierarchy,
            pricingHierarchyDesc: event.hierarchyDesc,
          });
        }
      } else if (action?.name == 'region') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            region: '',
            regionDesc: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            region: event.regionCode,
            regionDesc: event.regionDesc,
          });
        }
      } else if (
        [
          'ediOption',
          'dropSize',
          'promoNews',
          'deliveryDay',
          'verwerkingstoeslag',
          'att7',
          'ediType',
          'soldCountry',
          'language',
          'ldeOption',
          'ldeType',
          'ldeType',
        ].includes(event.target.name)
      ) {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
        });
      } else if (event.target.name == 'pricingHierarchyOpt') {
        if (event.target.value != 'Yes') {
          setValues({
            ...fields,
            [event.target.name]: event.target.value,
            pricingHierarchy: '',
          });
        } else {
          setValues({
            ...fields,
            [event.target.name]: event.target.value,
          });
        }
      } else if (event.target.name == 'hierarchyTypeANode') {
        if (event.target.value == 'New') {
          setValues({
            ...fields,
            [event.target.name]: event.target.value,
            aNode: event.target.value,
          });
        } else {
          setValues({
            ...fields,
            [event.target.name]: event.target.value,
            aNode: '',
          });
        }
      } else if (event.target.name == 'hierarchyNumber') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          aNode: event.target.value,
        });
      } else if (event.target.name == 'name') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          merger: '',
          buyingGroup: '',
          name1: '',
        });
      } else if (event.target.name == 'buyingGroup') {
        setValues({
          ...fields,
          name1: '',
          [event.target.name]: event.target.value,
        });
      } else if (event.target.name == 'merger') {
        setValues({
          ...fields,
          name1: '',
          [event.target.name]: event.target.value,
        });
      } else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
    },
  ];
}
