import {
  postalCodeRegex,
  telephoneRegex,
  companyNumRegex,
  emailRegex,
  payerIDRegex,
  shipToIDRegex
} from '../../../../utils/Utils';

export function validateFields(values,copyClick) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')).country;

  if (copyClick) {
    if (values.existingShipToID == '') {
      errors.existingShipToID = 'Ship-To ID is required';
    } else if (values.existingShipToID!= '' && !shipToIDRegex.test(values.existingShipToID)) {
      errors.existingShipToID = 'Ship-To ID is incorrect';
    }
  }else{
    if(values.name != ''){
      if(values.segment == ''){
        errors.segment = 'Segment is required';
      }else if(values.subSegment == ''){
        errors.subSegment = 'Sub-Segment is required'
      }
    }
  }
  

  return errors;
}

// else if (values.mobile === '' && ['Netherlands', 'United Kingdom', 'France'].includes(country)) {
//   errors.mobile = 'Mobile is required';
// else if (values.email === '') {
//   errors.email = 'Email is required';
// }
