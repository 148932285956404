import React, { useState, useEffect } from 'react';

import ApprovalsDashboard from '../newapproval/approval_dashboard';
import MyGrid from '../newapproval/Approvals_MainPage';
const Approvals = (props) => {
  const [isDone, setIsDone] = useState(false);
  const [activePage, setActivePage] = useState('mainpage');
  console.log('approvals', props.data);
  useEffect(() => {
    setIsDone(true);
  }, []);

  return (
    <>
      {isDone && activePage == 'mainpage' && (
        <>
          {/* <ApprovalsMainPage
            setActivePage={setActivePage}
            review={props.review}
            setCrData={props.setCrData}
            data={props.data}
            // filterData={props.data}
            setData={(val) => props.setData(val)}
            RequestCreationType={props.RequestCreationType}
          /> */}
          {/* <ApprovalsDashboard />
          <br /> */}
          <MyGrid RequestCreationType={props.RequestCreationType} />
        </>
      )}
    </>
  );
};

export default Approvals;
