import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Button, ButtonGroup, Row, Col } from 'react-bootstrap';
import Select, { components } from 'react-select';
import MandatoryText from '../../../components/MandatoryText';
import CreatePlantData from '../plantdata/plantData';
import { formTemplate } from '../Data';
import { mainPageFormTemplate } from './Data';
import { validateFields } from './FormValidation';
import { FetchCountries, FetchM1M2, FetchCustomerReqTypes, FetchShipToTypes } from './FlowCallAPI';
import { fetchUserRoleAccessCountry } from '../../createcustomer/mainpage/FlowCallAPI';
import { SaveDraft } from '../FlowCallAPI';
import { useFormFields } from './HooksLib';

const CreateCustMainPage = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  const { instance, accounts } = useMsal();

  const [translations, setTranslations] = useState(props.translations);

  // let translations = {
  //   'Creation': 'Creation',
  //   'Country': 'Country',
  //   ''
  // }

  useEffect(() => {
    // if (!isAuthenticated) navigate('/');
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    console.log(inputs);
    if (
      props.draftTicketNum[0] !== 'D' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected'
    ) {
      setIsDisable(true);
    }
  }, []);

  const countriesRes = fetchUserRoleAccessCountry(
    accounts[0].username != '' ? accounts[0].username : '',
  );

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkForward, setLinkForward] = useState('');

  const [isSave, setIsSave] = useState(false);
  const [draftMsg, setDraftMsg] = useState('');
  const plantDataForm = props.data.plantData
    ? JSON.parse(sessionStorage.getItem('createCustomerPlantData')) || props.data.plantData
    : JSON.parse(sessionStorage.getItem('createCustomerPlantData')) || formTemplate.plantData;

  // const saveDraft = (e) => {
  //   console.log(props.draftTicketNum);
  //   if (inputs.country) {
  //     setIsSave(true);
  //   }
  // };

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    setErrors(validateFields(inputs));
    setIsSubmitting(true);
    setLinkForward(e.target.getAttribute('data-link'));
  };

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      // navigate(linkForward);
      console.log(linkForward);
      props.setActivePage(linkForward);
    }
  }, [errors]);

  const [inputs, setInputs] = useFormFields(props.formTemplate);

  // const fetchM1M2Res = FetchM1M2(inputs.country);

  // const saveDraftRes = SaveDraft(accounts.length > 0 ? accounts[0].username : '', props.draftTicketNum, inputs, isSave);

  // useEffect(() => {
  //   if (saveDraftRes.result !== '') {
  //     setIsSave(false);
  //     let timeSave = new Date();

  //     if (saveDraftRes.result.result === 'Created') {
  //       setDraftMsg(`Draft created at ${timeSave.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`)}
  //     else if (saveDraftRes.result.result === 'Updated') setDraftMsg(`Draft updated at ${timeSave.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`)
  //   }
  // }, [saveDraftRes.result])

  const saveDraft = (e) => {
    console.log(props.draftTicketNum);
    if (inputs.country) {
      setIsSave(true);
    }
  };

  const saveDraftRes = SaveDraft(
    props.accounts.length > 0 ? props.accounts[0].username : '',
    props.draftTicketNum,
    isSave,
    'Plant',
  );

  useEffect(() => {
    // if (saveDraftRes.result !== '') {
    //   setIsSave(false);
    //   let timeSave = new Date();

    //   if (saveDraftRes.result.result === 'Created') {
    //     setDraftMsg(`Draft created at ${timeSave.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`);
    //   } else if (saveDraftRes.result.result === 'Updated') setDraftMsg(`Draft updated at ${timeSave.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`);
    // }
    setIsSave(false);
    if ('result' in saveDraftRes.result) setDraftMsg(saveDraftRes.result.result);
    else if ('error' in saveDraftRes.result) setDraftMsg(saveDraftRes.result.error);
  }, [saveDraftRes.result]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  useEffect(() => {
    if (inputs.country == 'France' && inputs.shipToType == '')
      inputs.shipToType = 'On-Trade – Indirect';
  }, [inputs]);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  function countryChange(event) {
    // Call this function onChange of country, then setInput(e) here.
    inputs.companyCode = event.target.selectedOptions[0].getAttribute('data-companycode');
    inputs.salesOrg = event.target.selectedOptions[0].getAttribute('data-salesorg');
    setInputs(event);
  }

  function getLang(id) {
    // console.log(props.translations);
    let obj = translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    setTranslations(props.translations);
  }, [props.translations]);

  // const m1Options = fetchM1M2Res.m1Vals.map((val) => {
  //   return {
  //     ...val,
  //     m1: val.m1,
  //     m2: val.m2,
  //   };
  // });

  // ${Object.keys(validateFields(inputs)).length != 0 ? 'disabled' : '' }

  return (
    <form className="card" onSubmit={handleSubmit}>
      <Row className="sidebar-row">
        {/* <div
          className={`col-12 col-lg-2 text-left sidebar ${
            isMobile ? (isSidebarOpen ? '' : 'collapse') : ''
          }`}
        >
          <Row>
            <ButtonGroup vertical className="btn-block">
              <Button className="btn-danger">{getLang('Main Page')}</Button>
              <Link to="" data-link="plant" className="btn btn-warning" onClick={handleSubmit}>
                {getLang('Plant Data')}
              </Link>
            </ButtonGroup>
          </Row> className="col-12 col-lg-10"
        </div> */}
        <div>
          <div className="card-body">
            <Row>
              <Col sm={12} lg={4}>
                <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Plant Creation')}</h2>
              </Col>
            </Row>

            {/* <div className="row">
              <div className="col-12 col-lg-4 text-left">
                <h2
                  style={{
                    color: 'rgb(177, 31, 36)',
                  }}
                >
                  {getLang('Profile Data')}
                </h2>
              </div>
            </div> */}
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="form-group">
                  <label className="form-label text-left">{getLang('Country')} *</label>
                  <select
                    id="select-country"
                    className={`form-control ${errors.country ? 'is-invalid-select' : ''}`}
                    name="country"
                    onChange={setInputs}
                    value={inputs.country}
                    required
                    disabled={isDisable}
                  >
                    <option value="">Select</option>
                    {/* {process.env.REACT_APP_ENV == 'prod' &&
                      countriesRes.countries
                        .filter((e) =>
                          [
                            'Belgium',
                            'France',
                            'Germany',
                            'Netherlands',
                            'United Kingdom',
                          ].includes(e.country),
                        )
                        .map((val) => (
                          <option
                            key={val.countryCode}
                            value={val.country}
                            data-companycode={val.companyCode}
                            data-salesorg={val.salesOrg}
                          >
                            {val.country}
                          </option>
                        ))} */}
                    {/* {process.env.REACT_APP_ENV != 'prod' && */}
                    {countriesRes.countries.map((val) => (
                      <option
                        key={val.countryCode}
                        value={val.country}
                        data-companycode={val.companyCode}
                        data-salesorg={val.salesOrg}
                      >
                        {val.country}
                      </option>
                    ))}
                  </select>
                  {errors.country && (
                    <div className="invalid-feedback text-left">{errors.country}</div>
                  )}
                </div>
              </div>
            </div>
            {inputs.country !== '' && (
              <CreatePlantData
                {...props}
                formTemplate={plantDataForm}
                saveDraft={saveDraft}
                draftMsg={draftMsg}
                translations={props.translations}
              />
            )}
          </div>
        </div>
      </Row>

      {/* <div className="card-footer">
        <div className="row">
          {!isDisable && (
            <div className="col-auto col-md-auto text-left">
              <Button
                variant="outline-warning"
                onClick={props.saveDraft}
                // disabled={process.env.REACT_APP == 'prod'}
              >
                Save Draft
              </Button>
            </div>
          )}
          <div className="col col-md text-left">
            <span className="align-middle">{props.draftMsg}</span>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="plant"
              className={`btn btn-${
                Object.keys(validateFields(inputs)).length == 0 || isDisable
                  ? 'danger'
                  : 'secondary'
              } btn-block`}
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Next
            </Link>
          </div>
        </div>
      </div> */}
    </form>
  );
};

export default CreateCustMainPage;
