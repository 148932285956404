import { ibanRegex } from '../../../../utils/Utils';

export function validateFields(values, copyClick, payerSap, ibanSap) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')).country;

  const soldToIDRegex = /^(1)\d{7}$/;

  if (copyClick) {
    if (values.existingSoldToID == '') {
      errors.existingSoldToID = 'Sold To ID is required';
    } else if (values.existingSoldToID != '' && !soldToIDRegex.test(values.existingSoldToID)) {
      errors.existingSoldToID = 'Payer ID is incorrect';
    }
  } else {
    if (values.priceLists == '') {
      errors.priceLists = 'Price List is required';
    }
  }

  // if (values.existingPayerID == '') {
  //   errors.existingPayerID = 'Payer ID is required';
  // } else if (values.existingPayerID != '' && !payerIDRegex.test(values.existingPayerID)) {
  //   errors.existingPayerID = 'Payer ID is incorrect';
  // } else if (values.name != '') {
  //   if (values.existingPayerID != payerSap) {
  //     errors.existingPayerID = 'Payer ID does not match';
  //   } else if (values.iban == '') {
  //     errors.iban = 'IBAN is required';
  //   } else if (values.iban != ibanSap && values.supportDocsBank == '') {
  //     errors.supportDocsBank = 'Support document is required';
  //   }
  // }

  return errors;
}
