import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Button, ButtonGroup, Row, Col, Modal, Spinner } from 'react-bootstrap';
import MandatoryText from '../../../components/MandatoryText';
import FeatherIcon from 'feather-icons-react';
import Select, { components } from 'react-select';

import { validateFields } from './utils/FormValidation';
import { FetchSoldToBapi, FetchPriceLists, FetchPriceList } from '../utils/FlowCallAPI';
import { useFormFields } from './utils/HooksLib';
import { formTemplate } from '../Data';
import { ibanLength, ibanPlaceholder } from '../../../utils/Utils';

const UpdatePriceListUpdate = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  const [translations, setTranslations] = useState(props.translations);

  const fileRegex = /\.[^.\\/:*?"<>|\r\n]+$/;

  useEffect(() => {
    // if (!isAuthenticated) navigate('/');
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    console.log(inputs);
    if (
      props.draftTicketNum[0] !== 'M' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected'
    ) {
      setIsDisable(true);
    }
    if (inputs.name != '') setIsDisableFields(false);
  }, []);

  const [inputs, setInputs] = useFormFields(props.formTemplate);

  const country = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')).country || '';

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkForward, setLinkForward] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [payerSap, setPayerSap] = useState(inputs.existingPayerID);
  const [ibanSap, setIbanSap] = useState(inputs.iban);
  const [isCopying, setIsCopying] = useState(false);
  const [copyClick, setCopyClick] = useState(false);
  // const priceListRes = FetchPriceList(country);
  const handleClose = () => setShowModal(false);

  const [isDisableFields, setIsDisableFields] = useState(true);

  const mainPageForm = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')) || {};

  const soldToDataRes = FetchSoldToBapi(
    inputs.existingSoldToID,
    mainPageForm.companyCode,
    mainPageForm.salesOrg,
    isCopying,
  );

  // const priceListOptions = priceListRes.pricelist.map((val) => {
  //   return {
  //     ...val,
  //     priceCode: val.priceCode,
  //     priceText: val.priceText,
  //     priceDesc: `${val.priceCode} - ${val.priceText}`,
  //   };
  // });

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(validateFields(inputs, false, payerSap, ibanSap));
      setIsSubmitting(true);
    }
  };

  // useEffect(() => {
  //   if (Object.keys(errors).length === 0 && isSubmitting) {
  //     props.setActivePage(linkForward);
  //   }
  // }, [errors]);

  function getLang(id) {
    // console.log(props.translations);
    let obj = translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    setTranslations(props.translations);
  }, [props.translations]);

  function copySoldToData(e) {
    setCopyClick(true);
    setErrors(validateFields(inputs, true));
    console.log(`I am true ${isCopying}`);
  }
  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && copyClick) {
      console.log(inputs.name, isDisableFields, copyClick);
      setCopyClick(false);
      setIsCopying(true);
      Object.entries(formTemplate.priceList).forEach(([key, value]) => {
        if (key !== 'existingPayerID') inputs[key] = value;
      });
    }
  }, [errors]);

  useEffect(() => {
    if (Object.keys(soldToDataRes.soldToData).length !== 0) {
      if (inputs.existingPayerID != '') setPayerSap(inputs.existingPayerID);
      Object.entries(soldToDataRes.soldToData).forEach(([key, value]) => {
        if (key in inputs) {
          if (key == 'iban') {
            if (value != '') inputs.ibanOpt = 'Update Existing';
            setIbanSap(value);
          }

          inputs[key] = value;
        }
      });
      sessionStorage.setItem('updatePriceListUpdate', JSON.stringify(inputs));
      setIsDisableFields(false);
    }
    setIsCopying(false);
  }, [soldToDataRes.soldToData]);

  useEffect(() => {
    if (mainPageForm.country != 'Belgium') {
      inputs.ibanOpt = 'Update Existing';
    }
  }, []);

  // const priceListsRes = FetchPriceLists(mainPageForm.country, isDisable);
  const priceListRes = FetchPriceList(mainPageForm.country);
  const priceListOptions = priceListRes.pricelist.map((val) => {
    return {
      ...val,
      priceCode: val.priceCode,
      priceText: val.priceText,
      priceDesc: `${val.priceCode} - ${val.priceText}`,
    };
  });

  return (
    <form className="card" onSubmit={handleSubmit}>
      <Modal show={isCopying} centered>
        <Modal.Body>
          <Row>
            <div className="col-auto">
              <Spinner animation="border" variant="warning" />
            </div>
            <div className="text-left">
              <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={inputs.supportDocsBank}></img>
        </Modal.Body>
      </Modal>
      <div className="card-header">
        <div className="col-12">
          <h3 className="card-title text-left">
            <strong>Customer Data {getLang('Update')}</strong>
          </h3>
        </div>
      </div>
      <Row className="sidebar-row">
        <div className="col-12">
          <div className="card-body">
            <Row>
              <Col sm={12} lg="auto">
                <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Price List Update')}</h2>
              </Col>
            </Row>
            <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">
                        {getLang('Existing Sold To ID')} *
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control${errors.existingSoldToID ? ' is-invalid' : ''}`}
                          name="existingSoldToID"
                          placeholder={`Enter ${getLang('Existing Sold To ID')}`}
                          value={inputs.existingSoldToID}
                          onChange={setInputs}
                          disabled={isDisable}
                        />
                        <span className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="button"
                            style={{
                              borderTopRightRadius: '3px',
                              borderBottomRightRadius: '3px',
                            }}
                            onClick={copySoldToData}
                            disabled={isDisable || inputs.existingSoldToID == ''}
                          >
                            {getLang('Copy')}
                          </button>
                        </span>
                        {errors.existingSoldToID && (
                          <div className="invalid-feedback text-left">
                            {errors.existingSoldToID}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Name')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                        name="name"
                        placeholder={`${getLang('Name')}`}
                        value={inputs.name}
                        onChange={setInputs}
                        disabled
                      />
                      {/* {errors.name && (
                        <div className="invalid-feedback text-left">{errors.name}</div>
                      )} */}
                    </div>
                  </div>
                  {/* <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Street')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.street ? 'is-invalid' : ''}`}
                        name="street"
                        value={inputs.street}
                        onChange={setInputs}
                        maxLength="35"
                        disabled
                      />
                      {errors.street && (
                        <div className="invalid-feedback text-left">{errors.street}</div>
                      )}
                    </div>
                  </div> */}
                  {/* <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Postal Code')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.postalCode ? 'is-invalid' : ''}`}
                        name="postalCode"
                        // maxLength={postalCodeLength(mainPageForm?.country)}
                        value={inputs.postalCode}
                        onChange={setInputs}
                        disabled
                      />
                      {errors.postalCode && (
                        <div className="invalid-feedback text-left">{errors.postalCode}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('City')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                        name="city"
                        value={inputs.city}
                        maxLength="35"
                        onChange={setInputs}
                        // disabled={inputs.name == '' || isDisable}
                        disabled
                      />
                      {errors.city && (
                        <div className="invalid-feedback text-left">{errors.city}</div>
                      )}
                    </div>
                  </div> */}

                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Price List')} *</label>
                      <Select
                        className="text-left"
                        name="price"
                        value={priceListOptions.filter((opt) => opt.priceCode == inputs.price)}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: '#cfcfcf',
                            primary50: '#cfcfcf',
                            primary: 'orange',
                          },
                        })}
                        isClearable
                        placeholder="Select"
                        isDisabled={isDisableFields || isDisable}
                        getOptionLabel={({ priceDesc }) => priceDesc}
                        getOptionValue={({ priceCode }) => priceCode}
                        options={priceListOptions}
                        onChange={(e, action) => {
                          setInputs(e, action);
                        }}
                      />
                      {errors.price && (
                        <div className="invalid-feedback-file text-left">{errors.price}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <br />

      <div className="card-footer">
        <div className="row">
          {!isDisable && (
            <div className="col-auto col-md-auto text-left">
              <Button variant="outline-warning" onClick={props.saveDraft}>
                Save Draft
              </Button>
            </div>
          )}
          <div className="col col-md text-left">
            <span className="align-middle">{props.draftMsg}</span>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="mainPage"
              data-back="true"
              className="btn btn-warning btn-block"
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Back
            </Link>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="priceListReview"
              className={`btn btn-danger btn-block${
                inputs.existingPayerID == '' || inputs.name == '' ? ' disabled' : ''
              }`}
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Review
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdatePriceListUpdate;
