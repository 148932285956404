import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import MaterialReactTable from 'material-react-table';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Chip, { ChipProps } from '@mui/material/Chip';
import { ButtonGroup, Row, Col, Modal, Spinner } from 'react-bootstrap';
import { red, blue, yellow, green, orange } from '@mui/material/colors';
import MandatoryText from '../../../components/MandatoryText';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Typography } from '@mui/material';
import '../../../components/layout/Tables/MaterialReactTable/materialReactTableStyles.scss';
import { validateFields } from './utils/FormValidation';
import { FetchBillToBapi } from '../utils/FlowCallAPI';
import { useFormFields } from './utils/HooksLib';
import { formTemplate } from '../Data';
import UpdateInvData from '../einvpayerupdate/EInvUpdateModal';

import Button from '@mui/material/Button';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const UpdateCustEInvBillUpdate = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  const [translations, setTranslations] = useState(props.translations);

  useEffect(() => {
    // if (!isAuthenticated) navigate('/');
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [tableData, setTableData] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  useEffect(() => {
    console.log(inputs);
    if (
      props.draftTicketNum[0] !== 'M' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected'
    ) {
      setIsDisable(true);
    }

    if (inputs.name != '') setIsDisableFields(false);

    console.log(props.draftTicketNum);
  }, []);

  const [inputs, setInputs] = useFormFields(props.formTemplate);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkForward, setLinkForward] = useState('');
  const [isCopying, setIsCopying] = useState(false);
  const [copyClick, setCopyClick] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [isDisableFields, setIsDisableFields] = useState(true);

  const mainPageForm = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')) || {};

  const billToDataRes = FetchBillToBapi(
    inputs.existingBillToID,
    mainPageForm.companyCode,
    mainPageForm.salesOrg,
    isCopying,
  );

  function getChipProps(row) {
    if (row == 'E') {
      return {
        // icon: <CheckCircleIcon style={{ fill: blue[500] }} />,

        label: 'E-Invoicing',
        style: {
          color: 'black',
          borderColor: green[500],
          backgroundColor: green[500],
        },
      };
    } else if (row == 'EP') {
      return {
        // icon: <CheckCircleIcon style={{ fill: blue[500] }} />,

        label: 'E-Payment',
        style: {
          color: '#fff',
          borderColor: green[500],
          backgroundColor: green[500],
        },
      };
    }
  }
  let key = Object.keys(rowSelection)?.find((k) => rowSelection[k] === true);
  useEffect(() => {
    console.log('use effect for row change', key);
    if (typeof key !== 'undefined') {
      {
        TABLE_COLUMNS.map((column) => {
          if (column.id == 'function' && tableData[key][column.accessorKey] == 'E') {
            inputs.function = 'E-Invoicing';
          } else if (column.id == 'function' && tableData[key][column.accessorKey] == 'EP') {
            inputs.function = 'E-Payment';
          } else if (column.id in inputs) {
            inputs[column.id] = tableData[key][column.accessorKey];
          }
        });
        sessionStorage.setItem('updateCustomerEInvBillUpdate', JSON.stringify(inputs));
      }
    }
  }, [rowSelection, key]);
  const TABLE_COLUMNS = [
    {
      accessorKey: 'Z_CONTACT_PERSON_ID',
      id: 'contactID',
      header: 'Contact ID',
      flex: 1,
      columnDefType: 'data',
      cellClassName: 'dashboardCell',
      minWidth: 90,
    },
    {
      accessorKey: 'Z_FIRSTNAME',
      id: 'firstName',
      header: 'First Name',
      flex: 1,
      columnDefType: 'data',
      cellClassName: 'dashboardCell',
      minWidth: 90,
    },
    {
      accessorKey: 'Z_NAME1',
      id: 'lastName',
      header: 'Last Name',
      flex: 1,
      columnDefType: 'data',
      cellClassName: 'dashboardCell',
      minWidth: 90,
    },
    {
      accessorKey: 'Z_CONTACT_PERSON_FUNCTION',
      id: 'function',
      header: 'Function',
      flex: 1,
      columnDefType: 'data',
      cellClassName: 'dashboardCell',
      minWidth: 90,
      Cell: (row) => {
        return (
          <Chip variant="outlined" {...getChipProps(row.row.original.Z_CONTACT_PERSON_FUNCTION)} />
        );
      },
    },
    {
      accessorKey: 'Z_EMAIL',
      id: 'contactEmail',
      header: 'Email ID',
      flex: 1,
      columnDefType: 'data',
      cellClassName: 'dashboardCell',
      minWidth: 90,
    },
  ];

  const handleInvUpdateModal = (row) => {
    setCreateModalOpen(true);
    setInputs({
      target: { name: 'eInvBillOpt', value: 'Update Existing' },
    });
    setRowData(row);
    setInputs({
      target: { name: 'eInvBillOpt', value: 'Update Existing' },
    });
    console.log('ROW DATA', rowData);
  };
  const handleNewRowAdd = (row) => {
    console.log('Add new called');
    inputs.eInvPayerOpt = 'Add New';
    setInputs({
      target: { name: 'eInvBillOpt', value: 'Add New' },
    });
    setCreateModalOpen(true);
  };
  const exportTheme = createTheme({
    palette: {
      primary: {
        main: '#DAA520',
      },
      // secondary: pink,
    },
  });
  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(validateFields(inputs, false));
      setIsSubmitting(true);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      props.setActivePage(linkForward);
    }
  }, [errors]);

  function getLang(id) {
    // console.log(props.translations);
    let obj = translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    setTranslations(props.translations);
  }, [props.translations]);

  function copyBillToData(e) {
    setCopyClick(true);
    setErrors(validateFields(inputs, true));
    console.log(`I am true ${isCopying}`);
  }

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && copyClick) {
      console.log(inputs.name, isDisableFields, copyClick);
      setCopyClick(false);
      setIsCopying(true);
      Object.entries(formTemplate.eInvBillUpdate).forEach(([key, value]) => {
        if (key !== 'existingBillToID') inputs[key] = value;
      });
    }
  }, [errors]);

  useEffect(() => {
    if (Object.keys(billToDataRes.billToData).length !== 0) {
      Object.entries(billToDataRes.billToData).forEach(([key, value]) => {
        if (key in inputs) {
          inputs[key] = value;
        }
      });
      sessionStorage.setItem('updateCustomerEInvBillUpdate', JSON.stringify(inputs));
      setIsDisableFields(false);
    }
    setIsCopying(false);
    if (Object.keys(billToDataRes.billToData).length !== 0) {
      setTableData(
        billToDataRes?.billToData.invoicingDetails?.item.map((i, index) => {
          return {
            id: index,
            ...i,
          };
        }),
      );
    }
  }, [billToDataRes.billToData]);

  return (
    <form className="card" onSubmit={handleSubmit}>
      <Modal show={isCopying} centered>
        <Modal.Body>
          <Row>
            <div className="col-auto">
              <Spinner animation="border" variant="warning" />
            </div>
            <div className="text-left">
              <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
      <div className="card-header">
        <div className="col-12">
          <h3 className="card-title text-left">
            <strong>Customer Data {getLang('Update')}</strong>
          </h3>
        </div>
      </div>
      <Row className="sidebar-row">
        <div className="col-12">
          <div className="card-body">
            <Row>
              <Col sm={12} lg={4}>
                <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                  {getLang('Bill-To E-Invoicing Update')}
                </h2>
              </Col>
            </Row>
            <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

            {mainPageForm.country == 'Netherlands' && (
              <>
                <Row>
                  <Col>
                    <h6 className="text-left">Let op de wet van de privacy</h6>
                  </Col>
                </Row>
                <br />
              </>
            )}

            <div className="row">
              <div className="col-12 col-lg-3">
                <div className="form-group">
                  <label className="form-label text-left">{getLang('Existing Bill To ID')} *</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className={`form-control${errors.existingBillToID ? ' is-invalid' : ''}`}
                      name="existingBillToID"
                      maxLength="8"
                      placeholder={`Enter ${getLang('Existing Bill To ID')}`}
                      value={inputs.existingBillToID}
                      onChange={setInputs}
                      disabled={isDisable}
                    />
                    <span className="input-group-append">
                      <button
                        className="btn btn-primary"
                        type="button"
                        style={{
                          borderTopRightRadius: '3px',
                          borderBottomRightRadius: '3px',
                          marginTop: '-2px',
                        }}
                        onClick={copyBillToData}
                        disabled={isDisable || inputs.existingBillToID == ''}
                      >
                        {getLang('Copy')}
                      </button>
                    </span>
                    {errors.existingBillToID && (
                      <div className="invalid-feedback text-left">{errors.existingBillToID}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3">
                <div className="form-group">
                  <label className="form-label text-left">{getLang('Customer Name')}</label>
                  <input
                    type="text"
                    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                    name="name"
                    value={inputs.name}
                    onChange={setInputs}
                    disabled
                  />
                  {errors.name && <div className="invalid-feedback text-left">{errors.name}</div>}
                </div>
              </div>
              <div className="materialReactTableWrapper">
                <MaterialReactTable
                  initialState={{ columnVisibility: { contactID: false } }}
                  enableRowSelection
                  onRowSelectionChange={setRowSelection}
                  state={{ rowSelection }}
                  enableSelectAll={false}
                  data={tableData}
                  columns={TABLE_COLUMNS}
                  enableGlobalFilter={false}
                  enableSorting={false}
                  enableStickyHeader
                  enableHiding={false}
                  enableDensityToggle={false}
                  enableFullScreenToggle={false}
                  enableFilters={false}
                  enableMultiRowSelection={false}
                  positionToolbarAlertBanner="none"
                  // onEditingRowSave={handleSaveRowEdits}
                  // onEditingRowCancel={handleCancelRowEdits}
                  renderTopToolbarCustomActions={(row) => (
                    <div style={{ padding: '4px 10px' }}>
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            //  gap: '1rem', flexWrap: 'wrap'
                          }}
                        >
                          <ThemeProvider theme={exportTheme}>
                            <Button
                              color="secondary"
                              onClick={() => handleInvUpdateModal(row)}
                              variant="contained"
                              startIcon={<EditOutlinedIcon style={{ color: 'black' }} />}
                              style={{ backgroundColor: '#f7a21d', color: 'black' }}
                              disabled={typeof key === 'undefined'}
                            >
                              Edit
                            </Button>
                            <Button
                              color="secondary"
                              onClick={() => handleNewRowAdd(row)}
                              variant="contained"
                              startIcon={<UploadFileIcon style={{ color: 'black' }} />}
                              style={{
                                backgroundColor: '#f7a21d',
                                color: 'black',
                                marginLeft: '1rem',
                              }}
                              disabled={inputs.name === ''}
                            >
                              Add New
                            </Button>
                          </ThemeProvider>
                        </Box>
                      </>
                    </div>
                  )}
                />
                <UpdateInvData
                  columns={TABLE_COLUMNS}
                  open={createModalOpen}
                  rowData={tableData}
                  onClose={() => setCreateModalOpen(false)}
                  getLang={getLang}
                  inputs={inputs}
                  setInputs={setInputs}
                  errors={errors}
                  setErrors={setErrors}
                  isDisable={isDisable}
                  handleSubmit={handleSubmit}
                  activeRow={key}
                  // onSubmit={handleCreateNewRow}
                />
              </div>
            </div>
          </div>
        </div>
      </Row>

      <br />

      <div className="card-footer">
        <div className="row">
          {!isDisable && (
            <div className="col-auto col-md-auto text-left">
              <Button variant="outline-warning" onClick={props.saveDraft}>
                Save Draft
              </Button>
            </div>
          )}
          <div className="col col-md text-left">
            <span className="align-middle">{props.draftMsg}</span>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="mainPage"
              data-back="true"
              className="btn btn-warning btn-block"
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Back
            </Link>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="eInvBillUpdateReview"
              className={`btn btn-danger btn-block${
                inputs.existingBillToID == '' || inputs.name == '' ? ' disabled' : ''
              }`}
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Review
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateCustEInvBillUpdate;
