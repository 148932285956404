import { useState, useEffect } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  useEffect(() => {
    sessionStorage.setItem('updateCustomerOthersGlnUpdate', JSON.stringify(fields));
  }, [fields]);

  return [
    fields,
    function (event) {
      if (['updateOption', 'warehouseEdType'].includes(event.target.name)) {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
        });
      } else if (event.target.name == 'existingShipToID') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          shipToName: '',
          glnShipTo: '',
        });
      } else if (event.target.name == 'existingSoldToID') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          soldToName: '',
          glnSoldTo: '',
        });
      } else if (event.target.name == 'existingPayerID') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          payerToName: '',
          glnPayerTo: '',
        });
      } else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
    },
  ];
}
