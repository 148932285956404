export const formTemplate = {
  mainpage: {
    country: '',
    salesOrg: '',
    companyCode: '',
  },
  profileData: {
    name: '',
    name2: '',
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    region: '',
    transportation_zone: '',
    language: '',
    telephone: '',
    mobile: '',
    email: '',
    contact_person: '',
    existingID: '',
  },
};
