import { useState, useEffect } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  useEffect(() => {
    sessionStorage.setItem('createCustomerShipTo', JSON.stringify(fields));
  }, [fields]);

  return [
    fields,
    function (event, action) {
      console.log(event, action);
      if (event == 'beesRelevantFlag') {
        setValues({
          ...fields,
          beesRelevantFlag: action,
        });
      } else if (event == 'promoNews') {
        setValues({
          ...fields,
          promoNews: action,
        });
      } else if (action?.name == 'pocSupplierShipTo') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            pocSupplierShipTo: '',
            pocSupplierDesc: '',
            pocPayer: '',
            pocPriceList: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            pocSupplierShipTo: event.pocNumber,
            pocSupplierDesc: event.pocDesc,
            pocPayer: event.pocPayer,
            pocPriceList: event.pocPriceListERP,
          });
        }
      } else if (action?.name == 'region') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            region: '',
            regionDesc: '',
            erpTranspZone: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            region: event.regionCode,
            regionDesc: event.regionDesc,
            erpTranspZone: event.erpTranspZone,
          });
        }
      } else if (action?.name == 'transportationZone') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            transportationZone: '',
            transZDesc: '',
            // regionDesc: '',
            // erpTranspZone: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            transportationZone: event.transZCode,
            transZDesc: event.transZDesc,
            // erpTranspZone: event.erpTranspZone,
          });
        }
      } else if (action?.name == 'language') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            language: '',
            langDesc: '',
            // regionDesc: '',
            // erpTranspZone: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            language: event.langText,
            langDesc: event.langDesc,
            // erpTranspZone: event.erpTranspZone,
          });
        }
      } else if (action?.name == 'truckType') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            truckType: '',
            truckDesc: '',
            truckCode: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            truckType: event.truckCode,
            truckDesc: event.truckDesc,
          });
        }
      } else if (action?.name == 'trailerType') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            trailerType: '',
            trailerDesc: '',
            trailerCode: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            trailerType: event.trailerCode,
            trailerDesc: event.trailerDesc,
          });
        }
      } else if (action?.name == 'profitCenter') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            profitCenter: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            profitCenter: event.profitCenter,
          });
        }
      } else if (action?.name == 'delivWindow') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            delivWindow: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            delivWindow: event.delivWindow,
          });
        }
      } else if (event.target.name == 'existingShipToID') {
        console.log(action, 'test hooks');

        setValues({
          ...fields,
          name: '',
          [event.target.name]: event.target.value.toUpperCase(),
        });
      } else if (event.target.name === 'segment' && event.target.value !== '') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          segmentDesc: event.target.selectedOptions[0].getAttribute('data-segment'),
        });
      } else if (event.target.name === 'subSegment' && event.target.value !== '') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          subSegmentDesc: event.target.selectedOptions[0].getAttribute('data-subsegment'),
        });
      } else if (event.target.name === 'deliveringPlant' && event.target.value !== '') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          deliveringPlantDesc: event.target.selectedOptions[0].getAttribute('data-plant'),
        });
      } else if (event.target.name === 'shippingCondition' && event.target.value !== '') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          shippingConditionDesc: event.target.selectedOptions[0].getAttribute('data-shipcondn'),
        });
      } else if (event.target.name === 'exciseDutyType' && event.target.value !== '') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          exciseDutyTypeDesc: event.target.selectedOptions[0].getAttribute('data-excise'),
        });
      } else if (
        [
          'ediOption',
          'ediType',
          'ldeOption',
          'ldeType',
          'telephoneOption',
          'subChannel',
          'hierarchyTypeDNode',
          'tier',
          'customerClassification',
        ].includes(event.target.name)
      ) {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
        });
      } else if (
        ['generalTelephone', 'logTelephone', 'invTelephone', 'orderTelephone'].includes(
          event.target.name,
        )
      ) {
        if (event.target.name == 'generalTelephone') {
          setValues({
            ...fields,
            ['generalTelephone']: event.target.value.toUpperCase(),
            ['logTelephone']: '',
            ['invTelephone']: '',
            ['orderTelephone']: '',
          });
        } else if (event.target.name == 'logTelephone') {
          setValues({
            ...fields,
            ['generalTelephone']: '',
            ['logTelephone']: event.target.value.toUpperCase(),
            ['invTelephone']: '',
            ['orderTelephone']: '',
          });
        } else if (event.target.name == 'invTelephone') {
          setValues({
            ...fields,
            ['generalTelephone']: '',
            ['logTelephone']: '',
            ['invTelephone']: event.target.value.toUpperCase(),
            ['orderTelephone']: '',
          });
        } else {
          setValues({
            ...fields,
            ['generalTelephone']: '',
            ['logTelephone']: '',
            ['invTelephone']: '',
            ['orderTelephone']: event.target.value.toUpperCase(),
          });
        }
        console.log('anmol', event.target.name);
      } else {
        console.log(action, 'else block', event);
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
        console.log(event.target.name);
      }
    },
  ];
}

// else if (event.target.name === 'pocSupplierShipTo' && event.target.value !== '' && event.target.type !== 'text') {
//     console.log(event.target)
//     setValues({
//         ...fields,
//         [event.target.name]: event.target.value,
//         pocPayer: event.target.selectedOptions[0].getAttribute('data-payer'),
//         pocPriceList: event.target.selectedOptions[0].getAttribute('data-pricelist')
//     })
// }

// else if (event.target.name === 'region' && event.target.value !== '') {
//   setValues({
//     ...fields,
//     [event.target.name]: event.target.value,
//     regionDesc: event.target.selectedOptions[0].getAttribute('data-region'),
//     transportationZone: event.target.selectedOptions[0].getAttribute('data-transpzone'),
//   });
// }
