import { useState, useEffect } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  useEffect(() => {
    sessionStorage.setItem('updateTruckTrailerUpdate', JSON.stringify(fields));
  }, [fields]);

  return [
    fields,
    function (event) {
      if (event.target.name == 'existingShipToID') {
        var today = new Date();
        var todayDisp = `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1}-${today.getDate() < 10 ? '0' + today.getDate() : today.getDate()}`;
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          name: '',
          city: '',
          postalCode: '',
          street: '',
        });
       
    }else if (['truckType','trailerType'].includes(event.target.name)) {
      setValues({
        ...fields,
        [event.target.name]: event.target.value,
      }); 
    }else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
    },
  ];
}
