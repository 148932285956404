/* eslint-disable no-useless-catch */
import { useState, useEffect } from 'react';
import axios from './axios';
// import axios from 'axios';

import { urls } from './Utils';

export default {
  addUserAccessRoute: async function (role, country, email, approver_role, approver_channel) {
    // const response = await axios
    //   .post(urls.addUserAccessURL, {
    //     accessType: accessType,
    //     country: country,
    //     email: email,
    //   })
    //   .then((response) => response.data)
    //   .catch((error) => {
    //     console.log(error);
    //     console.log(error.response);
    //     if (error.response) {
    //       return error.response.data; // => the response payload
    //     }
    //   });
    try {
      const response = await axios.post(urls.addUserAccessURL, {
        role: role,
        country: country,
        approver_role: approver_role,
        approver_channel: approver_channel,
        email: email,
      });

      return response.data;
    } catch (error) {
      console.log(error.response);
      throw error;
    }
  },

  removeUserAccessRoute: async function (accessType, country, email) {
    try {
      const response = await axios.delete(urls.removeUserAccessURL, {
        data: { accessType: accessType, country: country, email: email },
      });

      return response.data;
    } catch (error) {
      console.log(error.response);
      throw error;
    }
  },
};
