export function validateFields(values, copyClick, soldToSap) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')).country;

  const telephoneRegex = /^(\+|)[0-9]{6,}$/;

  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const soldToIDRegex = /^(1)\d{7}$/;

  const pocSupplierRegex =/^(4)\d{7}$|^(2)\d{7}$/;

  if (copyClick) {
    if (values.existingSoldToID == '') {
      errors.existingSoldToID = 'Sold To ID is required';
    } else if (values.existingSoldToID != '' && !soldToIDRegex.test(values.existingSoldToID)) {
      errors.existingSoldToID = 'Sold To ID is incorrect';
    }
  } else {
    console.log(soldToSap);
    if (values.name != '') {
      if (values.telephone != '' && !telephoneRegex.test(values.telephone)) {
        errors.telephone = 'Telephone number is incorrect';
      } else if (values.mobile !== '' && !telephoneRegex.test(values.mobile)) {
        errors.mobile = 'Mobile number is incorrect';
      } else if (values.email !== '' && !emailRegex.test(values.email)) {
        errors.email = 'Email is incorrect';
      } else if (country != 'Netherlands' && values.pocSupplier != '' && !pocSupplierRegex.test(values.pocSupplier)) {
        errors.pocSupplier = 'POC Supplier is incorrect';
      }
    }
  }

  //   if (values.existingSoldToID == '') {
  //     errors.existingSoldToID = 'Sold To ID is required';
  //   } else if (values.existingSoldToID != '' && !soldToIDRegex.test(values.existingSoldToID)) {
  //     errors.existingSoldToID = 'Sold To ID is incorrect';
  //   } else if (values.name != '') {
  //     if (values.telephone != '' && !telephoneRegex.test(values.telephone)) {
  //       errors.telephone = 'Telephone number is incorrect';
  //     } else if (country != 'Netherlands' && values.mobile === '') {
  //       errors.mobile = 'Mobile number is required';
  //     } else if (country != 'Netherlands' && values.mobile !== '' && !telephoneRegex.test(values.mobile)) {
  //       errors.mobile = 'Mobile number is incorrect';
  //     } else if (values.email === '') {
  //       errors.email = 'Email is required';
  //     } else if (values.email !== '' && !emailRegex.test(values.email)) {
  //       errors.email = 'Email is incorrect';
  //     }
  //   }

  return errors;
}
