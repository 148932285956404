import { glnIlnCodeRegex, postalCodeRegex, payerIDRegex } from '../../../utils/Utils';

export function validateFields(values) {
  let errors = {};

  const soldToPayer2Regex = /^(3)\d{7}$/;

  const pricingHierarchyRegex = /^\d{6}$/;

  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // console.log(values.att7 == '');

  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country;

  const customerReqType = JSON.parse(sessionStorage.getItem('createCustomerMainPage'))
    .customerReqType;

  const shipToType = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).shipToType;

  if (values.name == '') {
    errors.name = 'Name is required';
  } else if (values.postalCode == '') {
    errors.postalCode = 'Postal Code is required';
  } else if (
    values.postalCode != '' &&
    !postalCodeRegex[
      typeof values.soldCountry == 'undefined' ? 'Belgium' : values.soldCountry
    ].test(values.postalCode)
  ) {
    errors.postalCode = 'Postal Code is incorrect';
  } else if (values.houseNumber == '') {
    errors.houseNumber = 'House Number is required';
  } else if (values.street == '') {
    errors.street = 'Street is required';
  } else if (values.city == '') {
    errors.city = 'City is required';
  } else if (values.soldCountry == '') {
    errors.soldCountry = 'Country is required';
  } else if (values.region == '') {
    errors.region = 'Region is required';
  } else if (values.email == '') {
    errors.email = 'Email is required';
  }
  return errors;
}
