import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from '@azure/msal-react';

import { Link, useNavigate, useLocation } from 'react-router-dom';
import { loginRequest, snowBackendRequest } from '../../authConfig';
import Badge from 'react-bootstrap/Badge';
import { callMsGraphPhoto } from '../../utils/GraphCallAPI';

import '../../styles/TopBar.css';
// import { ProfilePhoto } from '../utils/GraphCallAPI';
import FeatherIcon from 'feather-icons-react';
import logo from '../../assets/images/abi-logo-2022.png';
import mavenLogo from '../../assets/images/maven_logo.png';
import mavenLogo4 from '../../assets/images/maven_logo_4.png';
import ChristmasGif from '../../assets/Christmas_gif.gif';
import ChistmasTree from '../../assets/ChristmasTree.gif';
import SnowFAll from '../../assets/SnowFallGif.gif';
import defaultProfilePhoto from '../../assets/images/profile.jpg';
import { Button } from 'react-bootstrap';
// import {DropdownSubmenu, NavDropdownMenu}  from "react-bootstrap-submenu";
// import guide from '../assets/guide.pdf';
import { adminUsers } from '../../utils/Utils';
import { FetchUserRole, FetchRoleAccess } from '../../pages/FlowCallAPI';
import { UserContext } from '../../context/UserContext';
import Sidebar from './Sidebar/Sidebar';

const TopBar = (props) => {
  const navigate = useNavigate();

  const location = useLocation();

  const [profilePhoto, setProfilePhoto] = useState(null);

  const { instance, accounts, inProgress } = useMsal();

  const isAuthenticated = useIsAuthenticated();

  const [isDropDownOpen, setisDropDownOpen] = useState(false);

  const [isCreateDropDownOpen, setIsCreateDropDownOpen] = useState(false);

  const [isCustomerCreateDropDownOpen, setIsCustomerCreateDropDownOpen] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  const { user, userDispatch } = useContext(UserContext);

  const [token, setToken] = useState('');

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const togglingDropDown = () => {
    setisDropDownOpen(!isDropDownOpen);
  };

  const toggleIsCreateDropDownOpen = () => {
    setIsCreateDropDownOpen(!isCreateDropDownOpen);
  };

  const toggleIsCustomerCreateDropDownOpen = () => {
    setIsCustomerCreateDropDownOpen(!isCustomerCreateDropDownOpen);
  };

  const [isHeaderOpen, setisHeaderOpen] = useState(false);

  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // const sidebarToggle = () => {
  //   togglingHeader();
  //   handleShow();
  // }

  const togglingHeader = () => {
    setisHeaderOpen(!isHeaderOpen);
  };
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [roleAccess, setRoleAccess] = useState('Requestor');
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  function getLang(id) {
    // console.log(props.translations);
    let obj = props.translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  const useClickOutside = (handler) => {
    let domNode = useRef();

    useEffect(() => {
      let handleEvent = (event) => {
        if (!domNode.current?.contains(event.target)) {
          handler();
        }
      };
      document.addEventListener('mousedown', handleEvent);
      return () => {
        document.addEventListener('mousedown', handleEvent);
      };
    });
    return domNode;
  };

  const domNode = useClickOutside(() => {
    setIsCreateDropDownOpen(false);
    setIsCustomerCreateDropDownOpen(false);
  });

  const domNodes = useClickOutside(() => {
    setisDropDownOpen(false);
  });

  useEffect(() => {
    if (isMobile) {
      // <Sidebar sidebarItems={props.items}/>
    }
  }, [isMobile]);

  useEffect(() => {
    console.log('Account: ', accounts[0]);

    if (accounts?.length > 0) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          localStorage.setItem('id_token', response.idToken);
        })
        .catch((err) => {
          console.log(`Error occured while acquiring token: ${err}`);
          instance.logout({
            account: accounts.length > 0 ? accounts[0] : null,
          });
        });
    }
    if (accounts?.length > 0) {
      instance
        .acquireTokenSilent({
          ...snowBackendRequest,
          account: accounts[0],
        })
        .then((response) => {
          localStorage.setItem('snow_api_access_token', response.accessToken);
        })
        .catch((err) => {
          console.log(`Error occured while acquiring token: ${err}`);
          instance.logout({
            account: accounts.length > 0 ? accounts[0] : null,
          });
        });
    }
    FetchUserRole(accounts[0].username).then((response) => {
      userDispatch({ type: 'SET_USER', payload: response });
    });
    FetchRoleAccess(accounts[0].username).then((response) => {
      console.log(response, 'rba response');
      localStorage.setItem('Role', response.role);
      setRoleAccess(response.role);
    });
  }, [accounts, inProgress]);
  useEffect(() => {
    console.log('get access token');
    // console.log('account user name  ' + accounts[0].username);
    if (accounts) {
      instance
        .acquireTokenSilent({
          // scopes: ['api://371e22d5-652f-40ed-b129-8c4a4c74107b/access_as_user'], // production
          scopes: ['api://b8f281cd-6a4b-4673-9e89-81ebf43d3882/access_as_user'], // dev
          account: accounts[0],
        })
        .then((response) => {
          if (response) {
            setToken(response.accessToken);
            localStorage.setItem('token-customscope', response.accessToken);
          }
        })
        .catch((error) => {
          console.log('error in custom', error);
        });
    }
    if (accounts?.length > 0) {
      instance
        .acquireTokenSilent({
          ...snowBackendRequest,
          account: accounts[0],
        })
        .then((response) => {
          localStorage.setItem('snow_api_access_token', response.accessToken);
        })
        .catch((err) => {
          console.log(`Error occured while acquiring token: ${err}`);
          instance.logout({
            account: accounts.length > 0 ? accounts[0] : null,
          });
        });
    }
  }, []);
  const XmasGif = {
    width: '100%',
    backgroundImage: `url(${SnowFAll})`,
    backgroundColor: 'black',
  };

  const Default = {
    backgroundColor: 'black',
  };

  // const sidebarItems = [
  //   {
  //     title: getLang('Home'),
  //     active: true,
  //   },
  //   {
  //     title: getLang('Ship-To'),
  //     onClick: handleSubmit,
  //     ['data-link']: 'shipto',
  //     ['data-back']: true,
  //     type: 'submit',
  //   },
  //   {
  //     title: getLang('Payer'),
  //     onClick: handleSubmit,
  //     ['data-link']: 'payer',
  //     ['data-back']: 'true',
  //     type: 'submit',
  //   },
  //   {
  //     title: getLang('Sold-To'),
  //     onClick: handleSubmit,
  //     ['data-link']: 'soldto',
  //     ['data-back']: 'true',
  //     type: 'submit',
  //   },
  //   {
  //     title: getLang('Bill-To'),
  //     onClick: handleSubmit,
  //     ['data-link']: 'billto',
  //     ['data-back']: 'true',
  //     type: 'submit',
  //   },
  // ];

  return (
    <div className="top-nav">
      <div
        className="header py-4"
        // style={{ backgroundColor: '#343a40' }}>
        // style={{ backgroundColor: 'black', backgroundImage: `url(${ChristmasGif})` }}
        style={Default}
      >
        {/* <img src={ChristmasGif} alt="loading" /> */}
        <div className="container">
          <div className="d-flex">
            <a className="header-brand" href="/">
              {/* <img src={ChistmasTree} className="christmasTree" alt="chirstmastree" /> */}
              <img
                src={mavenLogo4}
                className="header-maven-img"
                alt="maven logo"
                style={{ height: '3.5rem', marginRight: '0.5rem' }}
              />
              <img
                src={logo}
                className="header-brand-img"
                alt="abi logo"
                style={{
                  borderLeft: '1px solid #c9c9c9',
                  paddingLeft: '0.5rem',
                  paddingBottom: '0.5rem',
                  height: '2.5rem',
                }}
              />
            </a>
            <div className="d-flex order-lg-2 ml-auto text-left">
              {/* <Button
                size="sm"
                onClick={() => {
                  window.open(
                    `https://cmdeuautomationcoesa.blob.core.windows.net/assets/Maven (CMD EUR) Portal Guide.pdf`,
                  );
                }}
              >
                Guide
              </Button> */}
              <Button
                size="sm"
                style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
                onClick={() => {
                  window.open(
                    `https://cmdeuautomationcoesa.blob.core.windows.net/assets/Mandatory Fields Country Wise (4).xlsx`,
                  );
                }}
              >
                Mandatory Fields
              </Button>
              &emsp;
              <Button
                size="sm"
                style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
                onClick={() => {
                  window.open(
                    `https://app.powerbi.com/groups/me/reports/2f114c3a-c941-4cee-80cc-722f16db9840/ReportSection61adbf4b59a1c86af9f1`,
                  );
                }}
              >
                Power BI Dashboard
              </Button>
              &emsp;
              <span style={{ color: '#ffe552' }}>
                <strong>
                  {process.env.REACT_APP_ENV === 'prod'
                    ? 'LIVE'
                    : process.env.REACT_APP_ENV === 'test'
                    ? 'TEST'
                    : 'LOCAL'}
                </strong>
              </span>
              <div className="dropdown" ref={domNodes}>
                <a className="nav-link pr-0 leading-none" onClick={togglingDropDown}>
                  <img
                    className="avatar"
                    src={profilePhoto ? URL.createObjectURL(profilePhoto) : defaultProfilePhoto}
                    alt=""
                  />
                  {/* <ProfilePhoto graphData={graphData} /> */}
                  <span className="ml-2 d-none d-lg-block">
                    <span style={{ color: '#ffe552' }}>
                      <strong>{accounts.length > 0 ? accounts[0].name : ''}</strong>
                    </span>
                    <span>
                      &emsp;&emsp;&emsp;
                      <FeatherIcon icon="chevron-down" size={14} />
                    </span>
                    <small className="text-muted d-block mt-1">
                      {accounts.length > 0 ? accounts[0].username : ''}
                    </small>
                    <small className="text-muted d-block mt-1">
                      <span style={{ color: '#ffe552' }}>{localStorage.getItem('Role')}</span>
                    </small>
                  </span>
                </a>
                {isDropDownOpen && (
                  <div
                    // className="dropdown-menu dropdown-menu-right dropdown-menu-arrow show"
                    // className="dropdown-menu dropdown-menu-arrow show"className
                    className="dropdown-menu dropdown-menu-left show "
                    style={{ cursor: 'pointer', marginLeft: '48px' }}
                  >
                    <a
                      className="dropdown-item text-left"
                      onClick={() => {
                        window.open(
                          `https://cmdeuautomationcoesa.blob.core.windows.net/assets/Maven%20(CMD%20EUR)%20Portal%20Guide.pdf?sp=r&st=2023-08-01T08:23:14Z&se=2050-08-01T16:23:14Z&spr=https&sv=2022-11-02&sr=b&sig=6ySa6yfxF%2FHGrgaYiutoNfWz1Yp1F%2BaU8Qfov0%2BTd9o%3D`,
                        );
                      }}
                    >
                      Guide
                    </a>
                    <a
                      className="dropdown-item text-left"
                      // style={{ color: 'black' }}
                      onClick={() => {
                        // props.setActivePage('contactus');
                        navigate('/contactus');
                      }}
                    >
                      Support
                    </a>
                    <a className="dropdown-item text-left" onClick={() => instance.logout()}>
                      Sign out
                    </a>
                  </div>
                )}
              </div>
            </div>
            <a
              className={`header-toggler d-lg-none ml-3 ml-lg-0 ${isHeaderOpen ? '' : 'collapsed'}`}
              onClick={togglingHeader}
              // onClick={sidebarToggle}
            >
              <span className="header-toggler-icon"></span>
            </a>
          </div>
        </div>
      </div>

      <div
        className={`header collapse d-lg-flex p-0 ${isHeaderOpen ? 'show' : ''}`}
        id="headerMenuCollapse"
        //style={{ backgroundColor: '#e5b611' }}
        style={{ background: 'linear-gradient(90deg,#e3af32 50%,#f4e00f 100%)' }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 ml-auto">
              <div className="row lang-selector">
                <div className="col-auto my-auto" style={{ color: 'black' }}>
                  {getLang('Language')}
                </div>
                <div className="col my-auto">
                  <div className="form-group" style={{ marginBottom: '0' }}>
                    <select
                      id="select-lang"
                      name="appLang"
                      className="form-control"
                      onChange={props.changeLang}
                      value={props.lang}
                    >
                      <option value="en">English</option>
                      <option value="be-fr">Belgium French</option>
                      <option value="be-nl">Belgium Dutch</option>
                      <option value="fr">French</option>
                      <option value="de">German</option>
                      <option value="nl">Dutch</option>
                      <option value="it">Italian</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg order-lg-first">
              <ul className="nav nav-tabs border-0 flex-column flex-lg-row">
                {/* "nav nav-tabs border-0 flex-column flex-lg-row" */}
                <li className="nav-item">
                  <a
                    className={`nav-link ${props.activePage === 'home' ? ' active' : ''}`}
                    style={{ color: 'black' }}
                    onClick={() => {
                      sessionStorage.clear();
                      navigate('/');
                    }}
                  >
                    <FeatherIcon icon="home" size={14} />
                    &nbsp;{getLang('Home')}
                  </a>
                </li>
                {(localStorage.getItem('Role') === 'Requestor' ||
                  localStorage.getItem('Role') === 'Credit level approver') &&
                  props.activePage != '' && (
                    <>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${props.activePage === 'create' ? ' active' : ''}`}
                          style={{ color: 'black' }}
                          onClick={() => {
                            toggleIsCreateDropDownOpen();
                            props.setActivePage('create');
                            navigate('/create/general');
                          }}
                        >
                          <FeatherIcon icon="file-plus" size={14} />
                          &nbsp;{getLang('Create')}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${props.activePage === 'update' ? ' active' : ''}`}
                          style={{ color: 'black' }}
                          onClick={() => {
                            if (props.activePage === 'update') {
                              // If the current page is already 'update', force reload
                              window.location.reload();
                            } else {
                              // Otherwise, navigate to '/modify'
                              props.setActivePage('update');
                              navigate('/modify');
                            }
                          }}
                        >
                          <FeatherIcon icon="edit" size={14} />
                          &nbsp;{getLang('Update')}
                        </a>
                      </li>
                      {localStorage.getItem('Role') === 'Credit level approver' &&
                        props.activePage != '' && (
                          <>
                            <li className="nav-item">
                              <a
                                className={`nav-link ${
                                  props.activePage === 'approvals' ? ' active' : ''
                                }`}
                                style={{ color: 'black' }}
                                onClick={() => {
                                  props.setActivePage('approvals');
                                  navigate('/approvals');
                                }}
                              >
                                <FeatherIcon icon="check-square" size={14} />
                                &nbsp;{getLang('Approvals')}
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className={`nav-link ${
                                  props.activePage === 'admin' ? ' active' : ''
                                }`}
                                style={{ color: 'black' }}
                                onClick={() => {
                                  // props.setActivePage('admin');
                                  navigate('/allRequests');
                                }}
                              >
                                <FeatherIcon icon="database" size={14} />
                                &nbsp;{getLang('All Tickets')}
                              </a>
                            </li>
                          </>
                        )}
                    </>
                  )}
                {(localStorage.getItem('Role') === 'SPOC' ||
                  localStorage.getItem('Role') === 'CX Approver' ||
                  localStorage.getItem('Role') === 'Process Owner') &&
                  props.activePage != '' && (
                    <>
                      <li className="nav-item" ref={domNode}>
                        <a
                          className={`nav-link ${props.activePage === 'create' ? ' active' : ''}`}
                          style={{ color: 'black' }}
                          onClick={() => {
                            toggleIsCreateDropDownOpen();
                            setIsCustomerCreateDropDownOpen(false);
                          }}
                        >
                          <FeatherIcon icon="file-plus" size={14} />
                          &nbsp;{getLang('Create')}
                          <FeatherIcon icon="chevrons-down" size={14} />
                        </a>
                        {isCreateDropDownOpen && (
                          <div
                            className="dropdown-menu dropdown-menu-left show"
                            //className="dropdown-menu dropdown-menu-right dropdown-menu-arrow show"
                            style={{ cursor: 'pointer' }}
                          >
                            <a
                              className="dropdown-item text-left"
                              href="#"
                              onClick={() => {
                                toggleIsCreateDropDownOpen();
                                navigate('/create/hierarchy');
                                props.setActivePage('hierarchy');
                              }}
                              style={{ color: 'black' }}
                            >
                              <strong>&nbsp;{getLang('Hierarchy Creation')}</strong>
                            </a>
                            <a
                              className="dropdown-item text-left"
                              onClick={() => {
                                toggleIsCreateDropDownOpen();
                                props.setActivePage('plant');
                                navigate('/create/plant');
                              }}
                              style={{ color: 'black' }}
                            >
                              <strong>&nbsp;{getLang('Plant Creation')}</strong>
                            </a>
                            {/* {process.env.REACT_APP_ENV !== 'prod' && (
                              <a
                                className="dropdown-item text-left"
                                onClick={() => {
                                  toggleIsCreateDropDownOpen();
                                  props.setActivePage('extension');
                                }}
                                style={{ color: 'black' }}
                              >
                                <strong>&nbsp;{getLang('Customer Extension')}</strong>
                              </a>
                            )} */}
                            {/* <ul> */}
                            <a
                              // className={`nav-link ${
                              //   props.activePage === 'create' ? ' active' : ''
                              // }`}
                              className="dropdown-item text-left"
                              style={{ color: 'black' }}
                              onClick={() => {
                                toggleIsCustomerCreateDropDownOpen();
                              }}
                            >
                              {/* <FeatherIcon icon="file-plus" size={14} /> */}
                              <strong>&nbsp;{getLang('Customer')}</strong>
                              <FeatherIcon icon="chevrons-right" size={14} />
                            </a>
                            <li className="nav-item">
                              {isCustomerCreateDropDownOpen && (
                                <div
                                  //className="dropdown-item text-left"
                                  className="dropdown-menu dropdown-menu-right show"
                                  // className="dropdown-menu  show"
                                  //className="btn-group dropright"
                                  style={{ cursor: 'pointer' }}
                                >
                                  {/* <button className="subnavbtn">About <i className="fa fa-caret-down"></i></button>
                                <div clasNames="subnav-content">
                                  <a href="#company">Company</a>
                                  <a href="#team">Team</a>
                                  <a href="#careers">Careers</a>
                                </div> */}
                                  <a
                                    className="dropdown-item text-left"
                                    onClick={() => {
                                      toggleIsCreateDropDownOpen();
                                      props.setActivePage('create');
                                      navigate('/create/general');
                                    }}
                                    style={{ color: 'black' }}
                                  >
                                    <strong>&nbsp;{getLang('General Customer')}</strong>
                                  </a>
                                  <a
                                    className="dropdown-item text-left"
                                    onClick={() => {
                                      toggleIsCreateDropDownOpen();
                                      props.setActivePage('internal');
                                      navigate('/create/internal');
                                    }}
                                    style={{ color: 'black' }}
                                  >
                                    <strong>&nbsp;{getLang('Internal Customer')}</strong>
                                  </a>
                                  <a
                                    className="dropdown-item text-left"
                                    onClick={() => {
                                      toggleIsCreateDropDownOpen();
                                      props.setActivePage('vendor');
                                      navigate('/create/vendor');
                                    }}
                                    style={{ color: 'black' }}
                                  >
                                    <strong>&nbsp;{getLang('Vendor Customer')}</strong>
                                  </a>
                                  {/* {process.env.REACT_APP_ENV !== 'prod' && (
                                    <>
                                      <a
                                        className="dropdown-item text-left"
                                        onClick={() => {
                                          toggleIsCreateDropDownOpen();
                                          props.setActivePage('NL create');
                                        }}
                                        style={{ color: 'black' }}
                                      >
                                        <strong>&nbsp;{getLang('NL Customer')}</strong>
                                      </a>
                                      <a
                                        className="dropdown-item text-left"
                                        onClick={() => {
                                          toggleIsCreateDropDownOpen();
                                          props.setActivePage('intercompany');
                                        }}
                                        style={{ color: 'black' }}
                                      >
                                        <strong>&nbsp;{getLang('Inter Company Customer')}</strong>
                                      </a>

                                      <a
                                        className="dropdown-item text-left"
                                        onClick={() => {
                                          toggleIsCreateDropDownOpen();
                                          props.setActivePage('forwardagent');
                                        }}
                                        style={{ color: 'black' }}
                                      >
                                        <strong>&nbsp;{getLang('Forward Agent Customer')}</strong>
                                      </a>
                                    </>
                                  )} */}
                                </div>
                              )}
                            </li>
                            {/* </ul> */}
                          </div>
                        )}
                      </li>

                      <li className="nav-item">
                        <a
                          className={`nav-link ${props.activePage === 'update' ? ' active' : ''}`}
                          style={{ color: 'black' }}
                          onClick={() => {
                            if (props.activePage === 'update') {
                              // If the current page is already 'update', force reload
                              window.location.reload();
                            } else {
                              // Otherwise, navigate to '/modify'
                              props.setActivePage('update');
                              navigate('/modify');
                            }
                          }}
                        >
                          <FeatherIcon icon="edit" size={14} />
                          &nbsp;{getLang('Update')}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${props.activePage === 'admin' ? ' active' : ''}`}
                          style={{ color: 'black' }}
                          onClick={() => {
                            // props.setActivePage('admin');
                            navigate('/allRequests');
                          }}
                        >
                          <FeatherIcon icon="database" size={14} />
                          &nbsp;{getLang('All Tickets')}
                        </a>
                      </li>

                      {(localStorage.getItem('Role') === 'CX Approver' ||
                        localStorage.getItem('Role') === 'Process Owner') &&
                        props.activePage != '' && (
                          <>
                            <li className="nav-item">
                              <a
                                className={`nav-link ${
                                  props.activePage === 'approvals' ? ' active' : ''
                                }`}
                                style={{ color: 'black' }}
                                onClick={() => {
                                  props.setActivePage('approvals');
                                  navigate('/approvals');
                                }}
                              >
                                <FeatherIcon icon="check-square" size={14} />
                                &nbsp;{getLang('Approvals')}
                              </a>
                            </li>

                            {/* <li className="nav-item">
                              <a
                                className={`nav-link ${
                                  props.activePage === 'admin' ? ' active' : ''
                                }`}
                                style={{ color: 'black' }}
                                onClick={() => {
                                  // props.setActivePage('admin');
                                  navigate('/allRequests');
                                }}
                              >
                                <FeatherIcon icon="database" size={14} />
                                &nbsp;{getLang('All Tickets')}
                              </a>
                            </li> */}
                          </>
                        )}
                    </>
                  )}
                {/* <li className="nav-item">
                  <Link to={{ pathname: '/create', createNew: true }} className={`nav-link ${linkName.linkName === 'create' ? 'active' : ''}`}>
                    <FeatherIcon icon="file-plus" size={14} />
                    &nbsp;Create
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={{ pathname: '/update', createNew: true }} className={`nav-link ${linkName.linkName === 'update' ? 'active' : ''}`}>
                    <FeatherIcon icon="edit" size={14} />
                    &nbsp;Update
                  </Link>
                </li> */}

                {accounts.length > 0 && (
                  <>
                    {localStorage.getItem('Role') === 'Process Owner' && (
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            window.location.pathname == '/manage/access' ? ' active' : ''
                          }`}
                          style={{ color: 'black' }}
                          onClick={() => {
                            navigate('/manage/access');
                          }}
                        >
                          <FeatherIcon icon="unlock" size={14} />
                          &nbsp;{getLang('Manage Access')}
                        </a>
                      </li>
                    )}
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* {
        process.env.REACT_APP_ENV == 'prod' ? (
          <div className="header py-4" style={{ background: 'black' }}>
            <div className="container">
              <div className="d-flex justify-content-center">
                <span style={{ color: 'white' }}>
                  Please note that the <strong>App</strong> is moving to a new website. Kindly
                  bookmark the New URL:{' '}
                  <strong>
                    <a href="https://maven.ab-inbev.com/">maven.ab-inbev.com</a>
                  </strong>
                </span>
              </div>
            </div>
          </div>
        ) : null */}

      {/* <div className="header py-4">
        <div className="container">
          <div className="d-flex justify-content-center">
            <span>
              <strong style={{ color: '#e5b611' }}>Important:</strong> The{' '}
              <strong>Save Draft</strong> function will be disabled as it is under maintenance
            </span>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default TopBar;
