export const formTemplate = {
  mainPage: {
    country: '',
    salesOrg: '',
    companyCode: '',
    reqType: '',
    reqPage: '',
    shipToType: '',
  },
  blockUnblock: {
    blockUnblockOpt: '',
    existingShipToID: '',
    checkShipTo: '',
    nameShipTo: '',
    orderBlockShipTo: '',
    deliveryBlockShipTo: '',
    billingBlockShipTo: '',
    existingPayerID: '',
    checkPayer: '',
    namePayer: '',
    orderBlockPayer: '',
    deliveryBlockPayer: '',
    billingBlockPayer: '',
    existingSoldToID: '',
    checkSoldTo: '',
    nameSoldTo: '',
    orderBlockSoldTo: '',
    deliveryBlockSoldTo: '',
    billingBlockSoldTo: '',
    existingBillToID: '',
    checkBillTo: '',
    nameBillTo: '',
    orderBlockBillTo: '',
    deliveryBlockBillTo: '',
    billingBlockBillTo: '',
    supportDocsBank: '',
    supportDocsBankName: '',
  },
  payerUpdate: {
    existingPayerID: '',
    name: '',
    name2: '',
    name3: '',
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    region: '',
    regionDesc: '',
    poBox: '',
    poBoxPostalCode: '',
    telephone: '',
    mobile: '',
    email: '',
    supportDocsBank: '',
    supportDocsBankName: '',
  },
  nameDelivPlantUpdate: {
    existingShipToID: '',
    existingSoldToID: '',
    name: '',
    deliveringPlant: '',
    deliveringPlantDesc: '',
    telephone: '',
    mobile: '',
    email: '',
    shippingCondition: '',
    shippingConditionDesc: '',
    pocSupplier: '',
    pocSupplierDesc: '',
    promoFlag: '',
  },
  vatTaxUpdate: {
    existingPayerID: '',
    existingSoldToID: '',
    name: '',
    payerName: '',
    vatRegNumber: '',
    taxNumber: '',
  },
  ibanUpdate: {
    existingPayerID: '',
    name: '',
    iban: '',
    ibanOpt: '',
    bkType: '',
    accHolderName: '',
    supportDocsBank: '',
    supportDocsBankName: '',
    bankTypes: '',
  },
  eInvPayerUpdate: {
    existingPayerID: '',
    name: '',
    firstName: '',
    lastName: '',
    function: '',
    contactEmail: '',
    contactID: '',
    eInvPayerOpt: '',
  },
  eInvBillUpdate: {
    existingBillToID: '',
    name: '',
    firstName: '',
    lastName: '',
    function: '',
    contactEmail: '',
    contactID: '',
    eInvBillOpt: '',
  },
  othersUpdate: {
    updateOption: '',
    warehouseExistingShipToID: '',
    warehouseShipToName: '',
    warehouseNumber: '',
    warehouseCustomerNumber: '',
    warehouseEdType: '',
    warehouseCompanyCode: '',
    warehouseValidFrom: '',
    warehouseValidTo: '',
    taxWarehouseNumber: '',
    warehouseExternalEdNumber: '',
    warehousePartnerType: '',
    profitCenter: '',
    profitExistingShipToID: '',
    profitShipToName: '',
    glnExistingShipToID: '',
    glnShipToName: '',
    glnShipTo: '',
    glnExistingSoldToID: '',
    glnSoldToName: '',
    glnSoldTo: '',
    glnExistingPayerID: '',
    glnPayerName: '',
    glnPayer: '',
    textUpdateExistingSoldToID: '',
    textUpdateSoldToName: '',
    driverText: '',
    invText: '',
    textDeliveryDay: '',
  },
  othersWarehouseUpdate: {
    existingShipToID: '',
    name: '',
    warehouseNumber: '',
    warehouseCustomerNumber: '',
    warehouseEdType: '',
    warehouseEdTypeDesc: '',
    warehouseCompanyCode: '',
    warehouseValidFrom: '',
    warehouseValidTo: '',
    taxWarehouseNumber: '',
    warehouseExternalEdNumber: '',
    warehousePartnerType: '',
  },
  othersProfitUpdate: {
    profitCenter: '',
    existingShipToID: '',
    name: '',
  },
  othersGlnUpdate: {
    existingShipToID: '',
    shipToName: '',
    glnShipTo: '',
    existingSoldToID: '',
    soldToName: '',
    glnSoldTo: '',
    existingPayerID: '',
    payerName: '',
    glnPayer: '',
  },
  othersTextUpdate: {
    existingSoldToID: '',
    name: '',
    driverText: '',
    invText: '',
    deliveryDay: '',
  },
  ldeUpdate: {
    existingSoldToID: '',
    name: '',
    ldeNumber: '',
  },
  M1mapping: {
    existingShipToID: '',
    name: '',
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    m1: '',
    m2: '',
    shipToType: '',
    truckType: '',
    trailerType: '',
    att7: '',
  },

  routeToMarket: {
    existingShipToID: '',
    name: '',
    street: '',
    // houseNumber :'',
    postalCode: '',
    city: '',
    attributeType5: '',
    deliveringPlant: '',
    pocsupplier: '',
    searchTerm2: '',
    shippingCondition: '',
  },

  paymentTermChange: {
    existingPayerID: '',
    name: '',
    street: '',
    postalCode: '',
    city: '',
    paymentTerms: '',
    paymentTermsDesc: '',
  },

  creditLimit: {
    existingPayerID: '',
    name: '',
    street: '',
    // houseNumber :'',
    postalCode: '',
    city: '',
    riskCode: '',
    riskText: '',
    riskDesc: '',
    risk: '',
    creditLimits: '',
    paymentTerms: '',
    paymentTermsDesc: '',
  },

  acctgClerk: {
    existingPayerID: '',
    name: '',
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    acctgClerks: '',
  },

  priceList: {
    existingPayerID: '',
    name: '',
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    price: '',
    priceCode: '',
    priceText: '',
    priceDesc: '',
  },

  regulatorUpdate: {
    existingPayerID: '',
    name: '',
    street: '',
    // houseNumber :'',
    postalCode: '',
    city: '',
    regulator: '',
  },

  segmentUpdate: {
    existingShipToID: '',
    name: '',
    street: '',
    postalCode: '',
    city: '',
    segment: '',
    subSegment: '',
  },

  truckTrailerUpdate: {
    existingShipToID: '',
    name: '',
    street: '',
    postalCode: '',
    city: '',
    truckType: '',
    trailerType: '',
  },

  paymentMethodsUpdate: {
    existingPayerID: '',
    name: '',
    street: '',
    // houseNumber :'',
    postalCode: '',
    city: '',
    paymentMethods: '',
  },

  hierarchyMapUpdate: {
    existingSoldToID: '',
    name: '',
    street: '',
    postalCode: '',
    city: '',
    hierarchyType: '',
    pricingHierarchy: '',
  },

  addressUpdate: {
    existingShipToID: '',
    // existingPayerID: '',
    name: '',
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    region: '',
    regionDesc: '',
    timeZone: '',
    poBox: '',
    poBoxPostalCode: '',
  },

  deliveryPlan: {
    existingShipToID: '',
    name: '',
    street: '',
    postalCode: '',
    city: '',
    pocsupplier: '',
    deliveringPlant: '',
  },
  exciseDutyUpdate: {
    existingShipToID: '',
    edCustomerNumber: '',
    name: '',
    street: '',
    postalCode: '',
    city: '',
    edType: '',
    edValidFrom: '',
    edValidTo: '',
    taxWarehouseNumber: '',
    externalEdNumber: '',
    partnerType: '',
  },
  closedBlockUnbUpdate: {
    existingShipToID: '',
    name: '',
    street: '',
    postalCode: '',
    city: '',
    orderBlock: '',
    deliveryBlock: '',
    billingBlock: '',
  },
};

export const languages = ['English', 'Espanol', 'Italiano', 'French', 'Dutch', 'German'];
