import { useState, useEffect } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  useEffect(() => {
    sessionStorage.setItem('updateDeliveryPlanUpdate', JSON.stringify(fields));
  }, [fields]);

  return [
    fields,
    function (event) {
      if (event.target.name == 'existingShipToID') {
        
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          name:'',
          street: '',
          postalCode: '',
          city: '',
        });
      } else if (['pocSupplierShipTo','deliveringPlant'].includes(event.target.name)) {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
        });
      } else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
    },
  ];
}
