import React from 'react';
import Select from 'react-select';
const RegionSelect = ({
  errors,
  inputs,
  setInputs,
  getLang,
  regionOptions,
  isDisable,
  isDisableFields,
  creationtype,
}) => {
  return (
    <div className="col-12 col-lg-4">
      <div className="form-group">
        <label className="form-label text-left">{getLang('Region')} *</label>
        <Select
          className="text-left"
          name="region"
          value={regionOptions.filter((opt) => opt.regionCode == inputs.region)}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#cfcfcf',
              primary50: '#cfcfcf',
              primary: 'orange',
            },
          })}
          isClearable
          placeholder="Select"
          isDisabled={isDisableFields || isDisable}
          getOptionLabel={({ regionDesc }) => getLang(regionDesc)}
          getOptionValue={({ regionCode }) => regionCode}
          options={regionOptions}
          onChange={(e, action) => {
            setInputs(e, action);
          }}
        />
        {errors.region && (
          <div className="invalid-feedback-file text-left">{getLang(errors.region)}</div>
        )}
      </div>
    </div>
  );
};

export default RegionSelect;
