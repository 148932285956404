import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import MaterialReactTable from 'material-react-table';
import Chip, { ChipProps } from '@mui/material/Chip';
import { ButtonGroup, Row, Col, Modal, Spinner } from 'react-bootstrap';
import MandatoryText from '../../../components/MandatoryText';
import FeatherIcon from 'feather-icons-react';
import '../../../components/layout/Tables/MaterialReactTable/materialReactTableStyles.scss';
import { validateFields } from './utils/FormValidation';
import { FetchPayerBapi } from '../utils/FlowCallAPI';
import { useFormFields } from './utils/HooksLib';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Typography } from '@mui/material';
import { formTemplate } from '../Data';
import { ibanLength, ibanPlaceholder } from '../../../utils/Utils';
import Button from '@mui/material/Button';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import UpdateIbanDataModal from './UpdateIbanDataModal';
const UpdateCustIbanUpdate = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  const [translations, setTranslations] = useState(props.translations);

  const fileRegex = /\.[^.\\/:*?"<>|\r\n]+$/;

  useEffect(() => {
    // if (!isAuthenticated) navigate('/');
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    console.log(inputs);
    if (
      props.draftTicketNum[0] !== 'M' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected'
    ) {
      setIsDisable(true);
    }
    if (inputs.name != '') setIsDisableFields(false);
  }, []);

  const [inputs, setInputs] = useFormFields(props.formTemplate);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkForward, setLinkForward] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [payerSap, setPayerSap] = useState(inputs.existingPayerID);
  const [ibanSap, setIbanSap] = useState(inputs.iban);
  const [isCopying, setIsCopying] = useState(false);
  const [copyClick, setCopyClick] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const handleClose = () => setShowModal(false);
  const [rowData, setRowData] = useState([]);

  const [isDisableFields, setIsDisableFields] = useState(true);

  const mainPageForm = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')) || {};
  const [tableData, setTableData] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const payerDataRes = FetchPayerBapi(
    inputs.existingPayerID,
    mainPageForm.companyCode,
    mainPageForm.salesOrg,
    isCopying,
  );
  const exportTheme = createTheme({
    palette: {
      primary: {
        main: '#DAA520',
      },
      // secondary: pink,
    },
  });
  let key = Object.keys(rowSelection)?.find((k) => rowSelection[k] === true);
  useEffect(() => {
    console.log('use effect for row change', key);
    if (typeof key !== 'undefined') {
      {
        TABLE_COLUMNS.map((column) => {
          if (column.id in inputs) {
            inputs[column.id] = tableData[key][column.accessorKey];
          }
        });
        sessionStorage.setItem('updateCustomerIbanUpdate', JSON.stringify(inputs));
      }
    }
  }, [rowSelection, key]);
  const TABLE_COLUMNS = [
    {
      accessorKey: 'Z_PAYER_IBAN',
      id: 'iban',
      header: 'IBAN',
      flex: 1,
      columnDefType: 'data',
      cellClassName: 'dashboardCell',
      minWidth: 90,
    },
    {
      accessorKey: 'Z_ACCOUNT_HOLDER_NAME',
      id: 'accHolderName',
      header: 'Account Holder Name',
      flex: 1,
      columnDefType: 'data',
      cellClassName: 'dashboardCell',
      minWidth: 90,
    },
    {
      accessorKey: 'Z_PARTNER_BANK_TYPE',
      id: 'bkType',
      header: 'Bank Type',
      flex: 1,
      columnDefType: 'data',
      cellClassName: 'dashboardCell',
      minWidth: 90,
    },
  ];
  const handleInvUpdateModal = (row) => {
    setCreateModalOpen(true);
    setRowData(row);
    console.log('ROW DATA', rowData);
    setInputs({
      target: { name: 'ibanOpt', value: 'Update Existing' },
    });
  };
  const handleNewRowAdd = (row) => {
    console.log('Add new called');

    setInputs({
      target: { name: 'ibanOpt', value: 'Add New' },
    });
    setCreateModalOpen(true);
  };
  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(validateFields(inputs, false, payerSap, ibanSap));
      setIsSubmitting(true);
    }
  };

  // useEffect(() => {
  //   if (Object.keys(errors).length === 0 && isSubmitting) {
  //     props.setActivePage(linkForward);
  //   }
  // }, [errors]);

  function getLang(id) {
    // console.log(props.translations);
    let obj = translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    setTranslations(props.translations);
  }, [props.translations]);

  function copyPayerData(e) {
    setCopyClick(true);
    setErrors(validateFields(inputs, true, payerSap, ibanSap));
    console.log(`I am true ${isCopying}`);
  }

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && copyClick) {
      console.log(inputs.name, isDisableFields, copyClick);
      setCopyClick(false);
      setIsCopying(true);
      Object.entries(formTemplate.ibanUpdate).forEach(([key, value]) => {
        if (key !== 'existingPayerID') inputs[key] = value;
      });
    }
  }, [errors]);

  useEffect(() => {
    if (Object.keys(payerDataRes.payerData).length !== 0) {
      if (inputs.existingPayerID != '') setPayerSap(inputs.existingPayerID);
      Object.entries(payerDataRes.payerData).forEach(([key, value]) => {
        if (key in inputs) {
          if (key == 'iban') {
            if (value != '') inputs.ibanOpt = 'Update Existing';
            setIbanSap(value);
          }

          inputs[key] = value;
        }
      });
      sessionStorage.setItem('updateCustomerIbanUpdate', JSON.stringify(inputs));
      setIsDisableFields(false);
    }
    if (Object.keys(payerDataRes.payerData).length !== 0) {
      setTableData(
        payerDataRes?.payerData.ibanDetails?.item.map((i, index) => {
          return {
            id: index,
            ...i,
          };
        }),
      );
    }
    setIsCopying(false);
  }, [payerDataRes.payerData]);

  // useEffect(() => {
  //   if (mainPageForm.country != 'Belgium') {
  //     inputs.ibanOpt = 'Update Existing';
  //   }
  // }, []);

  const FileSize = (event) => {
    if (event.target.files[0].size > 1000000) {
      console.log('Hello please reduce', event.target.files[0].size);
      setShowErrorModal(true);
    }
  };

  return (
    <form className="card" onSubmit={handleSubmit}>
      <Modal show={isCopying} centered>
        <Modal.Body>
          <Row>
            <div className="col-auto">
              <Spinner animation="border" variant="warning" />
            </div>
            <div className="text-left">
              <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={inputs.supportDocsBank}></img>
        </Modal.Body>
      </Modal>
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered>
        <Modal.Header>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>The file size is greater than 1MB limit.</Col>
          </Row>
          <Row>
            <Col>Please reduce the file size and try again.</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <div className="col col-md"></div>
            <div className="col-auto col-md-auto text-right">
              <Button
                className="btn-danger btn-block"
                style={{ textDecoration: 'none' }}
                onClick={() => {
                  setShowErrorModal(false);
                }}
              >
                OK
              </Button>
            </div>
          </Row>
        </Modal.Footer>
      </Modal>
      <div className="card-header">
        <div className="col-12">
          <h3 className="card-title text-left">
            <strong>Customer Data {getLang('Update')}</strong>
          </h3>
        </div>
      </div>
      <Row className="sidebar-row">
        <div className="col-12">
          <div className="card-body">
            <Row>
              <Col sm={12} lg="auto">
                <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('IBAN change/update')}</h2>
              </Col>
            </Row>

            <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">
                        {getLang('Existing Payer ID')} *
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control${errors.existingPayerID ? ' is-invalid' : ''}`}
                          name="existingPayerID"
                          maxLength="8"
                          placeholder={`Enter ${getLang('Existing Payer ID')}`}
                          value={inputs.existingPayerID}
                          onChange={setInputs}
                          disabled={isDisable}
                        />
                        <span className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="button"
                            style={{
                              borderTopRightRadius: '3px',
                              borderBottomRightRadius: '3px',
                              marginTop: '-2px',
                            }}
                            onClick={copyPayerData}
                            disabled={isDisable || inputs.existingPayerID == ''}
                          >
                            {getLang('Copy')}
                          </button>
                        </span>
                        {errors.existingPayerID && (
                          <div className="invalid-feedback text-left">{errors.existingPayerID}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Name')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                        name="name"
                        placeholder={`${getLang('Name')}`}
                        value={inputs.name}
                        onChange={setInputs}
                        readOnly
                      />
                      {errors.name && (
                        <div className="invalid-feedback text-left">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Country')}</label>
                      <input
                        type="text"
                        className="form-control"
                        value={mainPageForm.country}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="materialReactTableWrapper">
                    <MaterialReactTable
                      enableRowSelection
                      onRowSelectionChange={setRowSelection}
                      state={{ rowSelection }}
                      enableSelectAll={false}
                      data={tableData}
                      columns={TABLE_COLUMNS}
                      enableGlobalFilter={false}
                      enableSorting={false}
                      enableStickyHeader
                      enableHiding={false}
                      enableDensityToggle={false}
                      enableFullScreenToggle={false}
                      enableFilters={false}
                      enableMultiRowSelection={false}
                      positionToolbarAlertBanner="none"
                      // onEditingRowSave={handleSaveRowEdits}
                      // onEditingRowCancel={handleCancelRowEdits}
                      renderTopToolbarCustomActions={(row) => (
                        <div style={{ padding: '4px 10px' }}>
                          <>
                            <Box
                              sx={{
                                display: 'flex',
                                //  gap: '1rem', flexWrap: 'wrap'
                              }}
                            >
                              <ThemeProvider theme={exportTheme}>
                                <Button
                                  color="secondary"
                                  onClick={() => handleInvUpdateModal(row)}
                                  variant="contained"
                                  startIcon={<EditOutlinedIcon style={{ color: 'black' }} />}
                                  style={{ backgroundColor: '#f7a21d', color: 'black' }}
                                  disabled={typeof key === 'undefined'}
                                >
                                  Edit
                                </Button>
                                <Button
                                  color="secondary"
                                  onClick={() => handleNewRowAdd(row)}
                                  variant="contained"
                                  startIcon={<UploadFileIcon style={{ color: 'black' }} />}
                                  style={{
                                    backgroundColor: '#f7a21d',
                                    color: 'black',
                                    marginLeft: '1rem',
                                  }}
                                  disabled={inputs.name === ''}
                                >
                                  Add New
                                </Button>
                              </ThemeProvider>
                            </Box>
                          </>
                        </div>
                      )}
                    />
                    <UpdateIbanDataModal
                      columns={TABLE_COLUMNS}
                      open={createModalOpen}
                      rowData={tableData}
                      onClose={() => setCreateModalOpen(false)}
                      getLang={getLang}
                      inputs={inputs}
                      setInputs={setInputs}
                      showModal={showModal}
                      setShowModal={setShowModal}
                      errors={errors}
                      setErrors={setErrors}
                      isDisable={isDisable}
                      handleSubmit={handleSubmit}
                      activeRow={key}
                      FileSize={FileSize}
                      // onSubmit={handleCreateNewRow}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <br />

      <div className="card-footer">
        <div className="row">
          {!isDisable && (
            <div className="col-auto col-md-auto text-left">
              <Button variant="outline-warning" onClick={props.saveDraft}>
                Save Draft
              </Button>
            </div>
          )}
          <div className="col col-md text-left">
            <span className="align-middle">{props.draftMsg}</span>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="mainPage"
              data-back="true"
              className="btn btn-warning btn-block"
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Back
            </Link>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="ibanUpdateReview"
              className={`btn btn-danger btn-block${
                inputs.existingPayerID == '' || inputs.name == '' || ibanSap == inputs.iban
                  ? ' disabled'
                  : ''
              }`}
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Review
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateCustIbanUpdate;
