import { useState, useEffect } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);
  // console.log("PROFILE DATA",fields);
  useEffect(() => {
    sessionStorage.setItem('createCustomerProfileData', JSON.stringify(fields));
  }, [fields]);

  return [
    fields,
    function (event, action) {
      if (action?.name == 'region') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            region: '',
            regionDesc: '',
            erpTranspZone: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            region: event.regionCode,
            regionDesc: event.regionDesc,
            erpTranspZone: event.erpTranspZone,
          });
        }
      } else if (['language'].includes(event.target.name)) {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
        });
      } else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
    },
  ];
}
