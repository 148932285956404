const prod = {
  url: {
    fetchPricingHierarchiesURL: 'https://mavenapis.ab-inbev.com/api/data/pricinghierarchies',
  },
};
const test = {
  url: {
    fetchPricingHierarchiesURL: 'https://mavenapistest.ab-inbev.com/api/data/pricinghierarchies',
  },
};
const local = {
  url: {
    fetchPricingHierarchiesURL: 'http://localhost:1234/api/data/pricinghierarchies',
  },
};
const configData =
  process.env.REACT_APP_ENV === 'prod'
    ? prod.url
    : process.env.REACT_APP_ENV === 'test'
    ? test.url
    : local.url;
export const soldToNamesWhs = [
  'K&PD_ALL_POCM (Name Merger)',
  'BC_ALL (Name Buying Group)',
  'K&PD_ALL_POCM_3PP (Name Merger)',
  'ALL_POCM',
  '3PP',
  'HTS',
  '3PP-HTS',
];

export const priceLists = [
  {
    code: 'P1',
    value: 'ALKO',
  },
  {
    code: 'P2',
    value: 'HEINON TUKKU',
  },
  {
    code: 'P3',
    value: 'KESKO',
  },
  {
    code: 'P4',
    value: 'KESPRO',
  },
  {
    code: 'P5',
    value: 'LIDL',
  },
  {
    code: 'P6',
    value: 'ME GROUP',
  },
  {
    code: 'P7',
    value: 'MEIRA NOVA',
  },
  {
    code: 'P8',
    value: 'MINIMANI',
  },
  {
    code: 'P9',
    value: 'PM-JUOMATUKKU',
  },
  {
    code: 'PA',
    value: 'SOK',
  },
  {
    code: 'PB',
    value: 'TOKMANNI',
  },
  {
    code: 'PC',
    value: 'VELJ. KESKINEN',
  },
  {
    code: 'PD',
    value: 'WIHURI',
  },
  {
    code: 'PE',
    value: 'SUOMEN ALKOHOLITUKKU',
  },
  {
    code: 'PF',
    value: 'Kesko Direct',
  },
  {
    code: 'PG',
    value: 'On-Trade Direct',
  },
  {
    code: 'PL',
    value: 'Domestic',
  },
];

export default configData;
