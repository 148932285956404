import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IconInfoSquareRounded } from '@tabler/icons-react';
import { Card, CardDeck, Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import TopBar from '../../../components/layout/TopBar';
import FeatherIcon from 'feather-icons-react';
import { MultiSelect } from '@mantine/core';
import { FetchTranslations } from '../../FlowCallAPI';
import { FetchCountries } from '../../createcustomer/mainpage/FlowCallAPI';
import { useFormFields } from './FormHook';
import { validateFields } from './FormValidation';
import adminService from '../../../utils/adminService';
import { useMsal } from '@azure/msal-react';
import { adminUsers } from '../../../utils/Utils';

const AccessPage = () => {
  const [lang, setLang] = useState(localStorage.getItem('appLang') || 'en');

  const navigate = useNavigate();

  const { instance, accounts } = useMsal();

  const fetchTranslationsRes = FetchTranslations(lang);

  const countriesRes = FetchCountries();

  const [showModal, setShowModal] = useState(false);

  const [modalMsg, setModalMsg] = useState('');

  const [errors, setErrors] = useState({});

  const handleMultiSelectChange = (value) => {
    console.log(value);
    const selectedValues = value.toString();
    setInputs({
      target: { name: 'country', value: selectedValues },
    });
  };

  const handleMultiSelectChannelChange = (value) => {
    console.log(value);
    const selectedValues = value.toString();
    setInputs({
      target: { name: 'approver_channel', value: selectedValues },
    });
  };

  const [inputs, setInputs] = useFormFields({
    role: '',
    country: '',
    approver_role: '',
    approver_channel: '',
    email: '',
  });

  const handleSubmit = (e) => {
    // setErrors(validateFields(inputs));
    // setIsSubmit(true)
    if (Object.keys(validateFields(inputs)).length > 0) {
      setErrors(validateFields(inputs));
    } else {
      addUserAccess(inputs);
    }
  };

  function addUserAccess(inputs) {
    adminService
      .addUserAccessRoute(
        inputs.role,
        inputs.country,
        inputs.email,
        inputs.approver_role,
        inputs.approver_channel,
      )
      .then((res) => {
        console.log(res?.result);
        setModalMsg(res?.result);
        setShowModal(true);
      })
      .catch((err) => {
        console.log(err.response);
        setModalMsg(err.response?.data?.error);
        setShowModal(true);
      });
  }
  const countryOptions = countriesRes.countries.map((val) => {
    return val.country;
  });

  function removeUserAccess(inputs) {
    adminService
      .removeUserAccessRoute(inputs.accessType, inputs.country, inputs.email)
      .then((res) => {
        console.log(res?.result);
        setModalMsg(res?.result);
        setShowModal(true);
      })
      .catch((err) => {
        console.log(err.response);
        setModalMsg(err.response?.data?.error);
        setShowModal(true);
      });
  }

  return (
    <>
      <div className="page">
        <div className="flex-fill">
          <div className="my-3 my-md-5" style={{ paddingRight: '1.5rem', paddingLeft: '1.5rem' }}>
            <Modal show={showModal} centered>
              <Modal.Header>
                <Modal.Title>Information</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>{modalMsg}</p>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => setShowModal(false)}
                >
                  {`OK`}
                </button>
              </Modal.Footer>
            </Modal>
            <Container>
              <Row>
                <Col>
                  <Card>
                    <Card.Header>
                      <Row
                        style={{
                          paddingTop: '0.5rem',
                          paddingBottom: '0.5rem',
                        }}
                      >
                        <Col xs={12} className="d-flex">
                          <h3
                            className="card-title text-left red-text"
                            style={{
                              marginTop: 'auto',
                              marginBottom: 'auto',
                            }}
                          >
                            <strong>{`Manage Access`}</strong>
                          </h3>
                        </Col>
                      </Row>
                    </Card.Header>
                    {accounts.length > 0 && (
                      <>
                        <Card.Body>
                          <Row>
                            <Col xs={12} md={4}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {`Role`}
                                  <span className="red-text">&nbsp;*</span>
                                </label>
                                <select
                                  className={`form-control ${
                                    errors.role ? 'is-invalid-select' : ''
                                  }`}
                                  name="role"
                                  value={inputs.role}
                                  onChange={setInputs}
                                >
                                  <option value="">Select</option>
                                  <option value="Requestor">Requestor</option>
                                  <option value="SPOC">SPOC</option>
                                  <option value="CX Approver">CX Approver</option>
                                  <option value="Credit level approver">
                                    Credit level approver
                                  </option>
                                  <option value="Process Owner" style={{ color: 'red' }}>
                                    Process Owner
                                  </option>
                                </select>
                                {errors.role && (
                                  <div className="invalid-feedback-file text-left">
                                    {errors.role}
                                  </div>
                                )}
                              </div>
                            </Col>
                            {(inputs.role == 'CX Approver' ||
                              inputs.role == 'Credit level approver') && (
                              <Col xs={12} md={4}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {`Approver Role`}
                                    <span className="red-text">&nbsp;*</span>
                                  </label>
                                  <select
                                    className={`form-control ${
                                      errors.approver_role ? 'is-invalid-select' : ''
                                    }`}
                                    name="approver_role"
                                    value={inputs.approver_role}
                                    onChange={setInputs}
                                  >
                                    <option value="">Select</option>
                                    <option value="CX">CX Approver</option>
                                    <option value="Cr Manager">Credit Manager</option>
                                    <option value="Second Cr Manager">
                                      Second level Credit Manager
                                    </option>
                                    <option value="Risk Advisor">Risk Advisor</option>
                                    <option value="Retail Manager" style={{ color: 'red' }}>
                                      Retail Manager (BE Only)
                                    </option>
                                    <option
                                      value="National Customer Manager"
                                      style={{ color: 'red' }}
                                    >
                                      National Customer Manager (BE Only)
                                    </option>
                                  </select>
                                  {errors.approver_role && (
                                    <div className="invalid-feedback-file text-left">
                                      {errors.approver_role}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            )}
                            {inputs.role != 'Process Owner' && (
                              // <Col xs={12} md={4}>
                              //   <div className="form-group">
                              //     <label className="form-label text-left">
                              //       {`Country`}
                              //       <span className="red-text">&nbsp;*</span>
                              //     </label>
                              //     <select
                              //       className={`form-control ${
                              //         errors.country ? 'is-invalid-select' : ''
                              //       }`}
                              //       name="country"
                              //       value={inputs.country}
                              //       onChange={setInputs}
                              //     >
                              //       <option value="">Select</option>
                              //       <option value="GCC">GCC</option>
                              //       {countriesRes.countries.map((val) => (
                              //         <option key={val.countryCode} value={val.countryCode}>
                              //           {val.country}
                              //         </option>
                              //       ))}
                              //     </select>
                              //     {errors.country && (
                              //       <div className="invalid-feedback-file text-left">
                              //         {errors.country}
                              //       </div>
                              //     )}
                              //   </div>
                              // </Col>

                              <Col xs={12} md={4}>
                                <label className="form-label text-left">
                                  {`Countries`}
                                  <span className="red-text">&nbsp;*</span>
                                </label>
                                <MultiSelect
                                  data={countryOptions}
                                  onChange={handleMultiSelectChange}
                                  value={inputs.country}
                                  placeholder="Belgium, Netherlands, ..."
                                  clearButtonProps={{ 'aria-label': 'Clear selection' }}
                                  clearable
                                />
                                {errors.country && (
                                  <div className="invalid-feedback-file text-left">
                                    {errors.country}
                                  </div>
                                )}
                              </Col>
                            )}
                            {inputs.country.includes('Netherlands') &&
                              (inputs.role == 'CX Approver' ||
                                inputs.role == 'Credit level approver') && (
                                <Col xs={12} md={4}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {`Channel`}
                                      <span className="red-text">&nbsp;*</span>
                                    </label>
                                    <MultiSelect
                                      data={['On Trade', 'Off Trade']}
                                      onChange={handleMultiSelectChannelChange}
                                      value={inputs.approver_channel}
                                      placeholder="On Trade, Off Trade...."
                                      clearButtonProps={{ 'aria-label': 'Clear selection' }}
                                      clearable
                                    />

                                    {errors.approver_channel && (
                                      <div className="invalid-feedback-file text-left">
                                        {errors.approver_channel}
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              )}
                            <Col xs={12} md={4}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {`Email ID`}
                                  <span className="red-text">&nbsp;*</span>
                                </label>
                                <input
                                  type="email"
                                  className={`form-control ${
                                    errors.email ? 'is-invalid-select' : ''
                                  }`}
                                  name="email"
                                  value={inputs.email}
                                  onChange={setInputs}
                                  placeholder="Eg: abc@ab-inbev.com"
                                />
                                {errors.email && (
                                  <div className="invalid-feedback-file text-left">
                                    {errors.email}
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer>
                          <Row>
                            <div className="col col-md text-right"></div>
                            <div className="col-auto col-md-auto text-right">
                              <button
                                type="button"
                                className={`btn btn-${
                                  Object.keys(validateFields(inputs)).length == 0
                                    ? 'danger'
                                    : 'secondary'
                                } btn-block`}
                                onClick={handleSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </Row>
                        </Card.Footer>
                      </>
                    )}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessPage;
