import { useState, useEffect } from 'react';
import { formTemplate } from '../Data';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  useEffect(() => {
    sessionStorage.setItem('createCustomerMainPage', JSON.stringify(fields));
  }, [fields]);

  return [
    fields,
    function (event, action) {
      if (action?.name == 'm1') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            m1: '',
            m2: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            m1: event.m1,
            m2: event.m2,
          });
        }
      } else if (event.target.name == 'country') {
        sessionStorage.setItem('createCustomerShipTo', JSON.stringify(formTemplate.plantData));
        setValues({
          ...fields,
          country: event.target.value,
          salesOrg: event.target.selectedOptions[0].getAttribute('data-salesorg'),
          companyCode: event.target.selectedOptions[0].getAttribute('data-companycode'),
        });
      }  else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
        });
      }
    },
  ];
}


// if (event.target.name === 'country' && event.target.value === '') {
//   console.log('Active');
//   setValues({
//     ...fields,
//     country: event.target.value,
//     m2: event.target.value,
//   });
// } else 

// else if (event.target.name === 'm1' && event.target.value !== '') {
//   setValues({
//     ...fields,
//     m1: event.target.value,
//     m2: event.target.selectedOptions[0].getAttribute('data-m2'),
//   });
// } else if (event.target.name === 'm1' && event.target.value === '') {
//   console.log('I see');
//   setValues({
//     ...fields,
//     m1: event.target.value,
//     m2: event.target.value,
//   });
// }