import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import TopBar from '../../../components/layout/TopBar';
import { Button, ButtonGroup, Row, Col, Modal, Spinner } from 'react-bootstrap';

import { validateFields as mainPageValidate } from '../mainpage/FormValidation';
import { validateFields as profilePageValidate } from '../profile/FormValidation';
import { PostToProfileDB } from './FlowCallAPI';

const CreateCustReview = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) navigate('/');
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  const [isSave, setIsSave] = useState(false);

  const [isSaveRequest, setIsSaveRequest] = useState(false);

  const fileRegex = /\.[^.\\/:*?"<>|\r\n]+$/;

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  const mainPageForm =
    sessionStorage.getItem('createCustomerMainPage') === null
      ? {}
      : JSON.parse(sessionStorage.getItem('createCustomerMainPage'));
  const profileDataForm =
    sessionStorage.getItem('createCustomerProfileData') === null
      ? {}
      : JSON.parse(sessionStorage.getItem('createCustomerProfileData'));

  const PostToProfileDBRes = PostToProfileDB(
    props.accounts.length > 0 ? props.accounts[0].username : '',
    props.draftTicketNum,
    // true,
    isSaveRequest,
  );

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    props.setActivePage(e.target.getAttribute('data-link'));
  };

  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const handleClose = () => setShowModal(false);

  const [showSubmitModal, setShowSubmitModal] = useState(false);

  const [mainPageErrors, setMainPageErrors] = useState({});
  const [profileDataErrors, setProfilePageErrors] = useState({});

  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleSave = () => {
    // setShowSubmitModal(true);
    setIsSave(true);
    setMainPageErrors(mainPageValidate(mainPageForm));
    setProfilePageErrors(profilePageValidate(profileDataForm));
    // setIsSaveRequest(true);
    // props.saveDraft();
  };

  useEffect(
    () => {
      console.log(
        `${JSON.stringify(mainPageErrors)} ${JSON.stringify(mainPageErrors)} ${JSON.stringify(
          mainPageErrors,
        )} ${JSON.stringify(profileDataErrors)} ${JSON.stringify(profileDataErrors)} ${isSave}`,
      );
      if (
        Object.keys(mainPageErrors).length == 0 &&
        Object.keys(profileDataErrors).length == 0 &&
        isSave
      ) {
        console.log('Can save');
        props.saveDraft();
      } else if (
        Object.keys(mainPageErrors).length != 0 ||
        Object.keys(profileDataErrors).length != 0
      ) {
        console.log('Found errors');
        setShowErrorModal(true);
        setIsSave(true);
      } else setIsSave(false);
    },
    [profileDataErrors, mainPageErrors],
    isSave,
  );

  function getLang(id) {
    // console.log(props.translations);
    let obj = props.translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    if (props.draftMsg !== '') {
      console.log('DRAFT MESSAGE', props.draftMsg);
      setIsSaveRequest(true);
    }
  }, [props.draftMsg]);

  useEffect(() => {
    setIsSaveRequest(false);
    setIsSave(false);
    console.log('Sharepoint', Object.keys(PostToProfileDBRes.result));
    if (Object.keys(PostToProfileDBRes.result).length !== 0) {
      props.setActivePage('success');
    } else if (PostToProfileDBRes.isError) {
      props.setActivePage('error');
    }
  }, [PostToProfileDBRes.result, PostToProfileDBRes.isError]);

  useEffect(() => {
    if (
      props.draftTicketNum[0] !== 'D' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected' &&
      props.status !== 'Duplicate'
    ) {
      setIsDisable(true);
    }
  }, []);
  console.log('MODA', isSave);
  return (
    isAuthenticated && (
      <form className="card">
        <Modal show={isSave} centered>
          <Modal.Body>
            <Row>
              <div className="col-auto">
                <Spinner animation="border" variant="warning" />
              </div>
              <div className="text-left">
                <span className="align-middle">
                  &nbsp;Please wait while your request is submitted
                </span>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered>
          <Modal.Header>
            <Modal.Title>Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                Errors found in:
                <ul>
                  {Object.keys(mainPageErrors).length != 0 && <li>Main Page</li>}
                  {Object.keys(profileDataErrors).length != 0 && <li>Profile Page</li>}
                </ul>
                Please correct them and try again.
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <div className="col col-md"></div>
              <div className="col-auto col-md-auto text-right">
                <Button
                  className="btn-danger btn-block"
                  style={{ textDecoration: 'none' }}
                  onClick={() => {
                    setShowErrorModal(false);
                  }}
                >
                  OK
                </Button>
              </div>
            </Row>
          </Modal.Footer>
        </Modal>
        <div className="card-header">
          <div className="col-11 col-lg-12">
            <h3 className="card-title text-left">
              <strong style={{ color: 'rgb(177, 31, 36)' }}>
                {getLang('Internal Customer Creation')}
              </strong>{' '}
              - {getLang('Review')}
            </h3>
          </div>
          <a
            className={`sidebar-toggler col-auto ${isSidebarOpen ? '' : 'collapsed'}`}
            onClick={togglingSidebar}
          >
            <span className="sidebar-toggler-icon"></span>
          </a>
        </div>
        <Row className="sidebar-row">
          <div className="col-12 col-lg-10">
            <div className="card-body text-left">
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col sm={12} lg={4}>
                      <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Main Page')}</h2>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Country')}</label>
                        <div className="form-control-textfield">{mainPageForm.country}</div>
                      </div>
                    </Col>
                  </Row>

                  <hr />

                  <Row>
                    <Col xs={6} lg={2}>
                      <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Profile')}</h2>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} lg={4}>
                      <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Name')}</label>
                        <div className="form-control-textfield">{profileDataForm.name}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Name')} 2</label>
                        <div className="form-control-textfield">{profileDataForm.name2}</div>
                      </div>
                    </Col>
                    {/* <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Personal Number (If Any)')}
                        </label>
                        <div className="form-control-textfield">{profileDataForm.searchterm2}</div>
                      </div>
                    </Col> */}
                    {/* <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('VAT Registration No')}
                        </label>
                        <div className="form-control-textfield">{profileDataForm.vatRegNumber}</div>
                      </div>
                    </Col> */}
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Postal Code')}</label>
                        <div className="form-control-textfield">{profileDataForm.postalCode}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Street')}</label>
                        <div className="form-control-textfield">{profileDataForm.street}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('House Number')}</label>
                        <div className="form-control-textfield">{profileDataForm.houseNumber}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('City')}</label>
                        <div className="form-control-textfield">{profileDataForm.city}</div>
                      </div>
                    </Col>
                    {/* <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Country')}</label>
                        <div className="form-control-textfield">{profileDataForm.country}</div>
                      </div>
                    </Col> */}
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Region')}</label>
                        <div className="form-control-textfield">{profileDataForm.regionDesc}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Language')}</label>
                        <div className="form-control-textfield">{profileDataForm.language}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Telephone')}</label>
                        <div className="form-control-textfield">{profileDataForm.telephone}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Mobile')}</label>
                        <div className="form-control-textfield">{profileDataForm.mobile}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('E-mail Address')}</label>
                        <div className="form-control-textfield">{profileDataForm.email}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Contact Person')}</label>
                        <div className="form-control-textfield">
                          {profileDataForm.contactPerson}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col xs={12} lg={4}>
                      <h3 style={{ color: '#e0702f' }}>{getLang('Sales Data')}</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Delivering Plant')}
                        </label>
                        <div className="form-control-textfield">
                          {profileDataForm.deliveringPlant}
                        </div>
                      </div>
                    </Col>
                    {mainPageForm.country == 'Italy' && (
                      <>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Truck Type')}</label>
                            <div className="form-control-textfield">
                              {profileDataForm.truckType}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Trailer Type')}
                            </label>
                            <div className="form-control-textfield">
                              {profileDataForm.trailerType}
                            </div>
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
              <br />
            </div>
          </div>
        </Row>

        {/* <br /> */}

        <div className="card-footer">
          <div className="row">
            <div className="col col-md"></div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link="mainpage"
                className="btn btn-warning btn-block"
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Back
              </Link>
            </div>
            {!isDisable && (
              <div className="col-auto col-md-auto text-right">
                <Link
                  data-link="success"
                  className="btn btn-danger btn-block"
                  style={{ textDecoration: 'none' }}
                  onClick={handleSave}
                >
                  {getLang('Submit')}
                </Link>
              </div>
            )}
          </div>
        </div>
      </form>
    )
  );
};

export default CreateCustReview;
